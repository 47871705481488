<template>
    <v-container fluid>
        <v-container fluid class="mt-10" v-if="mainOverlay">
            <v-progress-linear indeterminate
                               color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container fluid v-else>
            <v-row>
                <v-col cols="12" md="12" sm="12">
                    <v-overlay :value="loadingOverlay" color="white" z-index="999">
                        <v-progress-circular
                            color="wellis"
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-card color="transparent2">
                        <v-card-title>
                            <v-row>
                                <v-col cols="12" sm="12" offset-lg="8" lg="4">
                                    <v-text-field
                                        v-model="dt.search"
                                        color="wellis"
                                        append-icon="mdi-magnify"
                                        label="Search"
                                        single-line
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table
                                :locale="$store.getters['langStore/current']"
                                disable-pagination
                                :items-per-page="-1"
                                hide-default-footer
                                :headers="dt.headers"
                                :items="dt.items"
                                item-class="wellis"
                                :search="dt.search">

                                <template v-slot:header.actions>
                                    <v-btn color="wellis-text-white mr-2" small @click="fetch">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </template>


                                <template v-slot:item.username="{ item }">
                                    <v-btn color="wellis" text
                                           class="font-weight-bold"
                                           :to="{name: 'app.admin.users.fnd.lite-users', params: {fndUserId: item.fnd_user_id}, query: {search: item.username}}">
                                        <v-icon class="mr-2">mdi-open-in-new</v-icon>
                                        {{item.username}}

                                    </v-btn>
                                </template>

                                <template v-slot:item.fnd_user_email="{ item }">
                                    <v-btn color="wellis" text small
                                           class="font-weight-bold"
                                           :to="{name: 'app.admin.users.fnd', query: {search: item.fnd_user_email}}">
                                        <v-icon class="mr-2">mdi-open-in-new</v-icon>
                                        {{item.fnd_user_email}}
                                    </v-btn>
                                </template>

                                <template v-slot:item.fnd_user_customer_no="{ item }">
                                    <v-btn color="wellis" text small
                                           class="font-weight-bold"
                                           :to="{name: 'app.admin.users.fnd', query: {search: item.fnd_user_customer_no}}">
                                        <v-icon class="mr-2">mdi-open-in-new</v-icon>
                                        {{item.fnd_user_customer_no}}
                                    </v-btn>
                                </template>

                                <template v-slot:item.notify_email="{ item }">
                                    <v-chip small class="wellis-text-white wellis">{{item.notify_email}}</v-chip>
                                </template>

                                <template v-slot:item.created_at="{ item }">
                                    {{item.created_at | formatDate(true)}}
                                </template>

                                <template v-slot:item.updated_at="{ item }">
                                    {{item.updated_at | formatDate(true)}}
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import AppSys_FndUserApi from '../../../../../api/app-sys/fnduser.api';
import {EVENT_PAGE_TITLE_CHANGE_NO_TRANS} from '../../../../../core/constants';

export default {
    name: 'AdminLiteUsersOverview.vue',

    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE_NO_TRANS, 'Lite Users'), 100);
        this.fetch();
    },

    data() {
        return {
            mainOverlay: true,
            loadingOverlay: true,

            dt: {
                search: null,
                headers: [
                    {text: '', align: 'start', sortable: false, value: 'actions'},
                    {text: this.$t('v32.components.account.liteUsers.username'), align: 'start', sortable: true, value: 'username'},
                    {text: this.$t('v32.components.account.liteUsers.notifyEmail'), align: 'start', sortable: true, value: 'notify_email'},
                    {text: 'Main user identifier', align: 'start', sortable: true, value: 'fnd_user_email'},
                    {text: 'Main user customer no', align: 'start', sortable: true, value: 'fnd_user_customer_no'},
                    {text: 'Main user firstname', align: 'start', sortable: true, value: 'fnd_user_firstname'},
                    {text: 'Main user lastname', align: 'start', sortable: true, value: 'fnd_user_lastname'},
                    {text: this.$t('createdAt'), align: 'start', sortable: true, value: 'created_at'},
                    {text: this.$t('v32.updatedAt'), align: 'start', sortable: true, value: 'updated_at'},
                ],
                items: [],
            },
        };
    },

    methods: {
        fetch() {
            return new Promise((resolve, reject) => {
                this.loadingOverlay = true;
                AppSys_FndUserApi.liteUsers.allAsAdmin()
                    .then(response => {
                        this.dt.items = response.data.items;
                        this.fndUserId = this.$route.params.fndUserId;
                        resolve();
                    })
                    .catch(e => {
                        this.$ApplGeneral({
                            content: this.$t('api-error'),
                        });
                        reject(e);
                    })
                    .finally(() => {
                        this.mainOverlay = false;
                        this.loadingOverlay = false;
                    });
            });
        },
    },

};
</script>

<style scoped>

</style>