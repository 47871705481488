<template>
    <v-container>
        <v-alert
            border="left" colored-border :color="info.variant" elevation="2" v-if="this.info.text">
            {{$t(this.info.text)}}
        </v-alert>
        <div>
            <v-card light elevation="2">
                <v-overlay :value="loadingOverlay" absolute z-index="999">
                    <v-progress-circular
                        indeterminate
                        size="64"
                    ></v-progress-circular>
                </v-overlay>
                <v-card-text class="pa-10">
                    <div class="subheading text-center text-h5 color-wellis font-weight-bold mb-5">
                        <template>{{$t('components.login.title')}}</template>
                    </div>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <p class="text-center">
                                {{$t('v32.components.login.selectAuthMethod')}}
                            </p>
                            <v-btn class="float-left"
                                   color="wellis"
                                   :outlined="loginType !== 'default'"
                                   :class="loginType === 'default' ? 'wellis-text-white' : ''"
                                   x-large
                                   style="min-width: 40%;"
                                   @click="setAuthMethod('default')">
                                <v-icon>mdi-account-circle</v-icon>
                                MAIN
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn class="float-right"
                                   color="wellis"
                                   :class="loginType === 'lite' ? 'wellis-text-white' : ''"
                                   :outlined="loginType !== 'lite'"
                                   x-large
                                   style="min-width: 40%;"
                                   @click="setAuthMethod('lite')">
                                <v-icon>mdi-account-child-circle</v-icon>
                                LITE
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-form class="mt-5" @submit="formSubmit" lazy-validation ref="loginForm" v-model="formValid"
                            v-if="loginType === 'default'">
                        <v-text-field v-for="input in inputs" :key="input.key"
                                      :prepend-icon="input.icon"
                                      :type="input.type"
                                      :label="$t(input.placeholder)"
                                      v-model="credentials[input.model]"
                                      class="text-input-wellis"
                                      color="black"
                                      :rules="input.rules"
                                      :append-icon="input.appendIcon"
                                      v-on:click:append="input.onAppendIconClick"
                                      required
                                      autocomplete="off"/>
                        <v-btn class="mt-5" block="block" type="submit">{{
                                $t('components.login.button.login')
                            }}
                        </v-btn>
                    </v-form>
                    <v-form class="mt-5" v-if="loginType === 'lite'" @submit="formSubmit" lazy-validation ref="loginForm" v-model="formValid">
                        <v-text-field v-for="input in alternativeInputs" :key="input.key"
                                      :prepend-icon="input.icon"
                                      :type="input.type"
                                      :label="$t(input.placeholder)"
                                      v-model="liteUserCredentials[input.model]"
                                      class="text-input-wellis"
                                      color="black"
                                      :rules="input.rules"
                                      required
                                      :append-icon="input.appendIcon"
                                      v-on:click:append="input.onAppendIconClick"
                                      autocomplete="off"/>
                        <v-btn class="mt-5" block="block" type="submit">{{
                                $t('components.login.button.login')
                            }}
                        </v-btn>
                    </v-form>
                    <v-divider class="mt-5 mb-3"/>
                    <v-row no-gutters v-if="loginType === 'default'">
                        <v-col sm="12">
                            <v-btn text small color="wellis"
                                   @click="resetPassword.dialog = true">{{$t('components.login.forgot-password')}}
                            </v-btn>
                        </v-col>
                        <v-col sm="12">
                            <v-btn text small color="wellis"
                                   :to="{name: 'app.guest.reset-password'}">{{$t('v31.reset-password.link-text')}}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3" no-gutters>
                        <v-col offset-md="6" md="6" sm="12">
                            <LanguagePicker :guest-mode="true"/>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
        <v-dialog
            v-model="resetPassword.dialog"
            max-width="600"
        >
            <v-card>
                <v-card-title class="text-h5">{{$t('components.login.forgot-password')}}</v-card-title>
                <v-card-text class="text-center">
                    <v-text-field prepend-icon="mdi-account"
                                  type="email"
                                  :label="$t('components.login.inputs.username.placeholder')"
                                  v-model="resetPassword.username"
                                  class="text-input-wellis"
                                  color="wellis"
                                  required
                                  autocomplete="off"/>
                    <v-btn
                        color="wellis-text-white" @click="sendResetPassword">
                        {{$t('btnSend')}}
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import LanguagePicker from '../common/LanguagePicker';
import {EVENT_LANG_CHANGE, EVENT_PAGE_TITLE_CHANGE, PATTERN_EMAIL} from '../../core/constants';
import AuthApi from '../../api/auth.api';

export default {
    name: 'Login',
    components: {LanguagePicker},

    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE, 'components.login.title'), 100);
        if (this.$route.query.auth_method && this.loginTypes.map(x => x.key).includes(this.$route.query.auth_method)) {
            this.loginType = this.$route.query.auth_method;
        }
    },

    data() {
        return {

            resetPasswordDialog: false,
            resetPassword: {
                dialog: false,
                username: null,
            },

            loadingOverlay: false,
            disabledLogin: false,
            formValid: true,
            loginTypes: [
                {key: 'default', translate: 'v31.login.FND_USER'},
                {key: 'lite', name: null, translate: 'v3.login.technicalUser'},
            ],
            loginType: null,//'default',
            info: {
                variant: 'wellis',
                text: null,
            },
            credentials: {
                username: null,
                password: null,
            },
            liteUserCredentials: {
                customerNo: null,
                username: null,
                password: null,
            },
            inputs: [
                {
                    key: 'username',
                    type: 'email',
                    class: 'form-control',
                    icon: 'mdi-account',
                    model: 'username',
                    rules: [
                        v => !!v || this.$t('components.login.validation.username.required'),
                        v => PATTERN_EMAIL.test(v) || this.$t('components.login.validation.username.email'),
                    ],
                    placeholder: 'components.login.inputs.username.placeholder',
                    autocomplete: 'off',
                    appendIcon: null,
                    onAppendIconClick: function() {},
                },
                {
                    key: 'password',
                    type: 'password',
                    icon: 'mdi-key',
                    class: 'form-control',
                    model: 'password',
                    rules: [
                        v => !!v || this.$t('components.login.validation.password.required'),
                    ],
                    placeholder: 'components.login.inputs.password.placeholder',
                    autocomplete: 'new-password',
                    appendIcon: 'mdi-eye-off-outline',
                    onAppendIconClick: () => this.togglePasswordVisibility('inputs'),
                },
            ],

            alternativeInputs: [
                {
                    key: 'customer_no',
                    type: 'text',
                    class: 'form-control',
                    icon: 'mdi-account-key-outline',
                    model: 'customerNo',
                    rules: [
                        v => !!v || this.$t('v3.login.validation.customer_no.required'),
                    ],
                    placeholder: 'v3.login.validation.customer_no.placeholder',
                    autocomplete: 'off',
                    appendIcon: null,
                    onAppendIconClick: function() {},
                },
                {
                    key: 'username',
                    type: 'text',
                    class: 'form-control',
                    icon: 'mdi-account',
                    model: 'username',
                    rules: [
                        v => !!v || this.$t('v3.login.validation.username.required'),
                    ],
                    placeholder: 'v3.login.validation.username.placeholder',
                    autocomplete: 'off',
                    appendIcon: null,
                    onAppendIconClick: function() {},
                },
                {
                    key: 'password',
                    type: 'password',
                    icon: 'mdi-key',
                    class: 'form-control',
                    model: 'password',
                    rules: [
                        v => !!v || this.$t('components.login.validation.password.required'),
                    ],
                    placeholder: 'components.login.inputs.password.placeholder',
                    autocomplete: 'new-password',
                    appendIcon: 'mdi-eye-off-outline',
                    onAppendIconClick: () => this.togglePasswordVisibility('alternativeInputs'),
                },
            ],
        };
    },

    methods: {
        validate() {
            return new Promise((resolve, reject) => {
                if (this.$refs.loginForm.validate()) {
                    resolve();
                } else {
                    reject();
                }
            });
        },

        formSubmit(event) {
            event.preventDefault();
            this.loadingOverlay = true;
            this.validate().then(() => {
                if (this.loginType === 'default') {
                    this.login();
                } else {
                    this.alternativeLogin();
                }
            }).catch(() => this.loadingOverlay = false);
        },

        login() {
            this.info.variant = 'wellis';
            this.info.text = 'components.login.infoText';

            this.$store.dispatch('authStore/login', this.credentials).then(response => {
                if (response.data.defaults.DEFAULT_LANG) {
                    this.$store.dispatch('langStore/setLanguage', response.data.defaults.DEFAULT_LANG)
                        .then(() => {
                            this.$eventBus.$emit(EVENT_LANG_CHANGE, {
                                oldValue: this.$store.getters['langStore/current'],
                                newValue: response.data.defaults.DEFAULT_LANG,
                            });
                        });
                }
                this.$store.dispatch('categoryStore/clearCache').then(() => this.$router.push({name: 'app.start'}));
            }).catch(error => {
                this.info.text = 'whoops';
                if (error.response.data.exception) {
                    if (error.response.data.exception.class === 'AuthenticationException') {
                        this.info.text = 'v31.login.errors.' + error.response.data.message;
                        if (error.response.data.message === 'USER_PASSWORD_EXPIRED') {
                            this.$ApplGeneral({
                                content: this.$t(this.info.text),
                                onClose: () => {
                                    this.$router.push({
                                        name: 'app.guest.reset-password',
                                        query: {
                                            u: error.response.data.payload.u,
                                            c: error.response.data.payload.c,
                                            source: 'USER_PASSWORD_EXPIRED',
                                        },
                                    });
                                },
                            });
                        }
                    }
                }
            }).finally(() => this.loadingOverlay = false);
        },

        alternativeLogin() {
            this.info.variant = 'wellis';
            this.info.text = 'components.login.infoText';

            this.$store.dispatch('authStore/loginAsLiteUser', this.liteUserCredentials).then(() => {
                this.$store.dispatch('categoryStore/clearCache').then(() => this.$router.push({name: 'app.start'}));
            }).catch(error => {
                this.info.text = 'whoops';
                if (error.response.data.exception) {
                    if (error.response.data.exception.class === 'AuthenticationException') {
                        this.info.text = 'v31.login.errors.' + error.response.data.message;
                    }

                    if (error.response.data.exception.class === 'LiteUserPermissionException') {
                        this.info.text = 'v32.components.login.disallowedLogin';
                    }
                }
            }).finally(() => this.loadingOverlay = false);
        },

        togglePasswordVisibility(container) {
            let index = this[container].findIndex(x => x.key === 'password');
            if (this[container][index].type === 'password') {
                this[container][index].type = 'text';
                this[container][index].appendIcon = 'mdi-eye-outline';
            } else {
                this[container][index].type = 'password';
                this[container][index].appendIcon = 'mdi-eye-off-outline';
            }
        },

        sendResetPassword() {
            this.resetPassword.dialog = false;
            this.loadingOverlay = true;
            AuthApi.requestResetPassword(this.resetPassword.username).then(() => {
                this.$ApplGeneral({
                    content: this.$t('v31.reset-password.email-sent'),
                    onClose: () => {
                        this.$router.push({
                            name: 'app.guest.reset-password',
                            query: {
                                u: this.resetPassword.username,
                            },
                        });
                    },
                });
            }).catch(error => {
                let content = 'whoops';
                if (error.response && error.response.data.exception) {
                    if (error.response.data.exception.class === 'AuthenticationException') {
                        content = 'v31.login.errors.' + error.response.data.message;
                    }
                }
                this.$ApplGeneral({
                    content: this.$t(content),
                    onClose: () => this.resetPassword.dialog = true,
                });
            }).finally(() => {
                this.loadingOverlay = false;
            });
        },

        setAuthMethod(method) {
            if(this.loginType === method) {
                this.loginType = null;
                return;
            }
            this.loginType = method;
        },
    },

    computed: {
        resolveForgotPasswordUrl() {
            const lang = this.$store.getters['langStore/current'];
            return `https://accounts.wellis.com/${lang}/SignIn?is=wauth&locale_code=${lang}&forgot-password=1`;
        },
    },

    watch: {
        'loginType': function(val) {
            this.$router.replace({name: 'app.guest', params: {}, query: {auth_method: val}});
            this.$nextTick(() => this.$refs.loginForm.resetValidation());
        },
    },
};
</script>

<style scoped>

</style>
