import BackendService from '../../core/backend/backend.service';

const AppSys_FndUserApi = {

    get() {
        return BackendService.get('app-sys/users/fnd');
    },

    concrete(userId) {
        return BackendService.get(BackendService.createParameterURI('app-sys/users/fnd', [userId]));
    },

    create(userData) {
        return BackendService.post('app-sys/users/fnd', userData);
    },

    update(userId, userData) {
        return BackendService.put(BackendService.createParameterURI('app-sys/users/fnd', [userId]), userData);
    },

    liteUsers: {
        all(fndUserId) {
            return BackendService.get(BackendService.createParameterURI('app-sys/users/fnd', [fndUserId]) + '/lite-user');
        },

        allAsAdmin() {
            return BackendService.get('app-sys/users/fnd/-1/lite-user');
        },

        get(liteUserId, fndUserId) {
            return BackendService.query(BackendService.createParameterURI('account/lite-user', [liteUserId]), {
                fnd_user_id: fndUserId,
            });
        },

        create(username_, password_, notifyEmail_, fndUserId) {
            return BackendService.post('account/lite-user', {
                username: username_,
                notify_email: notifyEmail_,
                password: password_,
                fnd_user_id: fndUserId,
            });
        },

        update(id, username_, notifyEmail_, fndUserId) {
            return BackendService.update(BackendService.createParameterURI('account/lite-user', [id]), {
                username: username_,
                notify_email: notifyEmail_,
                fnd_user_id: fndUserId,
            });
        },

        updatePassword(id, password_, fndUserId) {
            return BackendService.update(BackendService.createParameterURI('account/lite-user', [id]) + '/password', {
                password: password_,
                fnd_user_id: fndUserId,
            });
        },

        permissions: {
            get(id, fndUserId) {
                return BackendService.query(BackendService.createParameterURI('account/lite-user', [id]) + '/permission', {
                    fnd_user_id: fndUserId,
                });
            },

            update(id, permission_, value_, fndUserId) {
                return BackendService.update(BackendService.createParameterURI('account/lite-user', [id]) + '/permission', {
                    permission: permission_,
                    value: value_,
                    fnd_user_id: fndUserId,
                });
            },

            grantAll(id, fndUserId) {
                return BackendService.post(BackendService.createParameterURI('account/lite-user', [id]) + '/permission/grant-all', {
                    fnd_user_id: fndUserId,
                });
            },

            revokeAll(id, fndUserId) {
                return BackendService.post(BackendService.createParameterURI('account/lite-user', [id]) + '/permission/revoke-all', {
                    fnd_user_id: fndUserId,
                });
            },
        },
    },
};

export default AppSys_FndUserApi;
