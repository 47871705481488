<template>
    <v-container fluid>
        <v-overlay :value="loadingOverlay" color="white">
            <v-progress-circular
                color="wellis"
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-row>
            <v-col cols="12" md="12" sm="12">
                <v-card>
                    <v-tabs
                        v-model="currentTab"
                        background-color="transparent"
                        color="wellis"
                        grow>
                        <v-tab
                            href="#general">
                            {{$t('v31.components.account.tabs.general')}}
                        </v-tab>

                        <v-tab
                            href="#security">
                            {{$t('v31.components.account.tabs.security')}}
                        </v-tab>

                        <v-tab
                            href="#defaults">
                            {{$t('v31.components.account.tabs.system-defaults')}}
                        </v-tab>

                        <v-tab href="#data">
                            {{$t('v31.components.account.tabs.data')}}
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="currentTab">
                        <v-tab-item value="general">
                            <v-card flat>
                                <v-form ref="generalForm" lazy-validation @submit="updateGeneral" v-model="general.__formValid">
                                    <v-card-text>
                                        <v-row>
                                            <v-col class="mt-5" cols="12" lg="4" sm="12">
                                                <v-text-field color="wellis"
                                                              outlined
                                                              :label="$t('v31.components.account.general.userId')"
                                                              readonly
                                                              :value="'#' + $store.getters['authStore/account'].userId"/>

                                                <v-text-field color="wellis"
                                                              outlined
                                                              :label="$t('v31.components.account.general.customerNo')"
                                                              readonly
                                                              :value="$store.getters['authStore/account'].customerNo"/>

                                                <v-text-field color="wellis"
                                                              outlined
                                                              :label="$t('v31.components.account.general.username')"
                                                              counter="100"
                                                              maxLength="100"
                                                              clearable
                                                              required
                                                              :rules="[v => !!v || this.$t('v3.mandatoryField')]"
                                                              v-model="general.username"/>
                                            </v-col>
                                            <v-col class="mt-5" cols="12" lg="4" sm="12">

                                                <v-text-field color="wellis"
                                                              outlined
                                                              :label="$t('v31.components.account.general.firstName')"
                                                              counter="50"
                                                              maxLength="50"
                                                              clearable
                                                              required
                                                              :rules="[v => !!v || this.$t('v3.mandatoryField')]"
                                                              v-model="general.firstName"/>

                                                <v-text-field color="wellis"
                                                              outlined
                                                              :label="$t('v31.components.account.general.lastName')"
                                                              counter="50"
                                                              maxLength="50"
                                                              clearable
                                                              required
                                                              :rules="[v => !!v || this.$t('v3.mandatoryField')]"
                                                              v-model="general.lastName"/>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn type="submit" color="wellis-text-white" min-width="200">{{$t('btnSave')}}</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-form>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item value="security">
                            <v-card flat>
                                <v-form ref="securityForm" lazy-validation @submit="updateSecurity" v-model="security.__formValid">
                                    <v-card-text>
                                        <v-row>
                                            <v-col class="mt-5" cols="12" lg="4" sm="12">
                                                <h2 class="mb-4">{{$t('v31.components.account.security.title')}}</h2>

                                                <v-text-field color="wellis"
                                                              outlined
                                                              type="password"
                                                              autocomplete="new-password"
                                                              v-model="security.password"
                                                              :label="$t('v31.components.account.security.old-password')"
                                                              :rules="[v => !!v || this.$t('v3.mandatoryField')]"/>

                                                <v-text-field color="wellis"
                                                              outlined
                                                              type="password"
                                                              autocomplete="new-password"
                                                              v-model="security.newPassword"
                                                              :label="$t('v31.reset-password.new-password')"
                                                              :rules="[v => !!v || this.$t('v3.mandatoryField')]"/>

                                                <v-text-field color="wellis"
                                                              outlined
                                                              type="password"
                                                              autocomplete="new-password"
                                                              v-model="security.newPasswordConfirmation"
                                                              :label="$t('v31.reset-password.password-confirm')"
                                                              :rules="[v => !!v || this.$t('v3.mandatoryField'),
                                                                        v => v === security.newPassword || this.$t('v31.reset-password.errors.pass-confirm')]"/>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn type="submit" color="wellis-text-white" min-width="200">{{$t('btnSave')}}</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-form>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item value="defaults">
                            <v-card flat>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" lg="3" sm="12" v-if="data">
                                            <v-select
                                                color="black"
                                                outlined
                                                item-color="wellis"
                                                :items="locales"
                                                :label="$t('v31.components.account.sysdef.default-lang')"
                                                item-value="value"
                                                item-text="title"
                                                v-model="defaults.DEFAULT_LANG"
                                                @change="updateSettings('DEFAULT_LANG')">
                                            </v-select>
                                            <v-select
                                                color="black"
                                                outlined
                                                item-color="wellis"
                                                :items="[{value: 'TRUE', title: $t('confirm.yes')}, {value: 'FALSE', title: $t('confirm.no')}]"
                                                :label="$t('components.app-settings.options.api.hasPrice.title')"
                                                item-value="value"
                                                item-text="title"
                                                v-model="defaults.PRICE_VISIBILITY"
                                                @change="updateSettings('PRICE_VISIBILITY')">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item value="data">
                            <v-card flat>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" lg="3" sm="12" v-if="data">
                                            <h2 class="mb-4">-</h2>
                                            <v-text-field color="wellis"
                                                          outlined
                                                          :label="$t('v31.components.account.data.currency')"
                                                          readonly
                                                          :value="data.currency"/>

                                            <v-text-field color="wellis"
                                                          outlined
                                                          :label="$t('v31.components.account.data.customerGroup')"
                                                          readonly
                                                          :value="data.customerGroup"/>

                                            <v-text-field color="wellis"
                                                          outlined
                                                          :label="$t('v31.components.account.data.vat')"
                                                          readonly
                                                          :value="data.vat ? $t('confirm.yes') : $t('confirm.no')"/>
                                            <v-text-field color="wellis"
                                                          outlined
                                                          :label="$t('v31.components.account.data.hierarchyId')"
                                                          readonly
                                                          :value="data.hierarchyId"/>

                                            <v-text-field color="wellis"
                                                          outlined
                                                          :label="$t('v31.components.account.data.regionMail')"
                                                          readonly
                                                          :value="data.regionMail"/>

                                            <v-text-field color="wellis"
                                                          outlined
                                                          :label="$t('v31.components.account.data.districtMail')"
                                                          readonly
                                                          :value="data.districtMail"/>

                                        </v-col>

                                        <v-col cols="12" lg="3" sm="12" v-if="data.billingInfo">
                                            <h2 class="mb-4">{{$t('components.billing.title')}}</h2>

                                            <v-text-field color="wellis"
                                                          outlined
                                                          :label="$t('components.billing.country')"
                                                          readonly
                                                          :value="data.billingInfo.countryCode || '-'"/>

                                            <v-text-field color="wellis"
                                                          outlined
                                                          :label="$t('components.billing.company')"
                                                          readonly
                                                          :value="data.billingInfo.companyName"/>

                                            <v-text-field color="wellis"
                                                          outlined
                                                          :label="$t('components.billing.zip')"
                                                          readonly
                                                          :value="data.billingInfo.zipCode"/>

                                            <v-text-field color="wellis"
                                                          outlined
                                                          :label="$t('components.billing.city')"
                                                          readonly
                                                          :value="data.billingInfo.city"/>

                                            <v-text-field color="wellis"
                                                          outlined
                                                          :label="$t('components.billing.address')"
                                                          readonly
                                                          :value="data.billingInfo.address1"/>

                                            <v-text-field color="wellis"
                                                          outlined
                                                          :label="$t('components.billing.address')"
                                                          readonly
                                                          :value="data.billingInfo.address2 || '-'"/>

                                            <v-text-field color="wellis"
                                                          outlined
                                                          :label="$t('components.billing.vatNo')"
                                                          readonly
                                                          :value="data.billingInfo.vatNo || '-'"/>
                                        </v-col>

                                        <v-col cols="12" lg="6" sm="12" v-if="data.shippingAddresses">
                                            <v-row class="">
                                                <v-col cols="12" sm="12" lg="2" v-if="hasMultipleShippingAddress">
                                                    <v-btn color="wellis" icon @click="prevShippingAddress">
                                                        <v-icon>mdi-arrow-left</v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="12" sm="12" lg="3">
                                                    <h2 class="mb-4">{{$t('components.checkout.shipping')}} #{{currShippingIndex + 1}} ({{countShippingAddresses}})</h2>
                                                </v-col>
                                                <v-col cols="12" sm="12" lg="2" v-if="hasMultipleShippingAddress">
                                                    <v-btn color="wellis" icon @click="nextShippingAddress">
                                                        <v-icon>mdi-arrow-right</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>

                                            <v-text-field color="wellis"
                                                          outlined
                                                          label="#"
                                                          readonly
                                                          :value="currentShippingAddress.addressId"/>

                                            <v-text-field color="wellis"
                                                          outlined
                                                          :label="$t('components.billing.country')"
                                                          readonly
                                                          :value="currentShippingAddress.countryCode"/>

                                            <v-text-field color="wellis"
                                                          outlined
                                                          :label="$t('components.billing.zip')"
                                                          readonly
                                                          :value="currentShippingAddress.zipCode"/>

                                            <v-text-field color="wellis"
                                                          outlined
                                                          :label="$t('components.billing.city')"
                                                          readonly
                                                          :value="currentShippingAddress.city"/>

                                            <v-text-field color="wellis"
                                                          outlined
                                                          :label="$t('components.billing.address')"
                                                          readonly
                                                          :value="currentShippingAddress.primaryAddressLine"/>

                                            <v-text-field color="wellis"
                                                          outlined
                                                          :label="$t('components.billing.address')"
                                                          readonly
                                                          :value="currentShippingAddress.secondaryAddressLine"/>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {EVENT_PAGE_TITLE_CHANGE} from '../../../core/constants';
import {empty} from '../../../helpers';
import AccountApiService from '../../../api/account.api';

export default {
    name: 'FrontendUser',

    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE, 'v31.components.account.title'), 100);

        if (this.$store.getters['authStore/isLiteUser']) {
            this.$ApplGeneral({
                content: this.$t('v32.liteUser.hasNoPermission'),
                onClose: function() {
                    this.$router.push({
                        name: 'app.start',
                    });
                },
            });
            return;
        }

        this.currentTab = this.$route.query.tab || 'general';
    },

    data() {
        return {
            loadingOverlay: false,
            currentTab: null,

            general: {
                __formValid: true,
                firstName: null,
                lastName: null,
                username: null,
            },

            security: {
                __formValid: true,
                password: null,
                newPassword: null,
                newPasswordConfirmation: null,
            },

            data: {},

            defaults: {
                DEFAULT_LANG: null,
                PRICE_VISIBILITY: null,
            },

            locales: [],

            currShippingIndex: 0,
        };
    },

    methods: {
        resolveGeneralData() {
            if (empty(this.general.firstName)) {
                this.general.firstName = this.$store.getters['authStore/account'].firstname;
            }

            if (empty(this.general.username)) {
                this.general.username = this.$store.getters['authStore/account'].username;
            }

            if (empty(this.general.lastName)) {
                this.general.lastName = this.$store.getters['authStore/account'].lastname;
            }
        },

        resolveSecurityData() {
            this.security.password = null;
            this.security.newPassword = null;
            this.security.newPasswordConfirmation = null;
        },

        resolveDataData() {
            this.loadingOverlay = true;
            AccountApiService.getDebugData().then(response => {
                this.$set(this, 'data', response.data.account);
                this.$set(this.data, 'shippingAddresses', Object.values(this.data.shippingAddresses).sort((a, b) => a.addressId > b.addressId));
                if (this.data.shippingAddresses.length > 0) {
                    this.currShippingIndex = 0;
                }
            }).finally(() => this.loadingOverlay = false);
        },

        resolveDefaultsData() {
            this.loadingOverlay = true;
            AccountApiService.getUserSettings()
                .then(response => {
                    Object.values(response.data.items).forEach(item => {
                        if (typeof this.defaults[item.key] !== 'undefined') {
                            this.defaults[item.key] = item.value || item.default;
                        }
                    });
                })
                .catch(() => {
                    this.$ApplGeneral({
                        content: this.$t('api-error'),
                    });
                })
                .finally(() => this.loadingOverlay = false);

            this.$store.getters['langStore/supported'].forEach(item => {
                this.locales.push({
                    value: item,
                    title: this.$t('languages')[item],
                });
            });
        },

        updateGeneral(e) {
            e.preventDefault();
            if (!this.$refs.generalForm.validate()) {
                return;
            }

            this.loadingOverlay = true;
            AccountApiService.updateGeneral(this.general.username, this.general.firstName, this.general.lastName)
                .then(response => {
                    this.$store.dispatch('authStore/setAccountInfoAttributes', response.data.fndUser);
                })
                .catch(error => {
                    let content = this.$t('whoops');
                    if (error.response.data.exception) {
                        if (error.response.data.exception.class === 'ValidationException') {
                            content = '';
                            if (error.response.data.payload) {
                                Object.values(error.response.data.payload).forEach(field => {
                                    Object.values(field).forEach(msg => {
                                        content += msg + '<br>';
                                    });
                                });
                            }
                        }
                    }

                    this.$ApplGeneral({
                        content: content,
                    });
                })
                .finally(() => {
                    this.loadingOverlay = false;
                });
        },

        updateSecurity(e) {
            e.preventDefault();
            if (!this.$refs.securityForm.validate()) {
                return;
            }

            this.loadingOverlay = true;
            AccountApiService.updatePassword(this.security.password, this.security.newPassword)
                .then(() => {
                    this.$ApplGeneral({
                        content: this.$t('v31.reset-password.success'),
                        onClose: () => {
                            this.security.password = null;
                            this.security.newPassword = null;
                            this.security.newPasswordConfirmation = null;
                            this.$refs.securityForm.resetValidation();
                        },
                    });
                })
                .catch(error => {
                    let content = this.$t('whoops');
                    if (error.response.data.exception) {
                        if (error.response.data.exception.class === 'ValidationException') {
                            content = '';
                            if (error.response.data.payload) {
                                Object.values(error.response.data.payload).forEach(field => {
                                    Object.values(field).forEach(msg => {
                                        content += msg + '<br>';
                                    });
                                });
                            }
                        }
                    }

                    if (error.response.data.message === 'INVALID_PASSWORD') {
                        content = this.$t('v31.components.account.security.invalid-password');
                    }

                    this.$ApplGeneral({
                        content: content,
                    });
                })
                .finally(() => {
                    this.loadingOverlay = false;
                });
        },

        nextShippingAddress() {
            if (this.currShippingIndex === this.data.shippingAddresses.length - 1) {
                this.currShippingIndex = 0;
            } else {
                this.currShippingIndex++;
            }
        },

        prevShippingAddress() {
            if (this.currShippingIndex === 0) {
                this.currShippingIndex = this.data.shippingAddresses.length - 1;
            } else {
                this.currShippingIndex--;
            }
        },

        updateSettings(key) {
            this.loadingOverlay = true;
            AccountApiService.updateSettings(key, this.defaults[key])
                .catch(() => {
                    this.$ApplGeneral({
                        content: this.$t('api-error'),
                    });
                })
                .finally(() => this.loadingOverlay = false);
        },
    },

    computed: {
        hasMultipleShippingAddress() {
            if (!this.data || !this.data.shippingAddresses) {
                return 0;
            }
            return this.data.shippingAddresses.length > 0;
        },

        countShippingAddresses() {
            return this.data.shippingAddresses.length;
        },

        currentShippingAddress() {
            return this.data.shippingAddresses[this.currShippingIndex];
        },
    },

    watch: {
        'currentTab': function(val) {
            history.pushState(
                {},
                null,
                this.$route.path + '?tab=' + val,
            );

            let method = `resolve${val.charAt(0).toUpperCase() + val.slice(1)}Data`;
            if (typeof this[method] === 'function') {
                this[method]();
            }
        },
    },
};
</script>

<style scoped>

</style>