<template>
    <v-dialog
        v-model="state"
        persistent
        max-width="600"
    >
        <v-card>
            <v-toolbar
                dark
                color="wellis"
            >
                <v-btn
                    icon
                    dark
                    @click="close"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{$t('v32.components.account.liteUsers.btnChangePassword')}}</v-toolbar-title>
            </v-toolbar>
            <v-form ref="securityForm" lazy-validation @submit="updatePassword" v-model="security.__formValid">
                <v-card-text>
                    <v-overlay :value="loadingOverlay" absolute color="white">
                        <v-progress-circular
                            color="wellis"
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-row>
                        <v-col class="mt-5" cols="12" sm="12">
                            <v-text-field color="wellis"
                                          outlined
                                          type="password"
                                          autocomplete="new-password"
                                          v-model="security.password"
                                          :label="$t('v31.components.account.security.old-password')"
                                          :rules="[v => !!v || this.$t('v3.mandatoryField')]"/>

                            <v-text-field color="wellis"
                                          outlined
                                          type="password"
                                          autocomplete="new-password"
                                          v-model="security.newPassword"
                                          :label="$t('v31.reset-password.new-password')"
                                          :rules="[v => !!v || this.$t('v3.mandatoryField')]"/>

                            <v-text-field color="wellis"
                                          outlined
                                          type="password"
                                          autocomplete="new-password"
                                          v-model="security.newPasswordConfirmation"
                                          :label="$t('v31.reset-password.password-confirm')"
                                          :rules="[v => !!v || this.$t('v3.mandatoryField'),
                                                                        v => v === security.newPassword || this.$t('v31.reset-password.errors.pass-confirm')]"/>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="wellis"
                        class="wellis-text-white"
                        min-width="30%"
                        type="submit"
                    >
                        {{$t('btnSave')}}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import AccountApiService from '../../../api/account.api';

export default {
    name: 'LiteUserChangePasswordDialog',

    props: {
        state: Boolean,
    },

    data() {
        return {
            loadingOverlay: false,

            security: {
                __formValid: false,
                password: null,
                newPassword: null,
                newPasswordConfirmation: null,
            },
        };
    },
    methods: {

        updatePassword(e) {
            e.preventDefault();
            if (this.$refs.securityForm.validate()) {
                this.loadingOverlay = true;
                AccountApiService.updatePassword(this.security.password, this.security.newPassword)
                    .then(() => {
                        this.$ApplGeneral({
                            content: this.$t('v31.reset-password.success'),
                            onClose: () => {
                                this.security.password = null;
                                this.security.newPassword = null;
                                this.security.newPasswordConfirmation = null;
                                this.$refs.securityForm.resetValidation();
                                this.close();
                            },
                        });
                    })
                    .catch(error => {
                        let content = this.$t('whoops');
                        if (error.response.data.exception) {
                            if (error.response.data.exception.class === 'ValidationException') {
                                content = '';
                                if (error.response.data.payload) {
                                    Object.values(error.response.data.payload).forEach(field => {
                                        Object.values(field).forEach(msg => {
                                            content += msg + '<br>';
                                        });
                                    });
                                }
                            }
                        }

                        if (error.response.data.message === 'INVALID_PASSWORD') {
                            content = this.$t('v31.components.account.security.invalid-password');
                        }

                        this.$ApplGeneral({
                            content: content,
                        });
                    })
                    .finally(() => this.loadingOverlay = false);
            }
        },

        close() {
            this.$refs.securityForm.resetValidation();
            this.security.password = null;
            this.security.newPassword = null;
            this.security.newPasswordConfirmation = null;

            this.$emit('close');
        },
    },
};
</script>

<style scoped>

</style>