<template>
    <div :class="['text-center', onClick !== null ? 'pointer' : '']" @click="clickEvent">
        <v-snackbar
            v-model="visible"
            light
            multi-line
            shaped
        >
            <div :class="`font-weight-bold`">
                <div v-if="text">
                    <v-icon v-if="icon !== null" :color="color" class="d-inline mr-1">{{icon}}</v-icon>
                    <div class="d-inline" v-if="text.indexOf('<br>') !== -1" v-html="text"></div>
                    <div class="d-inline" v-else>{{text}}</div>
                </div>

            </div>
            <template v-slot:action="{ attrs }" v-if="closeBtn">
                <v-btn
                    color="wellis"
                    text
                    v-bind="attrs"
                    @click="visible = false"
                >
                    {{$t('modal.close')}}
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
export default {
    name: "SnackBar",

    created() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'snackBar/update') {
                this.visible = false;
                this.text = state.snackBar.content;
                this.timeout = state.snackBar.timeout;
                this.color = state.snackBar.color;
                this.closeBtn = state.snackBar.closeButton;
                this.icon = state.snackBar.icon;
                this.onClick = state.snackBar.onClick;
                setTimeout(() => this.fire(), 100);
            }
        });
    },

    data() {
        return {
            timeoutTimer: null,
            visible: false,
            timeout: 1500,
            closeBtn: true,
            text: null,
            color: 'wellis',
            icon: null,
            onClick: null
        }
    },

    methods: {
        fire() {
            if (this.timeoutTimer) {
                clearTimeout(this.timeoutTimer);
            }
            this.visible = true;
            this.timeoutTimer = setTimeout(() => {
                this.visible = false;
                this.timeoutTimer = null;
                this.resetData();
            }, this.timeout);
        },

        resetData() {
            this.visible = false;
            this.timeout = 1500;
            this.closeBtn = true;
            this.text = null;
            this.color = 'wellis';
            this.icon = null;
            this.onClick = null;
        }
    },

    computed: {
        clickEvent() {
            if(this.onClick) {
                return this.onClick;
            } else {
                return function() {}
            }
        }
    }
}
</script>

<style scoped>

</style>
