<template>
    <div>
        <v-card class="mx-auto overflow-hidden" min-height="375" elevation="2" color="transparent2"
                max-width="1280">
            <v-img
                class="white--text align-end"
                :lazy-src="require('@/assets/img/placeholder_1800x600.jpg')"
                :src="data.header_image_url">

            </v-img>
            <v-card-title class="text-h2">{{data.title}}</v-card-title>
            <v-divider></v-divider>
            <v-card-subtitle class="pb-2 text-h5">
                <span>{{data.author_name}}</span>
                <span class="float-right">{{data.created_at | formatDate}}</span>
            </v-card-subtitle>
            <v-divider class="mt-1 mb-1"></v-divider>
            <v-card-text>
                <v-alert
                    border="left"
                    colored-border
                    color="wellis accent-4"
                >
                    <p v-html="nl2br(data.content)" v-if="!empty(data.content)"></p>
                    <p v-else>
                        {{$t('components.news.no-lead')}}
                    </p>
                </v-alert>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import NewsApiService from "../../../api/news.api";
import {empty, nl2br} from "../../../helpers";
import {EVENT_PAGE_TITLE_CHANGE} from "../../../core/constants";

export default {
    name: "NewsConcrete",
    data() {
        return {
            loading: true,
            data: {}
        }
    },

    mounted() {
        if(!this.hasLiteUserPermission('news')) {
            this.$ApplGeneral({
                content: this.$t('v32.liteUser.hasNoPermission'),
                onClose: function() {
                    this.$router.push({
                        name: 'app.start'
                    })
                }
            });
            return;
        }

        this.fetch(this.$route.params.slug);
    },

    methods: {
        fetch(slug) {
            this.loading = true;
            NewsApiService.concrete(slug)
                .then(response => {
                    this.data = response.data;
                    this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE,
                        'components.news.title',
                        this.data.category_name + ' / ' + this.data.title
                    )
                })
                .catch(() => this.$router.push({name: 'app.404'}))
                .finally(() => this.loading = false);
        },

        empty(value) {
            return empty(value);
        },

        hasLiteUserPermission(permission) {
            if (!this.$store.getters['authStore/isLiteUser']) {
                return true;
            }
            let permission_ = this.$store.getters['authStore/getLiteUserPermission'].find(x => x.permission === permission);
            return permission_.objstate === 'ACTIVE';
        },
    },

    computed: {
        nl2br() {
            return value => {
                return nl2br(value);
            }
        }
    }
}
</script>

<style scoped>

</style>
