var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('h2',{staticClass:"mb-3"},[_vm._v("Navigation")]),_c('h3',{staticClass:"mb-3"},[_vm._v("Products")]),_c('v-row',[_c('v-col',{attrs:{"xl":"3","lg":"4","md":"12","sm":"12","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto pointer",attrs:{"min-height":"64","elevation":hover ? 4 : 2}},[_c('v-card-text',{staticClass:"text&#45;&#45;primary align-center text-center"},[_c('span',{staticClass:"text-h5 wellis&#45;&#45;text"},[_vm._v("Categories")])])],1)]}}])})],1)],1),_c('h3',{staticClass:"mt-5 mb-3"},[_vm._v("Users / Account")]),_c('v-row',[_c('v-col',{attrs:{"xl":"3","lg":"4","md":"12","sm":"12","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto pointer",attrs:{"min-height":"64","elevation":hover ? 4 : 2,"to":{name: 'app.admin.users.fnd'}}},[_c('v-card-text',{staticClass:"text--primary align-center text-center"},[_c('span',{staticClass:"text-h5 wellis--text"},[_vm._v("Frontend users")])])],1)]}}])})],1),_c('v-col',{attrs:{"xl":"3","lg":"4","md":"12","sm":"12","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto pointer",attrs:{"min-height":"64","elevation":hover ? 4 : 2,"to":{name: 'app.admin.users.lite-users.overview'}}},[_c('v-card-text',{staticClass:"text--primary align-center text-center"},[_c('span',{staticClass:"text-h5 wellis--text"},[_vm._v("Lite users")])])],1)]}}])})],1)],1),_c('h3',{staticClass:"mt-5 mb-3"},[_vm._v("News")]),_c('v-row',[_c('v-col',{attrs:{"xl":"3","lg":"4","md":"12","sm":"12","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto pointer",attrs:{"min-height":"64","elevation":hover ? 4 : 2,"to":{name: 'app.admin.news.posts'}}},[_c('v-card-text',{staticClass:"text--primary align-center text-center"},[_c('span',{staticClass:"text-h5 wellis--text"},[_vm._v("Posts")])])],1)]}}])})],1),_c('v-col',{attrs:{"xl":"3","lg":"4","md":"12","sm":"12","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto pointer",attrs:{"min-height":"64","elevation":hover ? 4 : 2,"to":{name: 'app.admin.news.categories'}}},[_c('v-card-text',{staticClass:"text--primary align-center text-center"},[_c('span',{staticClass:"text-h5 wellis--text"},[_vm._v("Categories")])])],1)]}}])})],1)],1),_c('h3',{staticClass:"mt-5 mb-3"},[_vm._v("Orders")]),_c('v-row',[_c('v-col',{attrs:{"xl":"3","lg":"4","md":"12","sm":"12","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto pointer",attrs:{"min-height":"64","elevation":hover ? 4 : 2,"to":{name: 'app.admin.orders.failed'}}},[_c('v-card-text',{staticClass:"text--primary align-center text-center"},[_c('span',{staticClass:"text-h5 wellis--text"},[_vm._v("Failed orders")])])],1)]}}])})],1)],1),_c('h3',{staticClass:"mt-5 mb-3"},[_vm._v("Background processes")]),_c('v-row',[_c('v-col',{attrs:{"xl":"3","lg":"4","md":"12","sm":"12","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto pointer",attrs:{"min-height":"64","elevation":hover ? 4 : 2,"to":{name: 'app.admin.queue.jobs'}}},[_c('v-card-text',{staticClass:"text--primary align-center text-center"},[_c('span',{staticClass:"text-h5 wellis--text"},[_vm._v("Jobs")])])],1)]}}])})],1),_c('v-col',{attrs:{"xl":"3","lg":"4","md":"12","sm":"12","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto pointer",attrs:{"min-height":"64","elevation":hover ? 4 : 2,"to":{name: 'app.admin.queue.failed-jobs'}}},[_c('v-card-text',{staticClass:"text--primary align-center text-center"},[_c('span',{staticClass:"text-h5 wellis--text"},[_vm._v("Failed jobs")])])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12"}}),_c('v-col',{attrs:{"xl":"3","lg":"4","md":"12","sm":"12","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto pointer",attrs:{"min-height":"64","elevation":hover ? 4 : 2,"to":{name: 'app.admin.scheduler'}}},[_c('v-card-text',{staticClass:"text--primary align-center text-center"},[_c('span',{staticClass:"text-h5 wellis--text"},[_vm._v("Scheduler")])])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }