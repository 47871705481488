import BackendService from '../core/backend/backend.service';

const QuickReportApi = {
    get() {
        return BackendService.get('quick-report');
    },

    getReportAsSpreadsheet(uuid) {
        return BackendService.download(BackendService.createParameterURI('quick-report/get-spreadsheet', [uuid]), null,
            {
                responseType: 'arraybuffer'
            },
        );
    },

};

export default QuickReportApi;


