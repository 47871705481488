<template>
    <v-container fluid>
        <v-container fluid class="mt-10" v-if="mainOverlay">
            <v-progress-linear indeterminate
                               color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container fluid v-else>
            <v-row>
                <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                    <v-card>
                        <v-card-title>Details</v-card-title>
                        <v-card-text>
                            <v-form>
                                <v-row>
                                    <v-col cols="12" xl="3" sm="12">
                                        <v-text-field
                                            :value="job.id"
                                            label="Job ID"
                                            color="wellis"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="3" sm="12">
                                        <v-text-field
                                            :value="job.uuid"
                                            label="UUID"
                                            color="wellis"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="3" sm="12">
                                        <v-text-field
                                            :value="job.queue"
                                            label="Queue"
                                            color="wellis"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="3" sm="12">
                                        <v-text-field
                                            :value="job.object"
                                            label="Object"
                                            color="wellis"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="3" sm="12">
                                        <v-text-field
                                            :value="job.attempts"
                                            label="Attempts"
                                            color="wellis"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="3" sm="12">
                                        <v-text-field
                                            :value="job.reserved_at"
                                            label="Reserved at"
                                            color="wellis"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="3" sm="12">
                                        <v-text-field
                                            :value="job.available_at"
                                            label="Available at"
                                            color="wellis"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="3" sm="12">
                                        <v-text-field
                                            :value="job.created_at"
                                            label="Created at"
                                            color="wellis"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="12">
                    <v-card>
                        <v-card-title>Payload</v-card-title>
                        <v-card-text>
                            <pre style="background-color: #f1f1f1!important;"><code style="max-width:100%" v-html="resolvePayload"></code></pre>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import {EVENT_PAGE_TITLE_CHANGE_NO_TRANS} from "../../../../../core/constants";
import AppSys_QueueApi from "../../../../../api/app-sys/queue.api";
import {nl2br} from "../../../../../helpers";

export default {
    name: "AdminQueueJobDetails",

    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE_NO_TRANS, 'Jobs / Queue', 'Job details'), 100);

        this.fetch();
    },

    data() {
        return {
            mainOverlay: true,
            loadingOverlay: true,

            job: null,
        }
    },

    methods: {
        async fetch() {
            return new Promise((resolve, reject) => {
                this.loadingOverlay = true;
                AppSys_QueueApi.jobs.concrete(this.$route.params.id)
                    .then(response => {
                        this.job = response.data.job;
                        resolve(response.data.job);
                        this.$scrollTop();
                    })
                    .catch(error => {
                        this.$snackBar({
                            icon: 'mdi-alert',
                            text: this.$t('api-error')
                        })
                        reject(error);
                    })
                    .finally(() => {
                        this.mainOverlay = false;
                        this.loadingOverlay = false;
                    });
            });
        },
    },

    computed: {
        resolvePayload() {
            return nl2br(this.job.payload.data.command);
        }
    }
}
</script>

