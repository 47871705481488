import BackendService from "../core/backend/backend.service";

const StockMixedApiService = {
    async getStock(filters = null) {
        return BackendService.query('stock-mixed', {
            filters: filters ? JSON.stringify(filters) : null,
            withoutFilters: filters !== null
        });
    },
}

export default StockMixedApiService;
