import BackendService from "../core/backend/backend.service";

const StocklistApiService = {
    async getStocklist(filters = null) {
        return BackendService.query('stocklist', {
            filters: filters ? JSON.stringify(filters) : null,
            withoutFilters: filters !== null
        });
    },

    async getConfigurationsPrice(configs = []) {
        return BackendService.post('stocklist/configurations-price', {
            configs: JSON.stringify(configs)
        });
    },

    async configurationDetails(catalogNo, configNo, warehouse, query = null) {
        return BackendService.query(`stocklist/details/${catalogNo}/${configNo}/${warehouse}`, query);
    },

    async getTransit(filters = null) {
        return BackendService.query('stocklist/transit', {
            filters: filters ? JSON.stringify(filters) : null,
            withoutFilters: filters !== null
        });
    },

    async transitDetails(uniqueId, query = null) {
        return BackendService.query(`stocklist/transit/details/${uniqueId}`, query);
    },

    async getOffers(catalogNo, acrylicColor = null) {
        return BackendService.query('stocklist/offers', {
            catalog_no: catalogNo,
            acrylic_color: acrylicColor
        });
    },

    async search(value) {
        return BackendService.query('stocklist/search', {
            value: value
        });
    }
}

export default StocklistApiService;
