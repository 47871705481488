import Vue from 'vue';
import VueLocalStorage from "vue-localstorage";
import CategoryApiService from "../api/category.api";

Vue.use(VueLocalStorage);

/**
 * Actions
 */
export const FETCH_CATEGORIES = 'fetch';
export const CLEAR_CACHE = 'clearCache';

/**
 * Mutations
 */
export const SET_CATEGORY = 'setCategory';
export const PURGE = 'purgeCategories';

/**
 * Keys
 */
export const PRODUCT_KEY = 'app:categoryStore@products';
export const STOCKLIST_KEY = 'app:categoryStore@stocklist';

export default {
    namespaced: true,

    state: {
        categories: {
            product: {
                items: [],
                expiresAt: null,
                cache: false
            },

            stocklist: {
                items: [],
                expiresAt: null,
                cache: false
            }
        }
    },

    getters: {
        getProduct(state) {
            return state.categories.product;
        },

        getProductItems(state) {
            return state.categories.product.items;
        },

        isProductCached(state) {
            return state.categories.product.cache;
        }
    },

    actions: {
        [FETCH_CATEGORIES](context) {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve, reject) => {
                let cache = {
                    product: Vue.localStorage.get(PRODUCT_KEY, null)
                };

                let empty = 0;

                if (cache.product !== null) {
                    cache.product = JSON.parse(cache.product);

                    if (Date.now() >= new Date(cache.product.expiresAt)) {
                        empty++;
                    }
                } else {
                    empty++;
                }

                if (empty > 0) {
                    cache.product = {
                        items: []
                    };
                    await CategoryApiService.getProduct()
                        .then(response => {
                            cache.product.items = response.data;
                        })
                        .catch(error => {
                            reject(error);
                        });
                }

                context.commit(SET_CATEGORY, {
                    target: 'product',
                    items: cache.product.items,
                    cacheKey: PRODUCT_KEY
                });


                resolve();
            });
        },

        [CLEAR_CACHE](context) {
            return new Promise(resolve => {
                context.commit(PURGE);
                resolve();
            });
        }
    },

    mutations: {
        [SET_CATEGORY](state, attributes) {
            if (state.categories[attributes.target]) {
                let expiresAt = new Date();
                expiresAt.setHours(expiresAt.getHours() + 1);
                Vue.localStorage.set(attributes.cacheKey, JSON.stringify({
                    expiresAt: expiresAt.toString(),
                    items: attributes.items
                }));
                state.categories[attributes.target].items = attributes.items;
                state.categories[attributes.target].cache = true;
                state.categories[attributes.target].expiresAt = expiresAt;
            }
        },

        [PURGE](state) {
            state.categories = {
                product: {
                    items: [],
                    expiresAt: null,
                    cache: false
                },

                stocklist: {
                    items: [],
                    expiresAt: null,
                    cache: false
                }
            };
            Vue.localStorage.remove(PRODUCT_KEY);
            Vue.localStorage.remove(STOCKLIST_KEY);
        }
    }
}
