<template>
    <v-container fluid>
        <v-container fluid class="mt-10" v-if="mainOverlay">
            <v-progress-linear indeterminate
                               color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container fluid v-else>
            <v-overlay :value="loadingOverlay" absolute color="white">
                <v-progress-circular
                    color="wellis"
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
            <v-row v-if="items.length > 0">
                <v-col xl="3" lg="4" md="12" sm="12" cols="12" v-for="item in items" :key="$store.getters['langStore/current']+'_'+item.uuid">
                    <v-hover v-slot="{ hover }">
                        <v-card class="mx-auto pointer" min-height="64" :elevation="hover ? 4 : 2" @click="getSpreadsheet(item)">
                            <v-card-text class="align-center text-center">
                                <span class="text-h5 wellis--text">{{name(item)}}</span>
                            </v-card-text>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import {EVENT_PAGE_TITLE_CHANGE} from '../../../core/constants';
import QuickReportApi from '../../../api/quick-report.api';
import {handleDownloadedFile, LiteUser} from '../../../helpers';

export default {
    name: 'QuickReportView',

    data() {
        return {
            items: [],
            mainOverlay: true,
            loadingOverlay: false,
        };
    },

    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE, 'v3.components.quick-report.title'), 100);

        if (!this.hasLiteUserPermission('quick_reports')) {
            this.$ApplGeneral({
                content: this.$t('v32.liteUser.hasNoPermission'),
                onClose: function() {
                    this.$router.push({
                        name: 'app.start',
                    });
                },
            });
            return;
        }

        this.fetch();
    },

    methods: {
        hasLiteUserPermission(permission) {
            if (!this.$store.getters['authStore/isLiteUser']) {
                return true;
            }
            let permission_ = this.$store.getters['authStore/getLiteUserPermission'].find(x => x.permission === permission);
            return permission_.objstate === 'ACTIVE';
        },

        fetch() {
            if (!LiteUser.hasPermission(
                this.$store.getters['authStore/isLiteUser'],
                this.$store.getters['authStore/getLiteUserPermission'],
                'quick_reports',
            )) {
                this.$ApplGeneral({
                    content: this.$t('v32.liteUser.hasNoPermission'),
                    onClose: () => {
                        this.$router.push({name: 'app.start'});
                    }
                });
                return;
            }

            this.mainOverlay = true;
            return QuickReportApi.get().then(response => {
                this.items = response.data.items;
                this.items.forEach(item => {
                    item.attributes = JSON.parse(item.attributes);
                });
            }).catch(() => {
                this.$snackBar({
                    icon: 'mdi-alert',
                    allowOutsideClick: false,
                    text: this.$t('api-error'),
                });
            })
                .finally(() => this.mainOverlay = false);
        },

        getSpreadsheet(item) {
            if (!LiteUser.hasPermission(
                this.$store.getters['authStore/isLiteUser'],
                this.$store.getters['authStore/getLiteUserPermission'],
                'quick_reports',
            )) {
                this.$ApplGeneral({
                    content: this.$t('v32.liteUser.hasNoPermission'),
                    onClose: () => {
                        this.$router.push({name: 'app.start'});
                    }
                });
                return;
            }

            this.loadingOverlay = true;
            QuickReportApi.getReportAsSpreadsheet(item.uuid)
                .then(response => {
                    if (response.status === 204) {
                        this.$snackBar({
                            content: this.$t('components.my-orders.details.noTableData'),
                            icon: 'mdi-alert',
                        });
                    } else {
                        let filename = response.headers['content-disposition'].split('filename')[1];
                        filename = filename.substr(1, filename.length);
                        handleDownloadedFile(response.data, `media_${filename}`, response.type);
                    }
                })
                .catch(() => {
                    this.$snackBar({
                        content: this.$t('api-error'),
                        icon: 'mdi-alert',
                    });
                })
                .finally(() => {
                    this.loadingOverlay = false;
                });
        },
    },

    computed: {
        name() {
            return item => {
                return item.attributes.lang[this.$store.getters['langStore/current']] || item.label;
            };
        },
    },
};
</script>

<style scoped>

</style>