import BackendService from "../core/backend/backend.service";

const AuthenticationGateway = {
    async attempt(username, password) {
        return BackendService.post('authentication', {
            "username": username,
            "password": password
        });
    },

    async loginAsLiteUser(customerNo, username, password) {
        return BackendService.post('authentication/lite-user', {
            "customer_no": customerNo,
            "username": username,
            "password": password
        });
    },

    async requestResetPassword(username) {
        return BackendService.post('authentication/request-reset-password', {
            "username": username,
        });
    },

    async resetPassword(username, resetCode, password) {
        return BackendService.post('authentication/reset-password', {
            "username": username,
            "reset_code": resetCode,
            "password": password
        });
    }
}

export default AuthenticationGateway;


