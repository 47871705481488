export const EVENT_LANG_CHANGE = 'app:languagePicker@change';
export const EVENT_LANG_CHANGE_SIDEBAR = 'app:languagePicker@change(sidebar)';
export const EVENT_CART_COUNT_UPDATE = 'app:cart@updateCount';
export const EVENT_CART_FETCH = 'app:cart@fetch';
export const EVENT_SEARCH = 'app:search@trigger';
export const EVENT_TOKEN_REFRESHED = 'app:auth@tokenRefreshed';
export const EVENT_MEDIA_SELECT_FILE = 'media::toggleFileSelection';
export const EVENT_MEDIA_PREVIEW_IMAGE = 'media::previewImage';
export const EVENT_MEDIA_RESTRICT = 'media::openRestrictModal';
export const EVENT_MEDIA_RESTRICT_CLOSE = 'media::closeRestrictModal';
export const EVENT_MEDIA_RESTRICT_CHANGED = 'media::updateRestriction';
export const CHECKOUT_MASTER_SCHEDULE_CHANGE = 'checkout::masterScheduleDateChanged';
export const CHECKOUT_MASTER_SCHEDULE_CHANGE_MAIN = 'checkout::masterScheduleDateChanged::main';
export const MENU_HAMBURGER_CLOSE = 'app::aside::menu@close';
export const EVENT_FAULT_REPORT_SELECT_ITEM = 'app::faultReport@selectItem';
export const PATTERN_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const EVENT_PAGE_TITLE_CHANGE = 'app::pageTitle@change';
export const EVENT_PAGE_TITLE_CHANGE_NO_TRANS = 'app::pageTitle@change__wo_trans';
export const EVENT_MEDIA_PATH_CHANGE = 'app::media@pathChange';

export const SPA_REGEX = /^(WM|WU).+$/i;

export const EVENT_SALES_VIEW_DISABLE_BTN = 'app::salesView@disableButtons';
export const EVENT_SALES_VIEW_ENABLE_BTN = 'app::salesView@enableButtons';
