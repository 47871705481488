<template>
    <div @click="handleClick">
        <div v-if="quantity > 0">
            <v-badge color="wellis" overlap top right :content="quantity" offset-y="18" offset-x="20">
                <v-btn icon :disabled="!hasLiteUserPermission('place_order')">
                    <v-icon>mdi-cart</v-icon>
                </v-btn>
            </v-badge>
        </div>
        <div v-else>
            <v-btn icon :disabled="!hasLiteUserPermission('place_order')">
                <v-icon>mdi-cart</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
import {EVENT_CART_COUNT_UPDATE} from "../../core/constants";

export default {
    name: "CartIcon",

    created() {
        this.$eventBus.$on(EVENT_CART_COUNT_UPDATE, quantity => {
            this.$store.dispatch('cartStore/updateCount', quantity);
        });
    },

    methods: {
        handleClick() {
            if(!this.hasLiteUserPermission('place_order')) {
                return;
            }
            if (this.$route.name !== 'app.cart') {
                this.$router.push({name: 'app.cart'});
            }
        },

        hasLiteUserPermission(permission) {
            if (!this.$store.getters['authStore/isLiteUser']) {
                return true;
            }
            let permission_ = this.$store.getters['authStore/getLiteUserPermission'].find(x => x.permission === permission);
            return permission_.objstate === 'ACTIVE';
        },
    },

    computed: {
        quantity() {
            return this.$store.getters['cartStore/countItems'];
        }
    }
}
</script>

<style scoped>

</style>
