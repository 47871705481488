import BackendService from "../core/backend/backend.service";

const CategoryApiService = {
    async getProduct() {
        return BackendService.get('category/product');
    },

    async getNews(query = null) {
        return BackendService.query('category/news', query);
    },

    async newsCategory(id) {
        return BackendService.get(`category/news/${id}`);
    },

    async createCategory(logicalUnit, data) {
        let path = null;
        switch (logicalUnit) {
            case 'NEWS':
                path = 'category/news';
                break;
        }
        return BackendService.post(path, data);
    },

    async updateCategory(logicalUnit, id, data) {
        let path = null;
        switch (logicalUnit) {
            case 'NEWS':
                path = 'category/news';
                break;
        }
        return BackendService.put(`${path}/${id}`, data);
    },

    async updateOrder(logicalUnit, data) {
        return BackendService.update('category/sort', {
            logical_unit: logicalUnit,
            sort: data
        });
    },

    async deleteCategory(logicalUnit, id) {
        return BackendService.delete(`category/${id}`, {
            logical_unit: logicalUnit
        });
    },
}

export default CategoryApiService;


