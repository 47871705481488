<template>
    <v-card min-height="450">
        <v-overlay :value="loadingOverlay" absolute>
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-card-title>
            <v-hover>
                <template v-slot:default="{ hover }">
                    <v-img
                        class="white--text align-end"
                        height="254px"
                        contain
                        width="254px"
                        :style="hover ? 'opacity: 0.8;' : 'opacity: 1;'"
                        :lazy-src="require('@/assets/img/placeholder_300x300.png')"
                        :src="thumbnail">
                    </v-img>
                </template>
            </v-hover>
        </v-card-title>
        <v-card-subtitle>
            <div class="mt-2 wellis--text font-weight-bold d-block">
                {{product.catalogNo}}
            </div>
            <span class="d-block pointer">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">{{product.name | cut(27)}}</span>
                            </template>
                            <span>{{product.name}}</span>
                        </v-tooltip>
                    </span>
        </v-card-subtitle>
        <v-card-text>
            <v-container fluid v-if="$store.getters['authStore/currentRealm'] !== 'wellis_usa_realm'">
                <label>{{$t('components.stocklist.title')}}</label>
                <v-btn block :disabled="quantityStocklist === 0 || disableButtons"
                       :loading="btnStocklistLoading"
                       @click="showStocklistDialog">
                    {{`${quantityStocklist} ${unit}`}}
                </v-btn>
            </v-container>
            <v-container v-else>
                <div v-if="quantityStocklist>0">
                    <label>{{$t('components.stocklist.title')}}</label>
                    <v-btn block :disabled="quantityStocklist === 0 || disableButtons"
                           :loading="btnStocklistLoading"
                           @click="showStocklistDialog">
                        {{`${quantityStocklist} ${unit}`}}
                    </v-btn>
                </div>
            </v-container>
            <v-container fluid v-if="$store.getters['authStore/currentRealm'] !== 'wellis_usa_realm'">
                <label>{{$t('components.shopord.title')}}</label>
                <v-btn block :disabled="quantityPurchaseOrder === 0 || disableButtons"
                       :loading="btnShopOrdLoading"
                       @click="showShopOrdDialog">
                    {{`${quantityPurchaseOrder} ${unit}`}}
                </v-btn>
            </v-container>
            <v-container v-else>
                <div v-if="quantityPurchaseOrder>0">
                    <label v-if="product.catalogNo.toString().endsWith('-TR')">{{$t('v32.components.sale.TR')}}</label>
                    <label v-else-if="product.catalogNo.toString().endsWith('-EU')">{{$t('v32.components.sale.EU')}}</label>
                    <label v-else>{{$t('v32.components.sale.stockInEurope')}}</label>
                    <v-btn block :disabled="quantityPurchaseOrder === 0 || disableButtons"
                           :loading="btnShopOrdLoading"
                           @click="showShopOrdDialog">
                        {{`${quantityPurchaseOrder} ${unit}`}}
                    </v-btn>
                </div>
            </v-container>
        </v-card-text>
        <SaleShopOrdDialog :visible="shopOrdDialog"
                           @close="shopOrdDialog = false"
                           :catalog-no="product.catalogNo"
                           :name="product.name"
                           :items="resolveShopordItems" v-if="quantityPurchaseOrder > 0"/>
        <SaleStockDialog :visible="stocklistDialog"
                         @close="stocklistDialog = false"
                         :catalog-no="product.catalogNo"
                         :name="product.name"
                         :items="stocklist" v-if="quantityStocklist > 0"/>
    </v-card>
</template>

<script>
import SaleShopOrdDialog from "./SaleShopOrdDialog";
import {EVENT_SALES_VIEW_DISABLE_BTN, EVENT_SALES_VIEW_ENABLE_BTN} from "../../../core/constants";
import StocklistApiService from "../../../api/stocklist.api";
import SaleStockDialog from "./SaleStockDialog";
import PurchaseOrderApi from "../../../api/purchase-order.api";

export default {
    name: "SaleProductCard",
    components: {SaleStockDialog, SaleShopOrdDialog},
    props: {
        product: Object,
        disableButtons: Boolean
    },

    data() {
        return {
            loadingOverlay: false,
            shopOrdDialog: false,
            stocklistDialog: false,

            btnShopOrdLoading: false,
            btnStocklistLoading: false
        }
    },

    methods: {
        showShopOrdDialog() {
            this.$eventBus.$emit(EVENT_SALES_VIEW_DISABLE_BTN);
            this.btnShopOrdLoading = true;
            let data = [];
            Object.values(this.product.purchaseorder).forEach(item => {
                data.push(item.uniqueId);
            });

            this.resolveApiService().getItemsPrice(data)
                .then(response => {
                    const prices = Object.values(response.data.prices);
                    Object.values(this.purchaseorder).forEach(item => {
                        let shopordItem = prices.find(x => x.uniqueId === item.uniqueId);
                        if (shopordItem) {
                            item.price = shopordItem.price;
                        }
                    });
                    this.shopOrdDialog = true;
                    this.$eventBus.$emit(EVENT_SALES_VIEW_ENABLE_BTN);
                })
                .finally(() => this.btnShopOrdLoading = false);

        },

        showStocklistDialog() {
            this.$eventBus.$emit(EVENT_SALES_VIEW_DISABLE_BTN);
            this.btnStocklistLoading = true;
            let data = [];
            Object.values(this.product.stocklist).forEach(item => {
                data.push({
                    catalog_no: this.product.catalogNo,
                    config_no: item.configNo,
                    contract: item.contract
                });
            });

            StocklistApiService.getConfigurationsPrice(data)
                .then(response => {
                    const configs = Object.values(response.data.configs);
                    Object.values(this.product.stocklist).forEach(item => {
                        let config = configs.find(x => x.config_no === item.configNo);
                        if (config) {
                            item.price = config.price;
                        }
                    });
                    this.stocklistDialog = true;
                    this.$eventBus.$emit(EVENT_SALES_VIEW_ENABLE_BTN);
                })
                .finally(() => this.btnStocklistLoading = false);

        },

        resolveApiService() {
            return PurchaseOrderApi;
        }
    },

    computed: {
        thumbnail() {
            if (!this.product) {
                return null;
            }
            return this.product.media.thumbnailUrl;
        },

        quantity() {
            if (!this.product) {
                return 0;
            }

            return this.quantityStocklist + this.quantityShopOrd;
        },

        quantityStocklist() {
            if (!this.product) {
                return 0;
            }
            let quantity = 0;
            Object.values(this.product.stocklist).forEach(item => {
                quantity += parseInt(item.quantity);
            });
            return quantity;
        },

        quantityShopOrd() {
            if (!this.product) {
                return 0;
            }
            let quantity = 0;
            Object.values(this.product.shopord).forEach(item => {
                if (item.hidden !== true) {
                    quantity += parseInt(item.quantity);
                }
            });
            return quantity;
        },

        quantityPurchaseOrder() {
            if (!this.product || !this.product.purchaseorder || this.product.purchaseorder.length === 0) {
                return 0;
            }
            let quantity = 0;
            Object.values(this.product.purchaseorder).forEach(item => {
                if (item.hidden !== true) {
                    quantity += parseInt(item.quantity);
                }
            });
            return quantity;
        },

        unit() {
            if (!this.product) {
                return 'N/A';
            }
            return this.product.unit;
        },

        stocklist() {
            return Object.values(this.product.stocklist);
        },

        shopord() {
            return this.product.shopord;
        },

        purchaseorder() {
            return this.product.purchaseorder;
        },

        resolveShopordItems() {
            return this.purchaseorder;
        }
    }
}
</script>

<style scoped>

</style>
