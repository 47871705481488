<template>
    <v-container fluid>
        <v-container fluid class="mt-10" v-if="mainOverlay">
            <v-progress-linear indeterminate
                               color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container fluid v-else>
            <v-overlay :value="loadingOverlay" color="white">
                <v-progress-circular
                    color="wellis"
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
            <v-row>
                <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                    <v-card>
                        <v-card-title>Details</v-card-title>
                        <v-card-text>
                            <v-form>
                                <v-row>
                                    <v-col cols="12" xl="3" sm="12">
                                        <v-text-field
                                            :value="job.id"
                                            label="Job ID"
                                            color="wellis"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="3" sm="12">
                                        <v-text-field
                                            :value="job.uuid"
                                            label="UUID"
                                            color="wellis"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="3" sm="12">
                                        <v-text-field
                                            :value="job.queue"
                                            label="Queue"
                                            color="wellis"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="3" sm="12">
                                        <v-text-field
                                            :value="job.connection"
                                            label="Connection"
                                            color="wellis"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="3" sm="12">
                                        <v-text-field
                                            :value="job.object"
                                            label="Object"
                                            color="wellis"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="3" sm="12">
                                        <v-text-field
                                            :value="job.exception_object"
                                            label="Exception"
                                            color="wellis"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="3" sm="12">
                                        <v-text-field
                                            :value="job.failed_at"
                                            label="Failed at"
                                            color="wellis"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="3" sm="12">
                                        <v-text-field
                                            :value="job.notified_at"
                                            label="Notified at"
                                            color="wellis"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="12">
                    <v-card>
                        <v-card-title>Exception details</v-card-title>
                        <v-card-text>
                            <v-textarea :value="job.exception" color="wellis" rows="10" readonly></v-textarea>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="12">
                    <v-card>
                        <v-card-title>Payload</v-card-title>
                        <v-card-text>
                            <pre style="background-color: #f1f1f1!important;"><code style="max-width:100%" v-html="resolvePayload"></code></pre>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-bottom-navigation fixed style="z-index: 0" grow v-if="job">
            <v-btn icon @click="() => this.fetch()">
                <span>Refresh</span>
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn icon @click="triggerConfirmDialog('deleteJob')">
                <span>Delete</span>
                <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
            <v-btn icon @click="triggerConfirmDialog('restartJob^true')">
                <span>Restart (Online)</span>
                <v-icon>mdi-restart-alert</v-icon>
            </v-btn>
            <v-btn icon @click="triggerConfirmDialog('restartJob')">
                <span>Restart (Background)</span>
                <v-icon>mdi-restart</v-icon>
            </v-btn>
        </v-bottom-navigation>

        <!-- Confirm Dialog -->
        <v-dialog v-model="confirmDialog.dialog"
                  persistent
                  width="400">
            <v-card>
                <v-card-title>Wellis Online System API</v-card-title>
                <v-card-text class="text-center">
                    <v-icon color="wellis" size="96">mdi-progress-question</v-icon>
                    <p class="mt-5">Are you sure?</p>
                </v-card-text>
                <v-card-actions>
                    <v-container fluid>
                        <v-btn @click="confirmDialog.dialog = false">No</v-btn>
                        <v-btn @click="invokeConfirmDialogMethod" class="float-right">Yes</v-btn>
                    </v-container>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Error dialog -->
        <v-dialog v-model="errorDialog.dialog"
                  @click:outside="errorDialog.dialog = false"
                  min-width="800">
            <v-card>
                <v-toolbar dark
                           color="wellis">
                    <v-btn icon
                           @click="errorDialog.dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Wellis Online System API</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <pre class="mt-5" v-html="resolveErrorData"></pre>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import {EVENT_PAGE_TITLE_CHANGE_NO_TRANS} from "../../../../../core/constants";
import AppSys_QueueApi from "../../../../../api/app-sys/queue.api";
import {nl2br, PrettyJson} from "../../../../../helpers";

export default {
    name: "AdminQueueFailedJobDetails",

    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE_NO_TRANS, 'Jobs / Queue', 'Failed job details'), 100);

        this.fetch();
    },

    data() {
        return {
            mainOverlay: true,
            loadingOverlay: true,

            confirmDialog: {
                dialog: false,
                target: {
                    method: null,
                    param: null,
                },
            },

            errorDialog: {
                dialog: false,
                data: null,
            },

            job: null,
        }
    },

    methods: {
        async fetch() {
            return new Promise((resolve, reject) => {
                this.loadingOverlay = true;
                AppSys_QueueApi.failedJobs.concrete(this.$route.params.id)
                    .then(response => {
                        this.job = response.data.job;
                        resolve(response.data.job);
                        this.$scrollTop();
                    })
                    .catch(error => {
                        this.$snackBar({
                            icon: 'mdi-alert',
                            text: this.$t('api-error')
                        })
                        reject(error);
                    })
                    .finally(() => {
                        this.mainOverlay = false;
                        this.loadingOverlay = false;
                    });
            });
        },

        deleteJob() {
            this.loadingOverlay = true;
            AppSys_QueueApi.failedJobs.delete(this.job.id)
                .then(() => {
                    return this.$router.push({
                        name: 'app.admin.queue.failed-jobs'
                    });
                })
                .catch(error => {
                    this.errorDialog.dialog = true;
                    this.errorDialog.data = error.response.data;
                })
                .finally(() => {
                    this.loadingOverlay = false;
                    this.confirmDialog = false;
                });
        },

        restartJob(online) {
            this.loadingOverlay = true;
            AppSys_QueueApi.failedJobs.restart(this.job.id, online)
                .then(() => {
                    this.$snackBar({
                        icon: 'mdi-check',
                        text: 'Job has been restarted' + (online ? 'ONLINE!' : '!')
                    });
                    return this.$router.push({
                        name: 'app.admin.queue.failed-jobs'
                    });
                })
                .catch(error => {
                    this.errorDialog.dialog = true;
                    this.errorDialog.data = error.response.data;
                })
                .finally(() => {
                    this.loadingOverlay = false;
                    this.confirmDialog = false;
                });
        },

        triggerConfirmDialog(method) {
            this.confirmDialog = {
                dialog: false,
                target: {
                    method: null,
                    param: null,
                },
            };
            const attr = method.split('^');
            this.confirmDialog.target.method = attr[0];
            if (attr.length > 1) {
                this.confirmDialog.target.param = attr[1];
            }

            this.confirmDialog.dialog = true;
        },

        invokeConfirmDialogMethod() {
            return this[this.confirmDialog.target.method](this.confirmDialog.target.param);
        }
    },

    computed: {
        resolvePayload() {
            return nl2br(this.job.payload.data.command);
        },

        resolveErrorData() {
            return PrettyJson.print(this.errorDialog.data);
        }
    }
}
</script>

