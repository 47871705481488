import BackendService from '../core/backend/backend.service';

const AccountApiService = {

    async get() {
        return BackendService.query('account', {
            'validate': true,
        });
    },

    async summary() {
        return BackendService.get('account/summary');
    },

    async updateGeneral(username_, firstname_, lastname_) {
        return BackendService.put('account/general', {
            username: username_,
            firstname: firstname_,
            lastname: lastname_,
        });
    },

    async getDebugData() {
        return BackendService.get('account/debug');
    },

    async getUserSettings() {
        return BackendService.get('account/settings');
    },

    async updateSettings(key_, value_) {
        return BackendService.update('account/settings', {
            key: key_,
            value: value_,
        });
    },

    async updatePassword(oldPassword, newPassword) {
        return BackendService.post('account/password', {
            old_password: oldPassword,
            new_password: newPassword,
        });
    },

    settings: {
        setOption(key, value) {
            return BackendService.post('account/settings', {
                key: key,
                value: value,
            });
        },

        alterCustomer(customerNo) {
            return BackendService.post('account/alter-customer', {
                customer_no: customerNo,
            });
        },
    },

    orders: {
        all(filters) {
            return BackendService.query('account/orders', filters);
        },

        concrete: {
            header(orderNo) {
                return BackendService.query('account/orders/head', {
                    order_no: orderNo,
                });
            },

            items(orderNo) {
                return BackendService.query('account/orders/items', {
                    order_no: orderNo,
                });
            },

            invoices(orderNo) {
                return AccountApiService.invoices.all({
                    order_no: orderNo,
                    context: 'ORDER_DETAILS',
                });
            },
        },
    },

    invoices: {
        all(filters) {
            return BackendService.query('account/invoices', filters);
        },
    },

    loadings: {
        all(filters) {
            return BackendService.query('account/loadings', filters);
        },

        shipments(loadingId) {
            return BackendService.query('account/loadings/shipments', {
                loading_id: loadingId,
            });
        },

        shipmentLines(shipmentId) {
            return BackendService.query('account/loadings/shipments/lines', {
                shipment_id: shipmentId,
            });
        },
    },

    liteUsers: {
        all() {
            return BackendService.get('account/lite-user');
        },

        get(id) {
            return BackendService.get(BackendService.createParameterURI('account/lite-user', [id]));
        },

        create(username_, password_, notifyEmail_) {
            return BackendService.post('account/lite-user', {
                username: username_,
                notify_email: notifyEmail_,
                password: password_,
            });
        },

        update(id, username_, notifyEmail_) {
            return BackendService.update(BackendService.createParameterURI('account/lite-user', [id]), {
                username: username_,
                notify_email: notifyEmail_,
            });
        },

        updatePassword(id, password_) {
            return BackendService.update(BackendService.createParameterURI('account/lite-user', [id]) + '/password', {
                password: password_,
            });
        },

        permissions: {
            get(id) {
                return BackendService.get(BackendService.createParameterURI('account/lite-user', [id]) + '/permission');
            },

            update(id, permission_, value_) {
                return BackendService.update(BackendService.createParameterURI('account/lite-user', [id]) + '/permission', {
                    permission: permission_,
                    value: value_,
                });
            },

            grantAll(id) {
                return BackendService.post(BackendService.createParameterURI('account/lite-user', [id]) + '/permission/grant-all');
            },

            revokeAll(id) {
                return BackendService.post(BackendService.createParameterURI('account/lite-user', [id]) + '/permission/revoke-all');
            },
        },

    },
};

export default AccountApiService;


