<template>
    <v-container fluid class="mt-10" v-if="!product">
        <v-progress-linear indeterminate
                           color="wellis"
        ></v-progress-linear>
    </v-container>
    <v-container fluid v-else>
        <v-breadcrumbs :items="breadcrumb">
            <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
            </template>
            <template v-slot:item="{ item }">
                <v-breadcrumbs-item v-if="item.name !== null" :disabled="item.isLast" @click="() => $router.push(item.route)">
                    <span class="pointer">{{item.name}}</span>
                </v-breadcrumbs-item>
                <v-breadcrumbs-item v-else :disabled="false" @click="() => $router.push(item.route)">
                    <v-icon style="font-size:26px" class="pointer">{{item.icon}}</v-icon>
                </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs>
        <v-tabs
            v-model="tab"
            background-color="transparent"
            color="wellis"
            grow>
            <v-tab
                href="#tab-data">
                {{$t('v3.components.products.tabs.data')}}
            </v-tab>

            <v-tab
                v-if="hasForecastTab"
                href="#tab-forecast">
                {{$t('v3.components.products.tabs.forecast')}}
            </v-tab>

            <v-tab
                href="#tab-docs">
                {{$t('v3.components.products.tabs.docs')}}
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item value="tab-data">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="12" lg="5" sm="12">
                                <v-responsive>
                                    <v-carousel v-if="productImages.length > 0"
                                                :show-arrows="productImages.length > 1"
                                                :continuous="true" height="auto" hide-delimiter-background>
                                        <v-carousel-item
                                            v-for="item in productImages"
                                            :key="item"
                                            :src="item"
                                        ></v-carousel-item>
                                    </v-carousel>
                                </v-responsive>
                            </v-col>
                            <v-col cols="12" md="12" lg="6" sm="12">
                                <v-row>
                                    <v-col cols="12">
                                        <p class="text-h3 mb-0">{{product.name}}</p>
                                        <p class="text-h5 mb-0 wellis--text">
                                            {{product.catalogNo}}
                                            <v-avatar
                                                v-if="hasPrice && discount > 0"
                                                color="wellis"
                                                size="54"
                                            >
                                                <span class="white--text text-h6">-{{discount}}%</span>
                                            </v-avatar>
                                        </p>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <div v-if="product.description !== null">
                                            <div v-html="nl2br(product.description)"></div>
                                        </div>
                                        <p v-else>
                                            {{$t('components.products.no-description')}}
                                        </p>
                                    </v-col>
                                </v-row>
                                <div v-if="hasPrice">
                                    <v-row v-if="discount > 0">
                                        <v-col cols="12" sm="12" md="12" lg="3" v-if="hasVat" class="text-subtitle-1">
                                            <span class="text-line-through">
                                                {{factoryPrice | toPrice(currency, false)}} + {{$t('components.products.vat')}}
                                            </span>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" :lg="hasVat ? 3 : 12" class="text-subtitle-1">
                                            <span class="font-weight-bold wellis--text text-line-through">
                                                {{factoryPrice | toPrice(currency, hasVat)}}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12" lg="3" v-if="hasVat" class="text-subtitle-1">
                                            <span class="">
                                                {{unitPrice | toPrice(currency, false)}} + {{$t('components.products.vat')}}
                                            </span>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" :lg="hasVat ? 3 : 12" class="text-subtitle-1">
                                            <span class="font-weight-bold wellis--text">
                                                {{unitPrice | toPrice(currency, hasVat)}}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="hasAvailability">
                                        <v-col cols="12" lg="4" sm="12" md="4">
                                            <v-card elevation="1">
                                                <v-card-title>{{$t('components.products.availability.title') | capitalize}}</v-card-title>
                                                <v-card-text>
                                                    <div :class="['border-bottom mb-3', quantityInStock('default') <= 0 ? 'preorder' : '']">
                                                        {{availability}}
                                                    </div>
                                                    <div class="border-bottom mb-3"
                                                         v-if="quantityInStock('m3') > 0 && (this.product.catalogType !== 'NON' && !this.product.configurable)">
                                                        <p class="lead font-weight-bold color-gold">
                                                            {{$t('components.products.m3Stock', {num: `${quantityInStock('m3')} ${this.product.unit}`})}}
                                                        </p>
                                                    </div>
                                                    <div class="border-bottom mb-3"
                                                         v-if="$store.getters['authStore/getCustomerGroup'] === 'NAGYROM'">
                                                        <span class="wellis--text font-weight-bold">W-RO: </span> {{quantityInStockZero('ro')}} {{product.unit}}
                                                    </div>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="!configurable">
                                        <v-col cols="12" class="font-weight-bold">
                                            <label>{{$t('components.products.quantity')}}</label>
                                            <v-text-field v-model="quantity"
                                                          type="number"
                                                          color="wellis"
                                                          style="width: 200px;"
                                                          class="font-weight-bold"
                                                          append-outer-icon="mdi-plus" @click:append-outer="incrementQuantity"
                                                          prepend-icon="mdi-minus" @click:prepend="decrementQuantity">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-row>
                                    <v-col cols="12">
                                        <div v-if="configurable">
                                            <v-btn style="width: 200px;" :to="resolveConfiguratorLink()">{{$t('components.products.start-config')}}</v-btn>
                                            <p class="mt-2 text-muted">* {{$t('components.products.start-config-tip')}}</p>
                                        </div>
                                        <div v-else>
                                            <v-btn style="width: 200px;" @click="addCart">{{$t('components.products.availability.btnAddCart')}}</v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-container v-if="requiredProducts.length > 0" fluid>
                            <div class="display-1">{{$t('components.products.related.required')}}</div>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12" xl="3" lg="12" md="12" sm="12"
                                           v-for="product in requiredProducts" :key="'required_'+product.catalogNo+$store.getters['langStore/current']">
                                        <ProductCard :product="product" card/>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-container>
                        <v-container v-if="suggestedProducts.length > 0" fluid>
                            <div class="display-1">{{$t('components.products.related.suggested')}}</div>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12" xl="3" lg="12" md="12" sm="12"
                                           v-for="product in suggestedProducts" :key="'suggested_'+product.catalogNo+$store.getters['langStore/current']">
                                        <ProductCard :product="product" card/>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item value="tab-forecast" v-if="hasForecastTab">
                <v-card flat>
                    <v-card-text>
                        <v-row class="mt-3">
                            <v-col cols="12" lg="3" md="12" sm="12">
                                <label>{{$t('components.products.quantity')}}</label>
                                <v-text-field v-model="forecast.quantity"
                                              type="number"
                                              color="wellis"
                                              class="font-weight-bold"
                                              append-outer-icon="mdi-plus" @click:append-outer="incrementForecastQuantity"
                                              prepend-icon="mdi-minus" @click:prepend="decrementForecastQuantity">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" lg="9" md="12" sm="12">
                                <h1>{{$t('components.checkout.wanted-delivery-date')}}</h1>
                                <p class="mt-3">{{$t('components.configurator.forecastTooltip')}}</p>
                                <v-simple-table>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>{{$t('components.my-orders.date')}}</th>
                                        <th>{{$t('components.stocklist.sum-quantity')}}</th>
                                    </tr>
                                    </thead>
                                    <tbody v-if="forecast.request === null">
                                    <tr v-for="ms in forecast.data" :key="ms.ms_date+'_'+ms.quantity">
                                        <td v-if="ms.valid === 1">
                                            <v-icon>mdi-check</v-icon>
                                        </td>
                                        <td v-else>
                                            <v-icon>mdi-close</v-icon>
                                        </td>
                                        <td>{{ms.ms_date}}</td>
                                        <td>{{ms.qty}} {{product.unit}}</td>
                                    </tr>
                                    </tbody>
                                    <tbody v-else>
                                    <tr>
                                        <td colspan="3">
                                            <v-progress-linear indeterminate
                                                               color="wellis"
                                            ></v-progress-linear>
                                        </td>
                                    </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item value="tab-docs">
                <v-card flat>
                    <v-card-text>
                        <p>{{$t('v3.components.products.documents.noDocuments')}}</p>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>

<script>
import {EVENT_CART_COUNT_UPDATE, EVENT_LANG_CHANGE, EVENT_PAGE_TITLE_CHANGE} from '../../../core/constants';
import ProductApiService from '../../../api/product.api';
import CartApiService from '../../../api/cart.api';
import {LiteUser, nl2br} from '../../../helpers';
import ProductCard from './ProductCard';
import CheckoutApiService from '../../../api/checkout.api';

export default {
    name: 'ProductDetailsView',
    components: {ProductCard},
    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE,
            'components.products.title',
            this.$route.params.catalogNo,
        ), 100);

        if (!LiteUser.hasPermission(
            this.$store.getters['authStore/isLiteUser'],
            this.$store.getters['authStore/getLiteUserPermission'],
            'products',
        )) {
            this.$ApplGeneral({
                content: this.$t('v32.liteUser.hasNoPermission'),
                onClose: () => {
                    this.$router.push({name: 'app.start'});
                },
            });
            return;
        }

        this.$eventBus.$on(EVENT_LANG_CHANGE, () => {
            this.breadcrumb = [
                {
                    name: null,
                    icon: 'mdi-shopping',
                    route: {name: 'app.products'},
                    isLast: false,
                }];
            this.fetch();
        });

        if (this.$route.params.category) {
            this.fetchCategory();
        }

        this.fetch();
    },

    destroyed() {
        this.$eventBus.$off(EVENT_LANG_CHANGE);
    },

    data() {
        return {
            breadcrumb: [
                {
                    name: null,
                    icon: 'mdi-shopping',
                    route: {name: 'app.products'},
                    isLast: false,
                },
            ],
            category: null,
            productData: null,
            quantity: 1,
            offers: {
                stocklist: [],
                shopord: [],
            },

            tab: null,

            forecast: {
                quantity: 1,
                data: [],
                request: null,
            },
        };
    },

    methods: {
        fetchCategory() {
            this.$store.dispatch(`categoryStore/fetch`)
                .finally(() => {
                    this.category = this.$store.getters['categoryStore/getProductItems']
                        .find(item => item.slug === this.$route.params.category);

                    this.breadcrumb.push({
                        name: this.category.name,
                        icon: 'mdi-folder-home-outline',
                        route: {name: 'app.products.category', params: {category: this.category.slug}},
                        isLast: false,
                    });
                });
        },

        fetch() {
            ProductApiService.getProduct(this.$route.params.catalogNo, {
                'with_related': true,
            }).then(response => {
                this.productData = response.data.product;

                this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE,
                    'components.products.title',
                    this.productData.name,
                );

                this.breadcrumb.push({
                    name: this.productData.name,
                    isLast: true,
                });
            }).catch(() => {
                if (this.$route.params.category !== null) {
                    return this.$router.push({
                        name: 'app.products.category',
                        params: {category: this.$route.params.category},
                    });
                }
                return this.$router.push({name: 'app.start'});
            });
        },

        incrementQuantity() {
            if (this.quantity < 100) {
                this.quantity++;
            }
        },

        decrementQuantity() {
            if (this.quantity > 1) {
                this.quantity--;
            }
        },

        incrementForecastQuantity() {
            if (this.forecast.quantity < 100) {
                this.forecast.quantity++;
            }
        },

        decrementForecastQuantity() {
            if (this.forecast.quantity > 1) {
                this.forecast.quantity--;
            }
        },

        resolveConfiguratorLink() {
            return {
                name: 'app.configurator',
                params: {
                    productIdentifier: this.product.catalogNo,
                    host: 'product',
                },
            };
        },

        addCart() {
            if (!LiteUser.hasPermission(
                this.$store.getters['authStore/isLiteUser'],
                this.$store.getters['authStore/getLiteUserPermission'],
                'place_order',
            )) {
                this.$ApplGeneral({
                    content: this.$t('v32.liteUser.hasNoPermission'),
                });
                return;
            }

            this.loadingOverlay = true;
            let attributes = {
                'coordinator': 'addProductSimple',
                'product': this.product.catalogNo,
                'quantity': this.quantity,
            };
            CartApiService.add(attributes)
                .then(response => {
                    this.$eventBus.$emit(EVENT_CART_COUNT_UPDATE, response.data.count);
                    this.$snackBar({
                        content: this.$t('v3.components.cart.itemAdded'),
                        icon: 'mdi-check',
                        timeout: 1500,
                    });
                })
                .catch(() => {
                    this.$snackBar({
                        content: this.$t('whoops'),
                        icon: 'mdi-alert',
                    });
                })
                .finally(() => {
                    this.loadingOverlay = false;
                    this.quantity = 1;
                });
        },

        fetchForecast() {
            if (this.forecast.request) {
                clearTimeout(this.forecast.request);
                this.forecast.request = null;
            }
            this.forecast.request = setTimeout(() => {
                CheckoutApiService.masterScheduleDates(this.product.catalogNo, this.forecast.quantity)
                    .then(response => {
                        if (response.data.length > 0) {
                            this.forecast.data = response.data;
                        } else {
                            this.forecast.data = [
                                {
                                    ms_date: '-',
                                    qty: 0,
                                    valid: false,
                                },
                            ];
                        }
                    }).finally(() => this.forecast.request = null);
            }, 1000);
        },
    },

    computed: {
        product() {
            return this.productData;
        },

        productImages() {
            if (this.productData === null) {
                return [];
            }
            return this.productData.media.imageUrls;
        },

        hasPrice() {
            return this.productData.price !== null;
        },

        unitPrice() {
            if (this.productData.price === null) {
                return 0;
            }
            return this.productData.price.unitPrice;
        },

        factoryPrice() {
            if (this.productData.price === null) {
                return 0;
            }
            return this.productData.price.factoryPrice;
        },

        currency() {
            if (this.productData.price === null) {
                return null;
            }
            return this.productData.price.currency;
        },

        hasVat() {
            if (this.productData.price === null) {
                return null;
            }
            return this.productData.price.vat;
        },

        discount() {
            if (this.productData === null || this.productData.price === null) {
                return 0;
            }

            return this.productData.price.discount;
        },

        configurable() {
            return this.productData.configurable || false;
        },

        nl2br() {
            return value => nl2br(value);
        },

        availability() {
            if (this.productData.catalogType === 'NON' || this.productData.configurable) {
                return false;
            }
            if (this.productData.quantityInStock['default'] <= 0) {
                return this.$t('components.products.availability.preorder');
            } else {
                if (this.productData.quantityInStock['default'] > 20) {
                    return this.$t('components.products.availability.more', {unit: this.productData.unit});
                } else {
                    return this.$t('components.products.availability.less', {unit: this.productData.unit});
                }
            }
        },

        hasAvailability() {
            return this.productData.catalogType !== 'NON' && this.productData.configurable !== true;
        },

        quantityInStock() {
            return (type = 'default') => {
                return this.productData.quantityInStock[type];
            };
        },

        quantityInStockZero() {
            return (type = 'default') => {
                let qty = this.productData.quantityInStock[type];
                if (qty < 0) {
                    qty = 0;
                }

                return qty;
            };
        },

        requiredProducts() {
            if (!this.productData || !this.product.relatedProducts) {
                return [];
            }
            return [...this.productData.relatedProducts.required, ...this.productData.relatedProducts.packageOffer];
        },

        suggestedProducts() {
            if (!this.productData || !this.product.relatedProducts) {
                return [];
            }
            return this.productData.relatedProducts.suggested;
        },

        hasForecastTab() {
            return this.productData.configurable && !this.productData.partNo.startsWith('WT');
        },
    },

    watch: {
        '$route.params.catalogNo': function() {
            this.productData = null;
            this.breadcrumb = [
                {
                    name: null,
                    icon: 'mdi-shopping',
                    route: {name: 'app.products'},
                    isLast: false,
                }];
            this.fetch();
        },

        'tab': function(val) {
            if (val === 'tab-forecast') {
                this.fetchForecast();
            }
        },

        'forecast.quantity': function(val) {
            if (val < 1 || val > 99 || isNaN(val)) {
                this.forecast.quantity = 1;
                return;
            }
            this.fetchForecast();
        },
    },
};
</script>

<style scoped>

</style>
