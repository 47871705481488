import BackendService from "../core/backend/backend.service";

const CheckoutApiService = {
    async getPreviewData() {
        return BackendService.get('/checkout');
    },

    async getShippingMethods(paymentMethod) {
        return BackendService.query('/checkout/get-shipping-methods', {
            payment_method: paymentMethod
        });
    },

    async getShippingServices(paymentMethod, shippingMethod, address) {
        return BackendService.query('/checkout/get-shipping-services', {
            payment_method: paymentMethod,
            shipping_method: shippingMethod,
            address: address
        });
    },

    async checkout(checkoutData) {
        return BackendService.post('/checkout', {
            payment_method: checkoutData.selectedPaymentMethod,
            shipping_method: checkoutData.selectedShippingMethod,
            shipping: checkoutData.shippingAddress,
            notes: checkoutData.notes,
            shipping_service_code: checkoutData.shippingService,
            forecast: checkoutData.forecast
        });
    },

    async masterScheduleDates(partNo, quantity) {
        return BackendService.query('checkout/master-schedule-dates', {
            part_no: partNo,
            quantity: quantity
        });
    }
}

export default CheckoutApiService;


