<template>
    <v-container fluid>
        <v-container fluid class="mt-10" v-if="mainOverlay">
            <v-progress-linear indeterminate
                               color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container fluid v-else>
            <v-row>
                <v-col cols="12" md="12" sm="12">
                    <v-overlay :value="loadingOverlay" color="white">
                        <v-progress-circular
                            color="wellis"
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-row>
                        <v-col cols="12" xl="12" sm="12">
                            <v-card>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" xl="3" sm="12">
                                            <label>UUID</label>
                                            <v-text-field color="wellis"
                                                          readonly
                                                          :value="historyId">

                                            </v-text-field>
                                        </v-col>

                                        <v-col cols="12" xl="3" sm="12">
                                            <label>Scheduler Id</label>
                                            <v-text-field color="wellis"
                                                          readonly
                                                          :value="history.scheduler_id">

                                            </v-text-field>
                                        </v-col>

                                        <v-col cols="12" xl="3" sm="12">
                                            <label>Job Id</label>
                                            <v-text-field color="wellis"
                                                          readonly
                                                          :value="history.job_id">

                                            </v-text-field>
                                        </v-col>

                                        <v-col cols="12" xl="3" sm="12">
                                            <label>Result</label>
                                            <v-text-field color="wellis"
                                                          readonly
                                                          :value="history.result">

                                            </v-text-field>
                                        </v-col>

                                        <v-col cols="12" xl="3" sm="12">
                                            <label>Started at</label>
                                            <v-text-field color="wellis"
                                                          readonly
                                                          :value="history.started_at | formatDate(true)">

                                            </v-text-field>
                                        </v-col>

                                        <v-col cols="12" xl="3" sm="12">
                                            <label>Finished at</label>
                                            <v-text-field color="wellis"
                                                          placeholder="Still running..."
                                                          readonly
                                                          :value="history.finished_at | formatDate(true)">

                                            </v-text-field>
                                        </v-col>

                                        <v-col cols="12" xl="6" sm="12">
                                            <label>Message</label>
                                            <v-textarea color="wellis"
                                                        readonly
                                                        rows="3"
                                                        no-resize
                                                        :value="history.message || '-'">

                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="12" sm="12" v-if="history.exception !== null">
                            <v-card>
                                <v-card-title>Exception</v-card-title>
                                <v-card-text>
                                    <v-form>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" xl="12" sm="12">
                                                    <pre v-html="resolveException"></pre>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="12" sm="12">
                            <v-card>
                                <v-card-title>Log</v-card-title>
                                <v-card-text>
                                    <v-form>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" xl="12" sm="12">
                                                    <v-textarea :value="history.log"
                                                                color="wellis"
                                                                readonly
                                                                rows="30">
                                                    </v-textarea>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-bottom-navigation fixed style="z-index: 0" grow>
                <v-btn icon :to="{name: 'app.admin.scheduler.history', params: {uuid: this.schedulerId}}">
                    <span>History</span>
                    <v-icon>mdi-keyboard-backspace</v-icon>
                </v-btn>

                <v-btn icon :to="{name: 'app.admin.scheduler.edit', params: {uuid: this.schedulerId}}">
                    <span>{{history.scheduler_name || 'N/A'}}</span>
                    <v-icon>mdi-vector-link</v-icon>
                </v-btn>
                <v-btn icon @click="fetch">
                    <span>Refresh</span>
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-bottom-navigation>
        </v-container>
    </v-container>
</template>

<script>
import {EVENT_PAGE_TITLE_CHANGE_NO_TRANS} from "../../../../core/constants";
import AppSys_SchedulerApi from "../../../../api/app-sys/scheduler.api";
import {PrettyJson} from "../../../../helpers";

export default {
    name: "AdminSchedulerHistoryItem",

    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE_NO_TRANS, 'Scheduler', 'History details'), 100);

        this.fetch();
    },

    data() {
        return {
            mainOverlay: true,
            loadingOverlay: true,

            history: null,
        }
    },

    methods: {
        fetch() {
            this.loadingOverlay = true;
            AppSys_SchedulerApi.history.concrete(this.historyId)
                .then(response => {
                    this.history = response.data.item;
                    setTimeout(() => this.$eventBus.$emit(
                        EVENT_PAGE_TITLE_CHANGE_NO_TRANS, 'Scheduler', 'History details / ' + this.history.scheduler_name
                    ), 100);
                })
                .catch(() => {
                    this.$snackBar({
                        icon: 'mdi-alert',
                        text: this.$t('api-error')
                    })
                })
                .finally(() => {
                    this.mainOverlay = false;
                    this.loadingOverlay = false;
                });
        }
    },

    computed: {
        schedulerId() {
            return this.$route.params.uuid;
        },

        historyId() {
            return this.$route.params.historyId;
        },

        resolveException() {
            return PrettyJson.print(JSON.parse(this.history.exception));
        }
    }
}
</script>

<style scoped>

</style>
