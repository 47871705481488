<template>
    <v-container>
        <h1 class="title noselect">{{$t('components.dashboard.welcome')}}!</h1>
        <v-row class="mt-1">
            <!--            <v-col xl="3" lg="4" md="12" sm="12" cols="12">
                            <v-hover v-slot="{ hover }">
                                <v-card :elevation="hover ? 8 : 2" class="mx-auto pointer">
                                    <v-card-text class=" text-center text-h5">
                                        <span class="wellis&#45;&#45;text noselect">{{$t('components.stocklist.title')}}</span>
                                    </v-card-text>
                                    <v-card-text class="text-center pa-2">
                                        <span class="font-weight-bold" v-if="summary.stock !== null">{{summary.stock}} {{$t('product-s')}}</span>
                                        <v-progress-circular v-else size="20"
                                                             indeterminate
                                                             color="wellis"
                                        ></v-progress-circular>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-col>

                        <v-col xl="3" lg="4" md="12" sm="12" cols="12">
                            <v-hover v-slot="{ hover }">
                                <v-card :elevation="hover ? 8 : 2" class="mx-auto pointer">
                                    <v-card-text class=" text-center text-h5">
                                        <span class="wellis&#45;&#45;text noselect">{{$t('components.shopord.title')}}</span>
                                    </v-card-text>
                                    <v-card-text class="text-center pa-2">
                                        <span class="font-weight-bold" v-if="summary.shopord !== null">{{summary.shopord}} {{$t('product-s')}}</span>
                                        <v-progress-circular v-else size="20"
                                                             indeterminate
                                                             color="wellis"
                                        ></v-progress-circular>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-col>-->

            <v-col xl="3" lg="4" md="12" sm="12" cols="12">
                <v-hover v-slot="{ hover }">
                    <v-card :elevation="hover ? 8 : 2" class="mx-auto pointer" @click="redirectToLastOrder">
                        <v-card-text class=" text-center text-h5">
                            <span class="wellis--text noselect">{{$t('components.dashboard.summary.order')}}</span>
                        </v-card-text>
                        <v-card-text class="text-center pa-2">
                            <span class="font-weight-bold" v-if="summary.last_order !== null">{{summary.last_order.value}}</span>
                            <v-progress-circular v-else size="20"
                                                 indeterminate
                                                 color="wellis"
                            ></v-progress-circular>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>

            <v-col xl="3" lg="4" md="12" sm="12" cols="12">
                <v-hover v-slot="{ hover }">
                    <v-card :elevation="hover ? 8 : 2" class="mx-auto pointer" @click="redirectToLastLoading">
                        <v-card-text class=" text-center text-h5">
                            <span class="wellis--text noselect">{{$t('components.dashboard.summary.shipment')}}</span>
                        </v-card-text>
                        <v-card-text class="text-center pa-2">
                            <span class="font-weight-bold" v-if="summary.last_shipment !== null">{{summary.last_shipment.value}}</span>
                            <v-progress-circular v-else size="20"
                                                 indeterminate
                                                 color="wellis"
                            ></v-progress-circular>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>

            <v-col xl="3" lg="4" md="12" sm="12" cols="12">
                <v-hover v-slot="{ hover }">
                    <v-card :elevation="hover ? 8 : 2" class="mx-auto pointer" @click="redirectToInvoices">
                        <v-card-text class=" text-center text-h5">
                            <span class="wellis--text noselect">{{$t('components.dashboard.summary.balance')}}</span>
                        </v-card-text>
                        <v-card-text class="text-center pa-2">
                        <span class="font-weight-bold"
                              v-if="summary.balance !== null && !isNaN(summary.balance.details.value)">
                            {{summary.balance.details.value | toPrice(summary.balance.details.currency, false)}}
                        </span>
                            <span v-else-if="summary.balance !== null && summary.balance.details.value.includes('*')">
                                {{summary.balance.details.value}}
                            </span>
                            <v-progress-circular v-else size="20"
                                                 indeterminate
                                                 color="wellis"
                            ></v-progress-circular>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>

        <v-container v-if="fetchingNews" fluid class="mt-10">
            <v-progress-linear
                indeterminate
                color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container fluid class="mt-10" v-if="!fetchingNews">
            <v-subheader class="text-h5 pa-0 noselect">{{$t('components.news.special')}}</v-subheader>
            <v-row v-if="news.special.length > 0">
                <v-col md="4" sm="12" xs="12" v-for="item in news.special" :key="'special_'+item.id+'_'+$store.getters['langStore/current']">
                    <NewsItemCard :data="item"/>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <p v-if="hasLiteUserPermission('news')">
                        <v-icon color="wellis">mdi-alert-circle</v-icon>
                        {{$t('components.news.no-news')}}
                    </p>
                    <p v-else>
                        <v-icon color="wellis">mdi-alert-circle</v-icon>
                        {{$t('v32.liteUser.hasNoPermission')}}
                    </p>
                </v-col>
            </v-row>
        </v-container>

        <v-container fluid class="mt-10" v-if="!fetchingNews">
            <v-subheader class="text-h5 pa-0 noselect">{{$t('components.news.latests')}}</v-subheader>
            <v-row v-if="news.latests.length > 0">
                <v-col md="4" sm="12" xs="12" v-for="item in news.latests" :key="'special_'+item.id+'_'+$store.getters['langStore/current']">
                    <NewsItemCard :data="item"/>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <p v-if="hasLiteUserPermission('news')">
                        <v-icon color="wellis">mdi-alert-circle</v-icon>
                        {{$t('components.news.no-news')}}
                    </p>
                    <p v-else>
                        <v-icon color="wellis">mdi-alert-circle</v-icon>
                        {{$t('v32.liteUser.hasNoPermission')}}
                    </p>
                </v-col>
            </v-row>
            <div class="mt-5 text-center" v-if="news.special.length > 0 || news.latests.length > 0">
                <v-btn :to="{name: 'app.news'}" x-large>
                    {{$t('components.news.btnShowMore')}}
                </v-btn>
            </div>
        </v-container>
    </v-container>
</template>

<script>
import {EVENT_LANG_CHANGE, EVENT_PAGE_TITLE_CHANGE} from '../../../core/constants';
import AccountApiService from '../../../api/account.api';
import NewsItemCard from '../news/NewsItemCard';
import NewsApiService from '../../../api/news.api';

export default {
    name: 'DashboardView',
    components: {NewsItemCard},
    data() {
        return {
            summary: {
                stock: null,
                transit: null,
                shopord: null,
                last_order: null,
                last_shipment: null,
                balance: null,
            },

            fetchingNews: true,

            news: {
                special: [],
                latests: [],
            },
        };
    },

    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE, 'components.dashboard.title'), 100);

        this.$eventBus.$on(EVENT_LANG_CHANGE, () => {
            this.news.special = [];
            this.news.latests = [];
            this.fetchNews();
        });

        this.fetch();
    },

    destroyed() {
        this.$eventBus.$off(EVENT_LANG_CHANGE);
    },

    methods: {

        fetch() {
            return Promise.all([this.fetchSummary(), this.fetchNews()]);
        },

        fetchSummary() {
            return new Promise((resolve, reject) => {
                AccountApiService.summary()
                    .then(response => {
                        this.summary = response.data;
                        resolve();
                    })
                    .catch(() => {
                        this.summary = {
                            stock: '-',
                            transit: '-',
                            shopord: '-',
                            last_order: {value: '-'},
                            last_shipment: {value: '-'},
                            balance: {details: {value: '', currency: 'HUF'}},
                        };
                        reject();
                    });
            });
        },

        async fetchNews() {
            if (this.hasLiteUserPermission('news')) {
                this.fetchingNews = true;
                return new Promise((resolve, reject) => {
                    NewsApiService.get('ACTIVE', true)
                        .then(response => this.news.special = response.data.items)
                        .catch(reject);

                    NewsApiService.limitedGet('ACTIVE', 3)
                        .then(response => {
                            this.news.latests = response.data.items;
                            this.fetchingNews = false;
                            resolve();
                        })
                        .catch(reject);
                });
            } else {
                this.fetchingNews = false;
            }
        },

        redirectToLastOrder() {
            if (this.summary.last_order.value !== null && this.summary.last_order.value !== '-') {
                this.$router.push({
                    name: 'app.account.orders.details',
                    params: {
                        orderNo: this.summary.last_order.value,
                    },
                });
            }
        },

        redirectToLastLoading() {
            if (this.summary.last_shipment.value !== null && this.summary.last_shipment.value !== '-') {
                this.$router.push({
                    name: 'app.account.loadings.details',
                    params: {
                        loadingId: this.summary.last_shipment.value,
                    },
                });
            }
        },

        redirectToInvoices() {
            if (this.hasLiteUserPermission('my_invoices')) {
                this.$router.push({name: 'app.account.invoices'});
            } else {
                this.$ApplGeneral({
                    content: this.$t('v32.liteUser.hasNoPermission'),
                });
            }
        },

        hasLiteUserPermission(permission) {
            if (!this.$store.getters['authStore/isLiteUser']) {
                return true;
            }
            let permission_ = this.$store.getters['authStore/getLiteUserPermission'].find(x => x.permission === permission);
            return permission_.objstate === 'ACTIVE';
        },
    },
};
</script>

<style scoped>

</style>
