<template>
    <v-content>
        <v-system-bar v-if="topMessage" :color="topMessage.color" class="white--text" app>
            <v-container fluid class="text-center">
                <span>{{topMessage.text}}</span>
            </v-container>
        </v-system-bar>
        <v-overlay :value="loadingOverlay">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-container fill-height>
            <v-layout align-center justify-center>
                <v-flex class="text-xs-center login-form">
                    <div class="display-1 mb-3 text-center">
                        <img :src="require('@/assets/img/logo.svg')" alt="Wellis Online System logo" width="205">
                    </div>
                    <div v-if="$route.name === 'app.guest.reset-password'">
                        <ResetPassword/>
                    </div>
                    <div v-else>
                        <Login v-if="!authenticated"/>
                        <AfterLoginRealmSelect v-if="authenticated && !hasRealm"/>
                    </div>
                    <div class="text-center" v-html="$App.versionString"></div>
                    <v-divider class="mt-1 mb-1"></v-divider>
                    <div class="text-center">
                        2016 - {{(new Date().getFullYear())}} &copy; Wellis Online System
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
        <ApplicationGeneralMessage/>
    </v-content>
</template>

<script>
import Login from "../components/guest/Login";
import Vue from "vue";
import AfterLoginRealmSelect from "../components/guest/AfterLoginRealmSelect";
import axios from "axios";
import {EVENT_LANG_CHANGE, EVENT_PAGE_TITLE_CHANGE, EVENT_PAGE_TITLE_CHANGE_NO_TRANS} from "../core/constants";
import ApplicationGeneralMessage from "../components/common/ApplicationGeneralMessage";
import ResetPassword from "../components/guest/ResetPassword";

export default {
    name: "GuestLayout",
    components: {ResetPassword, ApplicationGeneralMessage, AfterLoginRealmSelect, Login},

    created() {
        Vue.prototype.$GuestLayout = this;
        this.fetchTopMessage();

        this.$eventBus.$on(EVENT_PAGE_TITLE_CHANGE, (title = null) => {
            if (this.subTitle) {
                document.title = `${this.subTitle} / ${this.$t(title)} - ${this.$t('title')}`;
            } else {
                document.title = `${this.$t(title)} - ${this.$t('title')}`;
            }
        });

        this.$eventBus.$on(EVENT_PAGE_TITLE_CHANGE_NO_TRANS, (title = null) => {
            if (this.subTitle) {
                document.title = `${this.subTitle} / ${title} - ${this.$t('title')}`;
            } else {
                document.title = `${title} - ${this.$t('title')}`;
            }
        });

        this.$eventBus.$on(EVENT_LANG_CHANGE, this.fetchTopMessage);

    },

    data() {
        return {
            loadingOverlay: false,
            topMessage: null,
        }
    },

    methods: {
        showOverlay() {
            return this.loadingOverlay = true;
        },

        hideOverlay() {
            return this.loadingOverlay = false;
        },

        async fetchTopMessage(){
            axios.get(window.location.origin + '/message.json?t=' + Date.now() + '&r=' + Math.random() * 1000)
                .then(response => {
                    if(response.data[this.$store.getters['langStore/current']]) {
                        this.topMessage = response.data[this.$store.getters['langStore/current']];
                    }
                });
        },
    },

    computed: {
        versionString() {
             return this.$App.versionString;
        },

        authenticated() {
            return this.$store.getters['authStore/check'];
        },

        hasRealm() {
            return this.$store.getters['authStore/hasRealm'];
        }
    }
}
</script>

<style scoped>
.login-form {
    max-width: 550px;
}
</style>
