<template>
    <v-content>
        <v-container fill-height="fill-height">
            <v-layout align-center justify-center>
                <v-flex class="text-xs-center login-form">
                    <div class="display-1 mb-3 text-center">
                        <img :src="require('@/assets/img/logo.svg')" alt="Wellis Online System logo" width="205">
                    </div>
                    <v-container>
                        <v-card light elevation="2">
                            <v-card-text class="pa-10">
                                <div class="subheading text-center text-h1 color-wellis font-weight-bold mb-5">
                                    <template>404</template>
                                </div>
                                <v-card-text class="text-center text-h2">
                                    {{$t('components.httpnotfound.text')}}
                                </v-card-text>
                                <div class="mt-5 mb-5"></div>
                                <v-layout align-center justify-center>
                                    <v-btn :to="{name:'app.start'}" color="secondary">{{$t('components.httpnotfound.return')}}</v-btn>
                                </v-layout>
                            </v-card-text>
                        </v-card>
                    </v-container>
                    <div class="text-center" v-html="$App.versionString"></div>
                    <v-divider class="mt-1 mb-1"></v-divider>
                    <div class="text-center">
                        2016 - {{(new Date().getFullYear())}} &copy; Wellis Online System <br>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </v-content>
</template>

<script>
export default {
    name: "HttpNotFound"
}
</script>

<style>

</style>
