<template>
    <v-app app>
        <v-btn
            v-scroll="onScroll"
            v-show="fab"
            fab
            dark
            fixed
            bottom
            right
            large
            color="wellis"
            @click="toTop">
            <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
        <router-view/>
        <SnackBar/>
        <v-dialog
            v-model="versionDialog"
            max-width="650px" persistent>
            <v-card>
                <v-card-title class="wellis--text">Wellis Online System Application</v-card-title>
                <v-card-text class="black--text font-weight-bold">
                    <div>
                        {{$t('outdatedApp')}}
                    </div>
                    <v-container class="text-center">
                        <v-btn color="wellis-text-white" @click="forceReload">
                            {{$t('btnUpdate')}}
                        </v-btn>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>


import BackendService from "./core/backend/backend.service";
import {EVENT_LANG_CHANGE_SIDEBAR} from "./core/constants";
import Vue from "vue";
import SnackBar from "./components/common/SnackBar";

export default {
    name: 'App',

    components: {SnackBar},

    created() {
        Vue.prototype.$App = this;
        this.$store.dispatch('appSettingsStore/init');
        this.version.ui = this.$wos.version;
        BackendService.checkServerReadiness()
            .then(response => {
                this.$set(this.version, 'server', response.data.version);
                this.$set(this, 'warnings', response.data.warning);
                this.$store.dispatch('configStore/updateConfiguration', response.data.config);
                this.checkClientVersion();
            })
            .catch(() => this.$set(this, 'version', false));

        this.$eventBus.$on(EVENT_LANG_CHANGE_SIDEBAR, () => {
            this.$store.dispatch('explorerStore/removeLangSensitives');
        });
    },

    data: () => ({
        version: {
            ui: 'n/a',
            server: 'n/a'
        },
        warnings: null,
        fab: false,

        versionDialog: false,
    }),

    methods: {
        onScroll(e) {
            if (typeof window === 'undefined') return
            const top = window.pageYOffset || e.target.scrollTop || 0
            this.fab = top > 20
        },

        toTop() {
            this.$vuetify.goTo(0)
        },

        checkClientVersion() {
            return new Promise(resolve => {
                const currentVersion = `${this.version.ui} (${parseInt(document.documentElement.dataset.build).toString(16)})`;
                let clientVer = localStorage.getItem('client_ver');
                if (!clientVer) {
                    localStorage.setItem('client_ver', currentVersion);
                }
                if (process.env.NODE_ENV === 'production' && clientVer !== currentVersion) {
                    this.versionDialog = true;
                }
                resolve([currentVersion, clientVer]);
            });
        },

        forceReload() {
            const currentVersion = `${this.version.ui} (${parseInt(document.documentElement.dataset.build).toString(16)})`;
            localStorage.setItem('client_ver', currentVersion);
            location.reload(true);
        }
    },

    computed: {
        versionString() {
            return `<b>API</b> <span class="wellis--text">v${this.version.server}</span> | <b>UI</b> <span class="wellis--text">v${this.version.ui}</span>`;
        },

        isServerAvailable() {
            return this.version.server !== false;
        },
    },
};
</script>
