import BackendService from "../../core/backend/backend.service";

const AppSys_QueueApi = {
    jobs: {
        countRows(queue_ = null) {
            return BackendService.query('app-sys/queue/job', {
                queue: queue_,
                count: true
            });
        },

        get(queue_ = null, fetchAll_ = false) {
            return BackendService.query('app-sys/queue/job', {
                queue: queue_,
                fetch_all: fetchAll_
            });
        },

        concrete(jobId) {
            return BackendService.get(
                BackendService.createParameterURI('app-sys/queue/job', [jobId])
            );
        }
    },

    failedJobs: {
        countRows(queue_ = null, connection_ = null, notifiedOnly_ = false) {
            return BackendService.query('app-sys/queue/failed-job', {
                queue: queue_,
                connection: connection_,
                notified_only: notifiedOnly_,
                count: true
            });
        },

        get(queue_ = null, connection_ = null, notifiedOnly_ = false, fetchAll_ = false) {
            return BackendService.query('app-sys/queue/failed-job', {
                queue: queue_,
                connection: connection_,
                notified_only: notifiedOnly_,
                fetch_all: fetchAll_
            });
        },

        concrete(failedJobId) {
            return BackendService.get(
                BackendService.createParameterURI('app-sys/queue/failed-job', [failedJobId])
            );
        },

        delete(failedJobId) {
            return BackendService.delete(
                BackendService.createParameterURI('app-sys/queue/failed-job', [failedJobId])
            )
        },

        restart(failedJobId, online_ = 'false') {
            return BackendService.post(
                BackendService.createParameterURI('app-sys/queue/failed-job', [failedJobId]), {
                    online: online_
                }
            )
        }
    }
};


export default AppSys_QueueApi;
