export default {
    namespaced: true,

    state: {
        content: null,
        color: 'wellis',
        timeout: 5000,
        closeButton: true,
        icon: null,
        onClick: null,
    },

    getters: {
        //
    },

    actions: {
        showMessage(context, data) {
            return new Promise(resolve => {
                context.commit('update', data);
                resolve();
            });
        }
    },

    mutations: {
        update(state, data) {
            state.content = data.content;
            state.color = data.color;
            state.color = data.color;
            state.timeout = data.timeout;
            state.closeButton = data.closeButton;
            state.icon = data.icon;
            state.onClick = data.onClick;
        }
    }
}
