<template>
    <v-container fluid>
        <v-container fluid class="mt-10" v-if="mainOverlay">
            <v-progress-linear indeterminate
                               color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container fluid v-else>
            <v-row>
                <v-col cols="12" md="12" sm="12">
                    <v-overlay :value="loadingOverlay" color="white" z-index="999">
                        <v-progress-circular
                            color="wellis"
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-card color="transparent2">
                        <v-card-title>
                            <v-row>
                                <v-col cols="12" sm="12" offset-lg="8" lg="4">
                                    <v-text-field
                                        v-model="dt.search"
                                        color="wellis"
                                        append-icon="mdi-magnify"
                                        label="Search"
                                        single-line
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table
                                :loading="dt.loading"
                                loading-text="Fetching users... Please wait."
                                :headers="dt.headers"
                                :items="users"
                                item-class="wellis"
                                :search="dt.search">
                                <template v-slot:header.actions>
                                    <v-btn color="wellis-text-white" small @click="openEditorDialog()">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn text
                                                   rounded
                                                   icon
                                                   color="wellis"
                                                   v-bind="attrs"
                                                   v-on="on">
                                                <v-icon>mdi-dots-horizontal</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item>
                                                <v-btn
                                                    outlined
                                                    width="100%"
                                                    color="wellis"
                                                    @click="openEditorDialog(item.id)">
                                                    Edit
                                                </v-btn>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-btn
                                                    outlined
                                                    width="100%"
                                                    color="wellis"
                                                    :to="{name: 'app.admin.users.fnd.lite-users', params: {fndUserId: item.id}}">
                                                    Lite users
                                                </v-btn>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </template>
                                <template v-slot:item.id="{ item }">
                                    <b>#{{item.id}}</b>
                                </template>

                                <template v-slot:item.customer_no="{ item }">
                                    <span class="wellis--text font-weight-bold">{{item.customer_no}}</span>
                                </template>

                                <template v-slot:item.objstate="{ item }">
                                    <v-chip small v-if="item.objstate === 'ACTIVE'" color="wellis-text-white">Active</v-chip>
                                    <v-chip small v-else>Inactive</v-chip>
                                </template>
                                <template v-slot:item.user_type="{ item }">
                                    <v-chip small v-if="item.user_type === 'ADMIN'" color="wellis-text-white">Admin</v-chip>
                                    <v-chip small v-else>User</v-chip>
                                </template>
                                <template v-slot:item.last_login_at="{ item }">
                                    {{item.last_login_at | formatDate(true)}}
                                </template>
                                <template v-slot:item.password_expired_at="{ item }">
                                    <v-chip small v-if="item.password_expired_at !== null" color="wellis-text-white">
                                        {{item.password_expired_at | formatDate(true)}}
                                    </v-chip>
                                    <v-chip small v-else>No</v-chip>
                                </template>
                                <template v-slot:item.created_at="{ item }">
                                    {{item.created_at | formatDate(true)}}
                                </template>
                                <template v-slot:item.updated_at="{ item }">
                                    {{item.updated_at | formatDate(true)}}
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-bottom-navigation fixed style="z-index: 0" grow>
                <v-btn icon @click="fetch">
                    <span>Refresh</span>
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-bottom-navigation>

            <!-- Editor Dialog -->
            <v-dialog
                v-model="editor.dialog"
                width="800">
                <v-card>
                    <v-toolbar
                        dark
                        color="wellis">
                        <v-btn
                            icon
                            dark
                            @click="editor.dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{editorDialogTitle}}</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="mt-5">
                        <v-form ref="userEditorForm" lazy-validation v-model="editor.__formValid" @submit="userEditorFormHandler">
                            <v-text-field color="wellis"
                                          single-line
                                          outlined
                                          dense
                                          label="E-mail address (username) * "
                                          clearable
                                          counter="100"
                                          maxLength="100"
                                          :rules="[v => !!v || this.$t('v3.mandatoryField')]"
                                          v-model="editor.user.email"/>
                            <v-text-field color="wellis"
                                          single-line
                                          outlined
                                          dense
                                          label="IFS Customer No *"
                                          clearable
                                          counter="20"
                                          maxLength="20"
                                          :rules="[v => !!v || this.$t('v3.mandatoryField')]"
                                          v-model="editor.user.customer_no"/>
                            <v-text-field color="wellis"
                                          single-line
                                          outlined
                                          dense
                                          label="Firstname *"
                                          clearable
                                          counter="50"
                                          maxLength="50"
                                          :rules="[v => !!v || this.$t('v3.mandatoryField')]"
                                          v-model="editor.user.firstname"/>
                            <v-text-field color="wellis"
                                          single-line
                                          outlined
                                          dense
                                          label="Lastname *"
                                          clearable
                                          counter="50"
                                          maxLength="50"
                                          :rules="[v => !!v || this.$t('v3.mandatoryField')]"
                                          v-model="editor.user.lastname"/>
                            <v-select v-model="editor.user.objstate"
                                      label="Status *"
                                      item-text="text"
                                      outlined
                                      dense
                                      item-value="value"
                                      :items="[{text: 'Active', value: 'ACTIVE'}, {text: 'Inactive', value: 'INACTIVE'}]"
                                      item-color="wellis"
                                      color="wellis"
                                      :rules="[v => !!v || this.$t('v3.mandatoryField')]">
                            </v-select>
                            <v-select v-model="editor.user.default_language"
                                      label="Default language *"
                                      outlined
                                      v-if="editor.user.id === null"
                                      dense
                                      :items="$store.getters['langStore/supported']"
                                      item-color="wellis"
                                      color="wellis"
                                      :rules="[v => !!v || this.$t('v3.mandatoryField')]">
                            </v-select>
                            <v-text-field color="wellis"
                                          single-line
                                          outlined
                                          dense
                                          :label="editor.id === null ? 'Password' : 'Password (leave empty if no change)'"
                                          :type="editor.visiblePassword ? 'text' : 'password'"
                                          autocomplete="new-password"
                                          :rules="editor.id === null ? [v => !!v || this.$t('v3.mandatoryField')] : []"
                                          v-model="editor.user.password">
                                <template v-slot:append-outer>
                                    <v-btn color="wellis mr-2" outlined @click="generatePassword()">
                                        <v-icon>
                                            mdi-alphabet-latin
                                        </v-icon>
                                    </v-btn>
                                </template>
                            </v-text-field>
                            <v-select v-model="editor.user.password_expired_at"
                                      label="Password expired *"
                                      item-text="text"
                                      outlined
                                      dense
                                      item-value="value"
                                      :items="[{text: 'Yes', value: 'TRUE'}, {text: 'No', value: 'FALSE'}]"
                                      item-color="wellis"
                                      color="wellis"
                                      :rules="[v => !!v || this.$t('v3.mandatoryField')]">
                            </v-select>
                            <v-btn block color="wellis-text-white" type="submit">Save</v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
    </v-container>
</template>

<script>
import {EVENT_PAGE_TITLE_CHANGE_NO_TRANS} from '../../../../../core/constants';
import AppSys_FndUserApi from '../../../../../api/app-sys/fnduser.api';
import {randomString} from '../../../../../helpers';

export default {
    name: 'AdminFrontendUsersView',

    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE_NO_TRANS, 'Users', 'Frontend users'), 100);
        this.fetch().then(() => this.dt.search = this.$route.query.search || null);
    },

    data() {
        return {
            loadingOverlay: false,
            mainOverlay: true,

            dt: {
                search: null,
                headers: [
                    {text: '', align: 'start', sortable: false, value: 'actions'},
                    {text: 'ID', align: 'start', sortable: true, value: 'id'},
                    {text: 'Username', align: 'start', sortable: true, value: 'email'},
                    {text: 'Customer No', align: 'start', sortable: true, value: 'customer_no'},
                    {text: 'Firstname', align: 'start', sortable: true, value: 'firstname'},
                    {text: 'Lastname', align: 'start', sortable: true, value: 'lastname'},
                    {text: 'Objstate', align: 'start', sortable: true, value: 'objstate'},
                    {text: 'Password expired at', align: 'start', sortable: true, value: 'password_expired_at'},
                    {text: 'User type', align: 'start', sortable: true, value: 'user_type'},
                    {text: 'Last login at', align: 'start', sortable: true, value: 'last_login_at'},
                    {text: 'Created at', align: 'start', sortable: true, value: 'created_at'},
                    {text: 'Updated at', align: 'start', sortable: true, value: 'updated_at'},
                ],
            },

            users: [],

            editor: {
                dialog: false,
                __formValid: true,
                visiblePassword: false,
                user: {
                    id: null,
                    email: null,
                    customer_no: null,
                    firstname: null,
                    lastname: null,
                    objstate: null,
                    password_expired_at: null,
                    password: null,
                    default_language: null
                },
            },
        };
    },

    methods: {
        fetch() {
            this.loadingOverlay = true;
            this.users = [];
            return new Promise((resolve, reject) => {
                AppSys_FndUserApi.get()
                    .then(response => {
                        this.users = response.data.items.map(item => {
                            item.actions = null;
                            return item;
                        });
                        resolve();
                    })
                    .catch(() => {
                        this.$ApplGeneral({
                            content: this.$t('api-error'),
                        });
                        reject();
                    })
                    .finally(() => {
                        this.loadingOverlay = false;
                        this.mainOverlay = false;
                    });
            });
        },

        openEditorDialog(userId = null) {
            if (userId) {
                this.loadingOverlay = true;
                AppSys_FndUserApi.concrete(userId)
                    .then(response => {
                        this.editor.user.id = response.data.fndUser.id;
                        this.editor.user.email = response.data.fndUser.email;
                        this.editor.user.customer_no = response.data.fndUser.customer_no;
                        this.editor.user.firstname = response.data.fndUser.firstname;
                        this.editor.user.lastname = response.data.fndUser.lastname;
                        this.editor.user.objstate = response.data.fndUser.objstate;
                        this.editor.user.password = null;
                        this.editor.user.password_expired_at = response.data.fndUser.password_expired_at !== null ? 'TRUE' : 'FALSE';

                        this.editor.visiblePassword = false;
                        this.editor.dialog = true;
                        this.$nextTick(() => this.$refs.userEditorForm.resetValidation());
                    })
                    .catch(() => {
                        this.$ApplGeneral({
                            content: this.$t('api-error'),
                        });
                    })
                    .finally(() => {
                        this.loadingOverlay = false;
                    });
            } else {
                this.clearUserEditorData();
                this.editor.dialog = true;
                this.$nextTick(() => this.$refs.userEditorForm.resetValidation());
            }
        },

        userEditorFormHandler(e) {
            e.preventDefault();

            this.validateEditorForm()
                .then(() => {
                    this.loadingOverlay = true;
                    if (this.editor.user.id !== null) { // UPDATE
                        let userData = {...this.editor.user};
                        delete userData.id;
                        AppSys_FndUserApi.update(this.editor.user.id, userData)
                            .then(response => {
                                this.clearUserEditorData();
                                this.$refs.userEditorForm.resetValidation();
                                this.editor.dialog = false;
                                const ind = this.users.findIndex(x => x.id === response.data.fndUser.id);
                                Object.keys(this.users[ind]).forEach(key => {
                                    this.users[ind][key] = response.data.fndUser[key];
                                });
                            })
                            .catch(() => {
                                let content_ = this.$t('whoops');

                                this.$ApplGeneral({
                                    content: content_,
                                });
                            })
                            .finally(() => {
                                this.loadingOverlay = false;
                            });
                    } else { // CREATE
                        AppSys_FndUserApi.create(this.editor.user)
                            .then(response => {
                                this.users.push(response.data.fndUser);
                                this.clearUserEditorData();
                                this.$refs.userEditorForm.resetValidation();
                                this.editor.dialog = false;
                            })
                            .catch(() => {
                                let content_ = this.$t('whoops');

                                this.$ApplGeneral({
                                    content: content_,
                                });
                            })
                            .finally(() => {
                                this.loadingOverlay = false;
                            });
                    }
                });
        },

        validateEditorForm() {
            return new Promise(resolve => {
                if (this.$refs.userEditorForm.validate()) {
                    resolve();
                }
            });
        },

        clearUserEditorData() {
            this.$set(this.editor, 'user', {
                id: null,
                email: null,
                customer_no: null,
                firstname: null,
                lastname: null,
                objstate: null,
                password_expired_at: null,
                password: null,
            });
        },

        generatePassword() {
            this.editor.user.password = randomString(10);
            this.editor.visiblePassword = true;
        },
    },

    computed: {
        editorDialogTitle() {
            return this.editor.user.id === null ? 'Create user' : 'Edit user';
        },
    },
};
</script>

<style scoped>

</style>