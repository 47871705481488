<template>
    <v-alert v-if="hasWarning"
             type="warning"
             shaped
             transition="scale-transition"
             color="wellis"
             border="left">
        <div v-for="(line, index) in warning" :key="index">
            <div v-html="line"></div>
        </div>
    </v-alert>
</template>

<script>
import {empty} from "../../helpers";

export default {
    name: "ApplicationWarning",

    computed: {
        hasWarning() {
            let warnings = this.$App.warnings;
            return warnings !== null && warnings.enabled === true && !empty(warnings[this.$store.getters['langStore/current']]);
        },

        warning() {
            return this.hasWarning ? this.$App.warnings[this.$store.getters['langStore/current']] : [];
        }
    }
}
</script>

<style scoped>

</style>
