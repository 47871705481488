var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto pointer overflow-hidden",attrs:{"max-width":"485","min-height":"375","elevation":hover ? 8 : 2,"to":{
            name: 'app.news.view',
            params: {
                date: _vm.$helpers.formatDate(_vm.data.created_at),
                slug: _vm.data.slug
            }
        }}},[_c('v-img',{staticClass:"white--text align-end",attrs:{"height":"200px","lazy-src":require('@/assets/img/placeholder_1800x600.jpg'),"src":_vm.data.header_image_url}},[_c('v-card-title',[_vm._v(_vm._s(_vm.data.title))])],1),_c('v-card-subtitle',{staticClass:"pb-2 wellis--text font-weight-bold"},[_c('span',[_vm._v(_vm._s(_vm.data.author_name))]),_c('span',{staticClass:"float-right"},[_vm._v(_vm._s(_vm._f("formatDate")(_vm.data.created_at)))])]),_c('v-card-text',{staticClass:"text--primary"},[(!_vm.empty(_vm.data.lead))?_c('p',{domProps:{"innerHTML":_vm._s(_vm.nl2br(_vm.data.lead))}}):_c('p',[_vm._v(" "+_vm._s(_vm.$t('components.news.no-lead'))+" ")])])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }