export default {
    namespaced: true,

    state: {
        visible: false,
        closeBtn: true,
        content: null,
        onClose: null
    },

    getters: {
        //
    },

    actions: {
        raise(context, data) {
            return new Promise(resolve => {
                context.commit('update', data);
                resolve();
            });
        }
    },

    mutations: {
        update(state, data) {
            state.visible = data.visible;
            state.closeBtn = data.closeBtn;
            state.content = data.content;
            state.onClose = data.onClose;
        }
    }
}
