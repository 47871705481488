<template>
    <v-container fluid>
        <v-container fluid class="mt-10" v-if="mainOverlay">
            <v-progress-linear indeterminate
                               color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container fluid v-else>
            <v-row>
                <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                    <v-overlay :value="loadingOverlay" color="white">
                        <v-progress-circular
                            color="wellis"
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-card color="transparent2">
                        <v-card-text>
                            <v-simple-table>
                                <thead>
                                <tr>
                                    <th></th>
                                    <th width="8%">Row ID</th>
                                    <th>Name</th>
                                    <th>Slug (URL)</th>
                                    <th>Restriction</th>
                                    <th>Status</th>
                                    <th>Created at</th>
                                    <th>Updated at</th>
                                </tr>
                                </thead>
                                <Draggable tag="tbody" v-model="categories" @end="onSortUpdate">
                                    <tr v-for="category in categories" :key="`${category.id}_${category.lang}`">
                                        <td>
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn text
                                                           rounded
                                                           icon
                                                           color="wellis"
                                                           v-bind="attrs"
                                                           v-on="on">
                                                        <v-icon>mdi-dots-horizontal</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item>
                                                        <v-btn
                                                            class="ma-2"
                                                            outlined
                                                            width="100%"
                                                            color="wellis"
                                                            @click="editCategoryDialog(category.id)">
                                                            Edit
                                                        </v-btn>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-btn
                                                            class="ma-2"
                                                            outlined
                                                            width="100%"
                                                            color="wellis"
                                                            @click="confirmDelete(category)">
                                                            Delete
                                                        </v-btn>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </td>
                                        <td width="8%">
                                            <v-icon>mdi-drag-variant</v-icon>
                                            #{{category.id}}
                                        </td>
                                        <td>{{category.name}}</td>
                                        <td>
                                            <v-chip small>/{{category.slug}}</v-chip>
                                        </td>
                                        <td>
                                            <v-chip v-if="category.restricted" color="secondary" small>Restricted</v-chip>
                                            <v-chip v-else color="wellis-text-white" small>Public</v-chip>
                                        </td>
                                        <td>
                                            <v-chip v-if="category.objstate === 'ACTIVE'" color="wellis-text-white" small>Active</v-chip>
                                            <v-chip v-else small>Inactive</v-chip>
                                        </td>
                                        <td>{{category.created_at | formatDate(true)}}</td>
                                        <td>{{category.updated_at | formatDate(true)}}</td>
                                    </tr>
                                </Draggable>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-bottom-navigation fixed style="z-index: 0" grow>
                <v-btn icon @click="() => this.fetch().then(() => this.$scrollTop())">
                    <span>Refresh</span>
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
                <v-btn icon @click="createCategoryDialog">
                    <span>New category</span>
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </v-bottom-navigation>
        </v-container>
        <v-dialog
            v-model="confirmDialog"
            persistent
            max-width="420"
        >
            <v-card>
                <v-card-title class="text-h5">Confirmation</v-card-title>
                <v-card-text class="text-center">
                    <v-icon x-large color="wellis">mdi-progress-question</v-icon>
                    <br><br>
                    Do you really want to delete this category?
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="black"
                        text
                        @click="confirmDialog = false"
                    >
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="wellis"
                        text
                        @click="deleteCategory"
                    >
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Editor dialog -->
        <v-dialog
            v-model="editor.dialog"
            fullscreen
            persistent>
            <v-card>
                <v-toolbar
                    dark
                    color="wellis"
                >
                    <v-btn
                        icon
                        dark
                        @click="editor.dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{editor.title}}</v-toolbar-title>
                    <v-btn
                        icon
                        dark
                        @click="save"
                    >
                        <v-icon>mdi-floppy</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-5">
                    <form ref="editorForm">
                        <v-row>
                            <v-col cols="12" xl="3" lg="3" sm="12">
                                <label>Hierarchies</label>
                                <v-autocomplete
                                    v-model="editor.form.hierarchies.value"
                                    chips
                                    small-chips
                                    :items="hierarchies"
                                    label="Please select"
                                    multiple
                                    color="wellis"
                                    item-color="wellis"
                                    item-text="description"
                                    item-value="hierarchy_id"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xl="3" lg="3" sm="12">
                                <label>Status</label>
                                <v-select color="wellis"
                                          v-model="editor.form.status.value"
                                          :items="[{value: true, text: 'Active'},{value: false, text: 'Inactive'}]"
                                          item-value="value"
                                          item-text="text"
                                          item-color="wellis">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-card v-for="locale in locales" :key="locale" class="mt-5 mb-5">
                            <v-card-title>[{{locale | toUpper}}]</v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" xl="3" lg="3" sm="12">
                                        <label>Name</label>
                                        <v-text-field v-model="editor.form.name.value[locale]"
                                                      @input="makeSlug(locale)"
                                                      outlined
                                                      color="wellis"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="3" lg="3" sm="12">
                                        <label>Slug</label>
                                        <v-text-field :value="editor.form.slug.value[locale]"
                                                      readonly
                                                      outlined
                                                      color="wellis"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xl="4" lg="4" sm="12">
                                        <label>Description</label>
                                        <v-textarea v-model="editor.form.description.value[locale]"
                                                    color="wellis"
                                                    outlined
                                                    counter="300"
                                                    rows="3"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>

                    </form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import {EVENT_PAGE_TITLE_CHANGE_NO_TRANS} from "../../../../core/constants";
import CategoryApiService from "../../../../api/category.api";
import Draggable from 'vuedraggable';
import {empty, strSlug} from "../../../../helpers";

export default {
    name: "AdminNewsCategoriesView",
    components: {Draggable},

    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE_NO_TRANS, 'News', 'Categories'), 100);

        this.fetch();
    },

    data() {
        return {
            mainOverlay: true,
            loadingOverlay: true,

            categories: [],
            hierarchies: [],
            locales: [],

            confirmDialog: false,
            deleteTarget: null,

            editor: {
                dialog: false,
                target: null,
                title: null,
                form: {
                    name: {
                        multiLang: true,
                        name: 'Name',
                        value: {}
                    },
                    slug: {
                        multiLang: true,
                        name: 'Slug',
                        value: {}
                    },
                    description: {
                        name: 'Description',
                        multiLang: true,
                        value: {}
                    },
                    hierarchies: {
                        name: 'Hierarchies',
                        value: []
                    },
                    status: {
                        name: 'Status',
                        value: false
                    },
                }
            }
        }
    },

    methods: {
        async fetch() {
            this.loadingOverlay = true;
            await CategoryApiService.getNews({is_administration: true})
                .then(response => {
                    this.$set(this, 'categories', response.data.categories);
                    this.$set(this, 'hierarchies', response.data.hierarchies);
                    this.$set(this, 'locales', response.data.locales);

                    Object.values(this.editor.form).map(item => {
                        if (item.multiLang) {
                            this.locales.forEach(lang => this.$set(item.value, lang, ''));
                        }
                    })
                })
                .finally(() => {
                    this.mainOverlay = false;
                    this.loadingOverlay = false;
                });
        },

        confirmDelete(item) {
            this.deleteTarget = item.id;
            this.confirmDialog = true;
        },

        deleteCategory() {
            this.loadingOverlay = true;
            CategoryApiService.deleteCategory('NEWS', this.deleteTarget)
                .then(() => {
                    this.$set(this, 'categories', this.categories.filter(x => x.id !== this.deleteTarget));
                    this.deleteTarget = null;
                    this.confirmDialog = false;
                })
                .catch(() => {
                    this.$snackBar({
                        icon: "mdi-alert",
                        title: this.$t('whoops'),
                    });
                })
                .finally(() => {
                    this.loadingOverlay = false;
                });
        },

        onSortUpdate() {
            let sort = [];
            this.categories.forEach(item => sort.push(item.id));
            CategoryApiService.updateOrder('NEWS', sort).catch(async () => await this.fetch());
        },

        createCategoryDialog() {
            this.resetForm();
            this.editor.title = 'Create new category';
            this.editor.dialog = true;
        },

        editCategoryDialog(categoryId) {
            this.loadingOverlay = true;
            this.resetForm();

            CategoryApiService.newsCategory(categoryId)
                .then(response => {
                    this.editor.target = categoryId;
                    this.editor.form.status.value = response.data.objstate === 'ACTIVE';
                    const attributes = JSON.parse(response.data.attributes);
                    this.editor.form.hierarchies.value = [];
                    attributes.hierarchy_lock.forEach(hierarchy => {
                        this.editor.form.hierarchies.value.push({
                            hierarchy_id: hierarchy,
                            description: this.findHierarchyDescription(hierarchy)
                        })
                    });
                    Object.values(response.data.localizations).forEach(localization => {
                        this.editor.form.name.value[localization.lang] = localization.name;
                        this.editor.form.slug.value[localization.lang] = localization.slug;
                        this.editor.form.description.value[localization.lang] = localization.description;
                    });

                    this.editor.title = 'Edit category [ID: ' + categoryId + ']';
                    this.editor.dialog = true;
                })
                .finally(() => {
                    this.loadingOverlay = false;
                });
        },

        findHierarchyDescription(hierarchy) {
            let description = 'N/A';
            let h = this.hierarchies.find(item => item.hierarchy_id === hierarchy);
            if (h) {
                description = h.description;
            }

            return description;
        },

        makeSlug(locale) {
            this.$set(this.editor.form.slug.value, locale, strSlug(this.editor.form.name.value[locale]));
        },

        validateForm() {
            return new Promise((resolve, reject) => {
                let error = [];
                this.locales.forEach(lang => {
                    if (empty(this.editor.form.name.value[lang])) {
                        error.push(this.$t('validation.required', {field: `${this.$t(this.editor.form.name.name)} [ ${lang.toUpperCase()} ]`}));
                    } else {
                        if (this.editor.form.name.value[lang].length > 150) {
                            error.push(this.$t('validation.maxlength', {
                                    field: `${this.$t(this.editor.form.name.name)} [ ${lang.toUpperCase()} ]`,
                                    num: 150
                                }
                            ));
                        }
                    }

                    if (empty(this.editor.form.slug.value[lang])) {
                        error.push(this.$t('validation.required', {field: `${this.$t(this.editor.form.slug.name)} [ ${lang.toUpperCase()} ]`}));
                    } else {
                        if (this.editor.form.slug.value[lang].length > 150) {
                            error.push(this.$t('validation.maxlength', {
                                    field: `${this.$t(this.editor.form.slug.name)} [ ${lang.toUpperCase()} ]`,
                                    num: 155
                                }
                            ));
                        }
                    }
                });

                if (error.length > 0) {
                    return reject(error);
                } else {
                    return resolve();
                }
            });
        },

        save() {
            this.validateForm()
                .then(() => {
                    let data = {
                        hierarchies: this.editor.form.hierarchies.value.map(item => item.hierarchy_id),
                        name: {},
                        slug: {},
                        description: {},
                        status: this.editor.form.status.value
                    };
                    this.locales.forEach(lang => {
                        data.name[lang] = this.editor.form.name.value[lang];
                        data.slug[lang] = this.editor.form.slug.value[lang];
                        data.description[lang] = this.editor.form.description.value[lang];
                    });

                    const apiServiceAction = this.editor.target === null
                        ? CategoryApiService.createCategory('NEWS', data)
                        : CategoryApiService.updateCategory('NEWS', this.editor.target, data);

                    apiServiceAction.then(() => {
                        this.$snackBar({
                            icon: "mdi-check",
                            text: this.editor.target === null
                                ? 'Category has been created!'
                                : 'Category has been updated!'
                        });
                        this.resetForm();
                        this.editor.target = null;
                        this.editor.dialog = false;
                        this.fetch();
                    }).catch(error => {
                        let html = [this.$t('components.administration.category.cant-save')];
                        if (error.response) {
                            if (error.response.data.message === 'CATEGORY_NOT_UNIQUE') {
                                html.push(this.$t('components.administration.category.unique', {field: error.response.data.payload.lang.toUpperCase()}));
                            }
                        }
                        return this.$snackBar({
                            icon: "mdi-alert",
                            text: html.join('<br>')
                        });
                    });
                })
                .catch(error => {
                    this.$snackBar({
                        icon: "mdi-alert",
                        text: error.join('<br>')
                    });
                })
        },

        resetForm() {
            this.editor.form.hierarchies.value = [];
            this.locales.forEach(lang => {
                this.editor.form.name.value[lang] = '';
                this.editor.form.slug.value[lang] = '';
                this.editor.form.description.value[lang] = '';
            });
        }
    }
}
</script>

<style scoped>

</style>
