<template>
    <v-container fluid>
        <v-container fluid class="mt-10" v-if="mainOverlay">
            <v-progress-linear indeterminate
                               color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container fluid v-else>
            <v-card>
                <v-card-title>General</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" xl="3" lg="3" sm="12">
                            <label>Status</label>
                            <v-select color="wellis"
                                      v-model="item.objstate.value"
                                      :items="[{value: true, text: 'Active'},{value: false, text: 'Inactive'}]"
                                      item-value="value"
                                      placeholder="Please select"
                                      item-text="text"
                                      item-color="wellis">
                            </v-select>
                        </v-col>
                        <v-col cols="12" xl="3" lg="3" sm="12">
                            <label>Category</label>
                            <v-select color="wellis"
                                      v-model="item.category_id.value"
                                      :items="categories"
                                      item-value="id"
                                      placeholder="Please select"
                                      item-text="name"
                                      item-color="wellis">
                            </v-select>
                        </v-col>
                        <v-col cols="12" xl="3" lg="3" sm="12">
                            <label>Type</label>
                            <v-select color="wellis"
                                      v-model="item.special.value"
                                      :items="[{value: true, text: 'Featured'},{value: false, text: 'Default'}]"
                                      item-value="value"
                                      placeholder="Please select"
                                      item-text="text"
                                      item-color="wellis">
                            </v-select>
                        </v-col>
                        <v-col cols="12" xl="3" lg="3" sm="12">
                            <label>Available at</label>
                            <v-menu
                                ref="menu"
                                v-model="datePickerDialog"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field class="mb-3"
                                                  v-bind="attrs"
                                                  clearable
                                                  v-on="on"
                                                  placeholder="Immediate"
                                                  color="wellis"
                                                  append-icon="mdi-calendar"
                                                  v-on:click:append="datePickerDialog = true"
                                                  v-model="item.available_at.value"/>
                                </template>
                                <v-date-picker
                                    v-model="item.available_at.value"
                                    color="wellis"
                                    :first-day-of-week="1"
                                    :locale="$store.getters['langStore/current']"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" xl="3" lg="3" sm="12">
                            <label>Hierarchies</label>
                            <v-autocomplete
                                v-model="item.hierarchies.value"
                                chips
                                small-chips
                                :items="hierarchies"
                                label="Please select"
                                multiple
                                color="wellis"
                                item-color="wellis"
                                item-text="description"
                                item-value="hierarchy_id"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" xl="3" lg="3" sm="12">
                            <label>Locales</label>
                            <v-autocomplete
                                v-model="selectedLocales"
                                chips
                                small-chips
                                :items="locales"
                                label="Please select"
                                multiple
                                color="wellis"
                                item-color="wellis"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <v-btn class="float-lg-right px-16" color="wellis-text-white" @click="onSave">Save</v-btn>
                        </v-col>
                    </v-row>
                    <v-divider class="mt-5 mb-5"></v-divider>
                    <v-tabs
                        v-model="currentLocale"
                        background-color="transparent"
                        color="wellis"
                        grow>
                        <v-tab v-for="locale in selectedLocales" :key="locale"
                               :href="`#tab-${locale}`">
                            {{locale}}
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="currentLocale">
                        <v-tab-item v-for="locale in selectedLocales" :key="locale" :value="`tab-${locale}`">
                            <v-row class="mt-3">
                                <v-col cols="12" xl="3" lg="3" sm="12">
                                    <v-row>
                                        <v-col cols="12" sm="12">
                                            <label>Header image URL</label>
                                            <v-text-field color="wellis"
                                                          v-model="item.localization[locale].header_image_url.value"
                                                          clearable>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12">
                                            <label>Title</label>
                                            <v-text-field color="wellis"
                                                          v-model="item.localization[locale].title.value"
                                                          clearable
                                                          maxLength="150">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12">
                                            <label>Lead</label>
                                            <v-textarea color="wellis"
                                                        v-model="item.localization[locale].lead.value"
                                                        clearable
                                                        counter="500">
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" xl="9" lg="9" sm="12">
                                    <v-card class="mx-auto overflow-hidden" min-height="375" elevation="2" color="transparent2"
                                            max-width="1280">
                                        <v-img
                                            class="white-text align-end"
                                            height="400"
                                            :lazy-src="require('@/assets/img/placeholder_1800x600.jpg')"
                                            :src="previewHeaderImage">
                                            <v-card-title class="text-h2">{{previewTitle}}</v-card-title>
                                        </v-img>

                                        <v-card-subtitle class="pb-2 text-h5">
                                            <span>__AUTHOR_NAME__</span>
                                            <span class="float-right">__CREATED_AT__</span>
                                        </v-card-subtitle>
                                        <v-divider class="mt-1 mb-1"></v-divider>
                                        <v-card-text>
                                            <v-alert
                                                border="left"
                                                colored-border
                                                color="wellis accent-4"
                                            >
                                                <TextEditor class="border" :content="item.localization[locale].content.value"
                                                            v-on:updateContent="content => item.localization[locale].content.value = content"/>
                                            </v-alert>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
            </v-card>
        </v-container>
    </v-container>
</template>

<script>
import {EVENT_PAGE_TITLE_CHANGE_NO_TRANS} from "../../../../core/constants";
import CategoryApiService from "../../../../api/category.api";
import NewsApiService from "../../../../api/news.api";
import {empty, nl2br} from "../../../../helpers";
import TextEditor from "../../../common/TextEditor";

export default {
    name: "AdminNewsPostEditor",
    components: {TextEditor},
    mounted() {
        const title = this.$route.name === 'app.admin.news.posts.create'
            ? 'Create new post'
            : 'Edit post';

        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE_NO_TRANS, 'News', title), 100);

        this.getCategories()
            .then(() => {
                if (this.isEditor) {
                    this.loadingOverlay = true;
                    NewsApiService.concrete(null, this.$route.params.id)
                        .then(response => {
                            this.postId = response.data.id;
                            this.item.objstate.value = response.data.objstate === 'ACTIVE';
                            this.item.category_id.value = response.data.category_id;
                            this.item.available_at.value = response.data.available_at;
                            const attributes = JSON.parse(response.data.attributes);
                            this.item.special.value = attributes.special;
                            if (attributes.hierarchy_lock.length > 0) {
                                attributes.hierarchy_lock.forEach(item => {
                                    const hierarchy = this.hierarchies.find(x => x.hierarchy_id === item);
                                    if (hierarchy) {
                                        this.item.hierarchies.value.push(hierarchy);
                                    }
                                });
                            }

                            response.data.localizations.forEach(item => {
                                this.addLocalization(item.lang, item);
                                this.selectedLocales.push(item.lang);
                                this.currentLocale = item.lang;
                            });
                        });
                }
            })
            .finally(() => {
                this.mainOverlay = false;
                this.loadingOverlay = false;
            })
    },

    data() {
        return {
            mainOverlay: true,
            loadingOverlay: true,

            datePickerDialog: false,

            categories: [],
            locales: [],
            hierarchies: [],
            selectedLocales: [],

            currentLocale: null,

            postId: null,
            item: {
                objstate: {
                    label: 'Status',
                    value: 'ACTIVE',
                    required: true
                },

                category_id: {
                    label: 'Category',
                    value: null,
                    required: true
                },

                available_at: {
                    label: 'Available at',
                    value: null,
                    required: false,
                },

                special: {
                    label: 'Type',
                    value: false,
                    required: true
                },

                hierarchies: {
                    label: 'Hierarchies',
                    value: [],
                    required: false
                },

                localization: {}
            }
        }
    },

    methods: {
        getCategories() {
            return new Promise((resolve, reject) => {
                this.loadingOverlay = true;
                CategoryApiService.getNews({is_administration: true})
                    .then(response => {
                        this.$set(this, 'categories', response.data.categories);
                        this.$set(this, 'hierarchies', response.data.hierarchies);
                        this.$set(this, 'locales', response.data.locales);
                        resolve(response.data);
                    })
                    .catch(e => reject(e))
                    .finally(() => {
                        this.loadingOverlay = false;
                    });
            });
        },

        addLocalization(locale, data = null) {
            this.$set(this.item.localization, locale, {
                locale: locale,
                header_image_url: {
                    label: 'Header image URL',
                    value: data ? data.header_image_url : null,
                    required: true
                },
                title: {
                    label: 'Title',
                    value: data ? data.title : null,
                    required: true,
                    maxLength: 150,
                },
                lead: {
                    label: 'Lead',
                    value: data ? data.lead : null,
                    required: false,
                    maxLength: 500
                },
                content: {
                    label: 'Content',
                    value: data ? data.content : '__TYPE_CONTENT_HERE__',
                    required: false,
                },
            });
        },

        empty(value) {
            return empty(value);
        },

        validateForm() {
            return new Promise((resolve, reject) => {
                let errors = [];
                Object.keys(this.item).forEach(key => {
                    if (key !== 'localization') {
                        if (this.item[key].required && empty(this.item[key].value)) {
                            errors.push(this.$t('validation.required', {field: this.item[key].label}));
                        }
                    } else {
                        Object.values(this.item[key]).forEach(item => {
                            Object.keys(item).forEach(subKey => {
                                if (subKey !== 'locale') {
                                    if (item[subKey].required && empty(item[subKey].value)) {
                                        errors.push("[" + item['locale'].toUpperCase() + "] " + this.$t('validation.required', {
                                            field: item[subKey].label
                                        }));
                                    }
                                }
                            });
                        });
                    }
                });


                if (errors.length > 0) {
                    return reject(errors);
                } else {
                    const formData = {
                        objstate: this.item.objstate.value ? 'ACTIVE' : 'INACTIVE',
                        category: this.item.category_id.value,
                        available_at: this.item.available_at.value,
                        attributes: {
                            special: this.item.special.value,
                            hierarchy_lock: this.item.hierarchies.value.map(item => item),
                        },
                        localizations: []
                    };

                    Object.values(this.item.localization).forEach(item => {
                        formData.localizations.push({
                            lang: item.locale,
                            header_image_url: item.header_image_url.value,
                            title: item.title.value,
                            lead: item.lead.value,
                            content: item.content.value,
                        })
                    });
                    return resolve(formData);
                }
            });
        },

        onSave() {
            this.validateForm()
                .then(data => {
                    if (this.isEditor) {
                        NewsApiService.update(this.postId, data)
                            .then(() => {
                                this.$snackBar({
                                    icon: "mdi-check",
                                    text: this.$t('components.administration.news.update-success')
                                });
                            })
                            .catch(() => {
                                this.$snackBar({
                                    icon: "mdi-alert",
                                    text: this.$t('whoops')
                                });
                            });
                    } else {
                        NewsApiService.create(data)
                            .then(() => {
                                this.$snackBar({
                                    icon: "mdi-check",
                                    text: this.$t('components.administration.news.create-success')
                                });
                                this.$router.push({name: 'app.admin.news.posts'})
                            })
                            .catch(() => {
                                this.$snackBar({
                                    icon: "mdi-alert",
                                    text: this.$t('whoops')
                                });
                            });
                    }
                })
                .catch(error => {
                    this.$snackBar({
                        icon: "mdi-alert",
                        text: error.join('<br>')
                    });
                })
        }
    },


    computed: {
        isEditor() {
            return this.$route.name !== 'app.admin.news.posts.create';
        },

        previewTitle() {
            if (this.currentLocale === null) {
                return '__TITLE__';
            }
            const currentLocale = this.currentLocale.replace('tab-', '');
            return this.item.localization[currentLocale].title.value || '__TITLE__';
        },

        previewHeaderImage() {
            if (this.currentLocale === null) {
                return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mOs+w8AAgEBfx2y/nAAAAAASUVORK5CYII=';
            }
            const currentLocale = this.currentLocale.replace('tab-', '');
            return this.item.localization[currentLocale].header_image_url.value
                || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mOs+w8AAgEBfx2y/nAAAAAASUVORK5CYII=';
        },

        nl2br() {
            return value => {
                return nl2br(value);
            }
        }
    },

    watch: {
        selectedLocales: function () {
            let keys = Object.keys(this.item.localization);
            keys.forEach(locale => {
                if (!this.selectedLocales.includes(locale)) {
                    delete this.item.localization[locale];
                }
            });

            this.selectedLocales.forEach(locale => {
                if (!keys.includes(locale)) {
                    this.addLocalization(locale);
                }
            });
        },

        currentLocale: function(val) {
            if(typeof val === 'undefined') {
                this.currentLocale = null;
            }
        }
    }
}
</script>

<style scoped>

</style>
