<template>
    <v-container fluid>
        <v-container fluid class="mt-10" v-if="mainOverlay">
            <v-progress-linear indeterminate
                               color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container fluid v-else>
            <v-row>
                <v-col cols="12" xl="3" lg="4" md="12" sm="12">
                    <v-card>
                        <v-card-title>{{$t('components.stocklist.filters')}}</v-card-title>
                        <v-card-subtitle>
                            <v-btn block @click="clearFilters">
                                {{$t('components.stocklist.btnClearFilters')}}
                            </v-btn>
                        </v-card-subtitle>
                        <v-divider></v-divider>
                        <v-card-text>
                            <!-- Product filters -->
                            <v-card color="transparent2">
                                <v-card-title>{{$t('components.stocklist.filterProducts')}}</v-card-title>
                                <v-card-text style="max-height: 350px; overflow-y: scroll">
                                    <v-checkbox v-for="(item, index) in productFilters"
                                                class="my-0"
                                                :key="index"
                                                v-model="item.checked"
                                                :label="item.name"
                                                color="wellis"
                                    ></v-checkbox>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn block @click="applyFilters">
                                        {{$t('components.stocklist.btnApplyFilters')}}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                            <!-- End of product filters -->

                            <!-- Warehouse filters -->
                            <v-card color="transparent2">
                                <v-card-title>{{$t('components.stocklist.warehouse')}}</v-card-title>
                                <v-card-text style="max-height: 350px; overflow-y: scroll">
                                    <v-checkbox v-for="(item, index) in warehouseFilters"
                                                class="my-0"
                                                :key="index"
                                                v-model="item.checked"
                                                :label="item.name"
                                                color="wellis"
                                    ></v-checkbox>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn block @click="applyFilters">
                                        {{$t('components.stocklist.btnApplyFilters')}}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                            <!-- End of warehouse filters -->

                            <!-- Characteristic filters -->
                            <v-card color="transparent2" v-for="(item, index) in characteristicFilters" :key="index">
                                <v-card-title>{{item.name}}</v-card-title>
                                <v-card-text style="max-height: 350px; overflow-y: scroll">
                                    <v-checkbox v-for="(characteristic, index2) in item.values" :key="index2"
                                                class="my-0"
                                                v-model="characteristic.checked"
                                                :label="characteristic.name"
                                                color="wellis"
                                    ></v-checkbox>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn block @click="applyFilters">
                                        {{$t('components.stocklist.btnApplyFilters')}}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                            <!-- End of warehouse filters -->
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" xl="9" lg="8" md="12" sm="12">
                    <v-overlay :value="loadingOverlay" color="white">
                        <v-progress-circular
                            color="wellis"
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-card color="transparent2">
                        <v-card-title>
                            <v-text-field clearable color="wellis" v-model="quickSearch" :label="$t('components.topbar.search')"></v-text-field>
                        </v-card-title>
                        <v-card-subtitle>{{$t('components.stocklist.dataLength', {len: dataLength, time: fetchTimeComputed})}}</v-card-subtitle>
                        <v-card-text>
                            <v-simple-table>
                                <thead>
                                <tr>
                                    <th width="10%"></th>
                                    <th>{{$t('components.stocklist.name') | capitalize}}</th>
                                    <th>{{$t('components.products.catalogNoShort') | capitalize}}</th>
                                    <th>{{$t('components.stocklist.configNoShort') | capitalize}}</th>
                                    <th>{{$t('components.stocklist.serialNoShort') | capitalize}}</th>
                                    <th>{{$t('components.stocklist.warehouse') | capitalize}}</th>
                                    <th>{{$t('components.products.price') | capitalize}}</th>
                                    <th>{{$t('components.shopord.needweek')}}</th>
                                    <th>{{$t('components.stocklist.acrylicColor') | capitalize}}</th>
                                    <th>{{$t('components.stocklist.cabinetColor') | capitalize}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in products" :key="item.uniqueId + '_' + $store.getters['langStore/current']">
                                    <td width="10%">
                                        <v-btn text small v-if="item.quantity > 0" @click="addCart(item)">
                                            <v-icon>mdi-cart</v-icon>
                                        </v-btn>
                                    </td>
                                    <td>
                                        <v-tooltip bottom>
                                            <span>{{item.name}}</span>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on">{{item.name | cut}}</span>
                                            </template>
                                        </v-tooltip>
                                    </td>
                                    <td>{{item.catalogNo}}</td>
                                    <td>
                                        <span class="pointer font-weight-bold"
                                              @click="viewConfiguration(item)">
                                            {{item.configNo}}
                                        </span>
                                    </td>
                                    <td>{{item.serialNo}}</td>
                                    <td class="wellis--text font-weight-bold">{{item.warehouse}}</td>
                                    <td>
                                        <div v-if="hasPrice(item)" class="wellis--text font-weight-bold">
                                            <small class="d-block black--text font-weight-light" v-if="item.price.vat">
                                                {{item.price.unitPrice | toPrice(item.price.currency, false)}} + {{$t('components.products.vat')}}
                                            </small>
                                            {{item.price.unitPrice | toPrice(item.price.currency, item.price.vat)}}
                                        </div>
                                        <div v-else>
                                            -
                                        </div>
                                    </td>
                                    <td>{{item.eta}}</td>
                                    <td>{{item.acrylicColor}}</td>
                                    <td>{{item.cabinetColor}}</td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-dialog
                :value="configurationDialog"
                max-width="650px"
                @click:outside="configurationDialog = false"
            >
                <v-card>
                    <v-card-title>
                        {{configurationData.configNo}}
                    </v-card-title>
                    <v-card-text>
                        <v-row v-for="item in configurationData.items" :key="item.optionValueId" dense>
                            <v-col cols="12" sm="12" md="12" lg="6" class="font-weight-bold">{{item.description}}</v-col>
                            <v-col cols="12" sm="12" md="12" lg="6" class="font-weight-bold wellis--text">{{findConfigurationValueDesc(item)}}</v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
    </v-container>
</template>

<script>
//import Vue from "vue";
import {EVENT_CART_COUNT_UPDATE, EVENT_LANG_CHANGE/*, EVENT_PAGE_TITLE_CHANGE*/} from "../../../core/constants";
import {__clone, empty, LiteUser} from '../../../helpers';
import StocklistApiService from "../../../api/stocklist.api";
import CartApiService from "../../../api/cart.api";

export default {
    name: "StocklistInTransitView",

    mounted() {
        this.$router.push({
            name: 'app.start'
        });
        return;

        /*Vue.prototype.$StocklistInTransitView = this;
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE, 'v3.components.stocklist-transit.title'), 100);

        if (!LiteUser.hasPermission(
            this.$store.getters['authStore/isLiteUser'],
            this.$store.getters['authStore/getLiteUserPermission'],
            'products',
        )) {
            this.$ApplGeneral({
                content: this.$t('v32.liteUser.hasNoPermission'),
                onClose: () => {
                    this.$router.push({name: 'app.start'});
                }
            });
            return;
        }

        this.$eventBus.$on(EVENT_LANG_CHANGE, () => {
            this.bootstrap();
        });

        this.bootstrap();*/
    },

    destroyed() {
        this.$eventBus.$off(EVENT_LANG_CHANGE);
    },

    data() {
        return {
            mainOverlay: true,
            loadingOverlay: true,
            stock: [],
            quickSearch: null,
            filters: {
                product: [],
                warehouse: [],
                characteristics: []
            },
            fetchTime: 0,
            queryFiltering: false,
            queryFilters: {
                category: [],
                product: [],
                warehouse: [],
                characteristics: []
            },

            configurationDialog: false,
            configurationData: {
                configNo: null,
                items: []
            },
        }
    },

    methods: {

        bootstrap(filters = null) {
            return new Promise(resolve => {
                this.loadingOverlay = true;
                let fetchStartTime = Date.now();
                StocklistApiService.getTransit(filters)
                    .then(response => {
                        this.$set(this, 'stock', Object.values(response.data.stocklist));
                        if (filters === null) {
                            this.filters.product = response.data.filters.product;
                            this.filters.warehouse = response.data.filters.warehouse;
                            this.filters.characteristics = Object.values(response.data.filters.characteristics);
                            this.mainOverlay = false;
                        }
                        if (this.hasQueryFilter) {
                            let filters = __clone(this.queryFilters);
                            this.queryFilters = {
                                category: [],
                                product: [],
                                warehouse: [],
                                characteristics: []
                            };
                            this.queryFiltering = false;
                            this.toggleFilters(filters);
                            this.applyFilters();
                        } else {
                            this.$scrollTop();
                        }
                        this.fetchTime = Date.now() - fetchStartTime;
                        resolve();
                    })
                    .catch(() => {
                        this.$snackBar({
                            icon: 'mdi-alert',
                            text: this.$t('components.stocklist.notready')
                        })
                    })
                    .finally(() => this.loadingOverlay = false);
            });
        },

        clearFilters() {
            this.filters.product.map(item => item.checked = false);
            this.filters.characteristics.map(item => item.values.map(char => char.checked = false));
            this.bootstrap();
        },

        applyFilters() {
            let filters = {
                product: [],
                characteristics: {},
                warehouse: [],
            };
            this.filters.product.map(item => {
                if (item.checked) {
                    filters.product.push(item.value);
                }
            });

            this.filters.warehouse.map(item => {
                if (item.checked) {
                    filters.warehouse.push(item.value);
                }
            });

            this.filters.characteristics.map(char => {
                char.values.map(item => {
                    if (item.checked) {
                        if (typeof filters.characteristics[char.characteristic_id] === 'undefined') {
                            filters.characteristics[char.characteristic_id] = [];
                        }
                        filters.characteristics[char.characteristic_id].push(item.option_value_id);
                    }
                })
            });

            this.bootstrap(filters);
        },

        toggleFilters(filters) {
            filters.product.map(x => {
                let product = this.filters.product.find(item => item.value === x);
                if (product) {
                    product.checked = true;
                }
            });

            for (const [key, value] of Object.entries(filters.characteristics)) {
                let char = this.filters.characteristics.find(item => item.characteristic_id === key);
                if (char) {
                    value.forEach(val => {
                        let opt = char.values.find(item => item.option_value_id === val);
                        if (opt) {
                            opt.checked = true;
                        }
                    });
                }
            }
        },

        viewConfiguration(item) {
            this.loadingOverlay = true;
            StocklistApiService.transitDetails(item.uniqueId, {
                with_configuration: true
            }).then(response => {
                this.configurationData.configNo = item.configNo;
                this.configurationData.items = Object.values(response.data.product.configuration);
                this.configurationDialog = true;
            }).finally(() => this.loadingOverlay = false);
        },

        findConfigurationValueDesc(item) {
            let value = item.values.find(x => item.selectedValue === x.optionValueId);
            return value ? value.description : item.selectedValue;
        },

        addCart(item) {
            if (!LiteUser.hasPermission(
                this.$store.getters['authStore/isLiteUser'],
                this.$store.getters['authStore/getLiteUserPermission'],
                'place_order',
            )) {
                this.$ApplGeneral({
                    content: this.$t('v32.liteUser.hasNoPermission')
                });
                return;
            }

            this.loadingOverlay = true;
            let attributes = {
                "coordinator": "addProductConfiguration",
                "product": item.uniqueId,
                "quantity": item.quantity,
                "host": 'STOCK_ETA',
                "configurations": {}
            };

            CartApiService.add(attributes)
                .then(response => {
                    this.$eventBus.$emit(EVENT_CART_COUNT_UPDATE, response.data.count);
                    item.quantity = 0;
                })
                .catch(error => {
                    if (error.response.data.exception) {
                        if (error.response.data.exception.class === 'DifferentWarehouseException') {
                            this.$snackBar({
                                content: this.$t('components.cart.different-stocklist-warehouse'),
                                icon: 'mdi-alert'
                            });
                        }

                        if (error.response.data.exception.class === 'DifferentStocklistTypeException') {
                            this.$snackBar({
                                content: this.$t('components.cart.different-stocklist-type'),
                                icon: 'mdi-alert'
                            });
                        }

                        if (error.response.data.exception.class === 'UnavailableQuantityException') {
                            this.$snackBar({
                                content: this.$t('components.cart.unavailable-quantity'),
                                icon: 'mdi-alert'
                            });
                        }
                    } else {
                        this.$snackBar({
                            content: this.$t('components.cart.add-cart-error'),
                            icon: 'mdi-alert'
                        });
                    }
                })
                .finally(() => this.loadingOverlay = false);
        },
    },

    computed: {
        products() {
            return this.stock;
        },

        filteredResults() {
            let items = [];
            this.products.forEach(item => {
                if (!empty(this.quickSearch)) {
                    let r = new RegExp(this.quickSearch, "iu");
                    if (r.test(item.name) || r.test(item.catalogNo)) {
                        items.push(item);
                    }
                } else {
                    items.push(item);
                }
            });

            items.sort((a, b) => a.name > b.name);

            return items;
        },

        productFilters() {
            return this.filters.product;
        },

        warehouseFilters() {
            return this.filters.warehouse;
        },

        characteristicFilters() {
            return this.filters.characteristics;
        },

        readinessFilters() {
            return this.filters.readiness;
        },

        dataLength() {
            return this.stock.length;
        },

        fetchTimeComputed() {
            return (Math.abs(this.fetchTime) / 1000).toFixed(2);
        },

        hasQueryFilter() {
            return this.queryFiltering;
        },

        hasPrice() {
            return item => {
                return item.price && typeof item.price !== 'undefined';
            }
        },
    }
}
</script>

<style scoped>

</style>
