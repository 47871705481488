import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
Vue.use(VueLocalStorage);

export const TOKEN_KEY = "backend_auth_token";

const JwtService = {
    getToken() {
        return Vue.localStorage.get(TOKEN_KEY);
    },

    saveToken(token) {
        return Vue.localStorage.set(TOKEN_KEY, token);
    },

    removeToken() {
        return Vue.localStorage.remove(TOKEN_KEY);
    },

    hasToken() {
        return Vue.localStorage.get(TOKEN_KEY) !== null;
    }

}

export default JwtService;
