import {VAT_MULTIPLIER} from '../core/config';
import store from '../store';

export const __clone = function(object) {
    return JSON.parse(JSON.stringify(object));
};

export const formatPrice = function(value, currency, vat) {
    return new Intl.NumberFormat(store.getters['langStore/current'], {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: currency.toLowerCase() === 'huf' ? 0 : 2,
    }).format(JSON.parse(vat) ? value * VAT_MULTIPLIER : value);
};

export const nl2br = function(str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    let breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
};

export const empty = function(object) {
    return typeof object === 'undefined'
        || object === ''
        || object === null
        || object.length === 0;
};

export const strSlug = function(string) {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return string.toString().toLowerCase()
        .replace(/\s+/g, '-')
        .replace(p, c => b.charAt(a.indexOf(c)))
        .replace(/&/g, '-and-')
        // eslint-disable-next-line no-useless-escape
        .replace(/[^\w\-]+/g, '')
        // eslint-disable-next-line no-useless-escape
        .replace(/\-\-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
};

export const zeroFill = function(number, len = 0) {
    let abs = Math.abs(number);
    let zFill = Math.max(0, len - Math.floor(abs).toString().length);
    let zStr = Math.pow(10, zFill).toString().substr(1);
    return zStr + number;
};

export const formatDate = function(date, time = false, utc = false) {
    const d = utc
        ? new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000)
        : new Date(date);
    let dateStr = `${d.getFullYear()}/${zeroFill(d.getMonth() + 1, 2)}/${zeroFill(d.getDate(), 2)}`;
    if (time) {
        dateStr += ` ${zeroFill(d.getHours(), 2)}:${zeroFill(d.getMinutes(), 2)}:${zeroFill(d.getSeconds(), 2)}`;
    }
    return dateStr;
};

export const handleDownloadedFile = function(blob, filename, type) {
    let file = new File([blob], filename, {type: type});
    let a = document.createElement('a');
    a.href = window.URL.createObjectURL(file);
    a.download = filename;
    return a.dispatchEvent(new MouseEvent('click'));
};

export const randomString = function(length, customCharset = null) {
    let charSet = customCharset || 'abcdefghijklmnopqrstuvwxyz0123456789';
    let str = '';
    for (let i = 0; i < length; i++) {
        let upperCase = Math.round(Math.random());
        let pos = Math.floor(Math.random() * charSet.length);
        let s = charSet.substring(pos, pos + 1);
        if (upperCase) {
            s = s.toUpperCase();
        }

        str += s;
    }

    return str;
};

export const PrettyJson = {
    __replacer: function(match, pIndent, pKey, pVal, pEnd) {
        var key = '<span class=json-key>';
        var val = '<span class=json-value>';
        var str = '<span class=json-string>';
        var r = pIndent || '';
        if (pKey)
            r = r + key + pKey.replace(/[": ]/g, '') + '</span>: ';
        if (pVal)
            r = r + (pVal[0] == '"' ? str : val) + pVal + '</span>';
        return r + (pEnd || '');
    },
    print: function(obj) {
        var jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/mg;
        return JSON.stringify(obj, null, 3)
            .replace(/&/g, '&amp;').replace(/\\"/g, '&quot;')
            .replace(/</g, '&lt;').replace(/>/g, '&gt;')
            .replace(jsonLine, this.__replacer);
    },
};

export const LiteUser = {
    hasPermission: function(isLiteUser, permissionSet, needle) {
        if (!isLiteUser) {
            return true;
        }
        let permission_ = permissionSet.find(x => x.permission === needle);
        return permission_.objstate === 'ACTIVE';
    },
};