var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:['pointer', _vm.selectedState ? 'selected' : ''],attrs:{"elevation":"0","color":"transparent"}},[(_vm.isDir)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pointer",attrs:{"elevation":hover ? 8 : 2}},[_c('v-container',[_c('v-card-title',{on:{"click":_vm.handleClick}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-folder")]),_c('span',_vm._g(_vm._b({staticStyle:{"font-size":"13px"}},'span',attrs,false),on),[_vm._v(_vm._s(_vm._f("cut")(_vm.item.name,20)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.item.name))])])],1)],1)],1)]}}],null,false,2480069048)}):_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pointer",attrs:{"elevation":hover ? 8 : 2},on:{"click":_vm.handleClick}},[_c('v-card-title',[(_vm.item.docType === 'DOC_TYPE_IMAGE')?_c('v-img',{staticClass:"white--text align-end",attrs:{"height":"200px","lazy-src":require('@/assets/img/placeholder_1800x600.jpg'),"src":("data:image/png;base64," + (_vm.item.thumbnail))}}):_c('v-img',{staticClass:"white--text align-end",attrs:{"height":"200px","contain":"","lazy-src":require('@/assets/img/x-zip-compressed.png'),"src":require('@/assets/img/x-zip-compressed.png')}})],1),_c('v-card-text',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"font-weight-bold",staticStyle:{"font-size":"13px"}},'span',attrs,false),on),[_vm._v(_vm._s(_vm._f("cut")(_vm.item.name,25))+"."+_vm._s(_vm.item.extension))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.item.name)+"."+_vm._s(_vm.item.extension))])])],1)],1)]}}])}),(_vm.$store.getters['authStore/isAdmin'] || _vm.item.type !== 'TYPE_DIR')?_c('v-speed-dial',{attrs:{"top":"","right":"","direction":"bottom"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"small":"","light":"","icon":"","fab":""}},[_c('v-icon',{attrs:{"color":"wellis"}},[_vm._v(" mdi-dots-vertical ")])],1)]},proxy:true}],null,false,1225634688)},[(_vm.$store.getters['authStore/isAdmin'])?_c('v-btn',{attrs:{"fab":""},on:{"click":_vm.openRestrictionModal}},[_c('v-icon',{attrs:{"color":"wellis"}},[_vm._v(" mdi-shield-lock-outline ")])],1):_vm._e(),(!_vm.isDir)?_c('v-btn',{attrs:{"fab":""},on:{"click":_vm.selectFile}},[(_vm.selectedState)?_c('v-icon',{attrs:{"color":"wellis"}},[_vm._v(" mdi-minus ")]):_c('v-icon',{attrs:{"color":"wellis"}},[_vm._v(" mdi-shape-square-rounded-plus ")])],1):_vm._e(),(!_vm.isDir)?_c('v-btn',{attrs:{"fab":""},on:{"click":_vm.download}},[_c('v-icon',{attrs:{"color":"wellis"}},[_vm._v(" mdi-download-outline ")])],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }