import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import Languages from '../core/lang';
import BackendService from "../core/backend/backend.service";

/**
 * Actions
 */
export const SET_LANGUAGE = 'setLanguage';

/**
 * Mutations
 */
export const FINALIZE = 'finalize';

Vue.use(VueLocalStorage);

export default {
    namespaced: true,

    state: {
        supportedLocales: Object.getOwnPropertyNames(Languages),
        language: Vue.localStorage.get('language')
    },

    getters: {
        current(state) {
            return state.language;
        },

        supported(state) {
            return state.supportedLocales;
        }
    },

    actions: {
        [SET_LANGUAGE](context, languages) {
            if (typeof languages === 'string') {
                context.commit(FINALIZE, languages);
            } else {
                const lang = context.state.supportedLocales.find(sl =>
                    languages.find(l => (l.split(new RegExp(sl, 'gi')).length - 1 > 0 ? sl : null)));
                context.commit(FINALIZE, lang);
            }
        }
    },

    mutations: {
        [FINALIZE](state, lang) {
            Vue.localStorage.set('language', lang);
            state.language = lang;
            BackendService.setLocalizationHeader();
        }
    }
}
