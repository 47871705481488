import BackendService from "../core/backend/backend.service";

const ConfiguratorApiService = {

    async configure(catalogNo, partNo, characteristicId, optionValueId, configuration, forcePrice = false) {
        return BackendService.post('configurator', {
            catalog_no: catalogNo,
            part_no: partNo,
            characteristic_id: characteristicId,
            option_value_id: optionValueId,
            configuration: configuration,
            forcePrice: forcePrice
        });
    },

    async getOffers(catalogNo, acrylicColor = null) {
        return BackendService.query('configurator/offers', {
            catalog_no: catalogNo,
            acrylic_color: acrylicColor
        });
    },

    async autoConfigurator(parent, child) {
        return BackendService.post('configurator/auto-configurator', {
            "parent": parent,
            "child": child
        });
    },

    async autoConfigureById(catalogNo, partNo, configurationId) {
        return BackendService.query('configurator/concrete', {
            catalog_no: catalogNo,
            part_no: partNo,
            config_no: configurationId
        });
    },

    async getConfiguration(configNo) {
        return BackendService.query(BackendService.createParameterURI('configurator/concrete', [configNo]));
    }
}

export default ConfiguratorApiService;


