<template>
    <v-card :class="['pointer', selectedState ? 'selected' : '']" elevation="0" color="transparent">
        <v-hover v-slot="{ hover }" v-if="isDir">
            <v-card class="pointer" :elevation="hover ? 8 : 2">
                <v-container>
                    <v-card-title @click="handleClick">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon class="mr-2">mdi-folder</v-icon>
                                <span v-bind="attrs" v-on="on" style="font-size: 13px;">{{item.name | cut(20)}}</span>
                            </template>
                            <span>{{item.name}}</span>
                        </v-tooltip>
                    </v-card-title>
                </v-container>
            </v-card>
        </v-hover>
        <v-hover v-slot="{ hover }" v-else>
            <v-card class="pointer" :elevation="hover ? 8 : 2" @click="handleClick">
                <v-card-title>
                    <v-img v-if="item.docType === 'DOC_TYPE_IMAGE'"
                           class="white--text align-end"
                           height="200px"
                           :lazy-src="require('@/assets/img/placeholder_1800x600.jpg')"
                           :src="`data:image/png;base64,${item.thumbnail}`"/>
                    <v-img v-else
                           class="white--text align-end"
                           height="200px"
                           contain
                           :lazy-src="require('@/assets/img/x-zip-compressed.png')"
                           :src="require('@/assets/img/x-zip-compressed.png')"/>
                </v-card-title>
                <v-card-text>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on"
                                  style="font-size: 13px;" class="font-weight-bold">{{item.name | cut(25)}}.{{item.extension}}</span>
                        </template>
                        <span>{{item.name}}.{{item.extension}}</span>
                    </v-tooltip>
                </v-card-text>
            </v-card>
        </v-hover>
        <v-speed-dial top right direction="bottom" v-if="$store.getters['authStore/isAdmin'] || item.type !== 'TYPE_DIR'">
            <template v-slot:activator>
                <v-btn
                    small
                    light
                    icon
                    fab>
                    <v-icon color="wellis">
                        mdi-dots-vertical
                    </v-icon>
                </v-btn>
            </template>
            <v-btn fab v-if="$store.getters['authStore/isAdmin']" @click="openRestrictionModal">
                <v-icon color="wellis">
                    mdi-shield-lock-outline
                </v-icon>
            </v-btn>
            <v-btn fab v-if="!isDir" @click="selectFile">
                <v-icon color="wellis" v-if="selectedState">
                    mdi-minus
                </v-icon>
                <v-icon color="wellis" v-else>
                    mdi-shape-square-rounded-plus
                </v-icon>
            </v-btn>
            <v-btn fab v-if="!isDir" @click="download">
                <v-icon color="wellis">
                    mdi-download-outline
                </v-icon>
            </v-btn>
        </v-speed-dial>
    </v-card>
</template>

<script>
import {EVENT_MEDIA_PATH_CHANGE, EVENT_MEDIA_PREVIEW_IMAGE, EVENT_MEDIA_RESTRICT, EVENT_MEDIA_SELECT_FILE} from "../../../core/constants";
import MediaApiService from "../../../api/media.api";
import {handleDownloadedFile} from "../../../helpers";

export default {
    name: "MediaItemCard",

    props: {
        item: Object,
        prev: Boolean,
        parentPath: String,
        currentPath: String,
        selectedState: Boolean
    },

    methods: {
        handleClick() {
            if (this.prev) {
                this.$eventBus.$emit(EVENT_MEDIA_PATH_CHANGE, this.parentPath);
            } else {
                if (this.item.type === 'TYPE_DIR') {
                    this.$eventBus.$emit(EVENT_MEDIA_PATH_CHANGE, this.item.path);
                } else {
                    if (this.item.docType === 'DOC_TYPE_IMAGE') {
                        this.previewImage();
                    }
                }
            }
        },

        download() {
            this.$MediaView.showOverlay();
            MediaApiService.singleDownload(this.item.path)
                .then(blobFile => {
                    return handleDownloadedFile(blobFile.data, `${this.item.name}.${this.item.extension}`, blobFile.type);
                })
                .catch(() => {
                    this.$snackBar({
                        content: this.$t('api-error'),
                        icon: 'mdi-alert'
                    });
                })
                .finally(() => {
                    this.$MediaView.hideOverlay();
                });
        },

        previewImage() {
            this.$eventBus.$emit(EVENT_MEDIA_PREVIEW_IMAGE, this.item);
        },

        selectFile() {
            this.$eventBus.$emit(EVENT_MEDIA_SELECT_FILE, this.item);
        },

        openRestrictionModal() {
            this.$eventBus.$emit(EVENT_MEDIA_RESTRICT, this.item);
        }
    },

    computed: {
        isDir() {
            return this.item.type === 'TYPE_DIR';
        }
    }
}
</script>

<style scoped>
/* This is for documentation purposes and will not be needed in your application */
.pointer .v-speed-dial {
    position: absolute;
}

.pointer .v-btn--floating {
    position: relative;
}

.selected {
    border: 3px dotted #b89453 !important;
    border-color: #b89453 !important;
}
</style>
