import BackendService from "../core/backend/backend.service";

const OrdersApiService = {
    failed: {
        async get(objstate) {
            return BackendService.query('/orders/failed', {
                "objstate": objstate
            });
        },

        async concrete(failedOrderId) {
            return BackendService.get(`/orders/failed/${failedOrderId}`)
        },

        async update(orderId, attributes) {
            return BackendService.put(`/orders/failed/order/${orderId}`, attributes);
        },

        async removeOrderLine(orderId, orderLineId) {
            return BackendService.delete(`/orders/failed/order/${orderId}`, {
                orderLineId: orderLineId
            });
        },

        async undoRemovedOrderLine(orderId, orderLineId) {
            return BackendService.update(`/orders/failed/order/undo-removal/${orderId}`, {
                orderLineId: orderLineId
            });
        },

        async restart(failedOrderId) {
            return BackendService.post(`/orders/failed/restart`, {
                failedOrderId : failedOrderId
            });
        },

        async inactivate(failedOrderId) {
            return BackendService.post(`/orders/failed/inactivate`, {
                failedOrderId : failedOrderId
            });
        },

        async notifications(failedOrderId) {
            return BackendService.post(`/orders/failed/notifications`, {
                failedOrderId : failedOrderId
            });
        },

        async sync(failedOrderId) {
            return BackendService.post(`/orders/failed/sync`, {
                failedOrderId : failedOrderId
            });
        },
    },
}

export default OrdersApiService;


