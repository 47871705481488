import BackendService from "../core/backend/backend.service";

const CartApiService = {
    async add(attributes) {
        return BackendService.post('cart', attributes);
    },

    async getCartItems() {
        return BackendService.get('cart');
    },

    async updateQuantity(id, quantity) {
        return BackendService.update('cart/quantity', {
            target: id,
            quantity: quantity
        });
    },

    async removeItem(target) {
        return BackendService.delete('cart', {
            "target": target
        });
    },

    async emptyCart() {
        return BackendService.delete('cart');
    },

    async updateConfiguration(target, configuration, price) {
        return BackendService.post('cart/update-configuration', {
            target: target,
            configuration: configuration,
            price: price
        });
    }
}

export default CartApiService;


