import BackendService from "../core/backend/backend.service";

const ShopOrdApiService = {
    async getShopOrd(filters = null) {
        return BackendService.query('shopord', {
            filters: filters ? JSON.stringify(filters) : null,
            withoutFilters: filters !== null
        });
    },

    async getDetails(uniqueId, query = null) {
        return BackendService.query(`shopord/details/${uniqueId}`, query);
    },

    async getOffers(catalogNo, acrylicColor = null) {
        return BackendService.query('shopord/offers', {
            catalog_no: catalogNo,
            acrylic_color: acrylicColor
        });
    },

    async search(value) {
        return BackendService.query('shopord/search', {
            value: value
        });
    },

    async getItemsPrice(items = []) {
        return BackendService.post('shopord/items-price', {
            items: JSON.stringify(items)
        });
    }
}

export default ShopOrdApiService;
