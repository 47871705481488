<template>
    <v-container fluid>
        <v-container fluid class="mt-10" v-if="mainOverlay">
            <v-progress-linear indeterminate
                               color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container fluid v-else>
            <v-row>
                <v-col cols="12" xl="3" lg="4" md="12" sm="12">
                    <v-card>
                        <v-card-title>Filters</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-card color="transparent2">
                                <v-card-title>Objstate</v-card-title>
                                <v-card-text>
                                    <v-select v-model="objstate"
                                              item-text="value"
                                              item-value="key"
                                              :items="objstates"
                                              item-color="wellis"
                                              color="wellis"
                                              @change="onObjstateChange">
                                    </v-select>
                                </v-card-text>
                            </v-card>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" xl="9" lg="8" md="12" sm="12">
                    <v-overlay :value="loadingOverlay" color="white">
                        <v-progress-circular
                            color="wellis"
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-card color="transparent2">
                        <v-card-text>
                            <v-simple-table>
                                <thead>
                                <tr>
                                    <th></th>
                                    <th width="8%">Row ID</th>
                                    <th>Order ID</th>
                                    <th>IFS Order No</th>
                                    <th>Phase</th>
                                    <th>Objstate</th>
                                    <th>Created at</th>
                                    <th>Updated at</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="failedOrder in orders" :key="objstate+'_'+failedOrder.order_id">
                                    <td>
                                        <v-menu offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn text
                                                       rounded
                                                       icon
                                                       color="wellis"
                                                       v-bind="attrs"
                                                       v-on="on">
                                                    <v-icon>mdi-dots-horizontal</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <!--                                                <v-list-item>
                                                                                                    <v-btn
                                                                                                        class="ma-2"
                                                                                                        outlined
                                                                                                        disabled
                                                                                                        width="100%"
                                                                                                        color="wellis"
                                                                                                    >
                                                                                                        [IFS] Go to order
                                                                                                    </v-btn>
                                                                                                </v-list-item>
                                                                                                <v-list-item>
                                                                                                    <v-btn
                                                                                                        class="ma-2"
                                                                                                        outlined
                                                                                                        disabled
                                                                                                        width="100%"
                                                                                                        color="wellis"
                                                                                                    >
                                                                                                        [IFS] Go to Customer
                                                                                                    </v-btn>
                                                                                                </v-list-item>-->
                                                <v-list-item>
                                                    <v-btn
                                                        class="ma-2"
                                                        outlined
                                                        width="100%"
                                                        color="wellis"
                                                        :to="{name: 'app.admin.orders.failed.details', params: {id: failedOrder.id} }"
                                                    >
                                                        Details
                                                    </v-btn>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-btn
                                                        class="ma-2"
                                                        outlined
                                                        width="100%"
                                                        color="wellis"
                                                        @click="openDetailsNewTab(failedOrder.id)"
                                                    >
                                                        Open details in new tab
                                                    </v-btn>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </td>
                                    <td width="8%">#{{failedOrder.id}}</td>
                                    <td>{{failedOrder.order_id}}</td>
                                    <td v-if="failedOrder.ifs_order_no">
                                        <v-chip color="wellis" text-color="white" small>{{failedOrder.ifs_order_no || '-'}}</v-chip>
                                    </td>
                                    <td v-else>-</td>
                                    <td><i>{{failedOrder.phase}}</i></td>
                                    <td>
                                        <v-chip color="wellis" text-color="white" small>{{resolveObjstate(failedOrder.objstate)}}</v-chip>
                                    </td>
                                    <td>{{failedOrder.created_at | formatDate(true)}}</td>
                                    <td>{{failedOrder.updated_at | formatDate(true)}}</td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-bottom-navigation fixed style="z-index: 0" grow>
                <v-btn icon @click="() => this.fetch().then(() => this.$scrollTop())">
                    <span>Refresh</span>
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-bottom-navigation>
        </v-container>
    </v-container>
</template>

<script>
import {EVENT_PAGE_TITLE_CHANGE} from "../../../../core/constants";
import OrdersApiService from "../../../../api/orders.api";

export default {
    name: "AdminFailedOrdersView",

    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE, 'components.administration.nav.failed-orders'), 100);

        this.fetch();
    },

    data() {
        return {
            mainOverlay: true,
            loadingOverlay: true,

            objstate: 'HANGING',
            objstates: [
                {key: 'HANGING', value: 'Pending'},
                {key: 'RESOLVED', value: 'Resolved'},
                {key: 'DELETED', value: 'Deleted'},
            ],
            orders: [],
        }
    },

    methods: {
        async fetch() {

            return new Promise((resolve, reject) => {
                this.loadingOverlay = true;
                OrdersApiService.failed.get(this.objstate)
                    .then(response => {
                        this.orders = response.data.items;
                        resolve(response.data);
                    })
                    .catch(error => {
                        this.$snackBar({
                            icon: 'mdi-alert',
                            text: this.$t('api-error')
                        })
                        reject(error);
                    })
                    .finally(() => {
                        this.mainOverlay = false;
                        this.loadingOverlay = false;
                    });
            });
        },

        onObjstateChange() {
            this.fetch(this.objstate);
        },

        openDetailsNewTab(failedOrderId) {
            const route = this.$router.resolve({name: 'app.admin.orders.failed.details', params: {id: failedOrderId}});
            window.open(route.href, '_blank');
        }
    },

    computed: {
        resolveObjstate() {
            return objstate => {
                return this.objstates.find(x => x.key === objstate).value;
            }
        }
    }
}
</script>

<style scoped>

</style>
