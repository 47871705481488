/**
 * Actions
 */
export const UPDATE_COUNT = 'updateCount';

/**
 * Mutations
 */
export const FINALIZE_UPDATE_COUNT = 'finalizeCountUpdate';

export default {
    namespaced: true,

    state: {
        count: 0
    },

    getters: {
        countItems(state) {
            return state.count;
        }
    },

    actions: {
        [UPDATE_COUNT](context, quantity) {
            context.commit(FINALIZE_UPDATE_COUNT, quantity);
        }
    },

    mutations: {
        [FINALIZE_UPDATE_COUNT](state, quantity) {
            state.count = quantity;
        },
    }
}
