import Vue from 'vue'
import App from './App.vue'
import vuetify from './vuetify'
import packageJson from '../package.json'

import '@mdi/font/css/materialdesignicons.min.css';

/**
 * Plugin imports
 */

import router from './router.js';
import BackendService from "./core/backend/backend.service";
import store from './store';
import {i18n} from "./core/lang/i18n";
import {formatDate, formatPrice, nl2br} from "./helpers";
import EventBus from './core/event';
import {empty} from "./helpers";

Vue.config.productionTip = false;

Vue.prototype.$eventBus = EventBus

Vue.prototype.$helpers = {
  empty,
  formatDate,

  isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
};

Vue.prototype.$wos = {
  version: packageJson.version
};

Vue.prototype.$scrollTop = function () {
  let element = document.getElementsByName('body');
  let top = element.offsetTop;
  window.scrollTo(0, top);
};

Vue.filter('capitalize', function (value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('toPrice', function (value, currency, vat) {
  return formatPrice(value, currency, vat);
});

Vue.filter('nl2br', function (value) {
  return nl2br(value);
});

Vue.filter('toUpper', function (value) {
  return value.toString().toUpperCase();
});

Vue.filter('toLower', function (value) {
  return value.toString().toLowerCase();
});

Vue.filter('formatDate', function (value, time = false, utc = false) {
  if(!value) {
    return null;
  }
  return formatDate(value, time, utc);
});

Vue.filter('cut', function (value, maxChar = 20) {
  if (value.length > maxChar) {
    return `${value.substr(0, maxChar).toString()}...`;
  }
  return value;
});

Vue.filter('niceBytesSize', function (bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
});

Vue.prototype.$snackBar = function(params) {
  let attributes = {
    content: (params.content || params.text) || null,
    color: params.color || 'wellis',
    timeout: params.timeout || 5000,
    closeButton: params.closeButton || true,
    icon: params.icon || null,
    onClick: params.onClick || null
  };
  return store.dispatch('snackBar/showMessage', attributes);
}

Vue.prototype.$ApplGeneral = function(params) {
  let attributes = {
    content: (params.content || params.text) || null,
    closeButton: params.closeBtn || true,
    onClose: params.onClose || null,
    visible: true
  };
  return store.dispatch('appError/raise', attributes);
}

// Initializing backend api service.
BackendService.bootstrap();
Vue.prototype.$backend = BackendService;

import './assets/css/app.css';

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
