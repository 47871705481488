<template>
    <v-container>
        <v-container fluid class="mt-10" v-if="loadingOverlay">
            <v-progress-linear indeterminate
                               color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container fluid v-else>
            <v-row>
                <v-col xl="4" lg="6" md="12" sm="12" cols="12" v-for="(category, index) in productCategories" :key="category.name+'_'+index+'_'+$store.getters['langStore/current']">
                    <v-hover v-slot="{ hover }">
                        <v-card class="mx-auto pointer" min-height="128" :elevation="hover ? 8 : 2"
                                :to="{name:'app.products.category', params:{category: category.slug}}">
                            <v-card-text class="text--primary align-center text-center">
                                <h4 class="text-h5 wellis--text">{{category.name}}</h4>
                                <div class="mt-3" v-if="category.children.length > 0">
                                    <i v-if="category.children.length === 1">
                                        {{$t('v3.components.products.moreCategories', {num: 1, cat: $t('v3.components.products.category')})}}
                                    </i>
                                    <i v-else>
                                        {{$t('v3.components.products.moreCategories', {num: category.children.length, cat: $t('v3.components.products.categories')})}}
                                    </i>
                                </div>
                                <div v-else class="mt-3">
                                    <i>{{$t('v3.components.products.showProducts')}}</i>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import {EVENT_PAGE_TITLE_CHANGE, EVENT_LANG_CHANGE} from "../../../core/constants";
import {LiteUser} from '../../../helpers';

export default {
    name: "ProductCategoryView",

    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE, 'components.products.title'), 100);

        if (!LiteUser.hasPermission(
            this.$store.getters['authStore/isLiteUser'],
            this.$store.getters['authStore/getLiteUserPermission'],
            'products',
        )) {
            this.$ApplGeneral({
                content: this.$t('v32.liteUser.hasNoPermission'),
                onClose: () => {
                    this.$router.push({name: 'app.start'});
                }
            });
            return;
        }

        this.$eventBus.$on(EVENT_LANG_CHANGE, () => this.fetch());

        this.fetch();
    },

    destroyed() {
        this.$eventBus.$off(EVENT_LANG_CHANGE);
    },

    data() {
        return {
            current: null,
            items: [],
            loadingOverlay: true
        }
    },

    methods: {
        fetch() {
            this.loadingOverlay = true;
            this.$store.dispatch('categoryStore/clearCache')
                .then(() => {
                    this.$store.dispatch(`categoryStore/fetch`)
                        .finally(() => {
                            this.loadingOverlay = false;
                        });
                });
        },
    },

    computed: {
        productCategories: function () {
            return this.$store.getters["categoryStore/getProductItems"];
        }
    },
}
</script>

<style scoped>

</style>
