import Vue from 'vue';
import Vuex from 'vuex';
import authStore from './store/auth.store';
import langStore from './store/language.store';
import categoryStore from './store/category.store';
import cartStore from './store/cart.store';
import configStore from './store/config.store';
import snackBar from './store/snackbar.store';
import appError from './store/apperror.store';
import explorerStore from './store/explorer.store';
import appSettingsStore from './store/appsettings.store';

Vue.use(Vuex);

export default new Vuex.Store({
   modules: {
      authStore,
      langStore,
      categoryStore,
      cartStore,
      configStore,
      snackBar,
      explorerStore,
      appSettingsStore,
      appError
   }
});
