<template>
    <v-container>
        <v-overlay :value="loadingOverlay" absolute color="white">
            <v-progress-circular
                color="wellis"
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-breadcrumbs :items="breadcrumb">
            <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
            </template>
            <template v-slot:item="{ item }">
                <v-breadcrumbs-item v-if="item.name !== null" :disabled="item.isLast">
                    <span class="pointer" @click="getContent(item.path)">{{item.name}}</span>
                </v-breadcrumbs-item>
                <v-breadcrumbs-item v-else :disabled="item.isLast">
                    <v-icon style="font-size:26px" class="pointer" @click="getContent(item.path)">{{item.icon}}</v-icon>
                </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs>
        <v-row>
            <v-col cols="12" lg="9" md="12" sm="12">
                <v-row v-if="folders.length > 0">
                    <v-col md="12" sm="12">
                        <h2 class="mt-2 mb-4">{{$t('v3.components.media.folders')}}</h2>
                        <v-row>
                            <v-col cols="12" lg="4" md="12" sm="12" v-for="(item, index) in folders" :key="compMedia.currentPath + '_' + index">
                                <MediaItemCard :item="item"/>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row v-if="files.length > 0">
                    <v-col md="12">
                        <h2 class="mt-2 mb-4">{{$t('v3.components.media.files')}}</h2>
                        <v-row>
                            <v-col cols="12" lg="4" md="12" sm="12" v-for="(item, index) in files" :key="compMedia.currentPath + '_' + index">
                                <MediaItemCard :item="item" :selected-state="resolveSelectedState(item.path)"/>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" lg="3" md="12" xs="12" sm="12">
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th>({{selectedFiles.length}}) {{totalSize | niceBytesSize}}</th>
                            <th class="text-left">
                                {{$t('components.media.selected-files')}}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(file, index) in selectedFiles" :key="file.name">
                            <td>
                                <v-btn small icon @click="removeSelected(index)">
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                            </td>
                            <td> {{file.name | cut(25)}}.{{file.extension}}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-btn class="mt-3" block @click="downloadSelectedFiles" v-if="selectedFiles.length > 0">{{$t('components.media.download')}}</v-btn>
            </v-col>
        </v-row>
        <v-overlay :z-index="999" :value="previewImage.state">
            <v-container class="modal-image-content">
                <v-img class="white--text" @click="previewImage.state = false"
                       v-click-outside="() => previewImage.state = false"
                       contain
                       max-height="550"
                       :src="previewImage.img"/>
            </v-container>
        </v-overlay>
        <div v-if="$store.getters['authStore/isAdmin']">
            <MediaItemRestriction :visible="restrictionTarget !== null" :media-item="restrictionTarget"/>
        </div>
    </v-container>
</template>

<script>
import {
    EVENT_MEDIA_PATH_CHANGE,
    EVENT_MEDIA_PREVIEW_IMAGE,
    EVENT_MEDIA_RESTRICT, EVENT_MEDIA_RESTRICT_CHANGED,
    EVENT_MEDIA_RESTRICT_CLOSE,
    EVENT_MEDIA_SELECT_FILE,
    EVENT_PAGE_TITLE_CHANGE,
} from '../../../core/constants';
import MediaApiService from '../../../api/media.api';
import {empty, handleDownloadedFile} from '../../../helpers';
import MediaItemCard from './MediaItemCard';
import Vue from 'vue';
import MediaItemRestriction from './MediaItemRestriction';

export default {
    name: 'MediaView',
    components: {MediaItemRestriction, MediaItemCard},
    data() {
        return {
            loadingOverlay: true,
            media: null,
            selectedFiles: [],
            breadcrumb: [],
            restrictionTarget: null,
            previewImage: {
                state: false,
                img: null,
            },
        };
    },

    mounted() {
        Vue.prototype.$MediaView = this;
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE, 'components.media.title'), 100);

        if (!this.hasLiteUserPermission('media')) {
            this.$ApplGeneral({
                content: this.$t('v32.liteUser.hasNoPermission'),
                onClose: function() {
                    this.$router.push({
                        name: 'app.start',
                    });
                },
            });
            return;
        }

        this.$eventBus.$on(EVENT_MEDIA_PATH_CHANGE, path => this.getContent(path));
        this.$eventBus.$on(EVENT_MEDIA_PREVIEW_IMAGE, this.resolvePreview);
        this.$eventBus.$on(EVENT_MEDIA_SELECT_FILE, this.handleFileSelection);
        this.$eventBus.$on(EVENT_MEDIA_RESTRICT, this.openRestrictionModal);
        this.$eventBus.$on(EVENT_MEDIA_RESTRICT_CLOSE, this.closeRestrictionModal);
        this.$eventBus.$on(EVENT_MEDIA_RESTRICT_CHANGED, this.updateMediaItemRestriction);


        let startPath = this.$route.hash || '';
        if (startPath.length > 0) {
            startPath = startPath.substr(1, startPath.length);
        }
        this.getContent(startPath);
    },

    destroyed() {
        this.$eventBus.$off(EVENT_MEDIA_PATH_CHANGE);
        this.$eventBus.$off(EVENT_MEDIA_PREVIEW_IMAGE);
        this.$eventBus.$off(EVENT_MEDIA_SELECT_FILE);
        this.$eventBus.$off(EVENT_MEDIA_RESTRICT);
        this.$eventBus.$off(EVENT_MEDIA_RESTRICT_CLOSE);
        this.$eventBus.$off(EVENT_MEDIA_RESTRICT_CHANGED);
    },

    methods: {
        hasLiteUserPermission(permission) {
            if (!this.$store.getters['authStore/isLiteUser']) {
                return true;
            }
            let permission_ = this.$store.getters['authStore/getLiteUserPermission'].find(x => x.permission === permission);
            return permission_.objstate === 'ACTIVE';
        },

        getContent(path = '', parentPath = null) {
            this.loadingOverlay = true;
            return new Promise((resolve, reject) => {
                MediaApiService.getContent(path, parentPath)
                    .then(response => {
                        this.media = response.data;
                        this.loadingOverlay = false;
                        history.pushState(
                            {},
                            null,
                            this.$route.path + (path.length > 0 ? ('#' + path) : ''),
                        );
                        resolve(response.data);
                    })
                    .catch(error => {
                        this.$snackBar({
                            content: this.$t('api-error'),
                            icon: 'mdi-alert',
                        });
                        this.hideOverlay();
                        this.breadcrumb.push({
                            name: null,
                            icon: 'mdi-folder-home-outline',
                            path: '',
                        });
                        reject(error);
                    });
            });
        },

        resolvePreview(mediaItem) {
            this.loadingOverlay = true;
            MediaApiService.previewImage(mediaItem.path)
                .then(response => {
                    this.previewImage.img = `data:${response.data.type};base64,${response.data.image}`;
                    this.previewImage.state = true;
                })
                .finally(() => {
                    this.loadingOverlay = false;
                });
        },

        showOverlay() {
            return this.loadingOverlay = true;
        },

        hideOverlay() {
            return this.loadingOverlay = false;
        },

        handleFileSelection(mediaItem) {
            let index = this.selectedFiles.findIndex(item => item.path === mediaItem.path);
            if (index > -1) {
                this.selectedFiles.splice(index, 1);
            } else {
                this.selectedFiles.push(mediaItem);
            }
            this.$forceUpdate();
        },

        downloadSelectedFiles() {
            this.showOverlay();
            const files = this.selectedFiles.map(item => item.path);
            MediaApiService.multipleDownload(files)
                .then(blobFile => {
                    let filename = blobFile.headers['content-disposition'].split('filename')[1];
                    filename = filename.substr(1, filename.length);
                    handleDownloadedFile(blobFile.data, `media_${filename}`, blobFile.type);
                    this.$set(this, 'selectedFiles', []);
                })
                .catch(() => {
                    this.$snackBar({
                        content: this.$t('api-error'),
                        icon: 'mdi-alert',
                    });
                })
                .finally(() => {
                    this.hideOverlay();
                });
        },

        removeSelected(index) {
            this.selectedFiles.splice(index, 1);
        },

        openRestrictionModal(mediaItem) {
            this.restrictionTarget = mediaItem;
        },

        closeRestrictionModal() {
            this.restrictionTarget = null;
        },

        updateMediaItemRestriction(data) {
            const index = this.media.items.findIndex(item => item.path === data.path);
            if (index > -1) {
                this.media.items[index].restricted = data.restricted;
            }
        },
    },

    computed: {
        compMedia() {
            return this.media;
        },

        folders() {
            if (this.media) {
                return this.media.items.filter(item => item.type === 'TYPE_DIR');
            }
            return [];
        },

        files() {
            if (this.media) {
                return this.media.items.filter(item => item.type !== 'TYPE_DIR');
            }
            return [];
        },

        totalSize() {
            let size = 0;
            this.selectedFiles.forEach(item => size += item.size);
            return size;
        },

        resolveSelectedState() {
            return path => {
                return this.selectedFiles.findIndex(item => item.path === path) > -1;
            };
        },
    },

    watch: {
        'media': {
            deep: true,
            immediate: true,
            handler(val) {
                if (!empty(val)) {
                    const items = this.media.currentPath.split('/');
                    const breadcrumb = [];
                    breadcrumb.push({
                        name: null,
                        icon: 'mdi-folder-home-outline',
                        path: '',
                    });
                    if (items.length > 0 && items[0] === '') {
                        items.splice(0, 1);
                    }
                    let maxPath = '';
                    if (items.length > 0) {
                        items.forEach((item, index) => {
                            if (maxPath.length > 0) {
                                maxPath += '/';
                            }
                            maxPath += item;
                            breadcrumb.push({
                                name: item,
                                path: maxPath,
                                isLast: index === items.length - 1,
                            });
                        });
                    } else {
                        breadcrumb[0].isLast = true;
                    }
                    this.$set(this, 'breadcrumb', breadcrumb);
                }
            },
        },
    },
};
</script>

<style scoped>
.modal-image-content {
    margin: auto;
    display: block;
    width: calc(75%);
}
</style>
