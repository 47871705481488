import BackendService from "../core/backend/backend.service";

const PurchaseOrderApi = {

    async getItemsPrice(items = []) {
        return BackendService.post('purchase-order/items-price', {
            items: JSON.stringify(items)
        });
    },


    async getDetails(uniqueId, query = null) {
        return BackendService.query(`purchase-order/details/${uniqueId}`, query);
    },

}

export default PurchaseOrderApi;
