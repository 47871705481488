<template>
    <v-container>
        <v-alert
            border="left" colored-border :color="info.variant" elevation="2" v-if="this.info.text">
            {{$t(this.info.text)}}
        </v-alert>
        <v-card light elevation="2">
            <v-overlay :value="loadingOverlay" absolute>
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
            <v-card-text class="pa-10">
                <div class="subheading text-center text-h5 color-wellis font-weight-bold mb-5">
                    <template>{{$t('components.after-login-realm-select.title')}}</template>
                </div>
                <p class="text" v-for="(text, index) in $t('components.after-login-realm-select.texts')" :key="index">
                    {{text}}
                </p>
                <v-list dense>
                    <v-list-item-group v-model="realm">
                        <v-list-item v-for="(realm, index) in $t('components.after-login-realm-select.realms')"
                                     :key="index" :value="realm.key">
                            <v-list-item-action>
                                <v-icon>mdi-account-convert</v-icon>
                            </v-list-item-action>
                            <v-list-item-action-text>
                                {{realm.name}}
                            </v-list-item-action-text>
                        </v-list-item>

                    </v-list-item-group>
                </v-list>
                <v-divider class="mt-5 mb-3"/>
                <v-row no-gutters>
                    <v-col offset-md="6" md="6" sm="12">
                        <LanguagePicker :guest-mode="true"/>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import LanguagePicker from "../common/LanguagePicker";
import RealmApiService from "../../api/realm.api";

export default {
    name: "AfterLoginRealmSelect",
    components: {LanguagePicker},
    data() {
        return {
            loadingOverlay: false,
            realm: null,
            info: {
                variant: 'wellis',
                text: null
            },
        }
    },

    methods: {
        selectRealm(realm) {
            this.info.text = null;
            RealmApiService.select(realm)
                .then(() => {
                    this.$router.push({name: 'app.start'});
                })
                .catch(error => {
                    if (!error.response || error.response.data.message !== 'REALM_ERROR') {
                        this.info.text = this.$t('whoops');
                    } else {
                        this.info.text = error.response.data.payload.realm;
                    }
                    this.loadingOverlay = false;
                });
        }
    },

    watch: {
        'realm': function (value) {
            this.loadingOverlay = true;
            this.selectRealm(value);
        }
    }
}
</script>

<style scoped>

</style>
