<template>
    <v-row justify="center">
        <v-dialog
            v-model="visible"
            persistent
            max-width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{$t('components.media.restrict-modal.title')}}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-overlay :value="loadingOverlay" absolute color="white">
                            <v-progress-circular
                                color="wellis"
                                indeterminate
                                size="64"
                            ></v-progress-circular>
                        </v-overlay>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-text-field
                                    :label="$t('components.media.restrict-modal.filename')"
                                    readonly
                                    color="wellis"
                                    :value="filename"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-text-field
                                    :label="$t('components.media.restrict-modal.path')"
                                    readonly
                                    color="wellis"
                                    :value="path"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-switch
                                    color="wellis"
                                    inset
                                    v-model="restricted"
                                    :label="$t('components.media.restrict-modal.restricted')"
                                ></v-switch>
                            </v-col>
                            <v-col cols="12" sm="12" v-if="restricted">
                                <v-autocomplete
                                    v-model="selectedHierarchies"
                                    chips
                                    small-chips
                                    :items="hierarchies"
                                    :label="$t('please-select')"
                                    multiple
                                    color="wellis"
                                    item-color="wellis"
                                    item-text="description"
                                    item-value="hierarchy_id"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="wellis darken-1" text @click="close">
                        {{$t('modal.close')}}
                    </v-btn>
                    <v-btn color="wellis darken-1" text @click="update">
                        {{$t('btnSave')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import {EVENT_MEDIA_RESTRICT_CHANGED, EVENT_MEDIA_RESTRICT_CLOSE} from "../../../core/constants";
import MediaApiService from "../../../api/media.api";

export default {
    name: "MediaItemRestriction",

    props: {
        visible: Boolean,
        mediaItem: Object
    },

    data() {
        return {
            loadingOverlay: true,
            restricted: false,
            hierarchies: [],
            selectedHierarchies: [],
            loader: null,
        }
    },

    methods: {
        close() {
            this.$eventBus.$emit(EVENT_MEDIA_RESTRICT_CLOSE);
        },

        fetch() {
            this.loadingOverlay = true;
            MediaApiService.permissions(this.mediaItem.path)
                .then(response => {
                    this.hierarchies = response.data.hierarchies;
                    this.restricted = response.data.restricted;
                    this.selectedHierarchies = [];
                    response.data.locks.forEach(hierarchyId => {
                        const hierarchy = this.hierarchies.find(item => item.hierarchy_id === hierarchyId);
                        if (hierarchy) {
                            this.selectedHierarchies.push(hierarchy);
                        }
                    });
                })
                .catch(() => {
                    this.$snackBar({
                        content: this.$t('api-error'),
                        icon: 'mdi-alert'
                    });
                })
                .finally(() => this.loadingOverlay = false);
        },

        update() {
            this.loadingOverlay = true;
            const data = {
                path: this.mediaItem.path,
                hierarchy_lock: this.restricted ? this.selectedHierarchies : []
            };
            MediaApiService.updatePermissions(data).then(() => {
                this.$eventBus.$emit(EVENT_MEDIA_RESTRICT_CHANGED, {
                    path: this.mediaItem.path,
                    restricted: data.hierarchy_lock.length > 0
                });
                this.$snackBar({
                    content: this.$t('components.media.restrict-modal.success'),
                    icon: 'mdi-checkbox-marked-circle-outline'
                });
            }).catch(() => {
                this.$snackBar({
                    content: this.$t('whoops'),
                    icon: 'mdi-alert'
                });
            }).finally(() => this.loadingOverlay = false);
        },

        /* showLoader() {
             return new Promise(resolve => {
                 if (!empty(this.loader)) {
                     this.loader.hide();
                     this.loader = null;
                 }
                 this.loader = this.$loading.show({
                     container: this.$refs.loadingContainer,
                     isFullPage: false
                 });
                 resolve(this.loader);
             });
         },

         hideLoader() {
             return new Promise(resolve => {
                 if (!empty(this.loader)) {
                     this.loader.hide();
                     this.loader = null;
                 }
                 resolve();
             });
         },*/
    },

    computed: {
        filename() {
            if (!this.mediaItem) {
                return null;
            }
            let filename = this.mediaItem.name;
            if (this.mediaItem.type === 'TYPE_FILE') {
                filename += '.' + this.mediaItem.extension;
            }
            return filename;
        },

        path() {
            if (!this.mediaItem) {
                return null;
            }
            return ` ~/${this.mediaItem.path}`;
        }
    },

    watch: {
        'mediaItem': function (value) {
            if (value) {
                this.fetch();
            }
        }
    }
}
</script>

<style scoped>

</style>
