import BackendService from "../core/backend/backend.service";

const RealmApiService = {
    async select(realm) {
        return BackendService.post('account/realm', {
            "realm": realm
        });
    }
}

export default RealmApiService;


