<template>
    <div>
        <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" depressed>
                    <v-icon class="mr-1">mdi-office-building-outline</v-icon>
                    {{currentRealmName}}
                </v-btn>
            </template>

            <v-list>
                <v-list-item v-for="realm in $t('components.after-login-realm-select.realms')" :key="realm.key" link
                             @click="preSelectRealm(realm.key)">
                    <v-list-item-title :class="currentRealmKey === realm.key ? 'wellis--text' : ''">{{realm.name}}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="450"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{$t('confirm.title')}}
                </v-card-title>
                <v-card-text v-html="$t('components.realm-select.warning-text')"></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="grey darken-1"
                        text
                        @click="dialog = false"
                    >
                        {{$t('confirm.no')}}
                    </v-btn>
                    <v-btn
                        color="wellis darken-1"
                        text
                        @click="selectRealm"
                    >
                        {{$t('confirm.yes')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import RealmApiService from "../../api/realm.api";

export default {
    name: "RealmSelect",

    data() {
        return {
            dialog: false,
            preSelectedRealm: null,
        }
    },

    methods: {
        preSelectRealm(key) {
            if (key !== this.currentRealmKey) {
                this.preSelectedRealm = key;
                this.dialog = true;
            }
        },

        selectRealm() {
            this.dialog = false;
            this.$ApplicationLayout.showOverlay();
            RealmApiService.select(this.preSelectedRealm)
                .then(() => {
                    location.reload(true);
                })
                .catch(error => {
                    if (!error.response || error.response.data.message !== 'REALM_ERROR') {
                        this.$snackBar({
                            content: this.$t('whoops'),
                            icon: 'mdi-alert',
                        });
                    } else {
                        this.$snackBar({
                            content: error.response.data.payload.realm,
                            icon: 'mdi-alert',
                        });
                    }
                    this.$ApplicationLayout.hideOverlay();
                });
        }
    },

    computed: {
        currentRealmName() {
            let current = this.$store.getters['authStore/currentRealm'];
            if (!current) {
                return 'N/A';
            }

            let realm = this.$t('components.after-login-realm-select.realms').find(item => item.key === current);
            return realm.name;
        },

        currentRealmKey() {
            return this.$store.getters['authStore/currentRealm'];
        },
    },
}
</script>

<style scoped>

</style>
