<template>
    <v-container fluid>
        <v-container fluid class="mt-10" v-if="loadingOverlay">
            <v-progress-linear indeterminate
                               color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container fluid v-else>
            <v-breadcrumbs :items="breadcrumb">
                <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                </template>
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item v-if="item.name !== null" :disabled="true">
                        <span class="pointer">{{item.name}}</span>
                    </v-breadcrumbs-item>
                    <v-breadcrumbs-item v-else :disabled="false" @click="() => $router.push({name: item.route})">
                        <v-icon style="font-size:26px" class="pointer">{{item.icon}}</v-icon>
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
            <v-row>
                <v-col cols="12" xl="3" lg="4" md="12" sm="12">
                    <v-card>
                        <v-card-title>{{$t('components.stocklist.filters')}}</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-list shaped>
                                <v-subheader>{{$t('v3.components.products.filter.category')}}</v-subheader>
                                <v-list-item-group color="wellis">
                                    <v-list-item v-for="item in categoryFilters"
                                                 :class="item.value === filters.category ? 'v-item--active v-list-item--active': ''"
                                                 :key="item.value+'_'+$store.getters['langStore/current']">
                                        <v-list-item-content @click="selectSubCategory(item.value)">
                                            <v-list-item-title v-text="item.name"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" xl="9" lg="8" md="12" sm="12">
                    <v-card color="transparent2">
                        <v-card-title>
                            <v-btn v-if="!$helpers.isMobile()"
                                   icon
                                   large
                                   class="float-right" @click="() => $store.dispatch('appSettingsStore/toggleGridView')">
                                <v-icon v-if="$store.getters['appSettingsStore/isGridView']">mdi-view-grid-outline</v-icon>
                                <v-icon v-else>mdi-table</v-icon>
                            </v-btn>
                            <span class="fetch-status">{{currentFetchStatus}}</span>
                            <v-spacer></v-spacer>
                            <div>
                                <v-select
                                    dense
                                    color="black"
                                    item-color="wellis"
                                    :items="sortValues"
                                    prepend-icon="mdi-sort"
                                    item-value="value"
                                    item-text="text"
                                    v-model="sortVal"
                                >
                                </v-select>
                            </div>

                        </v-card-title>
                        <v-card-text>
                            <v-container fluid class="mt-10" v-if="fetchingProducts">
                                <v-progress-linear indeterminate
                                                   color="wellis"
                                ></v-progress-linear>
                            </v-container>
                            <v-container v-if="$store.getters['appSettingsStore/isGridView']" fluid>
                                <v-row>
                                    <v-col cols="12" xl="3" lg="12" md="12" sm="12" v-for="product in items" :key="product.catalogNo+$store.getters['langStore/current']">
                                        <ProductCard :product="product" card :category="category"/>
                                    </v-col>
                                    <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="text-center" v-if="hasMore">
                                        <v-btn @click="fetchProducts" :loading="fetchingProducts">{{$t('components.products.category.btnShowMore')}}</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-container v-else fluid>
                                <template>
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <tbody>
                                            <ProductCard
                                                :product="product" v-for="product in items"
                                                :key="product.catalogNo+$store.getters['langStore/current']"
                                                :category="category"
                                            />
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                    <v-container fluid class="text-center" v-if="hasMore">
                                        <v-btn @click="fetchProducts" :loading="fetchingProducts">{{$t('components.products.category.btnShowMore')}}</v-btn>
                                    </v-container>
                                </template>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import ProductCard from "./ProductCard";
import ProductApiService from "../../../api/product.api";
import {EVENT_LANG_CHANGE, EVENT_PAGE_TITLE_CHANGE} from "../../../core/constants";
import {__clone, LiteUser} from '../../../helpers';

export default {
    name: "ProductCategoryItems",
    components: {ProductCard},
    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE, 'components.products.title'), 100);

        if (!LiteUser.hasPermission(
            this.$store.getters['authStore/isLiteUser'],
            this.$store.getters['authStore/getLiteUserPermission'],
            'products',
        )) {
            this.$ApplGeneral({
                content: this.$t('v32.liteUser.hasNoPermission'),
                onClose: () => {
                    this.$router.push({name: 'app.start'});
                }
            });
            return;
        }

        this.fetchCategory().then(() => {
            if (this.$route.query.subcategory) {
                this.selectSubCategory(this.$route.query.subcategory);
            }
        });

        this.$eventBus.$on(EVENT_LANG_CHANGE, () => {
            this.$router.push({name: 'app.products'});
        });
    },

    destroyed() {
        this.$eventBus.$off(EVENT_LANG_CHANGE);
    },

    data() {
        return {
            breadcrumb: [
                {
                    name: null,
                    icon: 'mdi-shopping',
                    route: 'app.products',
                    isLast: false,
                }
            ],
            category: null,
            loadingOverlay: false,
            fetchingProducts: false,
            filters: {
                category: null,
                categories: [],
                orderBy: 'name',
                orderType: 'asc',
                offset: 0,
            },
            items: [],
            hasMore: false,
            sortVal: 'nameAsc',

            totalItems: 0,

            sortValues: [
                {value: 'nameAsc', text: this.$t('components.products.category.sort.types.nameAsc')},
                {value: 'nameDesc', text: this.$t('components.products.category.sort.types.nameDesc')},
                {value: 'priceAsc', text: this.$t('components.products.category.sort.types.priceAsc')},
                {value: 'priceDesc', text: this.$t('components.products.category.sort.types.priceDesc')}
            ]
        }
    },

    methods: {
        fetchCategories() {
            return new Promise(resolve => {
                this.$store.dispatch(`categoryStore/fetch`)
                    .finally(() => {
                        this.loadingOverlay = false;
                        resolve();
                    });
            });
        },

        async fetchCategory() {
            if (this.$store.getters["categoryStore/getProductItems"].length === 0) {
                await this.$store.dispatch('categoryStore/clearCache').then(() => this.fetchCategories());
            }

            this.category = this.$store.getters["categoryStore/getProductItems"]
                .find(item => item.slug === this.$route.params.category);

            this.filters.category = this.category.slug;

            this.filters.categories = [{name: this.$t('v3.components.products.filter.all'), value: this.category.slug}, ...this.category.children.map(item => {
                return {name: item.name, value: item.slug};
            })];

            this.breadcrumb.push({
                name: this.categoryName,
                icon: 'mdi-folder-home-outline',
                isLast: true
            });

            this.fetchProducts();
        },

        selectSubCategory(category) {
            this.fetchingProducts = true;
            this.filters.category = category;
            this.items = [];
            this.hasMore = false;
            this.filters.offset = 0;
            this.filters.orderBy = 'name';
            this.filters.orderType = 'asc';

            if (this.$route.params.category !== this.filters.category) {
                history.pushState(
                    {},
                    null,
                    this.$route.path + '?subcategory=' + this.filters.category
                );
            }

            this.$scrollTop();
            this.fetchProducts();
        },

        fetchProducts() {
            this.fetchingProducts = true;
            ProductApiService.getProducts((this.filters.category || this.category.slug), this.filters.orderBy, this.filters.orderType, this.filters.offset)
                .then(response => {
                    this.filters.offset = response.data.currentOffset;
                    this.filters.orderBy = response.data.orderBy;
                    this.filters.orderType = response.data.orderType;
                    this.totalItems = response.data.total;
                    this.items = this.items.concat(response.data.products);
                    this.hasMore = response.data.hasMore;

                    if (this.category.name === response.data.category.name) {
                        this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE, 'components.products.title', this.category.name);
                    } else {
                        this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE,
                            'components.products.title',
                            response.data.category.name + ' / ' + this.category.name);
                    }

                    this.applySort();
                })
                .catch(() => {
                    return this.$router.push({name: 'app.404'});
                })
                .finally(() => {
                    this.loadingOverlay = false;
                    this.fetchingProducts = false;
                });
        },

        applySort() {
            let items = __clone(this.items);
            let currentSort = this.sortVal;
            this.$set(this, 'items', items.sort(function (a, b) {
                switch (currentSort) {
                    case 'nameAsc':
                        return a.name > b.name;
                    case 'nameDesc':
                        return a.name < b.name;
                    case 'priceAsc':
                        if (a.price === null) {
                            return false;
                        }
                        return a.price.unitPrice > b.price.unitPrice;
                    case 'priceDesc':
                        if (a.price === null) {
                            return false;
                        }
                        return a.price.unitPrice < b.price.unitPrice;
                }
            }));
        }
    },

    computed: {
        categoryName() {
            if (!this.category) {
                return '';
            }
            return this.category.name;
        },

        categoryFilters() {
            return this.filters.categories;
        },

        currentFetchStatus() {
            return `${this.items.length} / ${this.totalItems} ${this.$t('components.products.category.items')}`
        }
    },

    watch: {
        'sortVal': function (val) {
            switch (val) {
                case 'nameDesc':
                    this.filters.orderBy = 'name';
                    this.filters.orderType = 'desc';
                    break;

                case 'priceAsc':
                    this.filters.orderBy = 'price';
                    this.filters.orderType = 'asc';
                    break;

                case 'priceDesc':
                    this.filters.orderBy = 'price';
                    this.filters.orderType = 'desc';
                    break;

                case 'nameAsc':
                default:
                    this.filters.orderBy = 'name';
                    this.filters.orderType = 'asc';
                    break;
            }

            this.items = [];
            this.hasMore = false;
            this.filters.offset = 0;
            this.fetchProducts();
        }
    }
}
</script>

<style scoped>
.fetch-status {
    font-size: 16px !important;
}
</style>
