<template>
    <v-dialog v-model="visible"
              style="z-index: 9999"
              @click:outside="visible = false"
              width="500"
              max-width="600">
        <v-card>
            <v-toolbar dark
                       color="wellis">
                <v-btn icon
                       @click="visible = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Wellis Online System API</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="text-center" style="font-size:16px!important;">
                <v-container v-html="content"></v-container>
                <div class="mt-2">
                    <v-btn color="wellis-text-white" @click="visible = false">
                        {{ $t('modal.close') }}
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ApplicationGeneralMessage',

    created() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'appError/update') {
                this.content = state.appError.content;
                this.closeBtn = state.appError.closeButton;
                this.onClose = state.appError.onClose;
                this.visible = state.appError.visible;
            }
        });
    },

    data() {
        return {
            visible: false,
            closeBtn: true,
            content: null,
            onClose: null,
        };
    },

    watch: {
        'visible': function(val) {
            if (!val && this.onClose !== null) {
                this.onClose();
            }
        },
    },
};
</script>

<style scoped>

</style>
