import BackendService from "../core/backend/backend.service";

const MediaApiService = {
    getContent(path) {
        return BackendService.query('media', {
            path: path,
        });
    },

    previewImage(path) {
        return BackendService.query('media/preview-image', {
            path: path,
        });
    },

    singleDownload(path) {
        return BackendService.download('media/download-single', {path: path}, {responseType: 'arraybuffer'});
    },

    multipleDownload(files) {
        return BackendService.download('media/download-multiple', {files: files}, {responseType: 'arraybuffer'}, true);
    },

    permissions(path) {
        return BackendService.query('media/permissions', {
            path: path,
        });
    },

    updatePermissions(data) {
        return BackendService.post('media/permissions', data);
    },

    ifs: {
        downloadDocument(documentId) {
            return BackendService.download(`media/ifs/download-document/${documentId}`,{}, {responseType: 'arraybuffer'});
        }
    }
}

export default MediaApiService;


