<template>
    <div v-if="windowWidth > 760">
        <form @submit="submitHandler">
            <v-text-field class="mt-4" color="wellis" v-model="value" clearable :disabled="!hasLiteUserPermission('products')">
                <template v-slot:label>
                    {{$t('components.search.title')}}
                </template>
                <template v-slot:prepend>
                    <v-icon>mdi-magnify</v-icon>
                </template>
            </v-text-field>
        </form>
    </div>

    <div v-else>
        <v-btn icon @click="searchDialog = true" :disabled="!hasLiteUserPermission('products')">
            <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-dialog v-model="searchDialog">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                    <form @submit="submitHandler">
                        <v-text-field color="wellis" v-model="value" clearable :disabled="!hasLiteUserPermission('products')">
                            <template v-slot:label>
                                {{$t('components.search.title')}}
                            </template>
                            <template v-slot:prepend>
                                <v-icon>mdi-magnify</v-icon>
                            </template>
                        </v-text-field>
                    </form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {empty} from '../../../helpers';
import {EVENT_SEARCH} from '../../../core/constants';

export default {
    name: 'SearchToolbar',

    mounted() {
        window.onresize = () => {
            this.windowWidth = window.innerWidth;
        };
    },

    data() {
        return {
            searchDialog: false,
            value: null,
            windowWidth: window.innerWidth,
        };
    },

    methods: {
        submitHandler(e) {
            e.preventDefault();
            if (!this.hasLiteUserPermission('products')) {
                this.$ApplGeneral({
                    content: this.$t('v32.liteUser.hasNoPermission'),
                });
                return;
            }

            this.searchDialog = false;
            if (!empty(this.value)) {
                this.invokeSearchAction(this.value);
            }
        },

        invokeSearchAction(value) {
            if (this.$route.name !== 'app.search') {
                this.$router.push({
                    name: 'app.search',
                    query: {
                        search: value,
                    },
                });
            } else {
                this.$eventBus.$emit(EVENT_SEARCH, this.value);
            }
        },

        hasLiteUserPermission(permission) {
            if (!this.$store.getters['authStore/isLiteUser']) {
                return true;
            }
            let permission_ = this.$store.getters['authStore/getLiteUserPermission'].find(x => x.permission === permission);
            return permission_.objstate === 'ACTIVE';
        },
    },

    watch: {
        '$route.name': function(val) {
            if (val !== 'app.search') {
                this.value = null;
            }
        },
    },
};
</script>

<style scoped>

</style>
