<template>
    <v-container fluid>
        <v-container fluid class="mt-10" v-if="mainOverlay">
            <v-progress-linear indeterminate
                               color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container fluid v-else>
            <v-row>
                <v-col cols="12" md="12" sm="12">
                    <v-overlay :value="loadingOverlay" color="white">
                        <v-progress-circular
                            color="wellis"
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-card color="transparent2">
                        <v-card-text>
                            <v-simple-table>
                                <thead>
                                <tr>
                                    <th></th>
                                    <th>UUID</th>
                                    <th>Job Id</th>
                                    <th>Result</th>
                                    <th>Message</th>
                                    <th>Started at</th>
                                    <th>Finished at</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="job in items" :key="job.uuid">
                                    <td>
                                        <v-menu offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn text
                                                       rounded
                                                       icon
                                                       color="wellis"
                                                       v-bind="attrs"
                                                       v-on="on">
                                                    <v-icon>mdi-dots-horizontal</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item>
                                                    <v-btn
                                                        class="ma-2"
                                                        outlined
                                                        width="100%"
                                                        color="wellis"
                                                        :to="{name: 'app.admin.scheduler.history.concrete', params: {uuid: schedulerId, historyId: job.uuid} }"
                                                    >
                                                        Details
                                                    </v-btn>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-btn
                                                        class="ma-2"
                                                        outlined
                                                        width="100%"
                                                        color="wellis"
                                                        @click="openDetailsNewTab(job.uuid)"
                                                    >
                                                        Open details in new tab
                                                    </v-btn>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </td>
                                    <td>
                                        <v-chip small>{{job.uuid}}</v-chip>
                                    </td>
                                    <td class="font-weight-bold">{{job.job_id}}</td>
                                    <td>
                                        <v-chip :color="resolveResultColor(job.result).chip"
                                                :text-color="resolveResultColor(job.result).text" small>{{job.result}}
                                        </v-chip>
                                    </td>
                                    <td class="font-italic">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on" style="font-size: 13px;">{{job.message | cut(30)}}</span>
                                            </template>
                                            <span>{{job.message}}</span>
                                        </v-tooltip>
                                    </td>
                                    <td>{{job.started_at | formatDate(true)}}</td>
                                    <td>{{job.finished_at | formatDate(true)}}</td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-bottom-navigation fixed style="z-index: 0" grow>
            <v-btn icon :to="{name: 'app.admin.scheduler'}">
                <span>Back to Schedulers</span>
                <v-icon>mdi-keyboard-backspace</v-icon>
            </v-btn>
            <v-btn icon @click="filterDialog = true">
                <span>Search</span>
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn icon @click="() => this.countRows()">
                <span>Refresh</span>
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
        </v-bottom-navigation>

        <!-- OVER_FETCH_LIMIT Dialog -->
        <v-dialog v-model="overFetchLimitDialog.dialog"
                  persistent
                  width="400">
            <v-card>
                <v-card-title>Wellis Online System API</v-card-title>
                <v-card-text class="mt-5">
                    Your request has returned more than <b class="wellis--text">{{overFetchLimitDialog.rowsCount}}</b> rows.<br>
                    By default, only the first <b class="wellis--text">{{overFetchLimitDialog.fetchLimit}}</b> lines are being downloaded.<br>
                    Would You like to download all items?
                </v-card-text>
                <v-card-actions>
                    <v-container fluid>
                        <v-btn @click="setOverFetchLimitFetchAllValue(false)">No</v-btn>
                        <v-btn @click="setOverFetchLimitFetchAllValue(true)" class="float-right">Yes</v-btn>
                    </v-container>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- FILTER Dialog -->
        <v-dialog v-model="filterDialog"
                  @click:outside="filterDialog = false"
                  persistent
                  width="600">
            <v-card>
                <v-card-title>Filters</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <div class="mt-5">
                        <label>Result</label>
                        <v-select v-model="filters.result"
                                  item-text="text"
                                  item-value="value"
                                  :items="[{text: 'All', value: null}, {text: 'Success', value: 'SUCCESS'}, {text: 'Failed', value: 'FAILED'}, {text: 'In progress', value: 'RUNNING'}]"
                                  item-color="wellis"
                                  color="wellis">
                        </v-select>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="wellis-text-white" block @click="countRows">Search</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import {EVENT_PAGE_TITLE_CHANGE_NO_TRANS} from "../../../../core/constants";
import AppSys_SchedulerApi from "../../../../api/app-sys/scheduler.api";

export default {
    name: "AdminSchedulerHistoryView",

    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE_NO_TRANS, 'Scheduler', 'History'), 100);

        this.countRows();
    },

    data() {
        return {
            mainOverlay: true,
            loadingOverlay: true,

            filterDialog: false,

            overFetchLimitDialog: {
                dialog: false,
                fetchAll: false,
                rowsCount: 0,
                fetchLimit: 0
            },

            filters: {
                result: null,
            },

            items: [],
        }
    },

    methods: {
        countRows() {
            this.filterDialog = false;
            this.loadingOverlay = true;
            this.overFetchLimitDialog.fetchAll = false;
            this.overFetchLimitDialog.fetchLimit = 0;
            this.overFetchLimitDialog.rowsCount = 0;
            AppSys_SchedulerApi.history.countRows(this.schedulerId, this.filters)
                .then(response => {
                    this.overFetchLimitDialog.rowsCount = response.data.rows_count;
                    this.overFetchLimitDialog.fetchLimit = response.data.fetch_limit;

                    if (this.overFetchLimitDialog.rowsCount > this.overFetchLimitDialog.fetchLimit) {
                        this.overFetchLimitDialog.dialog = true;
                    } else {
                        if (this.overFetchLimitDialog.rowsCount > 0) {
                            this.fetch();
                        } else {
                            this.$set(this, 'items', []);
                        }

                    }
                })
                .catch(() => {
                    this.$snackBar({
                        icon: 'mdi-alert',
                        text: this.$t('api-error')
                    })
                })
                .finally(() => {
                    this.mainOverlay = false;
                    this.loadingOverlay = false;
                });
        },

        async fetch() {
            return new Promise((resolve, reject) => {
                this.loadingOverlay = true;
                AppSys_SchedulerApi.history.get(this.schedulerId, this.filters, this.overFetchLimitDialog.fetchAll)
                    .then(response => {
                        this.items = response.data.items;
                        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE_NO_TRANS, 'Scheduler', response.data.scheduler + ' / History'), 100);
                        resolve(response.data.items);
                        this.$scrollTop();
                    })
                    .catch(error => {
                        this.$snackBar({
                            icon: 'mdi-alert',
                            text: this.$t('api-error')
                        })
                        reject(error);
                    })
                    .finally(() => {
                        this.mainOverlay = false;
                        this.loadingOverlay = false;
                    });
            });
        },

        setOverFetchLimitFetchAllValue(val) {
            this.overFetchLimitDialog.fetchAll = val;
            this.overFetchLimitDialog.dialog = false;
            this.$nextTick(() => this.fetch());
        },

        openDetailsNewTab(historyId) {
            const route = this.$router.resolve({
                    name: 'app.admin.scheduler.history.concrete',
                    params: {
                        uuid: this.schedulerId,
                        historyId: historyId
                    }
                }
            );
            window.open(route.href, '_blank');
        },
    },

    computed: {
        resolveResultColor() {
            return result => {
                let data = {text: 'white', chip: 'light'};
                switch (result) {
                    case 'SUCCESS':
                        data.chip = 'green';
                        break;
                    case 'FAILED':
                        data.chip = 'red';
                        break;
                    default:
                        data.chip = 'wellis';
                }
                return data;
            };
        },

        schedulerId() {
            return this.$route.params.uuid || null;
        }
    }
}
</script>

<style scoped>

</style>
