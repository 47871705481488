import JwtService from "../core/backend/jwt.service";
import store from "../store";
import {CHECK} from "../store/auth.store";


export default function (to, from, next) {
    if (!JwtService.hasToken()) {
        return next('/');
    }
    store.dispatch(`authStore/${CHECK}`)
        .then(() => {
            if (store.getters['authStore/hasRealm']) {
                return next();
            } else {
                if (to.name !== 'app.realm') {
                    return next('/realms');
                } else {
                    return next();
                }
            }

        })
        .catch(() => {
            return next('/');
        });
}
