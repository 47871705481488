/**
 * Actions
 */
export const PUSH = 'pushComponent';
export const CLEAR = 'clear';
export const REMOVE = 'remove';
export const REMOVE_LANG_SENSITIVES = 'removeLangSensitives';

/**
 * Mutations
 */
export const STORE = 'store';
export const UPDATE = 'update';
export const RESET = 'reset';
export const UNSET = 'unset';

export default {
    namespaced: true,

    state: {
        history: []
    },

    getters: {
        history(state) {
            return state.history;
        },
    },

    actions: {
        [PUSH](context, historyItem) {
            let index = context.state.history.findIndex(item => item.getComponent() === historyItem.getComponent());
            if (index > -1) {
                context.commit(UPDATE, {
                    index: index,
                    historyItem: historyItem
                });
            } else {
                context.commit(STORE, historyItem);
            }
        },

        [CLEAR](context) {
            context.commit(RESET);
        },

        [REMOVE](context, componentName) {
            let index = context.state.history.findIndex(item => item.getComponent() === componentName);
            if (index > -1) {
                context.commit(UNSET, index);
            }
        },

        [REMOVE_LANG_SENSITIVES](context) {
            context.state.history.forEach((item, index) => {
                if (item.isLangSensitive()) {
                    context.commit(UNSET, index);
                }
            });
        }
    },

    mutations: {
        [STORE](state, historyItem) {
            state.history.push(historyItem);
        },

        [UPDATE](state, update) {
            let history = state.history[update.index];
            state.history.splice(update.index, 1);

            history.data = update.historyItem.getData();
            history.pageYOffset = update.historyItem.getPageYOffset();
            history.title = update.historyItem.getTitle();

            state.history.push(history);
        },

        [RESET](state) {
            state.history = [];
        },

        [UNSET](state, index) {
            state.history.splice(index, 1);
        }
    }

}
