import env2 from './locales/en.json';
import env3 from './locales/v3/en.json';
import env31 from './locales/v31/en.json';
import env32 from './locales/v32/en.json';

import dev2 from './locales/de.json';
import dev3 from './locales/v3/de.json';
import dev31 from './locales/v31/de.json';
import dev32 from './locales/v32/de.json';

import frv2 from './locales/fr.json';
import frv3 from './locales/v3/fr.json';
import frv31 from './locales/v31/fr.json';
import frv32 from './locales/v32/fr.json';

import huv2 from './locales/hu.json';
import huv3 from './locales/v3/hu.json';
import huv31 from './locales/v31/hu.json';
import huv32 from './locales/v32/hu.json';

import rov2 from './locales/ro.json';
import rov3 from './locales/v3/ro.json';
import rov31 from './locales/v31/ro.json';
import rov32 from './locales/v32/ro.json';

const en = {
    ...env2,
    ...env3,
    ...env31,
    ...env32,
};

const de = {
    ...dev2,
    ...dev3,
    ...dev31,
    ...dev32,
};

const fr = {
    ...frv2,
    ...frv3,
    ...frv31,
    ...frv32,
};

const hu = {
    ...huv2,
    ...huv3,
    ...huv31,
    ...huv32,
};

const ro = {
    ...rov2,
    ...rov3,
    ...rov31,
    ...rov32
};

export default {
    en,
    hu,
    fr,
    de,
    ro
};
