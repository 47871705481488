<template>
    <v-dialog
        v-model="visible"
        fullscreen
        hide-overlay
        persistent
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar
                dark
                color="wellis"
            >
                <v-btn
                    icon
                    dark
                    @click="close"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{$t('components.fault-report.findProduct')}}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="mt-5">
                <v-overlay :value="loadingOverlay" absolute color="white">
                    <v-progress-circular
                        color="wellis"
                        indeterminate
                        size="64"
                    ></v-progress-circular>
                </v-overlay>
                <form @submit="submitHandler">
                    <v-row>
                        <v-col cols="12" lg="2" md="12" sm="12">
                            <v-text-field color="wellis"
                                          prepend-icon="mdi-filter"
                                          :label="$t('components.products.catalogNo') | capitalize"
                                          clearable
                                          v-model="catalogNo"/>
                        </v-col>
                        <v-col cols="12" lg="2" md="12" sm="12">
                            <v-text-field color="wellis"
                                          prepend-icon="mdi-filter"
                                          :label="$t('components.stocklist.name')"
                                          clearable
                                          v-model="catalogDesc"/>
                        </v-col>
                        <v-col cols="12" lg="2" md="12" sm="12">
                            <v-text-field color="wellis"
                                          prepend-icon="mdi-filter"
                                          :label="$t('components.administration.failed-orders.orderNo')"
                                          clearable
                                          v-model="orderNo"/>
                        </v-col>
                        <v-col cols="12" lg="2" md="12" sm="12">
                            <v-text-field color="wellis"
                                          prepend-icon="mdi-filter"
                                          :label="$t('components.my-invoices.invoiceNo')"
                                          clearable
                                          v-model="invoiceNo"/>
                        </v-col>
                        <v-col cols="12" lg="2" md="12" sm="12">
                            <v-btn type="submit" icon x-large>
                                <v-icon>mdi-send</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </form>
                <v-divider></v-divider>
                <v-simple-table class="mt-5" v-if="items.length > 0">
                    <thead>
                    <tr>
                        <th></th>
                        <th>{{$t('components.stocklist.serialNo') | capitalize}}</th>
                        <th>{{$t('components.stocklist.name')}}</th>
                        <th>{{$t('components.administration.failed-orders.orderNo')}}</th>
                        <th>{{$t('components.my-invoices.invoiceNo')}}</th>
                        <th>{{$t('components.fault-report.dateOfManufacture')}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                        <td>
                            <v-btn type="button"
                                   icon
                                   color="wellis"
                                   @click="selectItem(item)">
                                <v-icon>mdi-arrow-left</v-icon>
                            </v-btn>
                        </td>
                        <td>{{item.serial_no}}</td>
                        <td>{{item.catalog_desc | cut(40)}}</td>
                        <td>{{item.order_no}}</td>
                        <td>{{item.invoice_no}}</td>
                        <td>{{item.manufacture_date | formatDate}}</td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import FaultReportApi from "../../../api/fault-report.api";
import {EVENT_FAULT_REPORT_SELECT_ITEM} from "../../../core/constants";

export default {
    name: "FaultReportProductFinder",
    props: {
        visible: Boolean
    },

    data() {
        return {
            loadingOverlay: false,
            catalogNo: null,
            orderNo: null,
            catalogDesc: null,
            invoiceNo: null,

            items: [],
        }
    },

    methods: {
        submitHandler(e) {
            e.preventDefault();
            const empty_ = ''.concat(this.orderNo || '', this.invoiceNo || '', this.catalogDesc || '', this.catalogNo || '').length === 0;
            if (empty_) {
                return;
            }
            this.loadingOverlay = true;
            FaultReportApi.findProduct(this.orderNo, this.invoiceNo, this.catalogDesc, this.catalogNo)
                .then(response => {
                    this.items = response.data;
                })
                .finally(() => this.loadingOverlay = false);
        },

        close() {
            this.$emit('close');
        },

        selectItem(item) {
            this.$eventBus.$emit(EVENT_FAULT_REPORT_SELECT_ITEM, item);
        }
    }
}
</script>

<style scoped>

</style>
