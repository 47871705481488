<template>
    <v-container>
        <v-container fluid class="mt-10" v-if="loadingOverlay">
            <v-progress-linear indeterminate
                               color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container fluid v-if="!loadingOverlay && categories.length > 0">
            <v-container v-for="category in categories" :key="`${category.id}_${$store.getters['langStore/current']}`">
                <v-subheader class="text-h5 pa-0">
                    <v-btn text color="wellis" class="text-h6" :to="{name: 'app.news.category', params: {slug: category.slug}}" large>
                        {{category.name}}
                    </v-btn>
                </v-subheader>
                <p v-html="category.description"></p>
                <v-row v-if="category.latest_news.length > 0">
                    <v-col md="4" sm="12" xs="12" v-for="item in category.latest_news" :key="'news_item'+item.id+'_'+$store.getters['langStore/current']">
                        <NewsItemCard :data="item"/>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                        <p>
                            <v-icon color="wellis">mdi-alert-circle</v-icon> {{$t('components.news.no-news')}}
                        </p>
                    </v-col>
                </v-row>
                <div class="mt-5 text-center" v-if="category.news_count > 3">
                    <v-btn :to="{name: 'app.news.category', params: {slug: category.slug}}" large>
                        {{$t('components.news.btnGoToCategory')}}
                    </v-btn>
                </div>
            </v-container>
        </v-container>
    </v-container>
</template>

<script>
import {EVENT_LANG_CHANGE, EVENT_PAGE_TITLE_CHANGE} from "../../../core/constants";
import NewsApiService from "../../../api/news.api";
import NewsItemCard from "./NewsItemCard";

export default {
    name: "NewsView",
    components: {NewsItemCard},
    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE, 'components.news.title'), 100);

        if(!this.hasLiteUserPermission('news')) {
            this.$ApplGeneral({
                content: this.$t('v32.liteUser.hasNoPermission'),
                onClose: function() {
                    this.$router.push({
                        name: 'app.start'
                    })
                }
            });
            return;
        }

        this.fetch();

        this.$eventBus.$on(EVENT_LANG_CHANGE, () => {
            this.fetch();
        });
    },

    destroyed() {
        this.$eventBus.$off(EVENT_LANG_CHANGE);
    },

    data() {
        return {
            items: [],
            loadingOverlay: true,
        }
    },

    methods: {
        fetch() {
            this.loadingOverlay = true;
            NewsApiService.categories()
                .then(response => this.items = response.data.items)
                .finally(() => this.loadingOverlay = false);
        },

        hasLiteUserPermission(permission) {
            if (!this.$store.getters['authStore/isLiteUser']) {
                return true;
            }
            let permission_ = this.$store.getters['authStore/getLiteUserPermission'].find(x => x.permission === permission);
            return permission_.objstate === 'ACTIVE';
        },
    },

    computed: {
        categories() {
            return this.items;
        }
    }
}
</script>

<style scoped>

</style>
