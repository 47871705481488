<template>
    <v-container fluid>
        <v-container fluid class="mt-10" v-if="mainOverlay">
            <v-progress-linear indeterminate
                               color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container fluid v-else>
            <v-row>
                <v-col cols="12" md="12" sm="12">
                    <v-overlay :value="loadingOverlay" color="white">
                        <v-progress-circular
                            color="wellis"
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-row>
                        <v-col cols="12" xl="3" sm="12">
                            <v-card>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="12">
                                            <label>Templates</label>
                                            <v-select v-model="cronTemplate"
                                                      item-text="text"
                                                      item-value="cron"
                                                      :items="cronTemplates"
                                                      placeholder="Pre-defined templates"
                                                      clearable
                                                      item-color="wellis"
                                                      color="wellis"
                                                      @change="onCronTemplateSelect">
                                            </v-select>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mt-3">
                                        <v-col cols="12" sm="12">
                                            <label>Minute</label>
                                            <v-text-field color="wellis"
                                                          clearable
                                                          v-model="data.schedule.min">

                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mt-3">
                                        <v-col cols="12" sm="12">
                                            <label>Hour</label>
                                            <v-text-field color="wellis"
                                                          clearable
                                                          v-model="data.schedule.hour">

                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mt-3">
                                        <v-col cols="12" sm="12">
                                            <label>Day of Month</label>
                                            <v-text-field color="wellis"
                                                          clearable
                                                          v-model="data.schedule.dom">

                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mt-3">
                                        <v-col cols="12" sm="12">
                                            <label>Month</label>
                                            <v-text-field color="wellis"
                                                          clearable
                                                          v-model="data.schedule.mon">

                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mt-3">
                                        <v-col cols="12" sm="12">
                                            <label>Day of week</label>
                                            <v-text-field color="wellis"
                                                          clearable
                                                          v-model="data.schedule.dow">

                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>

                        </v-col>
                        <v-col cols="12" xl="3" sm="12">
                            <v-card>
                                <v-card-text>
                                    <v-row v-if="isEditor">
                                        <v-col cols="12" sm="12">
                                            <label>UUID</label>
                                            <v-text-field color="wellis"
                                                          readonly
                                                          :value="uuid">

                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row :class="isEditor ? 'mt-3' : ''">
                                        <v-col cols="12" sm="12">
                                            <label>Name</label>
                                            <v-text-field color="wellis"
                                                          clearable
                                                          :disabled="isEditor"
                                                          placeholder="e.g.: MY_CUSTOM_TASK, CUST_JOB_UPDATE_TABLE"
                                                          counter="35"
                                                          v-model="taskName">

                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mt-3">
                                        <v-col cols="12" sm="12">
                                            <label>Status</label>
                                            <v-select v-model="data.objstate"
                                                      item-text="text"
                                                      item-value="value"
                                                      :items="[{text: 'Active', value: 'ACTIVE'}, {text: 'Inactive', value: 'INACTIVE'}]"
                                                      item-color="wellis"
                                                      color="wellis">
                                            </v-select>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mt-3">
                                        <v-col cols="12" sm="12">
                                            <label>Type</label>
                                            <v-select v-model="data.type_code"
                                                      :disabled="isEditor"
                                                      item-text="text"
                                                      item-value="value"
                                                      :items="[{text: 'Job', value: 'JOB'}, {text: 'SQL Block', value: 'SQL_BLOCK'}]"
                                                      item-color="wellis"
                                                      color="wellis">
                                            </v-select>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mt-3" v-if="data.type_code === 'JOB'">
                                        <v-col cols="12" sm="12">
                                            <label>Built-in job</label>
                                            <v-select v-model="data.job_id"
                                                      :disabled="isEditor"
                                                      item-value="jobId"
                                                      :items="Object.keys(availableJobs)"
                                                      item-color="wellis"
                                                      color="wellis">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="12">
                                            <v-btn color="wellis-text-white" block @click="submitHandler">Save</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" xl="6" sm="12" v-if="data.type_code === 'SQL_BLOCK'">
                            <label>SQL Block</label>
                            <v-textarea
                                v-model="data.sql_block_text"
                                clearable
                                outlined
                                rows="15"
                                color="wellis"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>

        <!-- Error dialog -->
        <v-dialog v-model="errorDialog.dialog"
                  @click:outside="errorDialog.dialog = false"
                  min-width="800">
            <v-card>
                <v-toolbar dark
                           color="wellis">
                    <v-btn icon
                           @click="errorDialog.dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Wellis Online System API</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <pre class="mt-5" v-html="resolveErrorData"></pre>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-bottom-navigation fixed style="z-index: 0" grow>
            <v-btn icon :to="{name: 'app.admin.scheduler'}">
                <span>Back to overview</span>
                <v-icon>mdi-keyboard-backspace</v-icon>
            </v-btn>
            <v-btn icon @click="fetch" v-if="isEditor">
                <span>Refresh</span>
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn icon :to="{name: 'app.admin.scheduler.history', params:{uuid: uuid}}" v-if="isEditor">
                <span>History</span>
                <v-icon>mdi-vector-link</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </v-container>
</template>

<script>
import {EVENT_PAGE_TITLE_CHANGE_NO_TRANS} from "../../../../core/constants";
import AppSys_SchedulerApi from "../../../../api/app-sys/scheduler.api";
import {PrettyJson, strSlug} from "../../../../helpers";

export default {
    name: "AdminSchedulerEditor",

    mounted() {
        if (this.isEditor) {
            setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE_NO_TRANS, 'Scheduler', 'Editor'), 100);
        } else {
            setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE_NO_TRANS, 'Scheduler', 'Create'), 100);
        }

        this.bootstrap()
            .then(() => {
                if (this.isEditor) {
                    this.fetch();
                }
            })
    },

    data() {
        return {
            mainOverlay: true,
            loadingOverlay: true,

            availableJobs: null,

            data: {
                name: null,
                objstate: 'INACTIVE',
                type_code: 'JOB',
                job_id: null,
                sql_block_text: null,
                schedule: {
                    min: null,
                    hour: null,
                    dom: null,
                    mon: null,
                    dow: null
                },
            },

            cronTemplate: null,
            cronTemplates: [
                {
                    text: 'At every minute.', cron: {
                        min: '*',
                        hour: '*',
                        dom: '*',
                        mon: '*',
                        dow: '*'
                    }
                },

                {
                    text: 'At every 15th minute.', cron: {
                        min: '*/15',
                        hour: '*',
                        dom: '*',
                        mon: '*',
                        dow: '*'
                    }
                },

                {
                    text: 'Every hour', cron: {
                        min: '0',
                        hour: '*',
                        dom: '*',
                        mon: '*',
                        dow: '*'
                    }
                },

                {
                    text: 'At minute 0 past every 6th hour.', cron: {
                        min: '0',
                        hour: '*/6',
                        dom: '*',
                        mon: '*',
                        dow: '*'
                    }
                },

                {
                    text: 'Every day', cron: {
                        min: '0',
                        hour: '0',
                        dom: '*',
                        mon: '*',
                        dow: '*'
                    }
                },

                {
                    text: 'Every day at 02:00', cron: {
                        min: '0',
                        hour: '2',
                        dom: '*',
                        mon: '*',
                        dow: '*'
                    }
                },

                {
                    text: 'Every Monday', cron: {
                        min: '0',
                        hour: '0',
                        dom: '*',
                        mon: '*',
                        dow: 'MON'
                    }
                },

                {
                    text: 'Every weekday', cron: {
                        min: '0',
                        hour: '0',
                        dom: '*',
                        mon: '*',
                        dow: '1-5'
                    }
                },

                {
                    text: 'Weekly', cron: {
                        min: '0',
                        hour: '0',
                        dom: '*',
                        mon: '*',
                        dow: '0'
                    }
                },

                {
                    text: 'Monthly', cron: {
                        min: '0',
                        hour: '0',
                        dom: '1',
                        mon: '*',
                        dow: '0'
                    }
                },

                {
                    text: 'Every year', cron: {
                        min: '0',
                        hour: '0',
                        dom: '1',
                        mon: '1',
                        dow: '*'
                    }
                },
            ],

            errorDialog: {
                dialog: false,
                data: null,
            },
        }
    },

    methods: {
        bootstrap() {
            return new Promise((resolve, reject) => {
                AppSys_SchedulerApi.getAvailableJobs()
                    .then(response => {
                        this.availableJobs = response.data.items;
                        resolve();
                    })
                    .catch(() => {
                        this.$snackBar({
                            icon: 'mdi-alert',
                            text: this.$t('api-error')
                        });
                        reject();
                    })
                    .finally(() => {
                        this.mainOverlay = false;
                        this.loadingOverlay = false;
                    })
            });
        },

        fetch() {
            this.loadingOverlay = true;
            AppSys_SchedulerApi.concrete(this.uuid)
                .then(response => {
                    this.data.name = response.data.schedule.name;
                    this.data.objstate = response.data.schedule.objstate;
                    this.data.sql_block_text = response.data.schedule.sql_block_text;
                    this.data.type_code = response.data.schedule.type_code;
                    this.data.job_id = response.data.schedule.job_id;

                    let cron = response.data.schedule.schedule.toString().split(' ');
                    this.data.schedule.min = cron[0];
                    this.data.schedule.hour = cron[1];
                    this.data.schedule.dom = cron[2];
                    this.data.schedule.mon = cron[3];
                    this.data.schedule.dow = cron[4];

                    setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE_NO_TRANS, 'Scheduler', this.data.name + '/ Editor'), 100);
                })
                .catch(() => {
                    this.$snackBar({
                        icon: 'mdi-alert',
                        text: this.$t('api-error')
                    });
                })
                .finally(() => {
                    this.loadingOverlay = false;
                })
        },

        onCronTemplateSelect() {
            this.data.schedule.mon = this.cronTemplate.mon;
            this.data.schedule.dom = this.cronTemplate.dom;
            this.data.schedule.dow = this.cronTemplate.dow;
            this.data.schedule.hour = this.cronTemplate.hour;
            this.data.schedule.min = this.cronTemplate.min;
        },

        submitHandler() {
            this.loadingOverlay = true;
            if (!this.isEditor) {
                AppSys_SchedulerApi.create(this.data)
                    .then(response => {
                        this.$snackBar({
                            icon: 'mdi-alert',
                            text: 'Job has been scheduled successfully!',
                        });

                        this.$router.push({
                            name: 'app.admin.scheduler.edit',
                            params: {
                                uuid: response.data.schedule.uuid
                            }
                        });
                    })
                    .catch(error => {
                        this.errorDialog.dialog = true;
                        this.errorDialog.data = error.response.data;
                    })
                    .finally(() => {
                        this.loadingOverlay = false;
                    });
            } else {
                AppSys_SchedulerApi.update(this.uuid, {
                    objstate: this.data.objstate,
                    schedule: this.data.schedule,
                    sql_block_text: this.data.sql_block_text
                }).then(response => {
                    this.$snackBar({
                        icon: 'mdi-alert',
                        text: 'Scheduled job has been updated!',
                    });

                    this.$router.push({
                        name: 'app.admin.scheduler.edit',
                        params: {
                            uuid: response.data.schedule.uuid
                        }
                    });
                }).catch(error => {
                    this.errorDialog.dialog = true;
                    this.errorDialog.data = error.response.data;
                }).finally(() => {
                    this.loadingOverlay = false;
                });
            }
        }
    },

    computed: {
        taskName: {
            get: function () {
                return (this.data.name || '').toUpperCase();
            },

            set: function (val) {
                this.data.name = strSlug(val).replaceAll('-', '_');
            }
        },

        resolveErrorData() {
            return PrettyJson.print(this.errorDialog.data);
        },

        isEditor() {
            return this.$route.name === 'app.admin.scheduler.edit';
        },

        uuid() {
            return this.$route.params.uuid || null;
        }
    },

    watch: {
        'data.type_code': function () {
            if (!this.isEditor) {
                this.data.job_id = null;
                this.data.sql_block_text = null;
            }
        }
    }
}
</script>

<style scoped>

</style>
