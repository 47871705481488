import GuestMiddleware from '../middlewares/guest.middleware';
import AuthMiddleware from '../middlewares/auth.middleware';

import GuestLayout from "../layout/GuestLayout";
import HttpNotFound from "../components/common/HttpNotFound";
import ApplicationLayout from "../layout/ApplicationLayout";
import DashboardView from "../components/application/dashboard/DashboardView";
import NewsView from "../components/application/news/NewsView";
import MediaView from "../components/application/media/MediaView";
import NewsCategoryItems from "../components/application/news/NewsCategoryItems";
import NewsConcrete from "../components/application/news/NewsConcrete";
import ProductCategoryView from "../components/application/product/ProductCategoryView";
import ProductCategoryItems from "../components/application/product/ProductCategoryItems";
import ProductDetailsView from "../components/application/product/ProductDetailsView";
import ConfiguratorView from "../components/application/configurator/ConfiguratorView";
import SaleView from "../components/application/sale/SaleView";
import CartView from "../components/application/cart/CartView";
import CheckoutView from "../components/application/checkout/CheckoutView";
import SearchView from "../components/application/search/SearchView";
import FaultReportView from "../components/application/fault-report/FaultReportView";
import StocklistInTransitView from "../components/application/stocklist/StocklistInTransitView";
import AdminDashboardView from "../components/application/administration/dashboard/AdminDashboardView";
import AdminNewsCategoriesView from "../components/application/administration/news/AdminNewsCategoriesView";
import AdminFailedOrdersView from "../components/application/administration/orders/AdminFailedOrdersView";
import AdminFailedOrderDetails from "../components/application/administration/orders/AdminFailedOrderDetails";
import AdminNewsView from "../components/application/administration/news/AdminNewsView";
import AdminNewsPostEditor from "../components/application/administration/news/AdminNewsPostEditor";
import AdminQueueJobsView from "../components/application/administration/queue/jobs/AdminQueueJobsView";
import AdminQueueJobDetails from "../components/application/administration/queue/jobs/AdminQueueJobDetails";
import AdminQueueFailedJobsView from "../components/application/administration/queue/failed-jobs/AdminQueueFailedJobsView";
import AdminQueueFailedJobDetails from "../components/application/administration/queue/failed-jobs/AdminQueueFailedJobDetails";
import AdminSchedulerView from "../components/application/administration/scheduler/AdminSchedulerView";
import AdminSchedulerEditor from "../components/application/administration/scheduler/AdminSchedulerEditor";
import MyInvoicesView from "../components/application/account/MyInvoicesView";
import MyOrdersView from "../components/application/account/MyOrdersView";
import MyOrderDetails from "../components/application/account/MyOrderDetails";
import MyLoadingsView from "../components/application/account/MyLoadingsView";
import MyLoadingDetails from "../components/application/account/MyLoadingDetails";
import AdminSchedulerHistoryView from "../components/application/administration/scheduler/AdminSchedulerHistoryView";
import AdminSchedulerHistoryItem from "../components/application/administration/scheduler/AdminSchedulerHistoryItem";
import FrontendUser from '../components/application/account/FrontendUser';
import AdminFrontendUsersView from '../components/application/administration/users/fnd/AdminFrontendUsersView';
import QuickReportView from '../components/application/quick-report/QuickReportView';
import MyLiteUsers from '../components/application/account/lite-users/MyLiteUsers';
import AdminLiteUsersView from '../components/application/administration/users/fnd/AdminLiteUsersView';
import AdminLiteUsersOverview from '../components/application/administration/users/fnd/AdminLiteUsersOverview';


const routes = [
    {
        path: '/',
        name: 'app.guest',
        beforeEnter: GuestMiddleware,
        component: GuestLayout
    },
    {
        path: '/reset-password',
        name: 'app.guest.reset-password',
        beforeEnter: GuestMiddleware,
        component: GuestLayout
    },
    {
        path: '/realms',
        name: 'app.realm',
        beforeEnter: AuthMiddleware,
        component: GuestLayout
    },
    {
        path: '/app',
        beforeEnter: AuthMiddleware,
        component: ApplicationLayout,
        children: [
            {
                path: '',
                name: 'app.start',
                component: DashboardView
            },

            {
                path: 'fault-report',
                name: 'app.fault-report',
                component: FaultReportView,
            },

            {
                path: 'products',
                name: 'app.products',
                component: ProductCategoryView
            },

            {
                path: 'products/:category(.*)?/view/:catalogNo(.*)',
                name: 'app.product.concrete',
                component: ProductDetailsView,
            },

            {
                path: 'products/:category(.*)',
                name: 'app.products.category',
                component: ProductCategoryItems
            },

            {
                path: 'configurator/:host/:productIdentifier',
                name: 'app.configurator',
                component: ConfiguratorView
            },

            {
                path: 'sale',
                name: 'app.sale',
                component: SaleView
            },

            {
                path: 'news',
                name: 'app.news',
                component: NewsView
            },
            {
                path: 'news/:slug',
                name: 'app.news.category',
                component: NewsCategoryItems,
            },
            {
                path: 'news/:date(.*)/:slug',
                name: 'app.news.view',
                component: NewsConcrete,
            },

            {
                path: 'media',
                name: 'app.media',
                component: MediaView
            },

            {
                path: 'cart',
                name: 'app.cart',
                component: CartView
            },

            {
                path: 'checkout',
                name: 'app.checkout',
                component: CheckoutView
            },

            {
                path: 'search',
                name: 'app.search',
                component: SearchView
            },

            {
                path: 'stocklist/in-transit',
                name: 'app.stocklist.transit',
                component: StocklistInTransitView
            },

            {
                path: 'account/invoices',
                name: 'app.account.invoices',
                component: MyInvoicesView
            },

            {
                path: 'account/orders',
                name: 'app.account.orders',
                component: MyOrdersView
            },

            {
                path: 'account/orders/:orderNo',
                name: 'app.account.orders.details',
                component: MyOrderDetails
            },

            {
                path: 'account/loadings',
                name: 'app.account.loadings',
                component: MyLoadingsView
            },

            {
                path: 'account/loadings/:loadingId',
                name: 'app.account.loadings.details',
                component: MyLoadingDetails
            },

            {
                path: 'quick-report',
                name: 'app.quick-report',
                component: QuickReportView
            },

            {
                path: 'account',
                name: 'app.account',
                component: FrontendUser
            },

            {
                path: 'account/my-lite-users',
                name: 'app.account.lite-users',
                component: MyLiteUsers
            },

            /** Administration section **/

            {
                path: 'admin',
                alias: 'admin/dashboard',
                name: 'app.admin',
                component: AdminDashboardView
            },

            {
                path: 'admin/news/categories',
                name: 'app.admin.news.categories',
                component: AdminNewsCategoriesView
            },

            {
                path: 'admin/news/posts',
                name: 'app.admin.news.posts',
                component: AdminNewsView
            },

            {
                path: 'admin/news/posts/new',
                name: 'app.admin.news.posts.create',
                component: AdminNewsPostEditor
            },

            {
                path: 'admin/news/posts/edit/:id',
                name: 'app.admin.news.posts.edit',
                component: AdminNewsPostEditor
            },

            {
                path: 'admin/orders/failed',
                name: 'app.admin.orders.failed',
                component: AdminFailedOrdersView
            },

            {
                path: 'admin/orders/failed/:id',
                name: 'app.admin.orders.failed.details',
                component: AdminFailedOrderDetails
            },

            {
                path: 'admin/queue/jobs',
                name: 'app.admin.queue.jobs',
                component: AdminQueueJobsView
            },

            {
                path: 'admin/queue/jobs/:id',
                name: 'app.admin.queue.jobs.details',
                component: AdminQueueJobDetails
            },

            {
                path: 'admin/queue/failed-jobs',
                name: 'app.admin.queue.failed-jobs',
                component: AdminQueueFailedJobsView
            },

            {
                path: 'admin/queue/failed-jobs/:id',
                name: 'app.admin.queue.failed-jobs.details',
                component: AdminQueueFailedJobDetails
            },

            {
                path: 'admin/scheduler',
                name: 'app.admin.scheduler',
                component: AdminSchedulerView
            },

            {
                path: 'admin/scheduler/create',
                name: 'app.admin.scheduler.create',
                component: AdminSchedulerEditor
            },

            {
                path: 'admin/scheduler/:uuid',
                name: 'app.admin.scheduler.edit',
                component: AdminSchedulerEditor
            },

            {
                path: 'admin/scheduler/:uuid/history',
                name: 'app.admin.scheduler.history',
                component: AdminSchedulerHistoryView
            },

            {
                path: 'admin/scheduler/:uuid/history/:historyId',
                name: 'app.admin.scheduler.history.concrete',
                component: AdminSchedulerHistoryItem
            },

            {
                path: 'admin/users/fnd',
                name: 'app.admin.users.fnd',
                component: AdminFrontendUsersView
            },

            {
                path: 'admin/users/fnd/:fndUserId/lite-users',
                name: 'app.admin.users.fnd.lite-users',
                component: AdminLiteUsersView
            },

            {
                path: 'admin/users/lite-users/overview',
                name: 'app.admin.users.lite-users.overview',
                component: AdminLiteUsersOverview
            },
        ]
    },

    {
        path: '*',
        name: 'app.404',
        component: HttpNotFound
    }
];

export default routes;
