import store from "../store";
import {i18n} from "../core/lang/i18n";

export default function (to, from, next) {
    if (store.getters['langStore/current'] && store.getters['langStore/current'] !== i18n.locale) {
        i18n.locale = store.getters['langStore/current'];
        next();
    } else if (!store.getters['langStore/current']) {
        store.dispatch('langStore/setLanguage', navigator.languages)
            .then(() => {
                i18n.locale = store.getters['langStore/current'];
                next();
            });
    }
}
