<template>
    <v-container fluid>
        <v-container fluid class="mt-10" v-if="mainOverlay">
            <v-progress-linear indeterminate
                               color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container fluid v-else>
            <v-row>
                <v-col cols="12" xl="3" lg="4" md="12" sm="12">
                    <v-card>
                        <v-card-title>Filters</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-card color="transparent2">
                                <v-card-title>Queue</v-card-title>
                                <v-card-text>
                                    <v-select v-model="queue"
                                              item-text="text"
                                              item-value="value"
                                              :items="queues"
                                              item-color="wellis"
                                              color="wellis"
                                              @change="countRows">
                                    </v-select>
                                </v-card-text>
                            </v-card>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" xl="9" lg="8" md="12" sm="12">
                    <v-overlay :value="loadingOverlay" color="white">
                        <v-progress-circular
                            color="wellis"
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-card color="transparent2">
                        <v-card-text>
                            <v-simple-table>
                                <thead>
                                <tr>
                                    <th></th>
                                    <th width="8%">Job ID</th>
                                    <th>UUID</th>
                                    <th>Queue</th>
                                    <th>Object</th>
                                    <th>Attempts</th>
                                    <th>Reserved at</th>
                                    <th>Available at</th>
                                    <th>Created at</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="job in jobs" :key="queue+'_'+job.id">
                                    <td>
                                        <v-menu offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn text
                                                       rounded
                                                       icon
                                                       color="wellis"
                                                       v-bind="attrs"
                                                       v-on="on">
                                                    <v-icon>mdi-dots-horizontal</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item>
                                                    <v-btn
                                                        class="ma-2"
                                                        outlined
                                                        width="100%"
                                                        color="wellis"
                                                        :to="{name: 'app.admin.queue.jobs.details', params: {id: job.id} }"
                                                    >
                                                        Details
                                                    </v-btn>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-btn
                                                        class="ma-2"
                                                        outlined
                                                        width="100%"
                                                        color="wellis"
                                                        @click="openDetailsNewTab(job.id)"
                                                    >
                                                        Open details in new tab
                                                    </v-btn>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </td>
                                    <td width="8%">#{{job.id}}</td>
                                    <td>
                                        <v-chip color="light" small>{{job.uuid}}</v-chip>
                                    </td>
                                    <td>
                                        <v-chip :color="resolveQueueColor(job.queue)" text-color="white" small>{{job.queue}}</v-chip>
                                    </td>
                                    <td>
                                        <v-chip color="wellis" small text-color="white">{{job.object}}</v-chip>
                                    </td>
                                    <td>
                                        <v-chip color="secondary" small>{{job.attempts}}</v-chip>
                                    </td>
                                    <td>{{job.reserved_at || '-'}}</td>
                                    <td>{{job.available_at}}</td>
                                    <td>{{job.created_at}}</td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-bottom-navigation fixed style="z-index: 0" grow>
            <v-btn icon @click="() => this.countRows()">
                <span>Refresh</span>
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
        </v-bottom-navigation>

        <!-- OVER_FETCH_LIMIT Dialog -->
        <v-dialog v-model="overFetchLimitDialog.dialog"
                  persistent
                  width="400">
            <v-card>
                <v-card-title>Wellis Online System API</v-card-title>
                <v-card-text class="mt-5">
                    Your request has returned more than <b class="wellis--text">{{overFetchLimitDialog.rowsCount}}</b> rows.<br>
                    By default, only the first <b class="wellis--text">{{overFetchLimitDialog.fetchLimit}}</b> lines are being downloaded.<br>
                    Would You like to download all items?
                </v-card-text>
                <v-card-actions>
                    <v-container fluid>
                        <v-btn @click="setOverFetchLimitFetchAllValue(false)">No</v-btn>
                        <v-btn @click="setOverFetchLimitFetchAllValue(true)" class="float-right">Yes</v-btn>
                    </v-container>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import {EVENT_PAGE_TITLE_CHANGE_NO_TRANS} from "../../../../../core/constants";
import AppSys_QueueApi from "../../../../../api/app-sys/queue.api";

export default {
    name: "AdminQueueJobsView",

    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE_NO_TRANS, 'Queue', 'Jobs'), 100);

        this.countRows();
    },

    data() {
        return {
            mainOverlay: true,
            loadingOverlay: true,

            overFetchLimitDialog: {
                dialog: false,
                fetchAll: false,
                rowsCount: 0,
                fetchLimit: 0
            },

            queues: [
                {text: 'All', value: null},
                {text: 'orders', value: 'orders'},
                {text: 'mails', value: 'mails'},
                {text: 'default', value: 'default'},
            ],
            queue: null,

            jobs: [],
        }
    },

    methods: {

        countRows() {
            this.loadingOverlay = true;
            this.overFetchLimitDialog.fetchAll = false;
            this.overFetchLimitDialog.fetchLimit = 0;
            this.overFetchLimitDialog.rowsCount = 0;
            AppSys_QueueApi.jobs.countRows(this.queue)
                .then(response => {
                    this.overFetchLimitDialog.rowsCount = response.data.rows_count;
                    this.overFetchLimitDialog.fetchLimit = response.data.fetch_limit;

                    if (this.overFetchLimitDialog.rowsCount > this.overFetchLimitDialog.fetchLimit) {
                        this.overFetchLimitDialog.dialog = true;
                    } else {
                        if (this.overFetchLimitDialog.rowsCount > 0) {
                            this.fetch();
                        } else {
                            this.$set(this, 'jobs', []);
                        }

                    }
                })
                .catch(() => {
                    this.$snackBar({
                        icon: 'mdi-alert',
                        text: this.$t('api-error')
                    })
                })
                .finally(() => {
                    this.mainOverlay = false;
                    this.loadingOverlay = false;
                });
        },

        async fetch() {
            return new Promise((resolve, reject) => {
                this.loadingOverlay = true;
                AppSys_QueueApi.jobs.get(this.queue, this.overFetchLimitDialog.fetchAll)
                    .then(response => {
                        this.jobs = response.data.items;
                        resolve(response.data.items);
                        this.$scrollTop();
                    })
                    .catch(error => {
                        this.$snackBar({
                            icon: 'mdi-alert',
                            text: this.$t('api-error')
                        })
                        reject(error);
                    })
                    .finally(() => {
                        this.mainOverlay = false;
                        this.loadingOverlay = false;
                    });
            });
        },

        setOverFetchLimitFetchAllValue(val) {
            this.overFetchLimitDialog.fetchAll = val;
            this.overFetchLimitDialog.dialog = false;
            this.$nextTick(() => this.fetch());
        },

        openDetailsNewTab(jobId) {
            const route = this.$router.resolve({name: 'app.admin.queue.jobs.details', params: {id: jobId}});
            window.open(route.href, '_blank');
        }
    },

    computed: {
        resolveQueueColor() {
            return queue => {
                let color = '';
                switch (queue) {

                    case 'orders':
                        color = 'wellis';
                        break;

                    case 'mails':
                        color = 'primary';
                        break;

                    default:
                        color = 'secondary';
                }

                return color;
            }
        }
    }

}
</script>

<style scoped>

</style>
