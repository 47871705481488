/**
 * Actions
 */
export const UPDATE = 'updateConfiguration';

/**
 * Mutations
 */
export const STORE = 'storeConfiguration';

export default {
    namespaced: true,

    state: {
        config: {}
    },

    getters: {
        getLoginModuleStatus(state) {
            if(state.config.modules) {
                return state.config.modules.login;
            }
            return false;
        },

        getStocklistModuleStatus(state) {
            if(state.config.modules) {
                return state.config.modules.stocklist;
            }
            return false;
        },

        getShopOrdModuleStatus(state) {
            if(state.config.modules) {
                return state.config.modules.shopord;
            }
            return false;
        },

        getMixedStockModuleStatus(state) {
            if(state.config.modules) {
                return state.config.modules.mixed;
            }
            return false;
        },

        getProductConfigurationModuleStatus(state) {
            if(state.config.modules) {
                return state.config.modules.product_config;
            }
            return false;
        }
    },

    actions: {
        [UPDATE](context, config) {
            context.commit(STORE, config);
        }
    },

    mutations: {
        [STORE](state, config) {
            state.config = config;
        },
    }
}
