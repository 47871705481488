import BackendService from "../../core/backend/backend.service";
import {__clone} from "../../helpers";

const AppSys_SchedulerApi = {

    getAvailableJobs() {
        return BackendService.get('app-sys/scheduler/available-jobs');
    },

    countRows(params_ = null) {
        let params = !params_ ? {} : __clone(params_);
        params.count = true;
        return BackendService.query('app-sys/scheduler', params);
    },

    get(params_ = null, fetchAll_ = false) {
        let params = !params_ ? {} : __clone(params_);
        params.fetch_all = fetchAll_;
        return BackendService.query('app-sys/scheduler', params);
    },

    concrete(uuid) {
        return BackendService.get(
            BackendService.createParameterURI('app-sys/scheduler', [uuid])
        );
    },

    create(job) {
        return BackendService.post('app-sys/scheduler', job);
    },

    update(uuid, job) {
        return BackendService.put(
            BackendService.createParameterURI('app-sys/scheduler', [uuid]),
            job
        );
    },

    startNow(uuid_) {
        return BackendService.post('app-sys/scheduler/start-now', {
            uuid: uuid_
        });
    },

    history: {
        countRows(schedulerId, params_ = null) {
            let params = !params_ ? {} : __clone(params_);
            params.count = true;
            return BackendService.query(BackendService.createParameterURI('app-sys/scheduler/history', [schedulerId]), params);
        },

        get(schedulerId, params_ = null, fetchAll_ = false) {
            let params = !params_ ? {} : __clone(params_);
            params.fetch_all = fetchAll_;
            return BackendService.query(BackendService.createParameterURI('app-sys/scheduler/history', [schedulerId]), params);
        },

        concrete(uuid) {
            return BackendService.get(
                BackendService.createParameterURI('app-sys/scheduler/history/concrete', [uuid])
            );
        },
    }
};


export default AppSys_SchedulerApi;
