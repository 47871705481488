<template>
    <v-card elevation="2" :class="['mb-3', isChild ? 'ml-5' : '']">
        <v-list-item :key="cartItem.id">
            <v-list-item-avatar>
                <v-img contain
                       :lazy-src="require('@/assets/img/placeholder_300x300.png')"
                       :src="cartItem.product.media.thumbnailUrl"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-on="on" v-bind="attrs" class="wellis--text">{{cartItem.name | cut(50)}}</span>
                            <small class="ml-2 font-weight-bold grey--text"
                                   v-if="isStockItem">[{{$t('components.stocklist.title') | toUpper}}]</small>
                            <small class="ml-2 font-weight-bold grey--text"
                                   v-if="isShopOrdItem">
                                 <span v-if="$store.getters['authStore/currentRealm'] === 'wellis_usa_realm'">
                            [{{$t('v32.components.sale.stockInEurope') | toUpper}}]
                        </span>
                                <span v-else>[{{$t('components.shopord.title') | toUpper}}]</span>
                            </small>
                        </template>
                        <span>{{cartItem.name}}</span>
                    </v-tooltip>
                </v-list-item-title>
                <v-list-item-subtitle>
                    <v-row>
                        <v-col cols="12" sm="12" md="12" lg="6">
                            {{cartItem.catalogNo}}
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="6">
                            {{cartItem.quantity}} {{cartItem.product.unit}}
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12">
                            <div v-if="hasForecast">
                                <b>{{$t('components.checkout.wanted-delivery-date')}}:</b>
                                <span class="ml-2 font-weight-bold wellis--text">{{wantedDeliveryDate}}</span>
                            </div>
                        </v-col>
                    </v-row>
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="hasForecast">
                <v-btn text @click="getAvailableDates">
                    <v-icon color="wellis" v-if="wantedDeliveryDate === null">mdi-exclamation</v-icon>
                    <v-icon class="forecast-icon">mdi-calendar-month</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>

        <v-dialog
            v-model="forecastDialog"
            max-width="700"
        >
            <v-card>
                <v-card-title class="text-h5">{{$t('components.checkout.select-date-modal.title')}}</v-card-title>
                <v-card-text class="text-center">
                    <div v-for="(qty_, date) in forecast" :key="`wdate_${date}`">
                        <v-card outlined class="mb-3" hover @click="selectWantedDeliveryDate(date, qty_)">
                            <v-card-text>
                                <v-layout justify-center>
                                    <b :class="['date', date === wantedDeliveryDate ? 'date-selected' : '' ]">{{date}}</b>
                                    <v-spacer></v-spacer>
                                    {{$t('forecast.availableQty', {qty: qty_})}}
                                </v-layout>
                            </v-card-text>
                        </v-card>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="wellis"
                        text
                        @click="forecastDialog = false"
                    >
                        {{$t('modal.close')}}
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import {CHECKOUT_MASTER_SCHEDULE_CHANGE_MAIN} from '../../../core/constants';

export default {
    name: 'CheckoutItem',
    props: {
        cartItem: Object,
        isChild: Boolean,
    },

    mounted() {
        this.bootstrap();
    },

    data() {
        return {
            availableDates: [],
            wantedDeliveryDate: null,
            forecastDialog: false,
        };
    },

    methods: {
        bootstrap() {
            if (this.hasForecast) {
                this.wantedDeliveryDate = null;
            } else {
                if (this.cartItem.type === 'SHOPORD' || this.cartItem.type === 'PURCHORD') {
                    let year = this.cartItem.product.needWeek.substr(0, this.cartItem.product.needWeek.indexOf('-'));
                    let week = this.cartItem.product.needWeek.substr(this.cartItem.product.needWeek.indexOf('-') + 1, this.cartItem.product.needWeek.length);
                    let date = new Date(year, 0, (1 + (week - 1) * 7));
                    let month = date.getMonth() + 1;
                    if (month < 10) {
                        month = '0' + month.toString();
                    }
                    this.wantedDeliveryDate = `${date.getFullYear()}-${month}`;
                }

                if (this.cartItem.type === 'STOCK_FIFO' || this.cartItem.type === 'STOCK_ETA') {
                    let date = new Date();
                    date.setDate(date.getDate() + 14);
                    let month = date.getMonth() + 1;
                    if (month < 10) {
                        month = '0' + month.toString();
                    }
                    this.wantedDeliveryDate = `${date.getFullYear()}-${month}`;
                }
            }
        },

        getAvailableDates() {
            this.forecastDialog = true;
        },

        selectWantedDeliveryDate(date, qty) {
            if (qty >= this.cartItem.quantity) {
                let data = {
                    cartItemId: this.cartItem.id,
                    msDate: date,
                    prevMsDate: this.wantedDeliveryDate,
                    partNo: this.cartItem.partNo,
                    catalogNo: this.cartItem.catalogNo,
                };

                this.wantedDeliveryDate = data.msDate;
                this.$eventBus.$emit(CHECKOUT_MASTER_SCHEDULE_CHANGE_MAIN, data);
            }
        },
    },

    computed: {
        isStockItem() {
            return this.cartItem.type.indexOf('STOCK') !== -1;
        },

        isShopOrdItem() {
            return this.cartItem.type === 'SHOPORD' || this.cartItem.type === 'PURCHORD';
        },

        hasForecast() {
            return !this.isChild
                && this.cartItem.type === 'PRODUCT' && Object.keys(this.$CheckoutView.$data.forecast).includes('record_' + this.cartItem.id);
            // && !empty(this.$CheckoutView.$data.forecast[this.cartItem.partNo]);
        },

        forecast() {
            return this.$CheckoutView.$data.customerForecast[this.cartItem.catalogNo];
        },

        validMsDates() {
            return this.availableDates.filter(item => item.valid === 1);
        },
    },
};
</script>

<style scoped>
.date {
    font-size: 19px;
}

.date-selected {
    color: #b89453;
}

.forecast-icon {
    animation: pulse 1.5s infinite;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(244, 157, 22, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(244, 157, 22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244, 157, 22, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(244, 157, 22, 0.4);
        box-shadow: 0 0 0 0 rgba(244, 157, 22, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(244, 157, 22, 0);
        box-shadow: 0 0 0 10px rgba(244, 157, 22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244, 157, 22, 0);
        box-shadow: 0 0 0 0 rgba(244, 157, 22, 0);
    }
}
</style>
