<template>
    <v-dialog v-if="visible" v-model="model"
              persistent
              width="800">
        <v-card>
            <v-toolbar dark
                       color="wellis">
                <v-btn icon
                       @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Attribute editor</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="mt-5">
                <v-row>
                    <v-col cols="12" xl="12" sm="12">
                        <v-text-field
                            v-model="target.quantity"
                            label="Quantity"
                            color="wellis"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" xl="12" sm="12">
                        <v-text-field
                            v-model.number="target.discount"
                            label="Discount"
                            color="wellis"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row v-if="target.type === 'SHOPORD' || target.type === 'STOCK_FIFO' || target.type === 'STOCK_ETA' || target.type === 'PURCHORD'">
                    <v-col cols="12" xl="12" sm="12">
                        <v-text-field
                            v-model="target.unique_id"
                            label="Unique ID"
                            color="wellis"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row v-if="target.type !== 'PRODUCT' && target.type !== 'OTHER'">
                    <v-col cols="12" xl="12" sm="12">
                        <v-textarea color="wellis"
                                    rows="15"
                                    no-resize
                                    label="Attributes"
                                    v-model="target.item_data"></v-textarea>
                    </v-col>
                </v-row>

                <v-row v-if="target.type === 'PRODUCT' && target.configuration !== '*'">
                    <v-col cols="12" xl="12" sm="12">
                        <v-textarea color="wellis"
                                    rows="15"
                                    label="Configuration"
                                    no-resize
                                    v-model="target.configuration"></v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "FailedOrderItemAttributeEditor",
    props: {
        visible: Boolean,
        target: Object
    },

    data() {
        return {
            model: true,
        }
    },

    methods: {
        close() {
            this.$emit('close');
        },
    },

    watch: {
        visible: function (val) {
            if (val) {
                this.target.item_data = JSON.stringify(JSON.parse(this.target.item_data), null, 2);
                if (this.target.configuration !== '*') {
                    this.target.configuration = JSON.stringify(JSON.parse(this.target.configuration), null, 2);
                }
            }
        },
    }
}
</script>

<style scoped>

</style>
