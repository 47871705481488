<template>
    <v-container fluid>
        <h2 class="mb-3">Navigation</h2>

        <h3 class="mb-3">Products</h3>
        <v-row>
            <v-col xl="3" lg="4" md="12" sm="12" cols="12">
                <v-hover v-slot="{ hover }">
                    <v-card class="mx-auto pointer" min-height="64" :elevation="hover ? 4 : 2">
                        <v-card-text class="text&#45;&#45;primary align-center text-center">
                            <span class="text-h5 wellis&#45;&#45;text">Categories</span>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>

        <h3 class="mt-5 mb-3">Users / Account</h3>
        <v-row>
            <v-col xl="3" lg="4" md="12" sm="12" cols="12">
                <v-hover v-slot="{ hover }">
                    <v-card class="mx-auto pointer" min-height="64" :elevation="hover ? 4 : 2"
                            :to="{name: 'app.admin.users.fnd'}">
                        <v-card-text class="text--primary align-center text-center">
                            <span class="text-h5 wellis--text">Frontend users</span>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>

            <v-col xl="3" lg="4" md="12" sm="12" cols="12">
                <v-hover v-slot="{ hover }">
                    <v-card class="mx-auto pointer" min-height="64" :elevation="hover ? 4 : 2"
                            :to="{name: 'app.admin.users.lite-users.overview'}">
                        <v-card-text class="text--primary align-center text-center">
                            <span class="text-h5 wellis--text">Lite users</span>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>

        <h3 class="mt-5 mb-3">News</h3>
        <v-row>
            <v-col xl="3" lg="4" md="12" sm="12" cols="12">
                <v-hover v-slot="{ hover }">
                    <v-card class="mx-auto pointer" min-height="64" :elevation="hover ? 4 : 2"
                            :to="{name: 'app.admin.news.posts'}">
                        <v-card-text class="text--primary align-center text-center">
                            <span class="text-h5 wellis--text">Posts</span>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>

            <v-col xl="3" lg="4" md="12" sm="12" cols="12">
                <v-hover v-slot="{ hover }">
                    <v-card class="mx-auto pointer" min-height="64" :elevation="hover ? 4 : 2"
                            :to="{name: 'app.admin.news.categories'}">
                        <v-card-text class="text--primary align-center text-center">
                            <span class="text-h5 wellis--text">Categories</span>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>

        <h3 class="mt-5 mb-3">Orders</h3>
        <v-row>
            <v-col xl="3" lg="4" md="12" sm="12" cols="12">
                <v-hover v-slot="{ hover }">
                    <v-card class="mx-auto pointer" min-height="64" :elevation="hover ? 4 : 2"
                            :to="{name: 'app.admin.orders.failed'}">
                        <v-card-text class="text--primary align-center text-center">
                            <span class="text-h5 wellis--text">Failed orders</span>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>

        <h3 class="mt-5 mb-3">Background processes</h3>
        <v-row>
            <v-col xl="3" lg="4" md="12" sm="12" cols="12">
                <v-hover v-slot="{ hover }">
                    <v-card class="mx-auto pointer" min-height="64" :elevation="hover ? 4 : 2"
                            :to="{name: 'app.admin.queue.jobs'}">
                        <v-card-text class="text--primary align-center text-center">
                            <span class="text-h5 wellis--text">Jobs</span>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col xl="3" lg="4" md="12" sm="12" cols="12">
                <v-hover v-slot="{ hover }">
                    <v-card class="mx-auto pointer" min-height="64" :elevation="hover ? 4 : 2"
                            :to="{name: 'app.admin.queue.failed-jobs'}">
                        <v-card-text class="text--primary align-center text-center">
                            <span class="text-h5 wellis--text">Failed jobs</span>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="12"></v-col>
            <v-col xl="3" lg="4" md="12" sm="12" cols="12">
                <v-hover v-slot="{ hover }">
                    <v-card class="mx-auto pointer" min-height="64" :elevation="hover ? 4 : 2"
                            :to="{name: 'app.admin.scheduler'}">
                        <v-card-text class="text--primary align-center text-center">
                            <span class="text-h5 wellis--text">Scheduler</span>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {EVENT_PAGE_TITLE_CHANGE_NO_TRANS} from '../../../../core/constants';

export default {
    name: 'AdminDashboardView',

    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE_NO_TRANS, 'Administration'), 100);
    },
};
</script>

<style scoped>

</style>
