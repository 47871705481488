<template>
    <div v-if="guestMode">
        <v-select
            dense
            color="black"
            item-color="wellis"
            :items="items"
            prepend-icon="mdi-web"
            item-value="value"
            item-text="title"
            v-model="selected"
            @change="onChange"
        >
        </v-select>
    </div>
    <v-menu bottom left v-else>
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" depressed>
                <v-icon class="mr-1">mdi-web</v-icon>
                {{selectedTitle}}
            </v-btn>
        </template>

        <v-list>
            <v-list-item v-for="item in items" :key="item.value" link @click="select(item.value)">
                <v-list-item-title :class="selected === item.value ? 'wellis--text' : ''">{{item.title}}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import {EVENT_LANG_CHANGE} from "../../core/constants";

export default {
    name: "LanguagePicker",

    props: {
        guestMode: Boolean
    },

    created() {
        this.$store.getters["langStore/supported"].forEach(item => {
            this.items.push({
                value: item,
                title: this.$t('languages')[item]
            });
        });

        this.selected = this.$store.getters['langStore/current'];
    },

    data() {
        return {
            selected: null,
            items: [],
        }
    },

    methods: {

        select(value) {
            this.selected = value;
            this.handle();
        },

        showOverlay() {
            if(this.guestMode) {
                this.$GuestLayout.showOverlay();
            } else {
                this.$ApplicationLayout.showOverlay();
            }
        },

        hideOverlay() {
            if(this.guestMode) {
                this.$GuestLayout.hideOverlay();
            } else {
                this.$ApplicationLayout.hideOverlay();
            }
        },

        handle() {
            this.showOverlay();
            setTimeout(() => {
                let old = this.$i18n.locale;
                this.$i18n.locale = this.selected;
                this.$store.dispatch('langStore/setLanguage', this.selected)
                    .then(() => {
                        this.$eventBus.$emit(EVENT_LANG_CHANGE, {
                            oldValue: old,
                            newValue: this.$i18n.locale
                        });
                        this.hideOverlay();
                    });
            }, 500);
        },

        onChange() {
            this.handle();
        }
    },

    computed: {
        selectedTitle() {
            return this.items.find(item => item.value === this.selected).title;
        }
    }
}
</script>

<style scoped>

</style>
