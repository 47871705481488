<template>
    <div>
        <v-navigation-drawer app dark
                             v-model="menuState">
            <v-layout justify-center class="mt-3">
                <v-hover>
                    <template v-slot:default="{ hover }">
                        <v-img :src="require('@/assets/img/logo_white.png')"
                               class="pointer"
                               :max-width="170"
                               @click="clickLogo"
                               :style="hover ? 'opacity: 0.7;' : 'opacity: 1;'"/>
                    </template>
                </v-hover>
            </v-layout>
            <v-layout class="mt-2" justify-center v-if="!isProductionMode">
                <div class="warning--text font-weight-bold env-mode noselect">
                     {{isTestMode ? 'TEST BUILD' : 'DEV BUILD'}}
                </div>
            </v-layout>
            <v-layout class="mt-2" justify-center>
                <div class="white--text noselect" v-html="$App.versionString"></div>
            </v-layout>
            <div class="" v-if="!$helpers.isMobile()">
                <v-divider class="mt-3 mb-3"></v-divider>
                <v-layout justify-center>
                    <div :class="['white--text font-weight-bold noselect', tokenExpiration < 60 ? 'token-will-expire' : '']">{{remainingAccountTime}}</div>
                </v-layout>
                <v-divider class="mt-3 mb-3"/>
                <v-layout justify-center>
                    <div class="white--text font-italic noselect">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-on="on" v-bind="attrs">{{describeUser | cut(20)}}</span>
                            </template>
                            <span>{{describeUser}}</span>
                        </v-tooltip>
                    </div>
                </v-layout>
                <v-layout justify-center>
                    <div class="wellis--text font-weight-bold noselect">{{currentRealmName | cut(30)}}</div>
                </v-layout>
                <v-layout justify-center v-if="$store.getters['authStore/isLiteUser']">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-on="on" v-bind="attrs" class="white--text font-weight-bold noselect">/ {{$t('v32.liteUserModeText') | toUpper}} /</span>
                        </template>
                        <span>{{describeLiteUser}}</span>
                    </v-tooltip>
                </v-layout>
                <v-divider class="mt-3 mb-3"/>
            </div>
            <v-layout>
                <Navigation/>
            </v-layout>
            <v-footer absolute class="text-center noselect">
                <div>
                    2016 - {{(new Date().getFullYear())}} &copy; Wellis OS
                </div>
            </v-footer>
        </v-navigation-drawer>

        <v-system-bar v-if="topMessage" :color="topMessage.color" class="white--text" app>
            <v-container fluid class="text-center">
                <span>{{topMessage.text}}</span>
            </v-container>
        </v-system-bar>
        <v-app-bar app>
            <v-app-bar-nav-icon @click="menuState = !menuState"></v-app-bar-nav-icon>
            <div v-if="windowWidth > 760" class="noselect">
                <v-toolbar-title v-if="!subTitle">{{$t(pageTitle)}}</v-toolbar-title>
                <v-toolbar-title v-else>{{subTitle}} / {{$t(pageTitle)}}</v-toolbar-title>
            </div>


            <v-spacer></v-spacer>
            <SearchToolbar/>

            <RealmSelect v-if="!$helpers.isMobile() && canChangeRealm"/>
            <LanguagePicker :guest-mode="false" v-if="!$helpers.isMobile()"/>

            <CartIcon/>

            <v-menu bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-account</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-subheader v-if="!$store.getters['authStore/isLiteUser']">{{describeUser | cut(30)}}</v-subheader>
                    <v-subheader v-else>{{describeLiteUser | cut(30)}}</v-subheader>

                    <v-list-item link @click="redirectToRoute('app.account')" v-if="!$store.getters['authStore/isLiteUser']">
                        <v-list-item-action>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{$t('v31.components.account.title')}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item link @click="redirectToRoute('app.account.lite-users')" v-if="!$store.getters['authStore/isLiteUser']">
                        <v-list-item-action>
                            <v-icon>mdi-account-supervisor-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{$t('v32.components.account.liteUsers.title')}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link @click="liteUserPasswordChangeDialog = true" v-else>
                        <v-list-item-action>
                            <v-icon>mdi-form-textbox-password</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{$t('v32.components.account.liteUsers.btnChangePassword')}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>


                    <v-divider></v-divider>

                    <v-list-item link @click="redirectToRoute('app.account.invoices')" :disabled="!hasLiteUserPermission('my_invoices')">
                        <v-list-item-action>
                            <v-icon>mdi-account-cash</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{$t('components.my-invoices.title')}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item link @click="redirectToRoute('app.account.orders')" :disabled="!hasLiteUserPermission('my_orders')">
                        <v-list-item-action>
                            <v-icon>mdi-file-document-multiple-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{$t('components.my-orders.title')}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item link @click="redirectToRoute('app.account.loadings')" :disabled="!hasLiteUserPermission('my_shipments')">
                        <v-list-item-action>
                            <v-icon>mdi-truck-delivery-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{$t('components.my-shipments.title')}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item link @click="showApplicationSettings" v-if="!$store.getters['authStore/isLiteUser']">
                        <v-list-item-action>
                            <v-icon>mdi-cogs</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{$t('components.app-settings.title')}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="logout">
                        <v-list-item-action>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{$t('components.account.logout')}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <v-main>
            <v-container fluid>
                <v-overlay :value="loadingOverlay">
                    <v-progress-circular
                        indeterminate
                        size="64"
                    ></v-progress-circular>
                </v-overlay>
                <v-alert v-if="$store.getters['authStore/currentRealm'] === 'wellis_usa_realm' && 1 > 2"
                         type="warning"
                         prominent
                         transition="scale-transition"
                         color="wellis"
                         border="left">
                    <b>Effective Immediately </b><br> <span class="font-weight-normal">
                                        All spas shipped from the domestic warehouse will now receive your container discount but will incur a $900 ocean freight surcharge.
                    <br>Swim spas will also receive the discount and incur a $4500 surcharge, Olympus models $3000 surcharge.    </span>
                </v-alert>
                <ApplicationWarning/>
                <router-view/>
            </v-container>
        </v-main>
        <ApplicationSettings :visible="applicationSettingsDialog" @close="applicationSettingsDialog = false"/>
        <LiteUserChangePasswordDialog :state="liteUserPasswordChangeDialog" @close="liteUserPasswordChangeDialog = false"/>
        <v-dialog
            v-model="tokenExpiredDialog"
            persistent
            max-width="400"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{$t('components.login.title')}}
                </v-card-title>
                <v-card-text>{{$t('components.login.session-expired')}}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="wellis"
                        text
                        @click="closeExpiredTokenDialog"
                    >
                        {{$t('modal.close')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <ApplicationGeneralMessage/>
    </div>
</template>

<script>
import Navigation from "../components/common/Navigation";
import {EVENT_LANG_CHANGE, EVENT_PAGE_TITLE_CHANGE, EVENT_PAGE_TITLE_CHANGE_NO_TRANS, EVENT_TOKEN_REFRESHED} from "../core/constants";
import LanguagePicker from "../components/common/LanguagePicker";
import Vue from "vue";
import ApplicationWarning from "../components/application/ApplicationWarning";
import CartIcon from "../components/application/CartIcon";
import RealmSelect from "../components/common/RealmSelect";
import axios from "axios";
import SearchToolbar from "../components/application/search/SearchToolbar";
import ApplicationSettings from "../components/application/ApplicationSettings";
import ApplicationGeneralMessage from '../components/common/ApplicationGeneralMessage';
import LiteUserChangePasswordDialog from '../components/application/lite-user/LiteUserChangePasswordDialog';

export default {
    name: "ApplicationLayout",
    components: {LiteUserChangePasswordDialog, ApplicationGeneralMessage, ApplicationSettings, SearchToolbar, RealmSelect, CartIcon, ApplicationWarning, LanguagePicker, Navigation},

    mounted() {
        Vue.prototype.$ApplicationLayout = this;

        this.$nextTick(() => {
            if (this.$helpers.isMobile() || window.innerWidth < 1300) {
                this.$set(this, 'menuState', false);
            }
        });

        this.initTimeInterval();
        this.$eventBus.$on(EVENT_TOKEN_REFRESHED, jwt => this.initTimeInterval(jwt.expiration));

        this.$eventBus.$on(EVENT_PAGE_TITLE_CHANGE, (title, subtitle = null) => {
            this.pageTitle = title;
            this.subTitle = subtitle;

            if (this.subTitle) {
                document.title = `${this.subTitle} / ${this.$t(title)} - ${this.$t('title')}`;
            } else {
                document.title = `${this.$t(title)} - ${this.$t('title')}`;
            }
        });

        this.$eventBus.$on(EVENT_PAGE_TITLE_CHANGE_NO_TRANS, (title, subtitle = null) => {
            this.pageTitle = title;
            this.subTitle = subtitle;

            if (this.subTitle) {
                document.title = `${this.subTitle} / ${title} - ${this.$t('title')}`;
            } else {
                document.title = `${title} - ${this.$t('title')}`;
            }
        });

        window.onresize = () => {
            this.windowWidth = window.innerWidth
        }

        this.$eventBus.$on(EVENT_LANG_CHANGE, this.fetchTopMessage);

        this.fetchTopMessage();
    },

    destroyed() {
        this.$eventBus.$off(EVENT_PAGE_TITLE_CHANGE);
    },

    data() {
        return {
            pageTitle: null,
            subTitle: null,
            loadingOverlay: false,
            topMessage: null,
            applicationSettingsDialog: false,
            windowWidth: window.innerWidth,
            tokenExpiration: 0,
            timeInterval: null,
            tokenExpiredDialog: false,
            menuState: true,
            liteUserPasswordChangeDialog: false,
        }
    },

    methods: {
        async fetchTopMessage() {
            axios.get(window.location.origin + '/message.json?t=' + Date.now() + '&r=' + Math.random() * 1000)
                .then(response => {
                    if (response.data[this.$store.getters['langStore/current']]) {
                        this.topMessage = response.data[this.$store.getters['langStore/current']];
                    }

                    if (response.data['*']) {
                        this.topMessage = response.data['*'];
                    }
                });
        },

        showOverlay() {
            return this.loadingOverlay = true;
        },

        hideOverlay() {
            return this.loadingOverlay = false;
        },

        logout() {
            let isLiteUser = this.$store.getters['authStore/isLiteUser'];
            this.$store.dispatch('authStore/logout').then(() => {
                this.showOverlay();
                this.$store.dispatch('categoryStore/clearCache')
                    .then(() => {
                        setTimeout(() => {
                            this.$router.push({name: 'app.guest', query: {auth_method: isLiteUser ? 'lite' : 'default'}});
                            this.hideOverlay();
                        }, 1000);
                    })
            });
        },

        clickLogo() {
            if (this.$route.name !== 'app.start') {
                this.$router.push({name: 'app.start'});
            }
        },

        showApplicationSettings() {
            this.applicationSettingsDialog = true;
        },

        initTimeInterval(expiration = null) {
            if (!expiration) {
                expiration = this.$store.getters['authStore/tokenExpiration'];
            }
            this.tokenExpiration = Math.round(expiration - (Date.now() / 1000));
            if (this.timeInterval !== null) {
                clearInterval(this.timeInterval);
                this.timeInterval = null;
            }

            this.timeInterval = setInterval(() => {
                this.tokenExpiration--;
            }, 1000);
        },

        closeExpiredTokenDialog() {
            this.$store.dispatch('authStore/logout')
                .then(() => this.$router.push({name: 'app.guest'}))
        },

        redirectToRoute(route, params_ = {}) {
            this.$router.push({
                name: route,
                params: params_
            });
        },

        hasLiteUserPermission(permission) {
            if (!this.$store.getters['authStore/isLiteUser']) {
                return true;
            }
            let permission_ = this.$store.getters['authStore/getLiteUserPermission'].find(x => x.permission === permission);
            return permission_.objstate === 'ACTIVE';
        },
    },

    computed: {
        describeUser() {
            return `${this.$store.getters['authStore/getCompanyName']}`;
        },

        describeLiteUser() {
            return `[${this.$store.getters['authStore/getLiteUser'].fnd_user_customer_no}] ` + this.$store.getters['authStore/getLiteUser'].username
        },

        currentRealmName() {
            let current = this.$store.getters['authStore/currentRealm'];
            if (!current) {
                return 'N/A';
            }

            let realm = this.$t('components.after-login-realm-select.realms').find(item => item.key === current);
            return realm.name;
        },

        canChangeRealm() {
            return this.$store.getters['authStore/canChangeRealm'];
        },

        remainingAccountTime() {
            let duration = this.tokenExpiration;
            let minutes = Math.floor((duration % 3600) / 60);
            let seconds = Math.floor(duration % 60);
            return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
        },

        isProductionMode() {
            return process.env.NODE_ENV === 'production';
        },

        isTestMode() {
            return process.env.NODE_ENV === 'test';
        },

        isDevMode() {
            return process.env.NODE_ENV === 'development';
        },
    },

    watch: {
        tokenExpiration: function () {
            if (this.tokenExpiration <= 0) {
                clearInterval(this.timeInterval);
                this.tokenExpiration = 0;
                this.tokenExpiredDialog = true;
            }
        }
    }
}
</script>

<style scoped>
.token-will-expire {
    animation: token-will-expire-flash 1s linear infinite;
}

@keyframes token-will-expire-flash {
    50% {
        opacity: 0.4;
    }
}

.env-mode {
    animation: token-will-expire-flash 1.7s linear infinite;
}

@keyframes env-mode {
    50% {
        opacity: 0.3;
    }
}

</style>
