<template>
    <v-container fluid>
        <form @submit="formSubmit" v-if="phase === 'form'" autocomplete="off">
            <v-row>
                <v-col cols="12" lg="6" md="12" sm="12">
                    <v-row>
                        <v-col cols="12" lg="12" md="12" sm="12">
                            <v-card elevation="2">
                                <v-card-title class="wellis--text">{{$t('components.fault-report.partnerInfo')}}</v-card-title>
                                <v-card-text>
                                    <v-text-field readonly
                                                  background-color="rgba(0, 0, 0, 0.06)"
                                                  outlined
                                                  persistent-hint
                                                  color="wellis"
                                                  :label="$t('components.billing.company')"
                                                  :hint="$t('v3.mandatoryField')"
                                                  :value="form.partner_company.value"
                                                  class="mb-3"
                                                  :error-messages="getValidationError('partner_company')"/>
                                    <v-text-field readonly
                                                  background-color="rgba(0, 0, 0, 0.06)"
                                                  outlined
                                                  persistent-hint
                                                  color="wellis"
                                                  :label="$t('components.checkout.email')"
                                                  :hint="$t('v3.mandatoryField')"
                                                  :value="form.partner_email.value"
                                                  class="mb-3"
                                                  :error-messages="getValidationError('partner_email')"/>
                                    <v-text-field readonly
                                                  background-color="rgba(0, 0, 0, 0.06)"
                                                  outlined
                                                  persistent-hint
                                                  color="wellis"
                                                  :label="$t('components.fault-report.name')"
                                                  :hint="$t('v3.mandatoryField')"
                                                  :value="form.partner_name.value"
                                                  class="mb-3"
                                                  :error-messages="getValidationError('partner_name')"/>
                                    <v-text-field
                                        persistent-hint
                                        outlined
                                        color="wellis"
                                        :hint="$t('v3.optionalField')"
                                        :label="$t('components.fault-report.phone')"
                                        :value="form.partner_phone.value"
                                        :error-messages="getValidationError('partner_phone')"/>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" lg="12" md="12" sm="12">
                            <v-card elevation="2">
                                <v-card-title class="wellis--text">{{$t('components.fault-report.endUserInfo')}}</v-card-title>
                                <v-card-text>
                                    <v-text-field outlined
                                                  persistent-hint
                                                  color="wellis"
                                                  class="mb-3"
                                                  :hint="$t('v3.optionalField')"
                                                  :label="$t('components.fault-report.name')"
                                                  v-model="form.end_user_name.value"
                                                  :error-messages="getValidationError('end_user_name')"/>
                                    <v-text-field outlined
                                                  persistent-hint
                                                  color="wellis"
                                                  class="mb-3"
                                                  :hint="$t('v3.optionalField')"
                                                  :label="$t('components.checkout.email')"
                                                  v-model="form.end_user_email.value"
                                                  :error-messages="getValidationError('end_user_email')"/>
                                    <v-text-field outlined
                                                  persistent-hint
                                                  color="wellis"
                                                  class="mb-3"
                                                  :hint="$t('v3.optionalField')"
                                                  :label="$t('components.fault-report.phone')"
                                                  v-model="form.end_user_phone.value"
                                                  :error-messages="getValidationError('end_user_phone')"/>
                                    <v-text-field outlined
                                                  persistent-hint
                                                  class="mb-3"
                                                  :hint="$t('v3.optionalField')"
                                                  color="wellis"
                                                  :label="$t('components.fault-report.subject')"
                                                  v-model="form.end_user_subject.value"
                                                  :error-messages="getValidationError('end_user_subject')"/>
                                    <v-select
                                        :label="$t('components.billing.country')"
                                        outlined
                                        color="wellis"
                                        item-color="wellis"
                                        :items="countries"
                                        :hint="$t('v3.optionalField')"
                                        persistent-hint
                                        item-value="name"
                                        item-text="name"
                                        v-model="form.end_user_country.value"
                                        :loading="countries.length === 0"
                                        class="mb-3"
                                        :error-messages="getValidationError('end_user_country')"/>
                                    <v-text-field outlined
                                                  persistent-hint
                                                  color="wellis"
                                                  class="mb-3"
                                                  :hint="$t('v3.optionalField')"
                                                  :label="$t('components.billing.zip')"
                                                  v-model="form.end_user_zip.value"
                                                  :error-messages="getValidationError('end_user_zip')"/>
                                    <v-text-field outlined
                                                  persistent-hint
                                                  color="wellis"
                                                  class="mb-3"
                                                  :hint="$t('v3.optionalField')"
                                                  :label="$t('components.billing.city')"
                                                  v-model="form.end_user_city.value"
                                                  :error-messages="getValidationError('end_user_city')"/>
                                    <v-text-field outlined
                                                  persistent-hint
                                                  color="wellis"
                                                  class="mb-3"
                                                  :hint="$t('v3.optionalField')"
                                                  :label="$t('components.billing.address')"
                                                  v-model="form.end_user_address.value"
                                                  :error-messages="getValidationError('end_user_address')"/>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" lg="6" md="12" sm="12">
                    <v-row>
                        <v-col cols="12" lg="12" md="12" sm="12">
                            <v-card elevation="2">
                                <v-card-title class="wellis--text">
                                    <v-container fluid>
                                        {{$t('components.fault-report.productInfo')}}
                                        <v-btn icon class="float-right" type="button" @click="productFinderDialog = true">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </v-container>
                                </v-card-title>
                                <v-card-text>
                                    <v-text-field readonly background-color="rgba(0, 0, 0, 0.06)"
                                                  outlined
                                                  class="mb-3"
                                                  persistent-hint
                                                  :hint="$t('v3.mandatoryField')"
                                                  color="wellis"
                                                  :label="$t('components.fault-report.serialNo')"
                                                  :value="form.product_serial.value"
                                                  :error-messages="getValidationError('product_serial')"/>
                                    <v-text-field readonly background-color="rgba(0, 0, 0, 0.06)"
                                                  outlined
                                                  persistent-hint
                                                  class="mb-3"
                                                  :hint="$t('v3.mandatoryField')"
                                                  color="wellis"
                                                  :label="$t('components.stocklist.name')"
                                                  :value="form.product_name.value"
                                                  :error-messages="getValidationError('product_name')"/>
                                    <v-text-field readonly background-color="rgba(0, 0, 0, 0.06)"
                                                  outlined
                                                  class="mb-3"
                                                  persistent-hint
                                                  :hint="$t('v3.mandatoryField')"
                                                  color="wellis"
                                                  :label="$t('components.fault-report.dateOfManufacture')"
                                                  :value="form.product_dom.value"
                                                  :error-messages="getValidationError('product_dom')"/>
                                    <v-menu
                                        ref="menu"
                                        v-model="datePickerDialog"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field outlined
                                                          readonly
                                                          class="mb-3"
                                                          persistent-hint
                                                          v-bind="attrs"
                                                          v-on="on"
                                                          :hint="$t('v3.mandatoryField')"
                                                          color="wellis"
                                                          append-icon="mdi-calendar"
                                                          v-on:click:append="datePickerDialog = true"
                                                          :label="$t('components.fault-report.installDate')"
                                                          :value="form.product_doc.value"
                                                          :error-messages="getValidationError('product_doc')"/>
                                        </template>
                                        <v-date-picker
                                            v-model="form.product_doc.value"
                                            color="wellis"
                                            :first-day-of-week="1"
                                            :locale="$store.getters['langStore/current']"
                                        ></v-date-picker>
                                    </v-menu>
                                    <v-text-field readonly background-color="rgba(0, 0, 0, 0.06)"
                                                  outlined
                                                  class="mb-3"
                                                  persistent-hint
                                                  :hint="$t('v3.mandatoryField')"
                                                  color="wellis"
                                                  :label="$t('components.administration.failed-orders.orderNo')"
                                                  :value="form.product_order_no.value"
                                                  :error-messages="getValidationError('product_order_no')"/>
                                    <v-text-field readonly background-color="rgba(0, 0, 0, 0.06)"
                                                  outlined
                                                  class="mb-3"
                                                  persistent-hint
                                                  :hint="$t('v3.mandatoryField')"
                                                  color="wellis"
                                                  :label="$t('components.my-invoices.invoiceNo')"
                                                  :value="form.product_invoice_no.value"
                                                  :error-messages="getValidationError('product_invoice_no')"/>
                                    <v-select
                                        :label="$t('components.fault-report.productWellisInstall')"
                                        outlined
                                        color="wellis"
                                        item-color="wellis"
                                        :items="[{value: 0, text: $t('confirm.no')}, {value: 1, text: $t('confirm.yes')}]"
                                        :hint="$t('v3.mandatoryField')"
                                        persistent-hint
                                        item-value="value"
                                        item-text="text"
                                        v-model="form.product_wellis_install.value"
                                        class="mb-3"
                                        :error-messages="getValidationError('product_wellis_install')"/>
                                    <v-select
                                        :label="$t('components.fault-report.productCategory')"
                                        outlined
                                        color="wellis"
                                        item-color="wellis"
                                        :items="productCategories"
                                        :hint="$t('v3.mandatoryField')"
                                        persistent-hint
                                        item-value="value"
                                        item-text="text"
                                        v-model="form.product_category.value"
                                        class="mb-3"
                                        :error-messages="getValidationError('product_category')"/>
                                    <v-select
                                        :label="$t('components.fault-report.issueOccurs')"
                                        outlined
                                        color="wellis"
                                        item-color="wellis"
                                        :items="issueOccurs"
                                        :hint="$t('v3.mandatoryField')"
                                        persistent-hint
                                        item-value="value"
                                        item-text="text"
                                        v-model="form.product_issue_occurs.value"
                                        class="mb-3"
                                        :error-messages="getValidationError('product_issue_occurs')"/>
                                    <v-select
                                        :label="$t('components.fault-report.issueType')"
                                        outlined
                                        color="wellis"
                                        item-color="wellis"
                                        :items="issueTypes"
                                        :hint="$t('v3.mandatoryField')"
                                        persistent-hint
                                        item-value="value"
                                        item-text="text"
                                        v-model="form.product_issue_type.value"
                                        class="mb-3"
                                        :error-messages="getValidationError('product_issue_type')"/>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-container class="mt-lg-3" fluid>
                <div v-for="level in maxLevels" :key="level">
                    <div v-if="currentLevel >= level">
                        <v-card elevation="2" class="position-center mb-3">
                            <v-card-title>
                                <v-container fluid>
                                    <span class="wellis--text">{{level}} / {{maxLevels}}</span>
                                    <v-btn type="button" icon class="float-right"
                                           v-if="currentLevel !== 1 && currentLevel === level"
                                           @click="clearLevel">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-container>
                            </v-card-title>
                            <v-card-text>
                                <v-select
                                    :label="$t('components.fault-report.faultCategory')"
                                    outlined
                                    color="wellis"
                                    item-color="wellis"
                                    :items="faultCategories"
                                    :hint="$t('v3.mandatoryField')"
                                    persistent-hint
                                    item-value="value"
                                    item-text="text"
                                    v-model="form[`fault_category_l${level}`].value"
                                    class="mb-3"
                                    @change="resetField(`fault_nature_l${level}`, `fault_category_l${level}`)"
                                    :error-messages="getValidationError(`fault_category_l${level}`)"/>
                                <v-select
                                    :label="$t('components.fault-report.faultNature')"
                                    outlined
                                    color="wellis"
                                    item-color="wellis"
                                    :items="getFaultNatures(form[`fault_category_l${level}`].value)"
                                    :hint="$t('v3.mandatoryField')"
                                    persistent-hint
                                    item-value="value"
                                    item-text="text"
                                    v-model="form[`fault_nature_l${level}`].value"
                                    class="mb-3"
                                    :error-messages="getValidationError(`fault_nature_l${level}`)"/>
                                <v-textarea color="wellis"
                                            outlined
                                            :label="$t('components.fault-report.faultDesc')"
                                            persistent-hint
                                            :hint="$t('v3.mandatoryField')"
                                            v-model="form[`fault_desc_l${level}`].value"
                                            :counter="form[`fault_desc_l${level}`].maxLength"
                                            @input="trimDesc(level)"
                                            class="mb-5"
                                            :error-messages="getValidationError(`fault_desc_l${level}`)"/>

                                <div v-if="form[`fault_attachments_l${level}`].value.length > 0">
                                    <h3 class="mb-2">{{$t('components.fault-report.attachments')}}</h3>
                                    <v-simple-table>
                                        <tr v-for="(item, index) in form[`fault_attachments_l${level}`].value" :key="index">
                                            <td width="10%">
                                                <v-btn icon
                                                       type="button"
                                                       @click="removeAttachment(`fault_attachments_l${level}`, index)">
                                                    <v-icon>mdi-trash-can-outline</v-icon>
                                                </v-btn>
                                            </td>
                                            <td>{{item.file.name | cut(35)}}</td>
                                            <td>{{item.file.size | niceBytesSize}}</td>
                                        </tr>
                                    </v-simple-table>
                                </div>
                                <v-container class="text-center" v-if="form[`fault_attachments_l${level}`].value.length < maxAttachments">
                                    <v-btn text color="wellis"
                                           type="button"
                                           @click="selectFile(`fault_attachments_l${level}`)">
                                        {{$t('components.fault-report.addAttachments')}}
                                    </v-btn>
                                </v-container>
                            </v-card-text>
                            <v-card-actions v-if="currentLevel < maxLevels && level === currentLevel">
                                <v-container fluid>
                                    <v-btn icon x-large
                                           type="button"
                                           class="float-right"
                                           @click="addLevel">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-container>
                            </v-card-actions>
                        </v-card>
                    </div>
                </div>
                <div class="mt-10 text-center">
                    <v-btn large color="wellis" type="submit" style="color:white" width="350">{{$t('btnSend')}}</v-btn>
                </div>
            </v-container>
        </form>

        <v-container fluid v-if="phase === 'fileUploading'">
            <div class="text-center">
                <v-progress-circular
                    color="wellis"
                    indeterminate
                    size="128"
                ></v-progress-circular>
                <p class="mt-3">{{$t('components.fault-report.fileUploadingText')}}</p>
            </div>
            <v-simple-table>
                <thead>
                <tr>
                    <th>{{$t('components.fault-report.fileName')}}</th>
                    <th>{{$t('components.fault-report.fileSize')}}</th>
                    <th width="30%">{{$t('components.administration.category.status')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in files" :key="index">
                    <td>{{item.file.name | cut(35)}}</td>
                    <td>{{item.file.size | niceBytesSize}}</td>
                    <td v-if="item.uploaded">
                        <v-icon color="wellis">mdi-check</v-icon>
                    </td>
                    <td v-else>
                        <v-progress-circular
                            color="wellis"
                            indeterminate
                            size="32"
                        ></v-progress-circular>
                    </td>
                </tr>
                </tbody>
            </v-simple-table>
        </v-container>

        <v-container fluid v-if="phase === 'completed'">
            <div class="text-center">
                <v-icon size="128" color="wellis">mdi-check</v-icon>
                <p class="mt-3">{{$t('components.fault-report.sentText')}}</p>
                <v-btn class="mt-5" min-width="200" @click="restart">{{$t('v3.components.fault-report.btnResetForm')}}</v-btn>
            </div>
        </v-container>

        <input type="file" hidden @change="addFile" ref="inputFile" :accept="acceptExtensions"/>

        <v-dialog
            v-model="resultDialog.value"
            max-width="400"
        >
            <v-card>

                <v-card-text>
                    <div class="text-center">
                        <v-icon color="wellis" class="mt-3 mb-3" size="72">mdi-close</v-icon>
                    </div>
                    <div>{{resultDialog.text}}</div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="wellis"
                        text
                        @click="resultDialog.value = false"
                    >
                        {{$t('modal.close')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <FaultReportProductFinder :visible="productFinderDialog" @close="productFinderDialog = false"/>
    </v-container>
</template>

<script>
import {EVENT_FAULT_REPORT_SELECT_ITEM, EVENT_LANG_CHANGE, EVENT_PAGE_TITLE_CHANGE} from "../../../core/constants";
import BackendService from "../../../core/backend/backend.service";
import FaultReportApi from "../../../api/fault-report.api";
import {empty} from "../../../helpers";
import FaultReportProductFinder from "./FaultReportProductFinder";

export default {
    name: "FaultReportView",
    components: {FaultReportProductFinder},
    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE, 'components.fault-report.title'), 100);

        if(!this.hasLiteUserPermission('fault_report')) {
            this.$ApplGeneral({
                content: this.$t('v32.liteUser.hasNoPermission'),
                onClose: function() {
                    this.$router.push({
                        name: 'app.start'
                    })
                }
            });
            return;
        }

        this.form.partner_company.value = this.$store.getters['authStore/getCompanyName'];
        this.form.partner_name.value = this.$store.getters['authStore/getFullname'];
        this.form.partner_email.value = this.$store.getters['authStore/getUsername'];
        if(this.$store.getters['authStore/isLiteUser']) {
            this.form.partner_email.value = this.$store.getters['authStore/getLiteUser'].notify_email;
        }

        this.$eventBus.$on(EVENT_FAULT_REPORT_SELECT_ITEM, this.selectProduct);

        this.getCountries();

        this.$eventBus.$on(EVENT_LANG_CHANGE, () => {
            this.getCountries();
        });
    },

    destroyed() {
        this.$eventBus.$off(EVENT_FAULT_REPORT_SELECT_ITEM);
        this.$eventBus.$off(EVENT_LANG_CHANGE);
    },

    data() {
        return {
            countries: [],

            form: {
                partner_company: {value: null, error: null, required: true, disabled: false},
                partner_email: {value: null, error: null, required: true, disabled: false},
                partner_phone: {value: null, error: null, required: false, disabled: false},
                partner_name: {value: null, error: null, required: true, disabled: false},
                end_user_name: {value: null, error: null, required: false, disabled: false},
                end_user_email: {value: null, error: null, required: false, disabled: false},
                end_user_phone: {value: null, error: null, required: false, disabled: false},
                end_user_subject: {value: null, error: null, required: false, disabled: false},
                end_user_country: {value: null, error: null, required: false, disabled: false},
                end_user_zip: {value: null, error: null, required: false, disabled: false},
                end_user_city: {value: null, error: null, required: false, disabled: false},
                end_user_address: {value: null, error: null, required: false, disabled: false},
                product_serial: {value: null, error: null, required: true, disabled: false},
                product_name: {value: null, error: null, required: true, disabled: false},
                product_dom: {value: null, error: null, required: true, disabled: false},
                product_doc: {value: null, error: null, required: true, disabled: false},
                product_order_no: {value: null, error: null, required: true, disabled: false},
                product_invoice_no: {value: null, error: null, required: true, disabled: false},
                product_wellis_install: {value: null, error: null, required: true, disabled: false},
                product_category: {value: null, error: null, required: true, disabled: false},
                product_issue_occurs: {value: null, error: null, required: true, disabled: false},
                product_issue_type: {value: null, error: null, required: true, disabled: false},

                fault_category_l1: {value: null, error: null, required: true, disabled: false},
                fault_nature_l1: {value: null, error: null, required: true, disabled: false},
                fault_desc_l1: {value: null, error: null, required: true, disabled: false, maxLength: 255},
                fault_attachments_l1: {value: [], error: null, required: false, disabled: false},

                fault_category_l2: {value: null, error: null, required: true, disabled: true},
                fault_nature_l2: {value: null, error: null, required: true, disabled: true},
                fault_desc_l2: {value: null, error: null, required: true, disabled: true, maxLength: 255},
                fault_attachments_l2: {value: [], error: null, required: false, disabled: true},

                fault_category_l3: {value: null, error: null, required: true, disabled: true},
                fault_nature_l3: {value: null, error: null, required: true, disabled: true},
                fault_desc_l3: {value: null, error: null, required: true, disabled: true, maxLength: 255},
                fault_attachments_l3: {value: [], error: null, required: false, disabled: true},
            },

            currentLevel: 1,
            maxLevels: 3,
            maxAttachments: 3,
            maxFileSize: 1024 * 1024 * 5,
            allowedExtensions: ['png', 'jpg', 'jpeg', 'gif', 'pdf'],
            selectedFile: null,
            fileContainerTarget: null,
            files: [],

            productSearchComponent: false,

            phase: 'form',

            datePickerDialog: false,
            resultDialog: {
                value: false,
                text: null
            },
            productFinderDialog: false,
        }
    },

    methods: {

        hasLiteUserPermission(permission) {
            if (!this.$store.getters['authStore/isLiteUser']) {
                return true;
            }
            let permission_ = this.$store.getters['authStore/getLiteUserPermission'].find(x => x.permission === permission);
            return permission_.objstate === 'ACTIVE';
        },

        getCountries() {
            return FaultReportApi.getCountries()
                .then(response => this.countries = response.data);
        },

        getValidationError(key) {
            return this.form[key].error;
        },

        clearErrors() {
            Object.keys(this.form).forEach(key => {
                this.form[key].error = null;
            });
        },

        validateForm() {
            this.clearErrors();
            return new Promise((resolve, reject) => {
                let errors = 0;
                const formData = {};
                Object.keys(this.form).forEach(key => {
                    if (this.form[key].required && !this.form[key].disabled && empty(this.form[key].value)) {
                        this.form[key].error = this.$t('components.fault-report.requiredField');
                        errors++;
                    } else {
                        if (!key.toString().includes('attachments')) {
                            formData[key] = this.form[key].value;
                        }
                    }
                });

                if (this.form.product_doc.error === null) {
                    const now = new Date();
                    now.setHours(0, 0, 0, 0);
                    const dom = new Date(this.form.product_dom.value + " 00:00:00");
                    const doc = new Date(this.form.product_doc.value + " 00:00:00");

                    if (dom > doc || doc > now) {
                        this.form.product_doc.error = this.$t('components.fault-report.invalidDomError');
                        reject(this.$t('components.fault-report.invalidDomError'));
                        return;
                    }
                }

                if (errors > 0) {
                    reject('form_invalidated');
                } else {
                    resolve(formData);
                }
            });
        },

        formSubmit(e) {
            e.preventDefault();
            this.validateForm()
                .then(data => {
                    data.sys_attach_len = this.form.fault_attachments_l1.value.length +
                        this.form.fault_attachments_l2.value.length +
                        this.form.fault_attachments_l3.value.length;

                    FaultReportApi.create(data)
                        .then(response => {
                            if (response.data.file_upload) {
                                this.startUpload(response.data.report.id);
                            } else {
                                this.phase = 'completed';
                            }
                        });
                })
                .catch(e => {
                    this.resultDialog.text = e === 'form_invalidated'
                        ? this.$t('validation.requiredError')
                        : e;
                    this.resultDialog.value = true;
                    this.$scrollTop();
                });
        },

        resetField(key, parent) {
            const natures = this.getFaultNatures(this.form[parent].value);
            if (natures.length === 1) {
                this.form[key].value = natures[0].key;
            } else {
                this.form[key].value = null;
            }
        },

        getFaultNatures(faultCategory) {
            let values = [];
            switch (faultCategory) {
                case 'Sérült':
                    values.push({key: 'Törött', text: this.$t('components.fault-report.broken')});
                    values.push({key: 'Karcos', text: this.$t('components.fault-report.scratched')});
                    values.push({key: 'Leesett alkatrész', text: this.$t('components.fault-report.removedPart')});
                    break;
                case 'Funkciónális probléma':
                    values.push({key: 'Motor', text: this.$t('components.fault-report.engine')});
                    values.push({key: 'Vezérlő', text: this.$t('components.fault-report.controller')});
                    values.push({key: 'Vízkezelő', text: this.$t('components.fault-report.waterTreatment')});
                    values.push({key: 'Kezelő', text: this.$t('components.fault-report.controlPanel')});
                    values.push({key: 'WiFi, Bluetooth, ...', text: this.$t('components.fault-report.wifiBt')});
                    break;
                case 'Folyás':
                    values.push({key: 'Toldó', text: this.$t('components.fault-report.piping')});
                    values.push({key: 'Jet', text: this.$t('components.fault-report.jet')});
                    values.push({key: 'Szűrőház', text: this.$t('components.fault-report.filterHouse')});
                    values.push({key: 'Vízesés', text: this.$t('components.fault-report.waterfall')});
                    values.push({key: 'Beszívó', text: this.$t('components.fault-report.suction')});
                    values.push({key: 'Egyéb (nem megállapítható)', text: this.$t('components.fault-report.unknown')});
                    break;
                case 'Hiányzó alkatrész':
                case 'Egyéb':
                    values.push({key: 'Nincs', text: this.$t('components.fault-report.none')});
                    break;
            }

            return values;
        },

        addLevel() {
            Object.keys(this.form).forEach(key => {
                if (key.toString().endsWith(`l${this.currentLevel + 1}`)) {
                    this.form[key].disabled = false;
                }
            });
            this.currentLevel++;
        },

        clearLevel() {
            Object.keys(this.form).forEach(key => {
                if (key.toString().endsWith(`l${this.currentLevel}`)) {
                    this.form[key].disabled = true;
                    if (this.form[key].value instanceof Array) {
                        this.form[key].value = [];
                    } else {
                        this.form[key].value = null;
                    }
                }
            });
            this.currentLevel--;
        },

        selectFile(target) {
            this.$refs['inputFile'].dispatchEvent(new MouseEvent("click"));
            this.fileContainerTarget = target;
        },

        addFile(e) {
            const file = e.target.files.item(0);
            if (file.size > this.maxFileSize) {
                this.resultDialog.text = this.$t('components.fault-report.invalidFileSize', {num: this.$options.filters.niceBytesSize(this.maxFileSize)});
                this.resultDialog.value = true;
                return;
            }

            let ext = file.name.substr(file.name.lastIndexOf('.') + 1, file.name.length);
            if (!this.allowedExtensions.includes(ext.toLocaleLowerCase())) {
                this.resultDialog.text = this.$t('components.fault-report.invalidFileType', {extensions: this.acceptExtensions});
                this.resultDialog.value = true;
                return;
            }

            if (this.form[this.fileContainerTarget].value.length + 1 > this.maxAttachments) {
                this.resultDialog.text = this.$t('components.fault-report.errorMaxAttachments');
                this.resultDialog.value = true;
                return;
            }

            this.form[this.fileContainerTarget].value.push({
                file: file,
                uploaded: false,
                container: this.fileContainerTarget
            });

            this.fileContainerTarget = null;
        },

        removeAttachment(container, index) {
            this.form[container].value.splice(index, 1);
        },

        selectProduct(data) {
            this.form.product_invoice_no.value = data.invoice_no;
            this.form.product_order_no.value = data.order_no;
            this.form.product_serial.value = data.serial_no;
            this.form.product_dom.value = data.manufacture_date;
            this.form.product_name.value = data.catalog_desc;
            this.productFinderDialog = false;
        },

        async startUpload(reportId) {

            this.phase = 'fileUploading';

            this.files = [
                ...this.form.fault_attachments_l1.value,
                ...this.form.fault_attachments_l2.value,
                ...this.form.fault_attachments_l3.value,
            ];

            this.form.fault_attachments_l1.value = [];
            this.form.fault_attachments_l2.value = [];
            this.form.fault_attachments_l3.value = [];


            for (const item of this.files) {
                let index = this.files.indexOf(item);

                let formData = new FormData();
                formData.append('report_id', reportId);
                formData.append('file', item.file);
                formData.append('is_last', (index + 1) === this.files.length);
                formData.append('container', item.container);

                await BackendService.upload('fault-report/upload', formData)
                    .then(() => {
                        this.files[index].uploaded = true;
                    });
            }

            this.phase = 'completed';
        },

        trimDesc(level) {
            if (this.form[`fault_desc_l${level}`].value !== null && this.form[`fault_desc_l${level}`].value.length > this.form[`fault_desc_l${level}`].maxLength) {
                this.$nextTick(() => {
                    this.$set(this.form[`fault_desc_l${level}`], 'value', this.form[`fault_desc_l${level}`].value.substr(0, this.form[`fault_desc_l${level}`].maxLength));
                });
            }
        },

        restart() {
            location.reload();
        }
    },

    computed: {
        acceptExtensions() {
            let accept = '';
            this.allowedExtensions.forEach(ext => {
                if (accept.length > 0) {
                    accept += ', ';
                }
                accept += `.${ext}`;
            });

            return accept;
        },

        progress() {
            return (uploaded, fileSize) => {
                return Math.floor((uploaded * 100) / fileSize);
            }
        },

        len() {
            return value => {
                return value === null ? 0 : value.length;
            }
        },

        productCategories() {
            return [
                {value: 'Szauna', text: this.$t('components.fault-report.productCategories.sauna')},
                {value: 'Jakuzzi és kiegészítő', text: this.$t('components.fault-report.productCategories.jacuzziOrAccessory')},
                {value: 'Szaniter', text: this.$t('components.fault-report.productCategories.sanitary')},
                {value: 'Kiegészítők, alkatrészek', text: this.$t('components.fault-report.productCategories.parts')},
                {value: 'Javítás', text: this.$t('components.fault-report.productCategories.repair')},
                {value: 'Telepítés', text: this.$t('components.fault-report.productCategories.install')},
                {value: 'Kérdés', text: this.$t('components.fault-report.productCategories.question')},
                {value: 'Szezonális szervíz', text: this.$t('components.fault-report.productCategories.seasonal')},
            ]
        },

        issueOccurs() {
            return [
                {value: 'Beüzemelésnél (DOA)', text: this.$t('components.fault-report.issues.doa')},
                {value: 'Garanciális (DAP)', text: this.$t('components.fault-report.issues.dap')},
                {value: 'Garancián túl', text: this.$t('components.fault-report.issues.outOfWarranty')}
            ]
        },

        issueTypes() {
            return [
                {value: 'Technikai bejelentés', text: this.$t('components.fault-report.issues.technical')},
                {value: 'Dokumentum', text: this.$t('components.fault-report.issues.document')},
                {value: 'Alkatrész igény', text: this.$t('components.fault-report.issues.partsEnquiry')},
                {value: 'Technikai támogatás', text: this.$t('components.fault-report.issues.requestSupport')}
            ]
        },

        faultCategories() {
            return [
                {value: 'Sérült', text: this.$t('components.fault-report.faultCategories.damaged')},
                {value: 'Folyás', text: this.$t('components.fault-report.faultCategories.leakage')},
                {value: 'Hiányzó alkatrész', text: this.$t('components.fault-report.faultCategories.missingPart')},
                {value: 'Funkciónális probléma', text: this.$t('components.fault-report.faultCategories.functional')},
                {value: 'Egyéb', text: this.$t('components.fault-report.faultCategories.other')}
            ]
        }
    },
}
</script>

<style scoped>

@media screen and (min-width: 760px) {
    .position-center {
        margin: 0 auto;
        width: 650px;
    }
}
</style>
