<template>
    <v-container fluid>
        <v-container fluid class="mt-10" v-if="loadingOverlay">
            <v-progress-linear indeterminate
                               color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container fluid v-else>
            <h2 class="mb-3">{{$t('components.products.title')}}</h2>
            <v-row v-if="result.products.length > 0">
                <v-col cols="12" xl="3" lg="12" md="12" sm="12" v-for="(product) in result.products" :key="product.catalogNo+$store.getters['langStore/current']">
                    <ProductCard :product="product" card/>
                </v-col>
            </v-row>
            <p v-else>{{$t('components.products.offers.notfound')}}</p>
        </v-container>
    </v-container>
</template>

<script>
import {EVENT_LANG_CHANGE, EVENT_PAGE_TITLE_CHANGE, EVENT_SEARCH} from "../../../core/constants";
import ProductApiService from "../../../api/product.api";
import {empty} from "../../../helpers";
import ProductCard from "../product/ProductCard";

export default {
    name: "SearchView",
    components: {ProductCard},
    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE, 'components.search.title'), 100);

        if (!empty(this.$route.query.search)) {
            this.clear().then(() => this.search(this.$route.query.search));
        } else {
            return this.$router.push({name: 'app.start'});
        }

        this.$eventBus.$on(EVENT_SEARCH, (value) => {
            this.clear().then(() => {
                this.$router.push({query: {search: value}});
                this.search(value);
            });
        });

        this.$eventBus.$on(EVENT_LANG_CHANGE, () => {
            this.clear().then(() => this.search(this.$route.query.search));
        });
    },

    destroyed() {
        this.$eventBus.$off(EVENT_SEARCH);
        this.$eventBus.$off(EVENT_LANG_CHANGE);
    },

    data() {
        return {
            loadingOverlay: true,

            result: {
                products: [],
            }
        }
    },

    methods: {
        clear() {
            return new Promise(resolve => {
                this.result.products = [];
                resolve();
            });
        },

        search(value) {
            this.loadingOverlay = true;
            ProductApiService.search(value)
                .then(response => this.result.products = response.data.result)
                .finally(() => this.loadingOverlay = false);
        },
    }
}
</script>

<style scoped>

</style>
