import AuthenticationGateway from "../api/auth.api";
import JwtService from "../core/backend/jwt.service";
import BackendService from "../core/backend/backend.service";
import {BACKEND_SERVER} from "@/core/config";
import AccountApiService from "../api/account.api";
import EventBus from "../core/event";
import {EVENT_TOKEN_REFRESHED} from "@/core/constants";

/**
 * Actions
 */
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const CHECK = 'checkAuthentication';
export const REFRESH_TOKEN = 'refreshToken';
export const SET_ACCOUNT_INFO_ATTR = 'setAccountInfoAttributes';
export const LOGIN_AS_LITE_USER = 'loginAsLiteUser';

/**
 * Mutations
 */
export const FINALIZE = 'finalizeAuthentication';
export const PURGE = 'purgeAuthentication';
export const UPDATE_TOKEN = 'updateToken';
export const UPDATE_ACCOUNT_INFO = 'updateAccountInfo';

export default {
    namespaced: true,

    state: {
        accountInfo: null,
        realm: null,
        canChangeRealm: false,
        appSettings: {},
        url: {},

        isLiteUser: false,
        liteUser: {},
    },

    getters: {
        account(state) {
            return state.accountInfo;
        },

        check(state) {
            return state.accountInfo !== null;
        },

        hasRealm(state) {
            return state.realm !== null;
        },

        currentRealm(state) {
            return state.realm;
        },

        canChangeRealm(state) {
            return state.canChangeRealm;
        },

        fromUSA(state) {
            return state.realm === 'wellis_usa_realm';
        },

        isAdmin(state) {
            if (!state.accountInfo) {
                return false;
            }
            return state.accountInfo.administrator || false;
        },

        appSettings(state) {
            return state.appSettings;
        },

        tokenExpiration(state) {
            return state.accountInfo ? state.accountInfo.tokenExpiration : null;
        },

        getFullname(state) {
            return state.accountInfo ? state.accountInfo.firstname + ' ' + state.accountInfo.lastname : null;
        },

        getCompanyName(state) {
            return state.accountInfo ? state.accountInfo.company : null;
        },

        getUrls(state) {
            return state.url;
        },

        getUsername(state) {
            return state.accountInfo.username;
        },

        isLiteUser(state) {
            return state.isLiteUser;
        },

        getLiteUser(state) {
            return state.liteUser;
        },

        getLiteUserPermission(state) {
            if(!state.liteUser) {
                return [];
            }
            return state.liteUser.permissions || [];
        },

        getCustomerGroup(state) {
            return state.accountInfo.customerGroup;
        }
    },

    actions: {
        [LOGIN](context, credentials) {
            return new Promise((resolve, reject) => {
                AuthenticationGateway.attempt(credentials.username, credentials.password)
                    .then(response => {
                        context.commit(FINALIZE, {
                            firstname: response.data.userData.firstname,
                            lastname: response.data.userData.lastname,
                            company: response.data.userData.company,
                            administrator: response.data.userData.administrator,
                            token: response.data.token,
                            tokenExpiration: response.data.tokenExpiration,
                            realm: response.data.realm,
                            canChangeRealm: response.data.canChangeRealm,
                            appSettings: response.data.appSettings,
                            url: response.data.url,
                            username: response.data.userData.username,
                            customerNo: response.data.userData.customerNo,
                            id: response.data.userData.id,
                            isLiteUser: response.data.userData.isLiteUser,
                            liteUser: response.data.userData.liteUser,
                            customerGroup: response.data.userData.customerGroup
                        });
                        resolve(response);
                    })
                    .catch(error => {
                        context.commit(PURGE);
                        reject(error);
                    });
            })
        },

        [LOGIN_AS_LITE_USER](context, credentials) {
            return new Promise((resolve, reject) => {
                AuthenticationGateway.loginAsLiteUser(credentials.customerNo, credentials.username, credentials.password)
                    .then(response => {
                        context.commit(FINALIZE, {
                            firstname: response.data.userData.firstname,
                            lastname: response.data.userData.lastname,
                            company: response.data.userData.company,
                            administrator: response.data.userData.administrator,
                            token: response.data.token,
                            tokenExpiration: response.data.tokenExpiration,
                            realm: response.data.realm,
                            canChangeRealm: response.data.canChangeRealm,
                            appSettings: response.data.appSettings,
                            url: response.data.url,
                            username: response.data.userData.username,
                            customerNo: response.data.userData.customerNo,
                            id: response.data.userData.id,
                            isLiteUser: response.data.userData.isLiteUser,
                            liteUser: response.data.userData.liteUser,
                            customerGroup: response.data.userData.customerGroup
                        });
                        resolve(response);
                    })
                    .catch(error => {
                        context.commit(PURGE);
                        reject(error);
                    });
            })
        },

        [LOGOUT](context) {
            return new Promise(resolve => {
                context.commit(PURGE);
                resolve();
            });
        },

        [CHECK](context) {
            return new Promise((resolve, reject) => {
                if (!JwtService.hasToken()) {
                    reject('TOKEN_MISMATCH');
                    context.commit(PURGE);
                } else {
                    BackendService.setAuthHeader();
                    AccountApiService.get()
                        .then(response => {
                            context.commit(FINALIZE, {
                                firstname: response.data.userData.firstname,
                                lastname: response.data.userData.lastname,
                                company: response.data.userData.company,
                                administrator: response.data.userData.administrator,
                                token: response.data.token,
                                tokenExpiration: response.data.tokenExpiration,
                                realm: response.data.realm,
                                canChangeRealm: response.data.canChangeRealm,
                                appSettings: response.data.appSettings,
                                url: response.data.url,
                                username: response.data.userData.username,
                                customerNo: response.data.userData.customerNo,
                                id: response.data.userData.id,
                                isLiteUser: response.data.userData.isLiteUser,
                                liteUser: response.data.userData.liteUser,
                                customerGroup: response.data.userData.customerGroup
                            });

                            context.dispatch('cartStore/updateCount', response.data.cart.count, {
                                root: true
                            });
                            resolve(response);
                        })
                        .catch(error => {
                            if (error.response) {
                                if (error.response.code !== 'ECONNABORTED') {
                                    context.commit(PURGE);
                                    reject(error);
                                }
                                return false;
                            }
                        });
                }
            });
        },

        [REFRESH_TOKEN](context, jwt) {
            return new Promise(resolve => {
                context.commit(UPDATE_TOKEN, jwt);
                resolve(jwt);
            });
        },

        [SET_ACCOUNT_INFO_ATTR](context, attributes) {
            return new Promise(resolve => {
                context.commit(UPDATE_ACCOUNT_INFO, attributes);
                resolve();
            })
        }
    },

    mutations: {
        [FINALIZE](state, data) {
            state.accountInfo = {
                firstname: data.firstname,
                lastname: data.lastname,
                company: data.company,
                administrator: data.administrator,
                tokenExpiration: data.tokenExpiration,
                username: data.username,
                userId: data.id,
                customerNo: data.customerNo,
                customerGroup: data.customerGroup
            };
            state.realm = data.realm;
            state.canChangeRealm = data.canChangeRealm;
            state.appSettings = data.appSettings;
            state.url = data.url;

            state.isLiteUser = data.isLiteUser;
            state.liteUser = data.liteUser;

            JwtService.saveToken(data.token);
            BackendService.setHeader(BACKEND_SERVER.headers.auth, 'Bearer ' + data.token);
        },

        [PURGE](state) {
            state.accountInfo = null;
            JwtService.removeToken();
            BackendService.removeHeader(BACKEND_SERVER.headers.auth);
        },

        [UPDATE_TOKEN](state, jwt) {
            if (state.accountInfo) {
                JwtService.saveToken(jwt.token);
                BackendService.setHeader(BACKEND_SERVER.headers.auth, 'Bearer ' + jwt.token);
                state.accountInfo.token = jwt.expiration;
                EventBus.$emit(EVENT_TOKEN_REFRESHED, jwt);
            }
        },

        [UPDATE_ACCOUNT_INFO](state, attributes) {
            if(state.accountInfo) {
                for (const [key, value] of Object.entries(attributes)) {
                    if(typeof state.accountInfo[key] !== 'undefined') {
                        state.accountInfo[key] = value;
                    }
                }
            }
        }
    }
};
