import Vue from 'vue';
import VueLocalStorage from "vue-localstorage";

Vue.use(VueLocalStorage);

const APP_SETTINGS_KEY = 'app:appSettings@data';

export default {
    namespaced: true,

    state: {
        gridView: true,
        drawerState: true,
    },

    getters: {
        isGridView(state) {
            return state.gridView;
        },

        drawerState(state) {
            return state.drawerState;
        }
    },

    actions: {

        init(context) {
            let settings = JSON.parse(Vue.localStorage.get(APP_SETTINGS_KEY, null));
            context.commit('bootstrap', settings);
            if (!settings) {
                context.commit('write');
            }
        },

        enableGridView(context) {
            return new Promise(resolve => {
                context.commit('setGridView', true);
                context.commit('write');
                resolve();
            });
        },

        disableGridView(context) {
            return new Promise(resolve => {
                context.commit('setGridView', false);
                context.commit('write');
                resolve();
            });
        },

        toggleGridView(context) {
            return new Promise(resolve => {
                context.commit('setGridView', !context.state.gridView);
                context.commit('write');
                resolve();
            });
        },

        toggleDrawerState(context) {
            return new Promise(resolve => {
                context.commit('setDrawerState', !context.state.drawerState);
                context.commit('write');
                resolve();
            });
        }
    },

    mutations: {
        bootstrap(state, settings = null) {
            if (settings !== null) {
                state.gridView = settings.gridView;
                state.drawerState = settings.drawerState;
            }
        },

        setGridView(state, value) {
            state.gridView = value;
        },

        setDrawerState(state, value) {
            state.drawerState = value;
        },

        write(state) {
            Vue.localStorage.set(APP_SETTINGS_KEY, JSON.stringify(state));
        }
    }
}
