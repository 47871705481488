<template>
    <v-container fluid>
        <v-row class="mb-15">
            <v-col cols="12" md="12" sm="12">
                <v-overlay :value="loadingOverlay" color="white">
                    <v-progress-circular
                        color="wellis"
                        indeterminate
                        size="64"
                    ></v-progress-circular>
                </v-overlay>
                <v-card color="transparent2">
                    <v-card-text>
                        <v-simple-table>
                            <thead>
                            <tr>
                                <th>{{$t('components.my-invoices.invoiceNo') | capitalize}}</th>
                                <th>{{$t('v3.components.my-orders.orderNo') | capitalize}}</th>
                                <th>{{$t('components.administration.category.status') | capitalize}}</th>
                                <th>{{$t('components.my-orders.date') | capitalize}}</th>
                                <th>{{$t('components.my-invoices.grossAmount') | capitalize}}</th>
                                <th>{{$t('components.my-invoices.remainingAmount') | capitalize}}</th>
                                <th>{{$t('components.my-invoices.typeInvoice')}}</th>
                                <th>{{$t('components.my-invoices.typeCmr')}}</th>
                                <th>{{$t('components.my-invoices.typeDeliveryNote')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in items" :key="item.invoice_no">
                                <td class="wellis--text font-weight-bold">{{item.invoice_no}}</td>
                                <td>
                                    <v-btn text @click="redirectToOrder(item.creators_reference)">

                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-on="on" v-bind="attrs">{{item.creators_reference | cut(15)}}</span>
                                            </template>
                                            <span>{{item.creators_reference}}</span>
                                        </v-tooltip>
                                    </v-btn>
                                </td>
                                <td>
                                    <v-chip small v-if="item.remaining !== 0">{{$t(`components.my-invoices.status.unpaid`)}}</v-chip>
                                    <v-chip small v-else color="wellis-text-white">{{$t(`components.my-invoices.status.paid`)}}</v-chip>
                                </td>
                                <td>{{item.invoice_date | formatDate}}</td>
                                <td>{{item.gross_amount | toPrice(item.currency, false)}}</td>
                                <td class="font-weight-bold wellis--text">{{item.remaining | toPrice(item.currency, false)}}</td>
                                <td>
                                    <v-btn small text :color="item.invoice === null ? '' : 'wellis'" :disabled="item.invoice === null"
                                           @click="downloadDocument(item.invoice)">
                                        <v-icon>mdi-download</v-icon>
                                    </v-btn>
                                </td>
                                <td>
                                    <v-btn small text :color="item.cmr === null ? '' : 'wellis'" :disabled="item.cmr === null"
                                           @click="downloadDocument(item.cmr)">
                                        <v-icon>mdi-download</v-icon>
                                    </v-btn>
                                </td>
                                <td>
                                    <v-btn small text :color="item.szall === null ? '' : 'wellis'" :disabled="item.szall === null"
                                           @click="downloadDocument(item.szall)">
                                        <v-icon>mdi-download</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-bottom-navigation class="mt-10" fixed style="z-index: 0" grow>
            <v-btn icon @click="filterDialog = true">
                <span>{{$t('components.stocklist.filters')}}</span>
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn @click="onClickBtnHasMore" :disabled="!hasMore">
                <span class="wellis--text">{{$t('components.products.category.btnShowMore')}}</span>
                <v-icon>mdi-page-next-outline</v-icon>
            </v-btn>
        </v-bottom-navigation>
        <!-- FILTER Dialog -->
        <v-dialog v-model="filterDialog"
                  @click:outside="filterDialog = false"
                  width="600">
            <v-card>
                <v-card-title>{{$t('components.stocklist.filters')}}</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row class="mt-2">
                        <v-col cols="12" sm="12">
                            <v-select
                                color="wellis"
                                item-color="wellis"
                                :items="[{text: $t('components.my-orders.status.all'), value: null},
                                {text: $t(`components.my-invoices.status.unpaid`), value: 0},{text: $t(`components.my-invoices.status.paid`), value: 1}]"
                                item-text="text"
                                item-value="value"
                                :label="$t('components.administration.category.status') | capitalize"
                                v-model="filters.paid_only"></v-select>

                            <v-text-field
                                color="wellis"
                                clearable
                                :label="$t('components.my-invoices.invoiceNo') | capitalize"
                                v-model="filters.invoice_no"></v-text-field>

                            <v-text-field
                                color="wellis"
                                clearable
                                :label="$t('v3.components.my-orders.orderNo') | capitalize"
                                v-model="filters.order_no"></v-text-field>

                            <v-text-field
                                color="wellis"
                                clearable
                                :label="$t('components.my-orders.customerPo') | capitalize"
                                v-model="filters.customer_po"></v-text-field>

                            <v-menu
                                ref="menu"
                                v-model="datePickerDialog.start"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field color="wellis"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  readonly
                                                  clearable
                                                  @click:clear="() => filters.date_start = null"
                                                  :label="$t('components.my-orders.dateStart') | capitalize"
                                                  v-on:click:append="datePickerDialog.start = true"
                                                  append-icon="mdi-calendar"
                                                  :value="filters.date_start"></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="filters.date_start"
                                    color="wellis"
                                    :first-day-of-week="1"
                                    :locale="$store.getters['langStore/current']"
                                ></v-date-picker>
                            </v-menu>

                            <v-menu
                                ref="menu"
                                v-model="datePickerDialog.end"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field color="wellis"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  readonly
                                                  clearable
                                                  @click:clear="() => filters.date_end = null"
                                                  :label="$t('components.my-orders.dateEnd') | capitalize"
                                                  v-on:click:append="datePickerDialog.end = true"
                                                  append-icon="mdi-calendar"
                                                  :value="filters.date_end"></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="filters.date_end"
                                    color="wellis"
                                    :first-day-of-week="1"
                                    :locale="$store.getters['langStore/current']"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="wellis-text-white" block @click="fetch(true)">{{$t('components.stocklist.btnApplyFilters')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="selectOrderNoDialog"
            max-width="650px"
            @click:outside="selectOrderNoDialog = false">
            <v-card>
                <v-card-title>{{$t('please-select')}}</v-card-title>
                <v-card-text>
                    <div v-if="selectOrderNoData !== null">
                        <v-simple-table>
                            <tbody>
                            <tr v-for="item in selectOrderNoData" :key="item">
                                <td>
                                    <v-btn text block color="wellis" :to="{name: 'app.account.orders.details', params: {orderNo: item}}">
                                        {{item}}
                                    </v-btn>
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>

import {EVENT_LANG_CHANGE, EVENT_PAGE_TITLE_CHANGE} from "../../../core/constants";
import AccountApiService from "../../../api/account.api";
import {__clone, handleDownloadedFile} from "../../../helpers";
import MediaApiService from "../../../api/media.api";

export default {
    name: "MyInvoicesView",

    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE, 'components.my-invoices.title'), 100);

        if(!this.hasLiteUserPermission('my_invoices')) {
            this.$ApplGeneral({
                content: this.$t('v32.liteUser.hasNoPermission'),
                onClose: function() {
                    this.$router.push({
                        name: 'app.start'
                    })
                }
            });
            return;
        }

        if (Object.keys(this.$route.query).length > 0) {
            this.resolveFiltersFromQuery();
        }

        this.$eventBus.$on(EVENT_LANG_CHANGE, () => {
            if (this.items.length > 0) {
                this.fetch(true);
            }
        });
    },

    destroyed() {
        this.$eventBus.$off(EVENT_LANG_CHANGE);
    },

    data() {
        return {
            loadingOverlay: false,

            filterDialog: true,
            datePickerDialog: {
                start: false,
                end: false,
            },

            items: [],

            filters: {
                paid_only: null,
                invoice_no: null,
                order_no: null,
                customer_po: null,
                date_start: null,
                date_end: null,
            },
            next: null,
            prev: null,
            hasMore: false,

            selectOrderNoDialog: false,
            selectOrderNoData: null,
        }
    },

    methods: {
        hasLiteUserPermission(permission) {
            if (!this.$store.getters['authStore/isLiteUser']) {
                return true;
            }
            let permission_ = this.$store.getters['authStore/getLiteUserPermission'].find(x => x.permission === permission);
            return permission_.objstate === 'ACTIVE';
        },

        fetch(clear = false) {
            if(this.hasLiteUserPermission('my_invoices')) {
                if (clear) {
                    this.$set(this, 'items', []);
                    this.filters.offset = null;
                }

                this.filtersToQuery();

                return new Promise((resolve, reject) => {
                    this.loadingOverlay = true;
                    this.filterDialog = false;
                    AccountApiService.invoices.all(this.filters)
                        .then(response => {
                            this.items = this.items.concat(response.data.items);
                            this.next = response.data.next;
                            this.prev = response.data.prev;
                            this.hasMore = response.data.hasMore;
                            if (clear) {
                                this.$scrollTop();
                            }
                            resolve();
                        })
                        .catch(e => {
                            this.$snackBar({
                                icon: "mdi-alert",
                                allowOutsideClick: false,
                                text: this.$t('api-error')
                            });
                            reject(e);
                        })
                        .finally(() => {
                            this.mainOverlay = false;
                            this.loadingOverlay = false;
                        });
                });
            } else {
                this.$ApplGeneral({
                    content: this.$t('v32.liteUser.hasNoPermission'),
                    onClose: function() {
                        this.$router.push({
                            name: 'app.start'
                        })
                    }
                });
            }
        },

        onClickBtnHasMore() {
            this.filters.offset = this.next;
            this.fetch();
        },

        filtersToQuery() {
            let filters = __clone(this.filters);
            this.$router.replace({query: {}});
            let activeFilters = {};
            for (const [key, value] of Object.entries(filters)) {
                if (key === 'offset') {
                    continue;
                }

                if (value !== null) {
                    activeFilters[key] = value;
                }
            }

            history.pushState({}, '', this.$route.path +
                (Object.keys(activeFilters).length > 0 ? '?' : '') +
                Object.keys(activeFilters)
                    .map(key => {
                        return (
                            encodeURIComponent(key) + '=' + encodeURIComponent(activeFilters[key])
                        )
                    })
                    .join('&'));
        },

        resolveFiltersFromQuery() {
            for (const [key, value] of Object.entries(this.$route.query)) {
                if (Object.keys(this.filters).includes(key)) {
                    if (!isNaN(value)) {
                        this.filters[key] = parseInt(value);
                    } else {
                        this.filters[key] = value;
                    }
                }
            }
        },

        downloadDocument(documentId) {
            this.loadingOverlay = true;
            MediaApiService.ifs.downloadDocument(documentId)
                .then(blobFile => {
                    return handleDownloadedFile(blobFile.data, blobFile.headers["content-disposition"].replace('attachment; filename=', ''), blobFile.type);
                })
                .catch(() => {
                    this.$snackBar({
                        icon: "mdi-alert",
                        text: this.$t('api-error')
                    });
                })
                .finally(() => {
                    this.loadingOverlay = false;
                });
        },

        redirectToOrder(orderNo_) {
            if ((orderNo_ || '').toString().includes(';')) {
                this.selectOrderNoData = orderNo_.toString().split(';');
                this.selectOrderNoDialog = true;
            } else {
                return this.$router.push({name: 'app.account.orders.details', params: {orderNo: orderNo_}});
            }
        }
    },
}
</script>

<style scoped>

</style>
