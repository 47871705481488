<template>
    <v-container fluid class="mt-10" v-if="products.length === 0">
        <v-progress-linear indeterminate
                           color="wellis"
        ></v-progress-linear>
    </v-container>
    <v-container fluid v-else>
        <v-overlay :value="loadingOverlay" absolute color="white">
            <v-progress-circular
                color="wellis"
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>

        <v-row>
            <v-col cols="12" lg="3" md="12" sm="12">
                <v-container fluid v-if="compCurrency !== 'DEFAULT'">
                    <div class="text-h4 font-weight-light">{{$t('components.cart.totalPrice') | capitalize}}</div>
                    <div class="text-h5 ml-1 mt-2 wellis--text">{{compSubTotal | toPrice(compCurrency, compHasVat)}}</div>
                </v-container>
                <v-container fluid>
                    <label>{{$t('components.products.quantity')}}</label>
                    <v-text-field v-model.number="quantity"
                                  type="number"
                                  color="wellis"
                                  full-width
                                  class="font-weight-bold"
                                  append-outer-icon="mdi-plus" @click:append-outer="incrementQuantity"
                                  prepend-icon="mdi-minus" @click:prepend="decrementQuantity">
                    </v-text-field>
                </v-container>
                <v-container fluid>
                    <v-btn block @click="addCart" v-if="isConfiguratorReady">{{$t('components.products.availability.btnAddCart')}}</v-btn>
                </v-container>
            </v-col>

            <v-col cols="12" lg="9" md="12" sm="12">
                <div v-for="(item, index) in compProducts" :key="index">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" lg="8" md="12" sm="12">
                                <h5 class="text-h5">{{item.name}}</h5>
                                <span class="wellis--text">{{item.catalogNo}}</span>
                            </v-col>
                            <v-col cols="12" lg="4" md="12" sm="12" v-if="index === 0 && host === 'product'">
                                <v-container fluid>
                                    <v-text-field type="text"
                                                  v-model="inputConfigurationId"
                                                  color="wellis"
                                                  :label="$t('components.configurator.config-placeholder')"
                                                  append-outer-icon="mdi-cog-sync-outline"
                                                  @click:append-outer="applyConfigurationId"
                                    ></v-text-field>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-container fluid>
                        <v-card v-if="hasForecast(item.partNo)">
                            <v-card-text>
                                <h6 class="text-h6">{{$t('components.checkout.wanted-delivery-date')}}</h6>
                                <small><i class="fa fa-exclamation-triangle text-wellis-gold"></i> {{$t('components.configurator.forecastTooltip')}}</small>

                                <v-list-item three-line v-for="ms in forecast" :key="ms.ms_date+'_'+ms.quantity">
                                    <v-list-item-content>
                                        <v-list-item-title>Three-line item</v-list-item-title>
                                        <v-list-item-subtitle>
                                            Secondary line text Lorem ipsum dolor sit amet,
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>
                                            consectetur adipiscing elit.
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <!--                                <v-simple-table dense>
                                                                    <template v-slot:default>
                                                                        <thead>
                                                                        <tr>
                                                                            <th>
                                                                                <v-progress-circular v-if="forecasting"
                                                                                                     indeterminate
                                                                                                     color="wellis"
                                                                                ></v-progress-circular>
                                                                            </th>
                                                                            <th>{{$t('components.my-orders.date')}}</th>
                                                                            <th>{{$t('components.stocklist.sum-quantity')}}</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        <tr v-for="ms in forecast" :key="ms.ms_date+'_'+ms.quantity">
                                                                            <td v-if="forecasting">
                                                                                <i class="fa fa-question"></i>
                                                                            </td>
                                                                            <td v-else><i :class="ms.valid === 1 ? 'fa fa-check text-wellis-gold' : 'fa fa-times'"></i></td>
                                                                            <td>{{ms.ms_date}}</td>
                                                                            <td>{{ms.qty}} {{item.unit}}</td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </template>
                                                                </v-simple-table>-->
                            </v-card-text>
                        </v-card>
                        <v-simple-table dense fixed-header>
                            <template v-slot:default>
                                <thead>
                                <th>{{$t('components.configurator.state')}}</th>
                                <th v-if="hasPrice(item) && item.price.discount > 0 && item.price.vat">{{$t('components.configurator.factoryNet')}}</th>
                                <th v-if="hasPrice(item) && item.price.discount > 0">{{$t('components.configurator.factory')}}</th>
                                <th v-if="hasPrice(item) && item.price.vat">{{$t('components.configurator.unitPriceNet')}}</th>
                                <th v-if="hasPrice(item)">{{$t('components.configurator.unitPrice')}}</th>
                                </thead>
                                <tbody>
                                <tr class="text-center">
                                    <td>
                                        <v-icon color="wellis" v-if="isConfigurationReady(Object.values(item.configuration))">mdi-check</v-icon>
                                        <v-icon color="wellis" v-else>mdi-pencil</v-icon>
                                    </td>
                                    <td v-if="hasPrice(item) && item.price.discount > 0 && item.price.vat">
                                        {{item.price.factoryPrice * quantity | toPrice(item.price.currency, false)}} + {{$t('components.products.vat')}}
                                    </td>
                                    <td v-if="hasPrice(item) && item.price.discount > 0">
                                        {{item.price.factoryPrice * quantity | toPrice(item.price.currency, item.price.vat)}}
                                    </td>
                                    <td v-if=" hasPrice(item) && item.price.vat">{{item.price.unitPrice * quantity | toPrice(item.price.currency, false)}} +
                                        {{$t('components.products.vat')}}
                                    </td>
                                    <td v-if="hasPrice(item)">{{item.price.unitPrice * quantity | toPrice(item.price.currency, item.price.vat)}}</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-container>
                    <v-divider></v-divider>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" lg="4" md="12" sm="12" v-for="(confItem, confIndex) in item.configuration" :key="confIndex">
                                <div>
                                    <v-select
                                        :readonly="confItem.readOnly"
                                        autocomplete="off"
                                        :name="confItem.characteristicId"
                                        :value="confItem.selectedValue"
                                        v-on:change="triggerConfigurationUpdate($event, item.catalogNo, item.partNo, confItem.characteristicId)"
                                        :items="confItem.values"
                                        item-value="optionValueId"
                                        item-text="description"
                                        :placeholder="$t('please-select')"
                                        :prepend-icon="confItem.readOnly ? 'mdi-lock-outline' : 'mdi-lock-open-variant-outline'"
                                        color="wellis"
                                        item-color="wellis"
                                    >
                                        <template v-slot:label>
                                            <div v-if="!confItem.readOnly">
                                                <div class="wellis--text" v-if="confItem.selectedValue !== null">{{confItem.description}}</div>
                                                <div class="preorder" v-else>{{confItem.description}}</div>
                                            </div>
                                            <div v-else>{{confItem.description}}</div>
                                        </template>
                                    </v-select>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-divider class="my-3" v-if="index !== compProducts.length - 1"></v-divider>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {EVENT_CART_COUNT_UPDATE, EVENT_LANG_CHANGE, EVENT_PAGE_TITLE_CHANGE} from "../../../core/constants";
import {CONFIGURATOR} from "../../../core/config";
import {__clone, empty, LiteUser} from '../../../helpers';
import StocklistApiService from "../../../api/stocklist.api";
import ShopOrdApiService from "../../../api/shopord.api";
import ProductApiService from "../../../api/product.api";
import CheckoutApiService from "../../../api/checkout.api";
import ConfiguratorApiService from "../../../api/configurator.api.service";
import CartApiService from "../../../api/cart.api";

export default {
    name: "ConfiguratorView",

    mounted() {
        if (CONFIGURATOR.VALID_HOSTS.indexOf(this.$route.params.host) === -1) {
            this.triggerInvalidComponentError();
        }

        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE,
            'components.configurator.title',
            this.$route.params.productIdentifier
        ), 100);

        this.$eventBus.$on(EVENT_LANG_CHANGE, () => {
            location.reload();
        });

        this.bootstrap();
    },

    destroyed() {
        this.$eventBus.$off(EVENT_LANG_CHANGE);
    },

    data() {
        return {
            loadingOverlay: false,
            baseProduct: null,
            products: [],
            quantity: 1,
            maxQuantity: 99,
            offers: {
                stocklist: [],
                shopord: [],
                acrylicColor: null
            },
            forecast: [],
            inputConfigurationId: null,
            forecastRequest: null,
        }
    },

    methods: {

        triggerInvalidComponentError() {
            this.$snackBar({
                content: this.$t('components.configurator.invalid'),
                icon: 'mdi-alert',
                timeout: 1500
            });
            return this.$router.push({name: 'app.start'});
        },

        bootstrap() {
            this.$set(this, 'baseProduct', this.$route.params.productIdentifier);
            this.$set(this, 'products', []);
            this.$set(this, 'quantity', 1);
            this.$set(this, 'inputConfigurationId', null);

            switch (this.host) {
                case 'STOCK_FIFO': {
                    let split = this.baseProduct.split('&');
                    let catalogNo = split[0];
                    let configNo = split[1];
                    let warehouse = split[2];
                    StocklistApiService.configurationDetails(catalogNo, configNo, warehouse, {
                        "with_media": true,
                        "with_configuration": true,
                        "with_related": true,
                        "is_configurator": true
                    }).catch(() => this.triggerInvalidComponentError())
                        .then(response => {
                            let baseProduct = __clone(response.data.product);
                            baseProduct.relatedProducts = null;
                            this.products.push(baseProduct);
                            this.maxQuantity = baseProduct.quantity;
                            if (response.data.product.relatedProducts.required.length > 0) {
                                response.data.product.relatedProducts.required.forEach(item => {
                                    if (item.configurable) {
                                        this.products.push(item);
                                    }
                                });
                            }
                            this.products.reverse();
                            this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE,
                                'components.configurator.title',
                                this.products[0].name
                            )
                        });
                    break;
                }

                case 'STOCK_ETA': {
                    StocklistApiService.transitDetails(this.baseProduct, {
                        "with_media": true,
                        "with_configuration": true,
                        "with_related": true,
                        "is_configurator": true
                    }).catch(() => this.triggerInvalidComponentError())
                        .then(response => {
                            let baseProduct = __clone(response.data.product);
                            baseProduct.relatedProducts = null;
                            this.products.push(baseProduct);
                            this.maxQuantity = baseProduct.quantity;
                            if (response.data.product.relatedProducts.required.length > 0) {
                                response.data.product.relatedProducts.required.forEach(item => {
                                    if (item.configurable) {
                                        this.products.push(item);
                                    }
                                });
                            }
                            this.products.reverse();
                            this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE,
                                'components.configurator.title',
                                this.products[0].name
                            )
                        });
                    break;
                }

                case 'SHOPORD': {
                    ShopOrdApiService.getDetails(this.baseProduct, {
                        "with_media": true,
                        "with_configuration": true,
                        "with_related": true,
                        "is_configurator": true
                    }).catch(() => this.triggerInvalidComponentError())
                        .then(response => {
                            let baseProduct = __clone(response.data.product);
                            baseProduct.relatedProducts = null;
                            this.products.push(baseProduct);
                            this.maxQuantity = baseProduct.quantity;
                            if (response.data.product.relatedProducts.required.length > 0) {
                                response.data.product.relatedProducts.required.forEach(item => {
                                    if (item.configurable) {
                                        this.products.push(item);
                                    }
                                });
                            }
                            this.products.reverse();
                            this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE,
                                'components.configurator.title',
                                this.products[0].name
                            )
                        });
                    break;
                }

                case 'PRODUCT':
                default: {
                    ProductApiService.getProduct(this.baseProduct, {
                        "with_related": true,
                        "with_configuration": true,
                    }).catch(() => this.triggerInvalidComponentError())
                        .then(response => {
                            let baseProduct = __clone(response.data.product);
                            baseProduct.relatedProducts = null;
                            this.products.push(baseProduct);
                            if (response.data.product.relatedProducts.required.length > 0) {
                                response.data.product.relatedProducts.required.forEach(item => {
                                    if (item.configurable) {
                                        this.products.push(item);
                                    }
                                });
                            }
                            this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE,
                                'components.configurator.title',
                                this.products[0].name
                            )
                            this.fetchOffers(false);
                            this.fetchForecast();
                        });
                }
            }
        },

        incrementQuantity() {
            this.quantity++;
            if (this.quantity > this.maxQuantity) {
                this.quantity = this.maxQuantity;
            }
            this.fetchForecast();
        },

        decrementQuantity() {
            this.quantity--;
            if (this.quantity < 1) {
                this.quantity = 1;
            }
            this.fetchForecast();
        },

        async triggerConfigurationUpdate(val, catalogNo, partNo, name) {
            this.loadingOverlay = true;
            let index = this.products.findIndex(item => item.catalogNo === catalogNo);

            ConfiguratorApiService.configure(catalogNo, partNo, name, val, this.products[index].configuration)
                .then(response => {
                    this.products[index].configuration = response.data.configuration;
                    this.products[index].price = response.data.price;
                    this.loadingOverlay = false;
                    if (this.host === 'product' && index === 0 && name === 'SZÍNVÁLASZTÉK') {
                        this.offers.acrylicColor = val;
                        this.fetchOffers();
                    }
                    if (this.host === 'product' && index === 0 && this.products.length > 0) {
                        this.autoConfigure();
                    }
                })
                .catch(() => {
                    this.loadingOverlay = false
                    this.$snackBar({
                        content: this.$t('api-error'),
                        icon: 'mdi-alert'
                    });
                });
        },

        autoConfigure() {
            let parent = this.products[0];
            this.compProducts.forEach(async (child, ind) => {
                if (ind > 0 && child.configurable) {
                    this.loadingOverlay = true;
                    await ConfiguratorApiService.autoConfigurator({
                        "catalogNo": parent.catalogNo,
                        "partNo": parent.partNo,
                        "configuration": parent.configuration,
                    }, {
                        "catalogNo": child.catalogNo,
                        "partNo": child.partNo,
                        "configuration": child.configuration,
                    })
                        .then(r => {
                            this.products[ind].configuration = r.data.configuration;
                            this.products[ind].price = r.data.price;
                        })
                        .finally(() => this.loadingOverlay = false);
                }
            });
        },

        addCart() {
            if (!this.isConfiguratorReady) {
                this.$snackBar({
                    content: this.$t('components.configurator.notready'),
                    icon: 'mdi-alert'
                });
                return false;
            }

            if (!LiteUser.hasPermission(
                this.$store.getters['authStore/isLiteUser'],
                this.$store.getters['authStore/getLiteUserPermission'],
                'place_order',
            )) {
                this.$ApplGeneral({
                    content: this.$t('v32.liteUser.hasNoPermission')
                });
                return;
            }

            let host = CONFIGURATOR.conversion.find(x => x.key === this.host);
            if(host) {
                host = host.value;
            } else {
                host = this.host;
            }

            this.loadingOverlay = true;
            let attributes = {
                "coordinator": "addProductConfiguration",
                "product": this.baseProduct,
                "quantity": this.quantity,
                "host": host,
                "configurations": {}
            };

            this.products.map(item => attributes.configurations[item.catalogNo] = item.configuration);
            CartApiService.add(attributes)
                .then(response => {
                    this.$eventBus.$emit(EVENT_CART_COUNT_UPDATE, response.data.count);

                    this.$snackBar({
                        content: this.$t('components.cart.add-cart-success'),
                        icon: 'mdi-check'
                    }).then(() => {
                        if (['stock-fifo', 'stock-eta', 'shopord'].indexOf(this.host) !== -1) {
                            this.$router.push({
                                name: 'app.cart'
                            });
                        } else {
                            this.bootstrap();
                        }
                    })
                })
                .catch(error => {
                    if (error.response.data.exception) {
                        if (error.response.data.exception.class === 'DifferentWarehouseException') {
                            this.$snackBar({
                                content: this.$t('components.cart.different-stocklist-warehouse'),
                                icon: 'mdi-alert'
                            });
                        }

                        if (error.response.data.exception.class === 'DifferentStocklistTypeException') {
                            this.$snackBar({
                                content: this.$t('components.cart.different-stocklist-type'),
                                icon: 'mdi-alert'
                            });
                        }
                    } else {
                        this.$snackBar({
                            content: this.$t('components.cart.add-cart-error'),
                            icon: 'mdi-alert'
                        });
                    }
                })
                .finally(() => this.loadingOverlay = false);
        },

        async fetchOffers(ask = true) {
            ConfiguratorApiService.getOffers(this.products[0].catalogNo, this.offers.acrylicColor)
                .then(response => {
                    this.offers.stocklist = Object.values(response.data.stocklist);
                    this.offers.shopord = response.data.shopord;
                    if (this.hasOffers && ask) {
                        this.$snackBar({
                            content: this.$t('components.products.offers.found'),
                            icon: 'mdi-check',
                            timeout: 5000,
                            onClick: () => document.getElementById("configurator_offers").scrollIntoView()
                        });
                    }
                });
        },

        applyConfigurationId(e) {
            e.preventDefault();
            if (!empty(this.inputConfigurationId) && !isNaN(this.inputConfigurationId)) {
                this.loadingOverlay = true;
                const configId = parseInt(this.inputConfigurationId);
                ConfiguratorApiService.autoConfigureById(this.products[0].catalogNo, this.products[0].partNo, configId)
                    .then(response => {
                        this.products[0].configuration = response.data.configuration;
                        this.products[0].price = response.data.price;
                        if (this.host === 'product' && this.products.length > 0) {
                            this.autoConfigure();
                        }
                        this.$snackBar({
                            content: this.$t('components.configurator.loaded-concrete'),
                            icon: 'mdi-check',
                            timeout: 3500
                        });
                        this.fetchOffers(true);
                    })
                    .catch(() => {
                        this.$snackBar({
                            content: this.$t('components.configurator.invalid-concrete'),
                            icon: 'mdi-alert',
                            timeout: 3500
                        });
                        this.inputConfigurationId = null;
                    })
                    .finally(() => this.loadingOverlay = false);
            }
        },

        fetchForecast() {
            if (this.host === 'PRODUCT') {
                if (this.forecastRequest) {
                    clearTimeout(this.forecastRequest);
                    this.forecastRequest = null;
                }
                this.forecastRequest = setTimeout(() => {
                    CheckoutApiService.masterScheduleDates(this.baseProduct, this.quantity)
                        .then(response => {
                            if (response.data.length > 0) {
                                this.forecast = response.data;
                            } else {
                                this.forecast = [];
                            }
                        }).finally(() => this.forecastRequest = null);
                }, 1000);
            }
        },
    },

    computed: {
        host() {
            return this.$route.params.host;
        },

        configurations() {
            return this.products.map(item => item.configuration);
        },

        compProducts() {
            return this.products;
        },

        isConfigurationReady() {
            return configuration => {
                let result = true;
                configuration.forEach(item => {
                    if (!result) {
                        return;
                    }

                    result = item.selectedValue !== null;
                });

                return result;
            }
        },

        compSubTotal() {
            let subTotal = 0;
            this.products.map(item => {
                if (item.price) {
                    subTotal += item.price.unitPrice;
                }
            });
            return subTotal * this.quantity;
        },

        compCurrency() {
            let currency = 'DEFAULT';
            this.products.forEach(item => {
                if (currency !== 'DEFAULT' && currency.length > 0) {
                    return;
                }
                if (item.price) {
                    currency = item.price.currency;
                }
            });
            return currency;
        },

        compHasVat() {
            let vat = null;
            this.products.forEach(item => {
                if (vat !== null) {
                    return;
                }

                if (item.price) {
                    vat = item.price.vat;
                }
            });
            return vat;
        },

        isConfiguratorReady() {
            if (this.compProducts.length === 0) {
                return false;
            }
            let result = true;
            this.compProducts.map(item => {
                if (!result) {
                    return;
                }
                result = this.isConfigurationReady(Object.values(item.configuration));
            })
            /*-------------------------------------------
             * Disabled by: mixed stocklist module.
             *-----------------------------------------*/
            let temp = true;
            /*if (this.host === 'PRODUCT') {
                temp = !this.baseProduct.toString().startsWith('WM')
                    || this.baseProduct.toString() === 'WM00549';
            }*/
            return result && temp;
        },

        hasPrice() {
            return item => {
                return item.price && typeof item.price !== 'undefined';
            }
        },

        offerShopOrd() {
            return this.offers.shopord;
        },

        offerStocklist() {
            return this.offers.stocklist;
        },

        hasOffers() {
            return (this.offers.shopord.length + this.offers.stocklist.length) > 0
        },

        acrylicColor() {
            return this.offers.acrylicColor;
        },

        hasForecast() {
            return partNo => {
                return this.products[0].partNo === partNo
                    && this.products[0].configurable === true
                    && this.forecast.length > 0;
            }
        },

        forecasting() {
            return this.forecastRequest !== null;
        }
    },

    watch: {
        'isConfiguratorReady': function (ready) {
            if (ready) {
                this.$scrollTop()
            }
        }
    }
}
</script>

<style scoped>

</style>
