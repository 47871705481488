import Vue from 'vue';
import VueAxios from "vue-axios";
import * as axios from "axios";
import JwtService from "./jwt.service";
import {BACKEND_SERVER} from "../config";
import store from "../../store";

const BackendService = {
    bootstrap() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = BACKEND_SERVER.url;
        Vue.axios.interceptors.response.use(function (response) {
            if (response.headers['x-app-refresh-token'] && response.headers['x-app-refresh-token-expiration']) {
                store.dispatch('authStore/refreshToken', {
                    token: response.headers['x-app-refresh-token'],
                    expiration: response.headers['x-app-refresh-token-expiration']
                });
            }
            return response;
        }, function (error) {
            return Promise.reject(error);
        });
        this.setLocalizationHeader();
    },

    setHeader(key, value) {
        Vue.axios.defaults.headers.common[key] = value;
    },

    setLocalizationHeader() {
        this.setHeader(BACKEND_SERVER.headers.lang, store.getters['langStore/current']);
    },

    setAuthHeader() {
        this.setHeader(BACKEND_SERVER.headers.auth, `Bearer ${JwtService.getToken()}`);
    },

    removeHeader(key) {
        delete Vue.axios.defaults.headers.common[key];
    },

    query(path, params) {
        return Vue.axios.get(path, {
            params: params
        });
    },

    download(path, params, options, post = false) {
        if (post) {
            return Vue.axios.post(path, params, options);
        }
        options.params = params;
        return Vue.axios.get(path, options);
    },

    upload(path, formData) {
        return Vue.axios.post(path, formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        });
    },

    get(path) {
        return Vue.axios.get(path);
    },

    post(path, params) {
        return Vue.axios.post(path, params);
    },

    update(path, params) {
        return Vue.axios.patch(path, params);
    },

    put(path, params) {
        return Vue.axios.put(path, params);
    },

    delete(path, params = null) {
        return Vue.axios.delete(path, {
            params: params
        });
    },

    formatResponse(array, tag = 'font') {
        if (!array) {
            return null;
        }
        let msg = '';
        Object.values(array).forEach(function (item) {
            msg += '<' + tag + '>' + item + '</' + tag + '>';
        });

        return msg;
    },

    checkServerReadiness() {
        return Vue.axios.get('/', {
            timeout: 7000
        });
    },

    createParameterURI(endpoint, parameters = []) {
        if(!endpoint.endsWith('/'))  {
            endpoint += '/';
        }
        parameters.forEach(param => {
            if(!endpoint.endsWith('/'))  {
                endpoint += '/';
            }

            endpoint += param;
        });

        return endpoint;
    }
};

export default BackendService
