var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.mainOverlay)?_c('v-container',{staticClass:"mt-10",attrs:{"fluid":""}},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"wellis"}})],1):_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"2","lg":"2","md":"12","sm":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Filters")]),_c('v-divider'),_c('v-card-text',[_c('v-card',{attrs:{"color":"transparent2"}},[_c('v-card-title',[_vm._v("Objstate")]),_c('v-card-text',[_c('v-select',{attrs:{"item-text":"text","item-value":"value","items":_vm.objstates,"item-color":"wellis","color":"wellis"},on:{"change":_vm.fetch},model:{value:(_vm.objstate),callback:function ($$v) {_vm.objstate=$$v},expression:"objstate"}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","xl":"10","lg":"10","md":"12","sm":"12"}},[_c('v-overlay',{attrs:{"value":_vm.loadingOverlay,"color":"white"}},[_c('v-progress-circular',{attrs:{"color":"wellis","indeterminate":"","size":"64"}})],1),_c('v-card',{attrs:{"color":"transparent2"}},[_c('v-card-text',[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th'),_c('th',{attrs:{"width":"8%"}},[_vm._v("Row ID")]),_c('th',[_vm._v("Title")]),_c('th',[_vm._v("Category")]),_c('th',[_vm._v("Author")]),_c('th',[_vm._v("Featured")]),_c('th',[_vm._v("Restriction")]),_c('th',[_vm._v("Available at")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Created at")]),_c('th',[_vm._v("Updated at")])])]),_c('tbody',_vm._l((_vm.items),function(item){return _c('tr',{key:item.id},[_c('td',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","rounded":"","icon":"","color":"wellis"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","width":"100%","color":"wellis","to":{name: 'app.admin.news.posts.edit', params: {id: item.id}}}},[_vm._v(" Open details ")])],1),_c('v-list-item',[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","width":"100%","color":"wellis"},on:{"click":function($event){return _vm.openDetailsNewTab(item.id)}}},[_vm._v(" Open details in new tab ")])],1)],1)],1)],1),_c('td',[_vm._v("#"+_vm._s(item.id))]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm._f("cut")(item.title,20)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])],1),_c('td',[_c('v-chip',{attrs:{"small":"","color":"wellis-text-white"}},[_vm._v(_vm._s(item.category_name))])],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm._f("cut")(item.author_name,10)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.author_name))])])],1),_c('td',[(item.special)?_c('v-chip',{attrs:{"color":"secondary","small":""}},[_vm._v("Featured")]):_c('v-chip',{attrs:{"small":""}},[_vm._v("No")])],1),_c('td',[(item.restricted)?_c('v-chip',{attrs:{"color":"secondary","small":""}},[_vm._v("Restricted")]):_c('v-chip',{attrs:{"color":"wellis-text-white","small":""}},[_vm._v("Public")])],1),_c('td',[(item.available_at)?_c('span',[_c('v-chip',{attrs:{"small":"","color":"wellis-text-white"}},[_vm._v(_vm._s(_vm._f("formatDate")(item.available_at,true)))])],1):_c('span',[_vm._v("-")])]),_c('td',[(item.objstate === 'ACTIVE')?_c('v-chip',{attrs:{"color":"wellis-text-white","small":""}},[_vm._v("Active")]):_c('v-chip',{attrs:{"small":""}},[_vm._v("Inactive")])],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm._f("formatDate")(item.created_at)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.created_at,true)))])])],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm._f("formatDate")(item.updated_at)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.updated_at,true)))])])],1)])}),0)])],1)],1)],1)],1)],1),_c('v-bottom-navigation',{staticStyle:{"z-index":"0"},attrs:{"fixed":"","grow":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function () { return this$1.fetch().then(function () { return this$1.$scrollTop(); }); }}},[_c('span',[_vm._v("Refresh")]),_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-btn',{attrs:{"icon":"","to":{name: 'app.admin.news.posts.create'}}},[_c('span',[_vm._v("Create new post")]),_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }