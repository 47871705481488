<template>
    <v-container fluid>
        <v-container fluid class="mt-10" v-if="mainOverlay">
            <v-progress-linear indeterminate
                               color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container fluid v-else>
            <v-row>
                <v-col cols="12" xl="2" lg="2" md="12" sm="12">
                    <v-card>
                        <v-card-title>Filters</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-card color="transparent2">
                                <v-card-title>Objstate</v-card-title>
                                <v-card-text>
                                    <v-select v-model="objstate"
                                              item-text="text"
                                              item-value="value"
                                              :items="objstates"
                                              item-color="wellis"
                                              color="wellis"
                                              @change="fetch">
                                    </v-select>
                                </v-card-text>
                            </v-card>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" xl="10" lg="10" md="12" sm="12">
                    <v-overlay :value="loadingOverlay" color="white">
                        <v-progress-circular
                            color="wellis"
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-card color="transparent2">
                        <v-card-text>
                            <v-simple-table>
                                <thead>
                                <tr>
                                    <th></th>
                                    <th width="8%">Row ID</th>
                                    <th>Title</th>
                                    <th>Category</th>
                                    <th>Author</th>
                                    <th>Featured</th>
                                    <th>Restriction</th>
                                    <th>Available at</th>
                                    <th>Status</th>
                                    <th>Created at</th>
                                    <th>Updated at</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in items" :key="item.id">
                                    <td>
                                        <v-menu offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn text
                                                       rounded
                                                       icon
                                                       color="wellis"
                                                       v-bind="attrs"
                                                       v-on="on">
                                                    <v-icon>mdi-dots-horizontal</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item>
                                                    <v-btn
                                                        class="ma-2"
                                                        outlined
                                                        width="100%"
                                                        color="wellis"
                                                        :to="{name: 'app.admin.news.posts.edit', params: {id: item.id}}">
                                                        Open details
                                                    </v-btn>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-btn
                                                        class="ma-2"
                                                        outlined
                                                        width="100%"
                                                        color="wellis"
                                                        @click="openDetailsNewTab(item.id)">
                                                        Open details in new tab
                                                    </v-btn>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </td>
                                    <td>#{{item.id}}</td>
                                    <td>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-on="on" v-bind="attrs">{{item.title | cut(20)}}</span>
                                            </template>
                                            <span>{{item.title}}</span>
                                        </v-tooltip>
                                    </td>
                                    <td>
                                        <v-chip small color="wellis-text-white">{{item.category_name}}</v-chip>
                                    </td>
                                    <td>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-on="on" v-bind="attrs">{{item.author_name | cut(10)}}</span>
                                            </template>
                                            <span>{{item.author_name}}</span>
                                        </v-tooltip>
                                    </td>
                                    <td>
                                        <v-chip v-if="item.special" color="secondary" small>Featured</v-chip>
                                        <v-chip v-else small>No</v-chip>

                                    </td>
                                    <td>
                                        <v-chip v-if="item.restricted" color="secondary" small>Restricted</v-chip>
                                        <v-chip v-else color="wellis-text-white" small>Public</v-chip>
                                    </td>
                                    <td>
                                        <span v-if="item.available_at">
                                            <v-chip small color="wellis-text-white">{{item.available_at | formatDate(true)}}</v-chip>
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <td>
                                        <v-chip v-if="item.objstate === 'ACTIVE'" color="wellis-text-white" small>Active</v-chip>
                                        <v-chip v-else small>Inactive</v-chip>
                                    </td>
                                    <td>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-on="on" v-bind="attrs">{{item.created_at | formatDate}}</span>
                                            </template>
                                            <span>{{item.created_at | formatDate(true)}}</span>
                                        </v-tooltip>
                                    </td>
                                    <td>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-on="on" v-bind="attrs">{{item.updated_at | formatDate}}</span>
                                            </template>
                                            <span>{{item.updated_at | formatDate(true)}}</span>
                                        </v-tooltip>
                                    </td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-bottom-navigation fixed style="z-index: 0" grow>
            <v-btn icon @click="() => this.fetch().then(() => this.$scrollTop())">
                <span>Refresh</span>
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn icon :to="{name: 'app.admin.news.posts.create'}">
                <span>Create new post</span>
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </v-container>
</template>

<script>
import {EVENT_PAGE_TITLE_CHANGE_NO_TRANS} from "../../../../core/constants";
import NewsApiService from "../../../../api/news.api";

export default {
    name: "AdminNewsView",
    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE_NO_TRANS, 'News', 'Posts'), 100);

        this.fetch();
    },

    data() {
        return {
            mainOverlay: true,
            loadingOverlay: true,

            objstate: 'ACTIVE',
            objstates: [{value: 'ACTIVE', text: 'Active'}, {value: 'INACTIVE', text: 'Inactive'}],
            items: []
        }
    },

    methods: {
        async fetch() {
            return new Promise((resolve, reject) => {
                this.loadingOverlay = true;
                NewsApiService.get(this.objstate, false, true)
                    .then(response => {
                        this.items = response.data.items;
                        resolve(response.data);
                    })
                    .catch(error => {
                        this.$snackBar({
                            icon: "mdi-alert",
                            text: this.$t('api-error')
                        });
                        reject(error);
                    })
                    .finally(() => {
                        this.mainOverlay = false;
                        this.loadingOverlay = false;
                    });
            });
        },

        openDetailsNewTab(newsId) {
            const route = this.$router.resolve({name: 'app.admin.news.posts.edit', params: {id: newsId}});
            window.open(route.href, '_blank');
        }
    }
}
</script>

<style scoped>

</style>
