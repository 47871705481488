import BackendService from "../core/backend/backend.service";

const NewsApiService = {
    async get(objstate, specialOnly = false, asAdmin = false) {
        let query = {
            objstate: objstate,
        };

        if (specialOnly) {
            query.special = true;
        }

        if (asAdmin) {
            query.as_administrator = true;
        }
        return BackendService.query('news', query);
    },

    async limitedGet(objstate, limit) {
        let query = {
            objstate: objstate,
            limit: limit
        };

        return BackendService.query('news', query);
    },

    async create(data) {
        return BackendService.post('news', data);
    },

    async concrete(slug, id = false) {
        if (!id) {
            return BackendService.query('news/concrete', {
                slug: slug
            });
        }
        return BackendService.query('news/concrete', {
            id: id
        });
    },

    async update(id, data) {
        return BackendService.put(`news/${id}`, data);
    },

    async categories() {
        return BackendService.get('news/categories');
    },

    async category(slug, offset) {
        return BackendService.query('news/categories/concrete', {
            target: slug,
            offset: offset
        });
    }
}

export default NewsApiService;


