<template>
    <v-container fluid class="pa-0">
        <v-list dense nav>
            <v-list-item-group color="light" v-model="current">
                <div v-for="item in items" :key="item.key">
                    <v-list-item link :exact="true" v-if="item.cond" :to="{name: item.route}" :disabled="item.disabled">
                        <v-list-item-action>
                            <v-icon>{{item.icon}}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{$t(item.title)}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </v-list-item-group>
        </v-list>
    </v-container>
</template>

<script>
export default {
    name: "Navigation",

    created() {
        this.current = this.items.findIndex(item => item.route === this.$route.name);
    },

    data() {
        return {
            current: null,
            items: [
                {
                    key: 'dashboard', title: 'components.dashboard.title', icon: 'mdi-view-dashboard', route: 'app.start',
                    cond: true,
                },
                {
                    key: 'admin', title: 'components.administration.title', icon: 'mdi-shield-account', route: 'app.admin',
                    cond: this.$store.getters['authStore/isAdmin'], disabled: false
                },
                {
                    key: 'fault-report', title: 'fault-report', icon: 'mdi-form-select', route: 'app.fault-report',
                    cond: true, disabled: !this.hasLiteUserPermission('fault_report'),
                },
                {
                    key: 'stock-mixed', title: 'components.stock-mixed.title', icon: 'mdi-sale', route: 'app.sale',
                    cond: this.$store.getters['configStore/getMixedStockModuleStatus'], disabled: !this.hasLiteUserPermission('products'),
                },
               /* {
                    key: 'stock-transit', title: 'v3.components.stocklist-transit.title',
                    icon: 'mdi-truck', route: 'app.stocklist.transit',
                    cond: this.$store.getters['authStore/fromUSA'], disabled: !this.hasLiteUserPermission('products'),
                },*/
                {
                    key: 'products', title: 'components.products.title', icon: 'mdi-shopping', route: 'app.products',
                    cond: true, disabled: !this.hasLiteUserPermission('products'),
                },
                {
                    key: 'news', title: 'components.news.title', icon: 'mdi-newspaper-variant-multiple', route: 'app.news',
                    cond: true, disabled: !this.hasLiteUserPermission('news'),
                },
                {
                    key: 'media', title: 'components.media.title', icon: 'mdi-folder-multiple-outline', route: 'app.media',
                    cond: true, disabled: !this.hasLiteUserPermission('media'),
                },
                {
                    key: 'quick-report', title: 'v3.components.quick-report.title', icon: 'mdi-file-export-outline', route: 'app.quick-report',
                    cond: true, disabled: !this.hasLiteUserPermission('quick_reports'),
                },
            ],
        };
    },

    methods: {
        hasLiteUserPermission(permission) {
            if (!this.$store.getters['authStore/isLiteUser']) {
                return true;
            }
            let permission_ = this.$store.getters['authStore/getLiteUserPermission'].find(x => x.permission === permission);
            return permission_.objstate === 'ACTIVE';
        },
    },
};
</script>

<style scoped>

</style>
