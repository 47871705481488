import BackendService from "../core/backend/backend.service";

const ProductApiService = {
    async getProducts(slug, orderBy, orderType, offset) {
        return BackendService.query('product', {
            "category": slug,
            "orderBy": orderBy,
            "orderType": orderType,
            "offset": offset
        });
    },

    async getProductsUnlimited(slug) {
        return BackendService.query('product', {
            "category": slug,
            "fetchLimit": -1
        });
    },

    async getProduct(catalogNo, query = null) {
        return BackendService.query(`product/${catalogNo}`, query);
    },

    async search(value) {
        return BackendService.query('product/search', {
            value: value
        });
    }
}

export default ProductApiService;


