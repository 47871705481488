<template>
    <v-card elevation="4" width="100%">
        <v-row class="ma-0">
            <v-col cols="12" sm="12" md="12" lg="1">
                <v-hover v-slot="{ hover }">
                    <router-link :to="resolveRouterLink()">
                        <v-img
                            :style="hover ? 'opacity: 0.7' : 'opacity: 1'"
                            class="white--text pointer"
                            :lazy-src="require('@/assets/img/placeholder_300x300.png')"
                            :src="product.product.media.thumbnailUrl">
                        </v-img>
                    </router-link>
                </v-hover>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="3">
                <v-card-title>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <router-link :to="resolveRouterLink()" class="wellis--text">
                                <span v-on="on" v-bind="attrs">{{product.name | cut(25)}}</span>
                            </router-link>
                        </template>
                        <span>{{product.name}}</span>
                    </v-tooltip>
                    <small class="ml-2 font-weight-bold grey--text"
                           v-if="isStockItem">[{{$t('components.stocklist.title') | toUpper}}]</small>
                    <small class="ml-2 font-weight-bold grey--text"
                           v-if="isShopOrdItem">
                        <span v-if="$store.getters['authStore/currentRealm'] === 'wellis_usa_realm'">
                            [{{$t('v32.components.sale.stockInEurope') | toUpper}}]
                        </span>
                        <span v-else>[{{$t('components.shopord.title') | toUpper}}]</span>
                    </small>
                </v-card-title>
                <v-card-subtitle>{{product.catalogNo}}</v-card-subtitle>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="2">
                <v-card-text v-if="!isChild">
                    <label class="font-weight-bold d-block">{{$t('components.products.quantity')}}</label>
                    <v-text-field v-model.number="quantity"
                                  type="number"
                                  color="wellis"
                                  class="font-weight-bold"
                                  append-outer-icon="mdi-plus" @click:append-outer="incrementQuantity"
                                  prepend-icon="mdi-minus" @click:prepend="decrementQuantity">
                    </v-text-field>
                </v-card-text>
                <v-card-text v-else>
                    <label class="font-weight-bold d-block">{{$t('components.products.quantity')}}</label>
                    <div class="end-price wellis--text">{{quantity}}</div>
                </v-card-text>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="3">
                <v-card-text v-if="product.price !== null">
                    <label class="font-weight-bold">{{$t('components.products.price')}}</label>
                    <p class="wellis--text end-price">{{product.price.unitPrice * product.quantity | toPrice(product.price.currency, product.price.vat)}}</p>
                    <p class="wellis--text end-price text-subtitle-1" v-if="product.price.vat">
                        {{product.price.unitPrice * product.quantity | toPrice(product.price.currency, false)}} + {{$t('components.products.vat')}}
                    </p>
                </v-card-text>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="2">
                <v-card-actions class="float-lg-right">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon
                                   large
                                   v-bind="attrs"
                                   color="wellis"
                                   :disabled="!hasConfiguration"
                                   v-on="on"
                                   @click="configurationDialog = true">
                                <v-icon v-if="hasConfiguration">mdi-eye-outline</v-icon>
                                <v-icon v-else>mdi-eye-off-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>{{$t('v3.components.cart.btnViewConfig')}}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon
                                   class="ml-lg-5"
                                   large
                                   :disabled="isChild || !isReconfigurable"
                                   v-bind="attrs"
                                   color="wellis"
                                   v-on="on"
                                   @click="configuratorDialog = true">
                                <v-icon>mdi-wrench-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>{{$t('v3.components.cart.btnReconfigure')}}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon
                                   class="ml-lg-5"
                                   large
                                   :disabled="isChild"
                                   v-bind="attrs"
                                   color="wellis"
                                   v-on="on"
                                   @click="confirmDialog = true">
                                <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>{{$t('v3.components.cart.btnRemoveItem')}}</span>
                    </v-tooltip>
                </v-card-actions>
            </v-col>
        </v-row>

        <v-dialog
            v-model="confirmDialog"
            persistent
            max-width="290"
        >
            <v-card>
                <v-card-title class="text-h5">{{$t('confirm.title')}}</v-card-title>
                <v-card-text class="text-center">
                    <v-icon x-large color="wellis">mdi-progress-question</v-icon>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="black"
                        text
                        @click="confirmDialog = false"
                    >
                        {{$t('confirm.cancel')}}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="wellis"
                        text
                        @click="removeItem"
                    >
                        {{$t('confirm.yes')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-if="hasConfiguration"
            :value="configurationDialog"
            max-width="650px"
            @click:outside="configurationDialog = false"
        >
            <v-card>
                <v-card-title>
                    {{$t('v3.components.cart.btnViewConfig')}}
                </v-card-title>
                <v-card-text>
                    <v-row v-for="item in Object.values(product.configuration)" :key="item.optionValueId" dense>
                        <v-col cols="12" sm="12" md="12" lg="6" class="font-weight-bold">{{item.description}}</v-col>
                        <v-col cols="12" sm="12" md="12" lg="6" class="font-weight-bold wellis--text">{{findConfigurationValueDesc(item)}}</v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <CartItemConfigurator v-if="hasConfiguration && isReconfigurable" :visible="configuratorDialog"
                              :configuration="product.configuration"
                              :cart-id="product.id"
                              :parent-id="product.parentId"
                              :catalog-no="product.catalogNo"
                              :part-no="product.partNo"
                              :price="product.price"
                              :quantity="quantity"/>
    </v-card>
</template>

<script>
import CartApiService from "../../../api/cart.api";
import {EVENT_CART_FETCH} from "../../../core/constants";
import CartItemConfigurator from "./CartItemConfigurator";

export default {
    name: "CartItem",
    components: {CartItemConfigurator},
    props: {
        product: Object,
        configurable: Boolean,
        isChild: Boolean
    },

    data() {
        return {
            changeCommitTimeout: null,
            confirmDialog: false,
            configurationDialog: false,
            configuratorDialog: false,

            triggerQuantityWatcher: true,
        }
    },

    methods: {
        resolveRouterLink() {
            return {name: 'app.product.concrete', params: {catalogNo: this.product.catalogNo}};
        },

        incrementQuantity() {
            this.product.quantity++;
            if (this.product.quantity > 99) {
                this.product.quantity = 99;
            }
        },


        decrementQuantity() {
            this.product.quantity--;
            if (this.product.quantity <= 0) {
                this.product.quantity = 1;
            }
        },

        removeItem() {
            this.$CartView.showLoadingOverlay();
            this.confirmDialog = false;
            CartApiService.removeItem(this.product.id)
                .then(() => this.$eventBus.$emit(EVENT_CART_FETCH))
                .catch(() => {
                    this.$snackBar({
                        content: this.$t('whoops'),
                        icon: 'mdi-alert'
                    });
                })
                .finally(() => this.$CartView.hideLoadingOverlay());
        },

        findConfigurationValueDesc(item) {
            let value = item.values.find(x => item.selectedValue === x.optionValueId);
            return value ? value.description : 'N/A';
        },

        setPrice(price) {
            this.product.price = price;
        },

        setConfiguration(configuration) {
            this.product.configuration = configuration;
        },

        updateItem(recordId, newItem) {
            this.$CartView.updateItem(recordId, newItem);
        },

        closeConfigurator() {
            this.configuratorDialog = false;
        }
    },

    computed: {
        quantity: {
            get: function () {
                return this.product.quantity;
            },

            set: function (newValue) {
                if (Number.isInteger(newValue)) {
                    if (newValue > 99) {
                        newValue = 99;
                    }

                    if (newValue < 1) {
                        newValue = 1;
                    }

                    this.product.quantity = newValue;
                }
            }
        },

        isStockItem() {
            return this.product.type.indexOf('STOCK') !== -1;
        },

        isShopOrdItem() {
            return this.product.type === 'SHOPORD' || this.product.type === 'PURCHORD';
        },

        hasConfiguration() {
            return this.product.configuration !== null && this.product.configuration !== '*';
        },

        isReconfigurable() {
            return this.configurable || (this.hasConfiguration && (!this.isStockItem && !this.isShopOrdItem));
        }
    },

    watch: {
        quantity(val, old) {
            if (!this.isChild) {
                if (this.changeCommitTimeout !== null) {
                    clearTimeout(this.changeCommitTimeout);
                    this.changeCommitTimeout = null;
                }

                if (this.triggerQuantityWatcher) {
                    this.changeCommitTimeout = setTimeout(() => {
                        if (this.triggerQuantityWatcher) {
                            this.$CartView.showLoadingOverlay();
                            CartApiService.updateQuantity(this.product.id, val)
                                .then(() => this.$eventBus.$emit(EVENT_CART_FETCH))
                                .catch(() => {
                                    this.triggerQuantityWatcher = false;
                                    this.product.quantity = old;
                                    this.$CartView.hideLoadingOverlay();
                                });
                        }
                    }, 700);
                }
                this.triggerQuantityWatcher = true;
            }
        }
    }
}
</script>

<style scoped>
.end-price {
    font-size: 20px;
}
</style>
