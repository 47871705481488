<template>
    <v-card :min-height="discountVal > 0 ? 515 : 410" v-if="card" elevation="2">
        <v-overlay :value="loadingOverlay" absolute>
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-hover>
            <template v-slot:default="{ hover }">
                <v-card-title>
                    <v-img
                        class="white--text align-end"
                        height="254px"
                        width="254px"
                        contain
                        :lazy-src="require('@/assets/img/placeholder_300x300.png')"
                        :src="product.media.thumbnailUrl">
                        <v-fade-transition>
                            <v-overlay v-if="hover" absolute color="wellis" class="pointer">
                                <div v-if="configurable">
                                    <v-btn
                                        :to="resolveLink">
                                        {{$t('components.products.availability.btnConfigurable')}}
                                    </v-btn>
                                </div>
                                <div v-else class="text-center">
                                    <v-btn :to="resolveLink">{{$t('components.products.availability.btnConfigurable')}}</v-btn>
                                    <v-divider class="mt-3"></v-divider>
                                    <v-text-field v-model="quantity" type="number" :label="$t('components.products.quantity')"
                                                  style="max-width: 175px"
                                                  class="font-weight-bold"
                                                  color="wellis"
                                                  append-outer-icon="mdi-plus" @click:append-outer="incrementQuantity"
                                                  prepend-icon="mdi-minus" @click:prepend="decrementQuantity"></v-text-field>
                                    <v-divider></v-divider>
                                    <v-btn @click="addCart">{{$t('components.products.availability.btnAddCart')}}</v-btn>
                                </div>
                            </v-overlay>
                        </v-fade-transition>
                    </v-img>
                </v-card-title>
            </template>
        </v-hover>
        <v-card-subtitle>
            <router-link class="mt-2 wellis--text font-weight-bold d-block"
                         :to="resolveLink">
                {{product.catalogNo}}
            </router-link>
            <span class="d-block pointer">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">{{product.name | cut(27)}}</span>
                            </template>
                            <span>{{product.name}}</span>
                        </v-tooltip>
                    </span>
        </v-card-subtitle>
        <v-card-text>
            <div v-if="priceDisplay">
                <v-row v-if="discountVal > 0">
                    <v-col cols="12" lg="6" md="12">
                        {{$t('components.products.discount')}}:
                    </v-col>
                    <v-col cols="12" lg="6" md="12" sm="12" class="text-right">
                        <span class="wellis--text font-weight-bold">{{discountVal}}%</span>
                    </v-col>
                </v-row>
                <v-row v-if="product.po_date && quantityInStock('default')<=0">
                  <v-col cols="12" lg="6" md="12">
                    {{ $t('components.products.po_date') }}:
                  </v-col>
                  <v-col cols="12" lg="6" md="12" sm="12" class="text-right">
                    <span class="wellis--text font-weight-bold">{{ product.po_date }}</span>
                  </v-col>
                </v-row>
                <v-row v-if="discountVal > 0" class="text-line-through">
                    <v-col cols="12" lg="6" md="12" sm="12" v-if="hasVat">
                        <small>{{factoryPrice | toPrice(currency, false)}} + {{$t('components.products.vat')}}</small>
                    </v-col>
                    <v-col cols="12" :lg="hasVat ? 6 : 12" md="12" sm="12" class="text-right wellis--text">
                        {{factoryPrice | toPrice(currency, hasVat)}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" lg="6" md="12" v-if="hasVat">
                        <small>{{unitPrice | toPrice(currency, false)}} + {{$t('components.products.vat')}}</small>
                    </v-col>
                    <v-col cols="12" :lg="hasVat ? 6 : 12" md="12" sm="12" class="text-right wellis--text">
                        {{unitPrice | toPrice(currency, hasVat)}}
                    </v-col>
                </v-row>
            </div>
            <v-row v-if="availability !== false">
                <v-col cols="12" md="12">
                    <div :class="quantityInStock('default') <= 0 ? 'preorder' : ''">
                        {{availability}}
                    </div>
                    <div v-if="quantityInStock('m3') > 0 && (product.catalogType !== 'NON' && !product.configurable)">
                        {{$t('components.products.m3Stock', {num: `${quantityInStock('m3')} ${product.unit}`})}}
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="$store.getters['authStore/getCustomerGroup'] === 'NAGYROM'">
                <v-col cols="12" md="12">
                    <span class="wellis--text font-weight-bold">W-RO: </span> {{quantityInStockZero('ro')}} {{product.unit}}
                </v-col>
            </v-row>
            <v-row>
                <div v-if="$helpers.isMobile()">
                    <div v-if="configurable">
                        <v-btn :to="resolveLink">{{$t('components.products.availability.btnConfigurable')}}</v-btn>
                    </div>
                    <div v-else class="text-center">
                        <v-btn :to="resolveLink">{{$t('components.products.availability.btnConfigurable')}}</v-btn>
                        <v-divider class="mt-3"></v-divider>
                        <v-text-field v-model="quantity" type="number" :label="$t('components.products.quantity')"
                                      style="max-width: 175px"
                                      class="font-weight-bold"
                                      append-outer-icon="mdi-plus" @click:append-outer="incrementQuantity"
                                      prepend-icon="mdi-minus" @click:prepend="decrementQuantity"></v-text-field>
                        <v-divider></v-divider>
                        <v-btn @click="addCart">{{$t('components.products.availability.btnAddCart')}}</v-btn>
                    </div>
                </div>
            </v-row>
        </v-card-text>
    </v-card>
    <tr v-else>
        <td>
            <v-img
                class="white--text align-end"
                height="38px"
                width="38px"
                contain
                :lazy-src="require('@/assets/img/placeholder_300x300.png')"
                :src="product.media.thumbnailUrl"/>
        </td>
        <td>
            <router-link class="wellis--text font-weight-bold d-block"
                         :to="resolveLink">
                {{product.catalogNo}}
            </router-link>
        </td>
        <td>{{product.name}}</td>
        <td v-if="priceDisplay">{{unitPrice | toPrice(currency, hasVat)}}</td>
        <td v-if="availability !== false">
            <v-row>
                <v-col cols="12" md="12">
                    <div :class="quantityInStock('default') <= 0 ? 'preorder' : ''">
                        {{availability}}
                    </div>
                    <div v-if="quantityInStock('m3') > 0 && (product.catalogType !== 'NON' && !product.configurable)">
                        {{$t('components.products.m3Stock', {num: `${quantityInStock('m3')} ${product.unit}`})}}
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="$store.getters['authStore/getCustomerGroup'] === 'NAGYROM' && product.catalogType !== 'NON' && !product.configurable">
                <v-col cols="12" md="12">
                    <span class="wellis--text font-weight-bold">W-RO: </span> {{quantityInStockZero('ro')}} {{product.unit}}
                </v-col>
            </v-row>
        </td>
        <td v-else>-</td>
        <td v-if="configurable">-</td>
        <td v-if="configurable">
            <v-btn :to="resolveLink">{{$t('components.products.availability.btnConfigurable')}}</v-btn>
        </td>
        <td v-if="!configurable">
            <v-text-field v-model="quantity" type="number" :label="$t('components.products.quantity')"
                          style="max-width: 175px"
                          class="font-weight-bold"
                          append-outer-icon="mdi-plus" @click:append-outer="incrementQuantity"
                          prepend-icon="mdi-minus" @click:prepend="decrementQuantity"></v-text-field>
        </td>
        <td v-if="!configurable">
            <v-btn small @click="addCart">{{$t('components.products.availability.btnAddCart')}}</v-btn>
        </td>
    </tr>
</template>

<script>
import CartApiService from '../../../api/cart.api';
import {EVENT_CART_COUNT_UPDATE} from '../../../core/constants';
import {LiteUser} from '../../../helpers';

export default {
    name: 'ProductCard',
    props: {
        product: Object,
        card: Boolean,
        category: Object,
    },

    data() {
        return {
            quantity: 1,
            loadingOverlay: false,
        };
    },

    methods: {

        incrementQuantity() {
            if (this.quantity < 100) {
                this.quantity++;
            }
        },

        decrementQuantity() {
            if (this.quantity > 1) {
                this.quantity--;
            }
        },

        addCart() {
            if (!LiteUser.hasPermission(
                this.$store.getters['authStore/isLiteUser'],
                this.$store.getters['authStore/getLiteUserPermission'],
                'place_order',
            )) {
                this.$ApplGeneral({
                    content: this.$t('v32.liteUser.hasNoPermission'),
                });
                return;
            }

            this.loadingOverlay = true;
            let attributes = {
                'coordinator': 'addProductSimple',
                'product': this.product.catalogNo,
                'quantity': this.quantity,
            };
            CartApiService.add(attributes)
                .then(response => {
                    this.$eventBus.$emit(EVENT_CART_COUNT_UPDATE, response.data.count);
                    this.$snackBar({
                        content: this.$t('v3.components.cart.itemAdded'),
                        icon: 'mdi-check',
                        timeout: 1500,
                    });
                })
                .catch(() => {
                    this.$snackBar({
                        content: this.$t('whoops'),
                        icon: 'mdi-alert',
                    });
                })
                .finally(() => {
                    this.loadingOverlay = false;
                    this.quantity = 1;
                });
        },
    },

    computed: {
        discountVal() {
            if (!this.product.price) {
                return 0;
            }
            return this.product.price.discount;
        },

        availability() {
            if (this.product.catalogType === 'NON' || this.product.configurable) {
                return false;
            }
            if (this.product.quantityInStock['default'] <= 0) {
                return this.$t('components.products.availability.preorder');
            } else {
                if (this.product.quantityInStock['default'] > 20) {
                    return this.$t('components.products.availability.more', {unit: this.product.unit});
                } else {
                    return this.$t('components.products.availability.less', {unit: this.product.unit});
                }
            }
        },

        configurable() {
            return this.product.configurable;
        },

        priceDisplay() {
            return this.product.price !== null;
        },

        unitPrice() {
            if (this.product.price === null) {
                return 0;
            }
            return this.product.price.unitPrice;
        },

        factoryPrice() {
            if (this.product.price === null) {
                return 0;
            }
            return this.product.price.factoryPrice;
        },

        currency() {
            if (this.product.price === null) {
                return null;
            }
            return this.product.price.currency;
        },

        hasVat() {
            if (this.product.price === null) {
                return null;
            }
            return this.product.price.vat;
        },

        quantityInStock() {
            return (type = 'default') => {
                return this.product.quantityInStock[type];
            };
        },

        quantityInStockZero() {
            return (type = 'default') => {
                let qty = this.product.quantityInStock[type];
                if (qty < 0) {
                    qty = 0;
                }

                return qty;
            };
        },

        resolveLink() {
            if (typeof this.category !== 'undefined' && this.category !== null) {
                return {
                    name: 'app.product.concrete',
                    params: {
                        category: this.category.slug,
                        catalogNo: this.product.catalogNo,
                    },
                };
            } else {
                return {
                    name: 'app.product.concrete',
                    params: {
                        catalogNo: this.product.catalogNo,
                    },
                };
            }
        },
    },

};
</script>

<style scoped>

</style>
