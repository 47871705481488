<template>
    <v-container fluid>
        <v-overlay :value="loadingOverlay" color="white" z-index="99999">
            <v-progress-circular
                color="wellis"
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-card color="transparent2">
            <v-card-title>
                <v-row>
                    <v-col cols="12" sm="12" offset-lg="8" lg="4">
                        <v-text-field
                            v-model="dt.search"
                            color="wellis"
                            append-icon="mdi-magnify"
                            :label="$t('components.topbar.search')"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :locale="$store.getters['langStore/current']"
                    disable-pagination
                    :items-per-page="-1"
                    hide-default-footer
                    :headers="dt.headers"
                    :items="dt.items"
                    item-class="wellis"
                    :search="dt.search">
                    <template v-slot:header.actions>
                        <v-btn color="wellis-text-white mr-2" small @click="fetch">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                        <v-btn color="wellis-text-white" small @click="openEditorDialog(null)">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="wellis"
                                       small
                                       outlined
                                       v-bind="attrs"
                                       v-on="on">
                                    {{$t('v32.components.account.liteUsers.btnActions')}}
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-btn
                                        outlined
                                        width="100%"
                                        color="wellis"
                                        @click="openEditorDialog(item.id)">
                                        {{$t('v32.components.account.liteUsers.btnEdit')}}
                                    </v-btn>
                                </v-list-item>

                                <v-list-item>
                                    <v-btn
                                        outlined
                                        width="100%"
                                        color="wellis"
                                        @click="openPermissionDialog(item.id)">
                                        {{$t('v32.components.account.liteUsers.btnPermissions')}}
                                    </v-btn>
                                </v-list-item>

                                <v-list-item>
                                    <v-btn
                                        outlined
                                        width="100%"
                                        color="wellis"
                                        @click="openPasswordDialog(item.id)">
                                        {{$t('v32.components.account.liteUsers.btnChangePassword')}}
                                    </v-btn>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>

                    <template v-slot:item.username="{ item }">
                        <v-icon v-if="!item.fnd_login_permitted">mdi-lock-outline</v-icon>
                        <v-icon v-else>mdi-lock-open-variant-outline</v-icon>
                        <span class="ml-2 wellis--text font-weight-bold">{{item.username}}</span>
                    </template>

                    <template v-slot:item.notify_email="{ item }">
                        <v-chip small class="wellis-text-white wellis">{{item.notify_email}}</v-chip>
                    </template>

                    <template v-slot:item.created_at="{ item }">
                        {{item.created_at | formatDate(true)}}
                    </template>

                    <template v-slot:item.updated_at="{ item }">
                        {{item.updated_at | formatDate(true)}}
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog
            v-model="dialog.editor.state"
            persistent
            width="800">
            <v-card>
                <v-toolbar
                    dark
                    color="wellis">
                    <v-btn
                        icon
                        dark
                        @click="dialog.editor.state = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="dialog.editor.target === null">{{$t('v32.components.account.liteUsers.dialog.editor.new')}}</v-toolbar-title>
                    <v-toolbar-title v-else>{{$t('v32.components.account.liteUsers.dialog.editor.edit')}}</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-5">
                    <v-form ref="editorForm" lazy-validation v-model="dialog.editor.__formValid" @submit="sendEditorForm">
                        <v-text-field color="wellis"
                                      single-line
                                      autocomplete="off"
                                      outlined
                                      :label="$t('v32.components.account.liteUsers.username')"
                                      clearable
                                      counter="50"
                                      maxLength="50"
                                      :rules="[v => !!v || this.$t('v3.mandatoryField')]"
                                      v-model="dialog.editor.data.username"/>
                        <v-text-field color="wellis"
                                      single-line
                                      autocomplete="off"
                                      outlined
                                      :label="$t('v32.components.account.liteUsers.notifyEmail')"
                                      clearable
                                      counter="100"
                                      type="email"
                                      maxLength="100"
                                      :rules="[v => !!v || this.$t('v3.mandatoryField')]"
                                      v-model="dialog.editor.data.notifyEmail"/>
                        <v-text-field color="wellis" v-if="dialog.editor.target === null"
                                      single-line
                                      outlined
                                      autocomplete="off"
                                      :label="$t('components.login.inputs.password.placeholder')"
                                      clearable
                                      :type="dialog.editor.visiblePassword ? 'text' : 'password'"
                                      :rules="[v => !!v || this.$t('v3.mandatoryField')]"
                                      v-model="dialog.editor.data.password">
                            <template v-slot:append-outer>
                                <v-btn color="wellis mr-2" outlined @click="generatePassword('editor')">
                                    <v-icon>
                                        mdi-alphabet-latin
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>
                        <v-btn color="wellis" class="wellis-text-white" block type="submit">
                            {{$t('btnSave')}}
                        </v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog.permission.state"
            persistent
            width="800">
            <v-card>
                <v-toolbar
                    dark
                    color="wellis">
                    <v-btn
                        icon
                        @click="dialog.permission.state = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{$t('v32.components.account.liteUsers.btnPermissions')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="white"
                        class="wellis--text"
                        small
                        @click="grantAllPermissions">
                        {{$t('v32.components.account.liteUsers.btnGrantAllPermissions')}}
                    </v-btn>
                    <v-btn
                        color="white"
                        class="ml-2 wellis--text"
                        small
                        @click="revokeAllPermissions">
                        {{$t('v32.components.account.liteUsers.btnRevokeAllPermissions')}}
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-5">
                    <v-row>
                        <v-col cols="12" lg="4" sm="12" v-for="item in dialog.permission.data" :key="dialog.permission.target+'_'+item.permission">
                            <v-switch v-model="item.value"
                                      :disabled="item.permission === 'products' && !dialog.permission.productVisibilityChangeable"
                                      color="wellis"
                                      :label="$t('v32.components.account.liteUsers.permissions.'+ item.permission)"
                                      @change="setPermission(dialog.permission.target, item.permission)"
                            ></v-switch>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog.password.state"
            persistent
            width="800">
            <v-card>
                <v-toolbar
                    dark
                    color="wellis">
                    <v-btn
                        icon
                        dark
                        @click="dialog.password.state = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{$t('v32.components.account.liteUsers.btnChangePassword')}}</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-5">
                    <v-form ref="passwordForm" lazy-validation v-model="dialog.password.__formValid" @submit="sendPasswordForm" autocomplete="off">
                        <v-text-field color="wellis"
                                      single-line
                                      outlined
                                      autocomplete="new-password"
                                      :label="$t('components.login.inputs.password.placeholder')"
                                      clearable
                                      :type="dialog.password.visiblePassword ? 'text' : 'password'"
                                      :rules="[v => !!v || this.$t('v3.mandatoryField')]"
                                      v-model="dialog.password.data.password">
                            <template v-slot:append-outer>
                                <v-btn color="wellis mr-2" outlined @click="generatePassword('password')">
                                    <v-icon>
                                        mdi-alphabet-latin
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>
                        <v-btn color="wellis" class="wellis-text-white" block type="submit">
                            {{$t('btnSave')}}
                        </v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import {EVENT_PAGE_TITLE_CHANGE} from '../../../../core/constants';
import AccountApiService from '../../../../api/account.api';
import {randomString, strSlug} from '../../../../helpers';

export default {
    name: 'MyLiteUsers',

    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE, 'v32.components.account.liteUsers.title'), 100);

        if (this.$store.getters['authStore/isLiteUser']) {
            this.$ApplGeneral({
                content: this.$t('v32.liteUser.hasNoPermission'),
                onClose: function() {
                    this.$router.push({
                        name: 'app.start',
                    });
                },
            });
            return;
        }

        this.fetch();
    },

    data() {
        return {
            mainOverlay: true,
            loadingOverlay: true,

            dt: {
                search: null,
                headers: [
                    {text: '', align: 'start', sortable: false, value: 'actions'},
                    {text: this.$t('v32.components.account.liteUsers.username'), align: 'start', sortable: true, value: 'username'},
                    {text: this.$t('v32.components.account.liteUsers.notifyEmail'), align: 'start', sortable: true, value: 'notify_email'},
                    {text: this.$t('createdAt'), align: 'start', sortable: true, value: 'created_at'},
                    {text: this.$t('v32.updatedAt'), align: 'start', sortable: true, value: 'updated_at'},
                ],
                items: [],
            },

            dialog: {
                editor: {
                    target: null,
                    state: false,
                    __formValid: false,
                    visiblePassword: false,
                    data: {
                        username: null,
                        notifyEmail: null,
                        password: null,
                    },
                },
                password: {
                    target: null,
                    state: false,
                    __formValid: false,
                    visiblePassword: false,
                    data: {
                        password: null,
                    },
                },
                permission: {
                    target: null,
                    state: false,
                    productVisibilityChangeable: false,
                    data: [],
                },
            },

        };
    },

    methods: {
        fetch() {
            return new Promise((resolve, reject) => {
                this.loadingOverlay = true;
                AccountApiService.liteUsers.all()
                    .then(response => {
                        this.dt.items = response.data.items;
                        resolve();
                    })
                    .catch(e => {
                        this.$snackBar({
                            icon: 'mdi-alert',
                            allowOutsideClick: false,
                            text: this.$t('api-error'),
                        });
                        reject(e);
                    })
                    .finally(() => {
                        this.mainOverlay = false;
                        this.loadingOverlay = false;
                    });
            });
        },

        openEditorDialog(liteUserId = null) {
            this.dialog.editor.target = liteUserId;
            this.dialog.editor.visiblePassword = false;

            if (!liteUserId) {
                this.dialog.editor.data.username = null;
                this.dialog.editor.data.notifyEmail = null;
                this.dialog.editor.data.password = null;
                this.dialog.editor.state = true;
                this.$nextTick(() => {
                    this.$refs.editorForm.resetValidation();
                });
            } else {
                this.loadingOverlay = true;
                AccountApiService.liteUsers.get(liteUserId)
                    .then(response => {
                        this.dialog.editor.data.username = response.data.liteUser.username;
                        this.dialog.editor.data.notifyEmail = response.data.liteUser.notify_email;
                    })
                    .catch(() => {
                        this.$ApplGeneral({
                            content: this.$t('api-error'),
                        });
                    })
                    .finally(() => {
                        this.loadingOverlay = false;
                        this.dialog.editor.state = true;
                        this.$nextTick(() => {
                            this.$refs.editorForm.resetValidation();
                        });
                    });
            }
        },

        openPermissionDialog(liteUserId) {
            this.loadingOverlay = true;
            this.dialog.permission.target = null;
            this.dialog.permission.data = [];
            AccountApiService.liteUsers.permissions.get(liteUserId)
                .then(response => {
                    this.dialog.permission.data = response.data.permissions;
                    this.dialog.permission.data.forEach(item => {
                        item.value = item.objstate === 'ACTIVE';
                    });
                    this.dialog.permission.target = liteUserId;

                    let po = this.dialog.permission.data.find(x => x.permission === 'place_order');
                    this.dialog.permission.productVisibilityChangeable = !po.value;

                    this.dialog.permission.state = true;
                })
                .catch(() => {
                    this.$ApplGeneral({
                        content: this.$t('api-error'),
                    });
                })
                .finally(() => {
                    this.loadingOverlay = false;
                });

        },

        openPasswordDialog(liteUserId) {
            this.dialog.password.target = liteUserId;
            this.dialog.password.state = true;
            this.$nextTick(() => {
                this.$refs.passwordForm.resetValidation();
            });
        },

        sendEditorForm(e) {
            e.preventDefault();
            if (!this.$refs.editorForm.validate()) {
                return;
            }
            this.loadingOverlay = true;
            this.getEditorFormAccountServiceMethod()
                .then(() => {
                    this.dialog.editor.state = false;
                    this.fetch();
                })
                .catch(error => {
                    let content = this.$t('whoops');
                    if (error.response.data.exception) {
                        if (error.response.data.exception.class === 'ValidationException') {
                            content = '';
                            if (error.response.data.payload) {
                                Object.values(error.response.data.payload).forEach(field => {
                                    Object.values(field).forEach(msg => {
                                        content += msg + '<br>';
                                    });
                                });
                            }
                        }
                    }

                    if (error.response.data.message && error.response.data.message === 'LITE_USERNAME_NOT_UNIQUE') {
                        content = this.$t('v32.components.account.liteUsers.notUniqueUsername');
                    }

                    this.loadingOverlay = false;
                    this.$ApplGeneral({
                        content: content,
                    });
                });
        },

        sendPasswordForm(e) {
            e.preventDefault();
            if (!this.$refs.passwordForm.validate()) {
                return;
            }
            this.loadingOverlay = true;
            AccountApiService.liteUsers.updatePassword(this.dialog.password.target, this.dialog.password.data.password)
                .then(() => {
                    this.dialog.password.state = false;
                    this.$ApplGeneral({
                        content: this.$t('v32.components.account.liteUsers.passwordUpdated'),
                    });
                    this.loadingOverlay = false;
                })
                .catch(error => {
                    let content = this.$t('whoops');
                    if (error.response.data.exception) {
                        if (error.response.data.exception.class === 'ValidationException') {
                            content = '';
                            if (error.response.data.payload) {
                                Object.values(error.response.data.payload).forEach(field => {
                                    Object.values(field).forEach(msg => {
                                        content += msg + '<br>';
                                    });
                                });
                            }
                        }
                    }

                    this.loadingOverlay = false;
                    this.$ApplGeneral({
                        content: content,
                    });
                });
        },

        getEditorFormAccountServiceMethod() {
            if (this.dialog.editor.target === null) {
                return AccountApiService.liteUsers.create(
                    this.dialog.editor.data.username,
                    this.dialog.editor.data.password,
                    this.dialog.editor.data.notifyEmail,
                );
            } else {
                return AccountApiService.liteUsers.update(
                    this.dialog.editor.target,
                    this.dialog.editor.data.username,
                    this.dialog.editor.data.notifyEmail,
                );
            }
        },

        generatePassword(target) {
            this.dialog[target].data.password = randomString(10);
            this.dialog[target].visiblePassword = true;
        },

        setPermission(liteUserId, permission, value = null) {
            this.loadingOverlay = true;
            let item = this.dialog.permission.data.find(x => x.permission === permission);
            let val = value || item.value;
            item.value = val;
            AccountApiService.liteUsers.permissions.update(liteUserId, permission, val)
                .then(() => {
                    if (permission === 'place_order') {
                        this.setPermission(liteUserId, 'products', true);
                    } else {
                        this.loadingOverlay = false;
                    }

                    let po = this.dialog.permission.data.find(x => x.permission === 'place_order');
                    this.dialog.permission.productVisibilityChangeable = !po.value;
                })
                .catch(error => {
                    let content = this.$t('whoops');
                    if (error.response.data.exception) {
                        if (error.response.data.exception.class === 'ValidationException') {
                            content = '';
                            if (error.response.data.payload) {
                                Object.values(error.response.data.payload).forEach(field => {
                                    Object.values(field).forEach(msg => {
                                        content += msg + '<br>';
                                    });
                                });
                            }
                        }
                    }

                    item.value = !val;
                    this.loadingOverlay = false;
                    this.$ApplGeneral({
                        content: content,
                    });
                });
        },

        grantAllPermissions() {
            this.loadingOverlay = true;
            AccountApiService.liteUsers.permissions.grantAll(this.dialog.permission.target)
                .then(() => {
                    this.openPermissionDialog(this.dialog.permission.target);
                })
                .catch(error => {
                    let content = this.$t('whoops');
                    if (error.response.data.exception) {
                        if (error.response.data.exception.class === 'ValidationException') {
                            content = '';
                            if (error.response.data.payload) {
                                Object.values(error.response.data.payload).forEach(field => {
                                    Object.values(field).forEach(msg => {
                                        content += msg + '<br>';
                                    });
                                });
                            }
                        }
                    }
                    this.loadingOverlay = false;
                    this.$ApplGeneral({
                        content: content,
                    });
                });
        },

        revokeAllPermissions() {
            this.loadingOverlay = true;
            AccountApiService.liteUsers.permissions.revokeAll(this.dialog.permission.target)
                .then(() => {
                    this.openPermissionDialog(this.dialog.permission.target);
                })
                .catch(error => {
                    let content = this.$t('whoops');
                    if (error.response.data.exception) {
                        if (error.response.data.exception.class === 'ValidationException') {
                            content = '';
                            if (error.response.data.payload) {
                                Object.values(error.response.data.payload).forEach(field => {
                                    Object.values(field).forEach(msg => {
                                        content += msg + '<br>';
                                    });
                                });
                            }
                        }
                    }
                    this.$ApplGeneral({
                        content: content,
                    });
                });
        },
    },

    watch: {
        'dialog.editor.data.username': function(val) {
            if (!val) {
                return;
            }
            this.dialog.editor.data.username = strSlug(val.toString()).replaceAll('-', '_').toLowerCase().trim();
        },
    },
};
</script>

<style scoped>

</style>