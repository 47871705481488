<template>
    <v-container fluid>
        <v-container fluid class="mt-10" v-if="mainOverlay">
            <v-progress-linear indeterminate
                               color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container fluid v-else>
            <v-row>
                <v-col cols="12" md="12" sm="12">
                    <v-overlay :value="loadingOverlay" color="white">
                        <v-progress-circular
                            color="wellis"
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-card color="transparent2">
                        <v-card-text>
                            <v-simple-table>
                                <thead>
                                <tr>
                                    <th>
                                        <v-btn small color="wellis-text-white"
                                               :to="{name: 'app.admin.scheduler.create'}">
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                    </th>
                                    <th>Name</th>
                                    <th>Status</th>
                                    <th>Type</th>
                                    <th>Job ID</th>
                                    <th>Last run time</th>
                                    <th>Next run time</th>
                                    <th>Created at</th>
                                    <th>Updated at</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="job in items" :key="job.uuid">
                                    <td>
                                        <v-menu offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn text
                                                       rounded
                                                       icon
                                                       color="wellis"
                                                       v-bind="attrs"
                                                       v-on="on">
                                                    <v-icon>mdi-dots-horizontal</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item>
                                                    <v-btn
                                                        class="ma-2"
                                                        outlined
                                                        width="100%"
                                                        color="wellis"
                                                        :to="{name: 'app.admin.scheduler.edit', params: {uuid: job.uuid} }"
                                                    >
                                                        Details
                                                    </v-btn>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-btn
                                                        class="ma-2"
                                                        outlined
                                                        width="100%"
                                                        color="wellis"
                                                        @click="openDetailsNewTab(job.uuid)"
                                                    >
                                                        Open details in new tab
                                                    </v-btn>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-btn
                                                        class="ma-2"
                                                        outlined
                                                        width="100%"
                                                        color="wellis"
                                                        :to="{name: 'app.admin.scheduler.history', params: {uuid: job.uuid}}"
                                                    >
                                                        History
                                                    </v-btn>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-btn
                                                        class="ma-2"
                                                        outlined
                                                        width="100%"
                                                        color="wellis"
                                                        @click="openHistoryNewTab(job.uuid)"
                                                    >
                                                        Open history in new tab
                                                    </v-btn>
                                                </v-list-item>
                                                <v-divider class="mt-3 mb-3"></v-divider>
                                                <v-list-item>
                                                    <v-btn
                                                        class="ma-2"
                                                        outlined
                                                        width="100%"
                                                        color="wellis"
                                                        @click="startNow(job.uuid)"
                                                    >
                                                        Start now
                                                    </v-btn>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </td>
                                    <td>
                                        <b>{{job.name}}</b>
                                    </td>
                                    <td>
                                        <v-chip :color="resolveObjstateColor(job.objstate).chip"
                                                :text-color="resolveObjstateColor(job.objstate).text" small>{{job.objstate}}
                                        </v-chip>
                                    </td>
                                    <td>
                                        <v-chip :color="resolveTypeColor(job.type_code_db).chip" :text-color="resolveTypeColor(job.type_code_db).text" small>{{job.type_code}}
                                        </v-chip>
                                    </td>
                                    <td>{{job.job_id || '-'}}</td>
                                    <td>
                                        <span v-if="job.last_run_time !== null">{{job.last_run_time | formatDate(true)}}</span>
                                        <span v-else>-</span>
                                    </td>
                                    <td>
                                        <span v-if="job.next_run_time !== null">{{job.next_run_time | formatDate(true)}}</span>
                                        <span v-else>-</span>
                                    </td>
                                    <td>{{job.created_at | formatDate(true)}}</td>
                                    <td>{{job.updated_at | formatDate(true)}}</td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-bottom-navigation fixed style="z-index: 0" grow>
            <v-btn icon @click="filterDialog = true">
                <span>Search</span>
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn icon @click="() => this.countRows()">
                <span>Refresh</span>
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
        </v-bottom-navigation>

        <!-- OVER_FETCH_LIMIT Dialog -->
        <v-dialog v-model="overFetchLimitDialog.dialog"
                  persistent
                  width="400">
            <v-card>
                <v-card-title>Wellis Online System API</v-card-title>
                <v-card-text class="mt-5">
                    Your request has returned more than <b class="wellis--text">{{overFetchLimitDialog.rowsCount}}</b> rows.<br>
                    By default, only the first <b class="wellis--text">{{overFetchLimitDialog.fetchLimit}}</b> lines are being downloaded.<br>
                    Would You like to download all items?
                </v-card-text>
                <v-card-actions>
                    <v-container fluid>
                        <v-btn @click="setOverFetchLimitFetchAllValue(false)">No</v-btn>
                        <v-btn @click="setOverFetchLimitFetchAllValue(true)" class="float-right">Yes</v-btn>
                    </v-container>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- FILTER Dialog -->
        <v-dialog v-model="filterDialog"
                  @click:outside="filterDialog = false"
                  persistent
                  width="600">
            <v-card>
                <v-card-title>Filters</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-card color="transparent2">
                        <v-card-title>Name</v-card-title>
                        <v-card-text>
                            <v-text-field color="wellis"
                                          clearable
                                          v-model="filters.name"
                                          placeholder="e.g.: or MY_CUST%, %CUSTOM% or MY_CUSTOM_JOB"
                            ></v-text-field>
                        </v-card-text>
                    </v-card>
                    <v-card color="transparent2">
                        <v-card-title>Objstate</v-card-title>
                        <v-card-text>
                            <v-select v-model="filters.objstate"
                                      item-text="text"
                                      item-value="value"
                                      :items="[{text: 'All', value: null}, {text: 'Active', value: 'ACTIVE'}, {text: 'Inactive', value: 'INACTIVE'}]"
                                      item-color="wellis"
                                      color="wellis">
                            </v-select>
                        </v-card-text>
                    </v-card>
                    <v-card color="transparent2">
                        <v-card-title>Type</v-card-title>
                        <v-card-text>
                            <v-select v-model="filters.type_code"
                                      item-text="text"
                                      item-value="value"
                                      :items="[{text: 'All', value: null}, {text: 'Job', value: 'JOB'}, {text: 'SQL Block', value: 'SQL_BLOCK'}]"
                                      item-color="wellis"
                                      color="wellis">
                            </v-select>
                        </v-card-text>
                    </v-card>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="wellis-text-white" block @click="countRows">Search</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import {EVENT_PAGE_TITLE_CHANGE_NO_TRANS} from "../../../../core/constants";
import AppSys_SchedulerApi from "../../../../api/app-sys/scheduler.api";

export default {
    name: "AdminSchedulerView",

    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE_NO_TRANS, 'Scheduler'), 100);

        this.countRows();
    },

    data() {
        return {
            mainOverlay: true,
            loadingOverlay: true,

            filterDialog: false,

            overFetchLimitDialog: {
                dialog: false,
                fetchAll: false,
                rowsCount: 0,
                fetchLimit: 0
            },

            filters: {
                name: null,
                objstate: null,
                type_code: null,
            },

            items: [],
        }
    },

    methods: {
        countRows() {
            this.filterDialog = false;
            this.loadingOverlay = true;
            this.overFetchLimitDialog.fetchAll = false;
            this.overFetchLimitDialog.fetchLimit = 0;
            this.overFetchLimitDialog.rowsCount = 0;
            AppSys_SchedulerApi.countRows(this.filters)
                .then(response => {
                    this.overFetchLimitDialog.rowsCount = response.data.rows_count;
                    this.overFetchLimitDialog.fetchLimit = response.data.fetch_limit;

                    if (this.overFetchLimitDialog.rowsCount > this.overFetchLimitDialog.fetchLimit) {
                        this.overFetchLimitDialog.dialog = true;
                    } else {
                        if (this.overFetchLimitDialog.rowsCount > 0) {
                            this.fetch();
                        } else {
                            this.$set(this, 'items', []);
                        }

                    }
                })
                .catch(() => {
                    this.$snackBar({
                        icon: 'mdi-alert',
                        text: this.$t('api-error')
                    })
                })
                .finally(() => {
                    this.mainOverlay = false;
                    this.loadingOverlay = false;
                });
        },

        async fetch() {
            return new Promise((resolve, reject) => {
                this.loadingOverlay = true;
                AppSys_SchedulerApi.get(this.filters, this.overFetchLimitDialog.fetchAll)
                    .then(response => {
                        this.items = response.data.items;
                        resolve(response.data.items);
                        this.$scrollTop();
                    })
                    .catch(error => {
                        this.$snackBar({
                            icon: 'mdi-alert',
                            text: this.$t('api-error')
                        })
                        reject(error);
                    })
                    .finally(() => {
                        this.mainOverlay = false;
                        this.loadingOverlay = false;
                    });
            });
        },

        setOverFetchLimitFetchAllValue(val) {
            this.overFetchLimitDialog.fetchAll = val;
            this.overFetchLimitDialog.dialog = false;
            this.$nextTick(() => this.fetch());
        },

        openDetailsNewTab(jobId) {
            const route = this.$router.resolve({name: 'app.admin.scheduler.edit', params: {uuid: jobId}});
            window.open(route.href, '_blank');
        },

        openHistoryNewTab(jobId) {
            const route = this.$router.resolve({name: 'app.admin.scheduler.history', params: {uuid: jobId}});
            window.open(route.href, '_blank');
        },

        startNow(jobId) {
            this.loadingOverlay = true;
            AppSys_SchedulerApi.startNow(jobId)
                .catch(() => {
                    this.$snackBar({
                        icon: 'mdi-alert',
                        text: this.$t('whoops')
                    })
                })
                .finally(() => {
                    this.loadingOverlay = false;
                });
        }
    },

    computed: {
        resolveTypeColor() {
            return typeCode => {
                return typeCode === 'JOB'
                    ? {text: 'white', chip: 'wellis'}
                    : {text: 'white', chip: 'secondary'};
            };
        },

        resolveObjstateColor() {
            return objstate => {
                return objstate === 'ACTIVE'
                    ? {text: 'white', chip: 'green'}
                    : {text: 'white', chip: 'red'};
            };
        }
    }
}
</script>

<style scoped>

</style>
