<template>
    <v-hover v-slot="{ hover }">
        <v-card class="mx-auto pointer overflow-hidden" max-width="485" min-height="375" :elevation="hover ? 8 : 2"
                :to="{
                name: 'app.news.view',
                params: {
                    date: $helpers.formatDate(data.created_at),
                    slug: data.slug
                }
            }">
            <v-img
                class="white--text align-end"
                height="200px"
                :lazy-src="require('@/assets/img/placeholder_1800x600.jpg')"
                :src="data.header_image_url">
                <v-card-title>{{data.title}}</v-card-title>
            </v-img>

            <v-card-subtitle class="pb-2 wellis--text font-weight-bold">
                <span>{{data.author_name}}</span>
                <span class="float-right">{{data.created_at | formatDate}}</span>
            </v-card-subtitle>


            <v-card-text class="text--primary">
                <p v-html="nl2br(data.lead)" v-if="!empty(data.lead)"></p>
                <p v-else>
                    {{$t('components.news.no-lead')}}
                </p>
            </v-card-text>
        </v-card>
    </v-hover>
</template>

<script>
import {empty, nl2br} from "../../../helpers";

export default {
    name: "NewsItemCard",

    props: {
        data: Object
    },

    methods: {
        empty(value) {
            return empty(value);
        }
    },

    computed: {
        nl2br() {
            return value => nl2br(value);
        }
    }
}
</script>

<style scoped>

</style>
