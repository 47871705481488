import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import LanguageMiddleware from './middlewares/language.middleware';
import FixSlash from './middlewares/fixslash.middleware';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
});

router.beforeEach((to, from, next) => {
    LanguageMiddleware(to, from, next);
    FixSlash(to, from, next);
});

export default router
