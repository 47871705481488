<template>
    <v-dialog
        v-model="visible"
        width="800"
        persistent
    >
        <v-card>
            <v-toolbar
                dark
                color="wellis"
            >
                <v-btn
                    icon
                    dark
                    @click="close"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{$t('components.app-settings.title')}}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="mt-5">
                <v-overlay :value="loadingOverlay" absolute color="white">
                    <v-progress-circular
                        color="wellis"
                        indeterminate
                        size="64"
                    ></v-progress-circular>
                </v-overlay>
                <v-subheader class="font-weight-bold ma-0 pa-0">{{$t('v3.components.app-settings.general')}}</v-subheader>
                <v-row>
                    <v-col cols="12" lg="12" md="12" sm="12">
                        <v-switch
                            :label="$t('components.app-settings.options.api.hasPrice.title')"
                            v-model="priceDisplay"
                            color="wellis"
                            @change="setPriceDisplay"
                        ></v-switch>
                        <p>* {{$t('components.app-settings.options.api.hasPrice.desc')}}</p>
                    </v-col>
                    <v-col cols="12" lg="12" md="12" sm="12">

                    </v-col>
                </v-row>
                <div v-if="$store.getters['authStore/isAdmin']">
                    <hr>
                    <v-subheader class="font-weight-bold ma-0 pa-0">{{$t('v3.components.app-settings.admin')}}</v-subheader>
                    <v-row>
                        <v-col cols="12" lg="12" md="12" sm="12">
                            <label>{{$t('components.app-settings.options.api.alterCustomer.title')}}</label>
                            <form @submit="alterCustomer">
                                <v-text-field color="wellis"
                                              single-line
                                              v-model="alterCustomerNo"
                                              :placeholder="$t('components.app-settings.options.api.alterCustomer.placeholder')"
                                              @click:append="alterCustomer"
                                              append-icon="mdi-send">
                                </v-text-field>
                            </form>
                            <p>* {{$t('components.app-settings.options.api.alterCustomer.desc')}}</p>
                        </v-col>

                    </v-row>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import AccountApiService from "../../api/account.api";

export default {
    name: "ApplicationSettings",

    props: {
        visible: Boolean
    },

    mounted() {
        this.priceDisplay = this.$store.getters["authStore/appSettings"].api.hasPrice;
    },

    data() {
        return {
            loadingOverlay: false,
            priceDisplay: false,

            alterCustomerNo: null,
        }
    },

    methods: {
        close() {
            this.$emit('close');
        },

        setPriceDisplay() {
            this.loadingOverlay = true;
            AccountApiService.settings.setOption('API->hasPrice', this.priceDisplay)
                .then(() => location.reload(true))
                .catch(() => {
                    this.priceDisplay = !this.priceDisplay;
                    this.loadingOverlay = false;
                    this.$snackBar({
                        content: this.$t('whoops'),
                        icon: 'mdi-alert'
                    });
                })
        },

        alterCustomer(e) {
            e.preventDefault();
            this.loadingOverlay = true;
            AccountApiService.settings.alterCustomer(this.alterCustomerNo)
                .then(() => {
                    this.$store.dispatch('categoryStore/clearCache');
                    location.reload(true);
                })
            .catch(() => {
                this.loadingOverlay = false;
                this.$snackBar({
                    content: this.$t('whoops'),
                    icon: 'mdi-alert'
                });
            })
        }
    },
}
</script>

<style scoped>

</style>
