<template>
    <v-container fluid class="mt-10" v-if="mainLoading">
        <v-progress-linear indeterminate
                           color="wellis"
        ></v-progress-linear>
    </v-container>
    <v-container fluid v-else>
        <v-container fluid v-if="items.length > 0">
            <v-overlay :value="loadingOverlay" absolute color="white">
                <v-progress-circular
                    color="wellis"
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
            <v-card color="transparent2">
                <div class="card-title">
                    <v-layout justify-center v-if="hasPrice">
                        <div v-if="hasVat" class="font-weight-light">
                            <span class="wellis--text font-weight-bold mr-2">{{$t('components.cart.net') | toUpper}}</span> {{total | toPrice(currency, false)}}
                        </div>
                        <v-icon v-if="hasVat" class="ml-6 mr-6">mdi-power-on</v-icon>
                        <div class="font-weight-light">
                            {{total | toPrice(currency, hasVat)}} <span class="ml-2 wellis--text font-weight-bold">{{$t('components.cart.gross') | toUpper}}</span>
                        </div>
                    </v-layout>
                </div>
                <v-card-text>
                    <div v-for="(cartItem, index) in items" :key="cartItem.id">
                        <v-lazy>
                            <v-row>
                                <v-col cols="12" sm="12" md="12" lg="12">
                                    <CartItem :product="cartItem"/>
                                </v-col>
                            </v-row>
                        </v-lazy>
                        <v-lazy v-for="childrenCartItem in cartItem.children" :key="childrenCartItem.id">
                            <v-row class="ml-lg-15 ">
                                <v-col cols="12" sm="12" md="12" lg="12">
                                    <CartItem :product="childrenCartItem" :is-child="true"/>
                                </v-col>
                            </v-row>
                        </v-lazy>
                        <v-divider v-if="index !== items.length - 1" class="mt-8 mb-8"></v-divider>
                    </div>
                </v-card-text>
            </v-card>
            <v-bottom-navigation fixed style="z-index: 0" v-if="items.length > 0" grow>
                <v-btn icon @click="() => this.fetch().then(() => this.$scrollTop())">
                    <span>{{$t('v3.components.cart.btnRefresh')}}</span>
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
                <v-btn icon @click="confirmDialog = true">
                    <span>{{$t('v3.components.cart.btnClear')}}</span>
                    <v-icon>mdi-cart-remove</v-icon>
                </v-btn>
                <v-btn icon link :to="{name: 'app.checkout'}">
                    <span class="wellis--text">{{$t('components.cart.btnCheckout')}}</span>
                    <v-icon>mdi-cash-register</v-icon>
                </v-btn>
            </v-bottom-navigation>
            <v-dialog
                v-model="confirmDialog"
                persistent
                max-width="290"
            >
                <v-card>
                    <v-card-title class="text-h5">{{$t('confirm.title')}}</v-card-title>
                    <v-card-text class="text-center">
                        <v-icon x-large color="wellis">mdi-progress-question</v-icon>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            color="black"
                            text
                            @click="confirmDialog = false"
                        >
                            {{$t('confirm.cancel')}}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="wellis"
                            text
                            @click="clearCart"
                        >
                            {{$t('confirm.yes')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
        <v-container fluid v-else class="text-center">
            <v-icon size="164" color="wellis">mdi-emoticon-sad-outline</v-icon>
            <h5 class="text-h5">{{$t('components.cart.empty')}}</h5>
        </v-container>
    </v-container>
</template>

<script>
import {EVENT_CART_COUNT_UPDATE, EVENT_CART_FETCH, EVENT_LANG_CHANGE, EVENT_PAGE_TITLE_CHANGE} from '../../../core/constants';
import CartApiService from '../../../api/cart.api';
import CartItem from './CartItem';
import Vue from 'vue';
import {LiteUser} from '../../../helpers';

export default {
    name: 'CartView',
    components: {CartItem},
    mounted() {
        Vue.prototype.$CartView = this;

        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE, 'components.cart.title'), 100);

        if (!LiteUser.hasPermission(
            this.$store.getters['authStore/isLiteUser'],
            this.$store.getters['authStore/getLiteUserPermission'],
            'place_order',
        )) {
            this.$ApplGeneral({
                content: this.$t('v32.liteUser.hasNoPermission'),
                onClose: () => {
                    this.$router.push({name: 'app.start'});
                },
            });
            return;
        }

        this.fetch();

        this.$eventBus.$on(EVENT_LANG_CHANGE, () => {
            this.fetch();
        });

        this.$eventBus.$on(EVENT_CART_FETCH, () => {
            this.fetch();
        });
    },

    destroyed() {
        this.$eventBus.$off(EVENT_LANG_CHANGE);
        this.$eventBus.$off(EVENT_CART_FETCH);
    },

    data() {
        return {
            mainLoading: true,
            loadingOverlay: false,
            items: [],
            confirmDialog: false,
        };
    },

    methods: {
        fetch() {
            if (!LiteUser.hasPermission(
                this.$store.getters['authStore/isLiteUser'],
                this.$store.getters['authStore/getLiteUserPermission'],
                'place_order',
            )) {
                this.$ApplGeneral({
                    content: this.$t('v32.liteUser.hasNoPermission'),
                    onClose: () => {
                        this.$router.push({name: 'app.start'});
                    },
                });
                return;
            }

            return new Promise((resolve, reject) => {
                this.loadingOverlay = true;
                CartApiService.getCartItems()
                    .then(response => {
                        this.items = response.data.items;
                        let itemsCount = 0;
                        this.items.forEach(item => {
                            itemsCount += 1 + item.children.length;
                        });
                        this.$eventBus.$emit(EVENT_CART_COUNT_UPDATE, itemsCount);
                        resolve();
                    })
                    .catch(error => reject(error))
                    .finally(() => {
                        this.mainLoading = false;
                        this.loadingOverlay = false;
                    });
            });
        },

        clearCart() {
            this.confirmDialog = false;
            this.loadingOverlay = true;
            CartApiService.emptyCart()
                .then(() => {
                    this.$eventBus.$emit(EVENT_CART_COUNT_UPDATE, 0);
                    this.fetch();
                })
                .catch(() => {
                    this.$snackBar({
                        content: this.$t('whoops'),
                        icon: 'mdi-alert',
                    });
                })
                .finally(() => this.loadingOverlay = false);
        },

        showLoadingOverlay() {
            this.loadingOverlay = true;
        },

        hideLoadingOverlay() {
            this.loadingOverlay = false;
        },

        updateItem(recordId, newItem) {
            let index = this.items.findIndex(x => x.id === recordId);
            if (index !== -1) {
                this.items[index].price = newItem.price;
                this.items[index].configuration = newItem.configuration;
            } else {
                this.items.forEach((item, index) => {
                    let subIndex = item.children.findIndex(x => x.id === recordId);
                    if (subIndex !== -1) {
                        this.items[index].children[subIndex].price = JSON.parse(newItem.price);
                        this.items[index].children[subIndex].configuration = JSON.parse(newItem.configuration);
                    }
                });
            }
        },
    },

    computed: {
        cartItems() {
            return this.items;
        },

        currency() {
            if (this.items.length === 0 || this.items[0].price === null) {
                return 'N/A';
            }
            return this.items[0].price.currency || 'N/A';
        },

        hasVat() {
            if (this.items.length === 0 || this.items[0].price === null) {
                return false;
            }
            return this.items[0].price.vat || false;
        },

        total() {
            if (this.items.length === 0 || this.items[0].price === null) {
                return 0;
            }
            let total = 0;
            this.items.map(item => {
                total += item.price.unitPrice * item.quantity;
                item.children.map(x => total += x.price.unitPrice * x.quantity);
            });

            return total;
        },

        hasPrice() {
            return !(this.items.length === 0 || this.items[0].price === null);

        },
    },
};
</script>

<style scoped>
.card-title {
    font-size: 20px !important;
}

@media screen and (max-width: 700px) {
    .card-title {
        font-size: 13px !important;
    }
}

@media screen and (max-width: 400px) {
    .card-title {
        font-size: 9px !important;
    }
}
</style>
