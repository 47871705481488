<template>
    <v-container fluid>
        <v-container fluid class="mt-10" v-if="mainOverlay">
            <v-progress-linear indeterminate
                               color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container fluid v-else>
            <v-row>
                <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                    <v-overlay :value="loadingOverlay" color="white">
                        <v-progress-circular
                            color="wellis"
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-card color="transparent2">
                        <v-row>
                            <v-col cols="12" xl="4" lg="4" md="12" sm="12">
                                <v-card>
                                    <v-card-title>Failed order details</v-card-title>
                                    <v-card-text>
                                        <v-form>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" xl="6" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.order_id"
                                                            label="Order ID"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="6" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.ifs_order_no || '-'"
                                                            label="IFS Order No"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="12" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.last_job_uuid"
                                                            label="Last Job ID"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="8" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.phase"
                                                            label="Phase"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="4" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.objstate"
                                                            label="Objstate"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="6" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.created_at | formatDate(true)"
                                                            label="Created at"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="6" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.updated_at| formatDate(true)"
                                                            label="Updated at"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-form>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" xl="6" sm="12">
                                                    <v-btn color="wellis" outlined width="100%"
                                                           :disabled="!resolveBtnRestart"
                                                           @click="onBtnRestartClick">
                                                        Resend Order
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="12" xl="6" sm="12">
                                                    <v-btn color="wellis" outlined width="100%"
                                                           :disabled="!resolveBtnNotification"
                                                           @click="onBtnNotificationClick">
                                                        Send Notification
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="12" xl="6" sm="12">

                                                </v-col>
                                                <v-col cols="12" xl="6" sm="12">
                                                    <v-btn color="wellis" outlined width="100%"
                                                           :disabled="!resolveBtnDelete"
                                                           @click="onBtnDeleteClick">
                                                        Delete
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col cols="12" xl="8" lg="8" md="12" sm="12">
                                <v-card>
                                    <v-card-title><abbr title="CreateCustomerOrderRequest">Request</abbr></v-card-title>
                                    <v-card-text>
                                        <v-form>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" xl="12" sm="12">
                                                        <v-textarea :value="JSON.stringify(JSON.parse(failedOrder.request), null, 2)"
                                                                    color="wellis" readonly rows="15" no-resize
                                                                    @click="showContentDialog('failedOrder.request', 'CreateCustomerOrderRequest')">
                                                        </v-textarea>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-form>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                                <v-card>
                                    <v-card-title>Exception</v-card-title>
                                    <v-card-text>
                                        <v-form>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" xl="4" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.exception.type"
                                                            label="Type"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="8" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.exception.file"
                                                            label="File"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="2" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.exception.line"
                                                            label="Line"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="2" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.exception.code"
                                                            label="Code"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="12" sm="12">
                                                        <v-textarea label="Message"
                                                                    color="wellis"
                                                                    readonly
                                                                    :value="failedOrder.exception.message"
                                                                    rows="3" no-resize>
                                                        </v-textarea>
                                                    </v-col>
                                                    <v-col cols="12" xl="12" sm="12">
                                                        <v-textarea label="TraceString"
                                                                    color="wellis"
                                                                    readonly
                                                                    :value="failedOrder.exception.traceString"
                                                                    rows="10" no-resize
                                                                    @click="showContentDialog('failedOrder.exception.traceString', 'Exception trace')">
                                                        </v-textarea>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-form>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                                <v-card>
                                    <v-card-title>
                                        <v-row>
                                            <v-col cols="12" xl="6" lg="12" sm="12">
                                                Order details
                                            </v-col>
                                            <v-col cols="12" xl="6" lg="12" sm="12">
                                                <v-btn class="float-lg-right" color="wellis" text :disabled="!changed" large @click="update">
                                                    <v-icon>mdi-content-save</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-form>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" xl="3" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.order.customer_no"
                                                            label="Customer No"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="3" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.order.company"
                                                            label="Company"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="3" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.order.firstname"
                                                            label="Firstname"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="3" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.order.lastname"
                                                            label="Lastname"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12" xl="3" sm="12">
                                                        <v-text-field
                                                            append-icon="mdi-pencil"
                                                            v-model="failedOrder.order.email"
                                                            label="E-mail address"
                                                            color="wellis"
                                                        ></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12" xl="3" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.order.contract"
                                                            label="Contract"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="3" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.order.ifs_authorize_code"
                                                            label="IFS Authorize code"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="3" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.order.customer_group"
                                                            label="Customer Group"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12" xl="3" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.order.payment_method"
                                                            label="Payment method"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="3" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.order.billing_address_id"
                                                            label="Billing address ID"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="3" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.order.shipping_method"
                                                            label="Shipping method"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="3" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.order.shipping_address_id"
                                                            label="Shipping address ID"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12" xl="3" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.order.temp_shipping ? 'TRUE' : 'FALSE'"
                                                            label="Temporary shipping"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="3" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.order.temp_shipping_country || '-'"
                                                            label="Temporary shipping Country"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="3" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.order.temp_shipping_zip  || '-'"
                                                            label="Temporary shipping Zip Code"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="3" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.order.temp_shipping_city  || '-'"
                                                            label="Temporary shipping City"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="3" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.order.temp_shipping_address1  || '-'"
                                                            label="Temporary shipping Address line 1"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="3" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.order.temp_shipping_address2  || '-'"
                                                            label="Temporary shipping Address line 2"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="3" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.order.shipping_service_id  || '-'"
                                                            label="Shipping Service"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="3" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.order.lang  || '-'"
                                                            label="Language"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="3" sm="12">
                                                        <v-text-field
                                                            :value="failedOrder.order.currency  || '-'"
                                                            label="Currency"
                                                            color="wellis"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xl="9" sm="12">
                                                        <v-textarea append-icon="mdi-pencil"
                                                                    label="Note text"
                                                                    color="wellis"
                                                                    v-model="failedOrder.order.note_text"
                                                                    rows="5"></v-textarea>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-form>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                                <v-card>
                                    <v-card-title>Order items</v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-simple-table>
                                                <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>ID</th>
                                                    <th>Campaign</th>
                                                    <th>Catalog No</th>
                                                    <th>Type</th>
                                                    <th>Name</th>
                                                    <th>Quantity</th>
                                                    <th>Discount</th>
                                                    <th>Unit price</th>
                                                    <th>Price</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="orderItem in failedOrder.order.items" :key="orderItem.id"
                                                    :class="orderItem.deleted_at !== null ? 'text-line-through' : ''">
                                                    <td>
                                                        <v-menu>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn text
                                                                       rounded
                                                                       icon
                                                                       color="wellis"
                                                                       v-bind="attrs"
                                                                       v-on="on">
                                                                    <v-icon>mdi-dots-horizontal</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <v-list>
                                                                <v-list-item>
                                                                    <v-btn v-if="orderItem.configuration !== '*'"
                                                                           class="ma-2"
                                                                           outlined
                                                                           width="100%"
                                                                           :disabled="orderItem.deleted_at !== null"
                                                                           @click="viewConfiguration(orderItem)"
                                                                           color="wellis">
                                                                        View configuration
                                                                    </v-btn>
                                                                </v-list-item>
                                                                <v-list-item>
                                                                    <v-btn class="ma-2"
                                                                           outlined
                                                                           width="100%"
                                                                           :disabled="orderItem.deleted_at !== null"
                                                                           color="wellis"
                                                                           @click="openAttributeEditorDialog(orderItem.id)">
                                                                        Open attribute editor
                                                                    </v-btn>
                                                                </v-list-item>
                                                                <v-list-item>
                                                                    <v-btn v-if="orderItem.deleted_at === null"
                                                                           class="ma-2"
                                                                           outlined
                                                                           width="100%"
                                                                           color="wellis"
                                                                           @click="removeOrderLine(orderItem.id)">
                                                                        Remove line
                                                                    </v-btn>
                                                                    <v-btn v-else
                                                                           class="ma-2"
                                                                           outlined
                                                                           width="100%"
                                                                           color="wellis"
                                                                           @click="undoRemovedOrderLine(orderItem.id)">
                                                                        Undo removed line
                                                                    </v-btn>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-menu>
                                                    </td>
                                                    <td>#{{orderItem.id}}</td>
                                                    <td>{{orderItem.campaign}}</td>
                                                    <td class="font-weight-bold wellis--text">{{orderItem.catalog_no}} {{orderItem.configuration !== '*' ? '*' : ''}}</td>
                                                    <td>{{orderItem.type}}</td>
                                                    <td class="wellis--text font-weight-bold">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <span v-on="on" v-bind="attrs">{{orderItem.name | cut(30)}}</span>
                                                            </template>
                                                            <span>{{orderItem.name}}</span>
                                                        </v-tooltip>
                                                    </td>
                                                    <td class="font-weight-bold text-center wellis--text">{{orderItem.quantity}}</td>
                                                    <td class="font-weight-bold text-center wellis--text">{{orderItem.discount > 0 ? orderItem.discount + '%' : '-'}}</td>
                                                    <td>{{(orderItem.unit_price_net / orderItem.quantity) | toPrice(failedOrder.order.currency, failedOrder.order.vat)}}</td>
                                                    <td>{{orderItem.unit_price_net | toPrice(failedOrder.order.currency, failedOrder.order.vat)}}</td>
                                                </tr>
                                                </tbody>
                                            </v-simple-table>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <!-- Content Dialog -->
        <v-dialog v-model="contentDialog"
                  fullscreen>
            <v-card>
                <v-toolbar dark
                           color="wellis">
                    <v-btn icon
                           @click="contentDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{contentDialogTitle}}</v-toolbar-title>

                    <v-btn icon
                           dark
                           @click="contentToClipboard">
                        <v-icon>mdi-content-copy</v-icon>
                    </v-btn>

                </v-toolbar>
                <v-card-text class="mt-5">
                    <pre v-html="resolveContentDialogContent">
                    </pre>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Attribute Editor -->
        <FailedOrderItemAttributeEditor :target="resolveAttributeEditorTarget" :visible="attributeEditorVisibility"
                                        @close="attributeEditorDialog = false"/>

        <!-- View configuration -->
        <v-dialog
            :value="configurationDialog"
            max-width="650px"
            @click:outside="configurationDialog = false">
            <v-card>
                <v-card-title>Configuration</v-card-title>
                <v-card-text>
                    <v-row v-for="item in configurationData.items" :key="item.optionValueId" dense>
                        <v-col cols="12" sm="12" md="12" lg="6" class="font-weight-bold">{{item.description}}</v-col>
                        <v-col cols="12" sm="12" md="12" lg="6" class="font-weight-bold wellis--text">{{findConfigurationValueDesc(item)}}</v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import {EVENT_PAGE_TITLE_CHANGE} from "../../../../core/constants";
import OrdersApiService from "../../../../api/orders.api";
import {__clone, PrettyJson} from "../../../../helpers";
import FailedOrderItemAttributeEditor from "./FailedOrderItemAttributeEditor";

export default {
    name: "AdminFailedOrderDetails",
    components: {FailedOrderItemAttributeEditor},
    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE, 'components.administration.failed-orders.concrete'), 100);

        this.fetch(this.$route.params.id);
    },

    data() {
        return {
            mainOverlay: true,
            loadingOverlay: true,

            contentDialog: false,
            contentDialogTarget: false,
            contentDialogTitle: null,
            contentDialogContent: null,

            attributeEditorDialog: false,
            attributeEditorTarget: null,

            configurationDialog: false,
            configurationData: {
                configNo: null,
                items: []
            },

            failedOrder: null,
            originalFailedOrder__: null
        }
    },

    methods: {
        fetch(failedOrderId) {
            return new Promise((resolve, reject) => {
                this.loadingOverlay = true;
                OrdersApiService.failed.concrete(failedOrderId)
                    .then(response => {
                        this.failedOrder = response.data;
                        this.originalFailedOrder__ = __clone(this.failedOrder);
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                        this.$snackBar({
                            icon: 'mdi-alert',
                            text: this.$t('api-error')
                        });
                        this.$router.push({name: 'app.admin.orders.failed'});
                    })
                    .finally(() => {
                        this.mainOverlay = false;
                        this.loadingOverlay = false;
                    });
            });
        },

        update() {
            this.loadingOverlay = true;
            OrdersApiService.failed.update(this.failedOrder.order.id, this.resolveAttributes(this.failedOrder))
                .then(() => this.originalFailedOrder__ = __clone(this.failedOrder))
                .catch(() => {
                    this.$snackBar({
                        icon: "mdi-alert",
                        text: this.$t('whoops')
                    });
                })
                .finally(() => {
                    this.mainOverlay = false;
                    this.loadingOverlay = false;
                });
        },


        contentToClipboard() {
            let data = null;
            if (this.contentDialogTarget === 'failedOrder.request') {
                data = JSON.stringify(JSON.parse(this.failedOrder.request), null, 2);
            } else {
                const __get = require('lodash.get');
                data = __get(this, this.contentDialogTarget);
            }
            navigator.clipboard.writeText(data);
        },

        showContentDialog(target, title) {
            this.contentDialogTarget = target;
            this.contentDialogTitle = title;
            this.contentDialog = true;
        },

        resolveAttributes(object) {
            return {
                email: object.order.email,
                noteText: object.order.note_text,
                items: object.order.items.map(item => {
                    return {
                        objId: item.id,
                        uniqueId: item.unique_id,
                        itemData: item.item_data,
                        configuration: item.configuration
                    }
                })
            }
        },

        removeOrderLine(orderLineId) {
            this.loadingOverlay = true;
            OrdersApiService.failed.removeOrderLine(this.failedOrder.order.id, orderLineId)
                .then(async () => await this.fetch(this.failedOrder.id))
                .catch(() => {
                    this.$snackBar({
                        icon: 'mdi-alert',
                        text: this.$t('whoops')
                    });
                })
                .finally(() => {
                    this.loadingOverlay = false;
                });
        },

        undoRemovedOrderLine(orderLineId) {
            this.loadingOverlay = true;
            OrdersApiService.failed.undoRemovedOrderLine(this.failedOrder.order.id, orderLineId)
                .then(async () => await this.fetch(this.failedOrder.id))
                .catch(() => {
                    this.$snackBar({
                        icon: 'mdi-alert',
                        text: this.$t('whoops')
                    });
                })
                .finally(() => {
                    this.loadingOverlay = false;
                });
        },

        onBtnRestartClick() {
            this.loadingOverlay = true;
            OrdersApiService.failed.restart(this.failedOrder.id)
                .then(() => {
                    this.$snackBar({
                        icon: "mdi-check",
                        html: this.$t('components.administration.failed-orders.resend-success')
                    });
                })
                .catch(() => {
                    this.$snackBar({
                        icon: 'mdi-alert',
                        text: this.$t('whoops')
                    });
                })
                .finally(async () => {
                    this.loadingOverlay = false;
                    await this.fetch(this.failedOrder.id);
                });
        },

        onBtnDeleteClick() {
            this.loadingOverlay = true;
            OrdersApiService.failed.inactivate(this.failedOrder.id)
                .then(() => {
                    this.$snackBar({
                        icon: 'mdi-check',
                        text: this.$t('components.administration.failed-orders.inactivation-success')
                    });
                })
                .catch(() => {
                    this.$snackBar({
                        icon: 'mdi-alert',
                        text: this.$t('whoops')
                    });
                })
                .finally(async () => {
                    this.loadingOverlay = false;
                    await this.fetch(this.failedOrder.id);
                });
        },

        onBtnNotificationClick() {
            this.loadingOverlay = true;
            OrdersApiService.failed.notifications(this.failedOrder.id)
                .then(() => {
                    this.$snackBar({
                        icon: "mdi-check",
                        html: this.$t('components.administration.failed-orders.notification-success')
                    });
                })
                .catch(() => {
                    this.$snackBar({
                        icon: 'mdi-alert',
                        text: this.$t('whoops')
                    });
                })
                .finally(async () => {
                    this.loadingOverlay = false;
                    await this.fetch(this.failedOrder.id);
                });
        },

        openAttributeEditorDialog(orderItemId) {
            this.attributeEditorTarget = orderItemId;
            this.attributeEditorDialog = true;
        },

        viewConfiguration(orderItem) {
            this.configurationData.items = Object.values(JSON.parse(orderItem.configuration));
            this.configurationDialog = true;
        },

        findConfigurationValueDesc(item) {
            let value = item.values.find(x => item.selectedValue === x.optionValueId);
            return value ? value.description : item.selectedValue;
        },
    },

    computed: {

        resolveJsonString() {
            if (!this.failedOrder) {
                return null;
            }

            return PrettyJson.print(JSON.parse(this.failedOrder.request));
        },

        resolveContentDialogContent() {
            if (this.contentDialogTarget === null) {
                return '';
            }

            if (this.contentDialogTarget === 'failedOrder.request') {
                return this.resolveJsonString;
            }

            const __get = require('lodash.get');
            return __get(this, this.contentDialogTarget);
        },

        changed() {
            return this.failedOrder.objstate === 'HANGING' &&
                JSON.stringify(this.resolveAttributes(this.failedOrder)) !== JSON.stringify(this.resolveAttributes(this.originalFailedOrder__));
        },

        resolveBtnDelete() {
            return this.failedOrder.objstate === 'HANGING';
        },

        resolveBtnRestart() {
            return (this.failedOrder.phase.startsWith('[1.0]')
                    || this.failedOrder.phase.startsWith('[2.0]'))
                && !this.failedOrder.order.ifs_order_no && this.failedOrder.objstate === 'HANGING';
        },

        resolveBtnNotification() {
            return this.failedOrder.order.ifs_order_no && this.failedOrder.objstate === 'RESOLVED';
        },

        resolveAttributeEditorTarget() {
            if (!this.attributeEditorTarget) {
                return {};
            }
            return this.failedOrder.order.items.find(x => x.id === this.attributeEditorTarget);
        },

        attributeEditorVisibility() {
            return this.attributeEditorDialog;
        }
    }
}
</script>

<style scoped>

</style>
