import BackendService from "../core/backend/backend.service";

const FaultReportApi = {
    async findProduct(orderNo = null, invoiceNo = null, catalogDesc = null, catalogNo = null ) {
        return BackendService.query('fault-report/find-product', {
            order_no: orderNo,
            catalog_no: catalogNo,
            catalog_desc: catalogDesc,
            invoice_no: invoiceNo
        });
    },

    async getCountries() {
        return BackendService.get('fault-report/get-countries');
    },

    async create(data) {
        return BackendService.post('fault-report', data);
    }
}

export default FaultReportApi;


