export const BACKEND_SERVER = {
    url: process.env.VUE_APP_API_URL,
    headers: {
        auth: 'x-app-authorization',
        lang: 'x-app-language'
    }
};

export const CONFIGURATOR = {
    VALID_HOSTS: ['product', 'stock-fifo', 'stock-eta', 'shopord'],
    conversion: [
        {key: 'product', value: 'PRODUCT'},
        {key: 'stock-fifo', value: 'STOCK_FIFO'},
        {key: 'stock-eta', value: 'STOCK_ETA'},
        {key: 'shopord', value: 'SHOPORD'},
    ]
}

export const VAT_MULTIPLIER = 1.27;
