<template>
    <v-dialog
        v-model="visible"
        fullscreen
        hide-overlay
        persistent
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar
                dark
                color="wellis"
            >
                <v-btn
                    icon
                    dark
                    @click="$parent.$parent.closeConfigurator()"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{$t('components.cart.configurator.title', {catalogNo: catalogNo})}}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="mt-5">
                <v-overlay :value="loadingOverlay" absolute color="white">
                    <v-progress-circular
                        color="wellis"
                        indeterminate
                        size="64"
                    ></v-progress-circular>
                </v-overlay>

                <v-container fluid>
                    <v-row>
                        <v-col cols="12" lg="4" md="12" sm="12" v-for="(confItem, confIndex) in configuration" :key="confIndex">
                            <div>
                                <v-select
                                    :readonly="confItem.readOnly"
                                    autocomplete="off"
                                    :name="confItem.characteristicId"
                                    :value="confItem.selectedValue"
                                    v-on:change="triggerConfigurationUpdate($event, catalogNo, partNo, confItem.characteristicId)"
                                    :items="confItem.values"
                                    item-value="optionValueId"
                                    item-text="description"
                                    :placeholder="$t('please-select')"
                                    :prepend-icon="confItem.readOnly ? 'mdi-lock-outline' : 'mdi-lock-open-variant-outline'"
                                    color="wellis"
                                    item-color="wellis"
                                >
                                    <template v-slot:label>
                                        <div v-if="!confItem.readOnly">
                                            <div class="wellis--text" v-if="confItem.selectedValue !== null">{{confItem.description}}</div>
                                            <div class="preorder" v-else>{{confItem.description}}</div>
                                        </div>
                                        <div v-else>{{confItem.description}}</div>
                                    </template>
                                </v-select>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import ConfiguratorApiService from "../../../api/configurator.api.service";
import CartApiService from "../../../api/cart.api";

export default {
    name: "CartItemConfigurator",
    props: {
        visible: Boolean,
        cartId: Number,
        parentId: Number,
        quantity: Number,
        catalogNo: String,
        partNo: String,
        configuration: Object,
        price: Object
    },

    data() {
        return {
            loadingOverlay: false,
        }
    },

    methods: {
        close() {
            this.$emit('close');
        },

        async triggerConfigurationUpdate(val, catalogNo, partNo, name) {
            this.loadingOverlay = true;

            ConfiguratorApiService.configure(catalogNo, partNo, name, val, this.configuration, true)
                .then(response => {
                    CartApiService.updateConfiguration(this.cartId, response.data.configuration, response.data.price)
                        .then(r => {
                            this.$parent.$parent.setConfiguration(response.data.configuration);
                            this.$parent.$parent.setPrice(response.data.price);
                            if(r.data.modifiedChildren.length > 0) {
                                r.data.modifiedChildren.forEach(item => this.$parent.$parent.updateItem(item.id, item));
                            }
                        })
                }).finally(() => this.loadingOverlay = false);
        },
    }
}
</script>

<style scoped>

</style>
