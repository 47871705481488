<template>
    <v-container>
        <v-card light elevation="2">
            <v-overlay :value="loadingOverlay" absolute z-index="999">
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
            <v-card-text class="pa-10">
                <div class="subheading text-center text-h5 color-wellis font-weight-bold mb-5">
                    <template v-if="source === 'RESET_PASSWORD'">{{ $t('v31.reset-password.title') }}</template>
                    <template v-if="source === 'USER_PASSWORD_EXPIRED'">
                        {{ $t('v31.login.errors.USER_PASSWORD_EXPIRED') }}
                    </template>
                </div>
                <v-form ref="resetPasswordForm" lazy-validation @submit="submitHandler" v-model="formValid">
                    <v-text-field v-if="source === 'RESET_PASSWORD'"
                                  color="wellis"
                                  outlined
                                  :label="$t('components.login.inputs.username.placeholder')"
                                  :rules="[v => !!v || this.$t('v3.mandatoryField')]"
                                  required
                                  v-model="username"/>
                    <v-text-field color="wellis"
                                  outlined
                                  type="password"
                                  required
                                  autocomplete="new-password"
                                  :label="$t('v31.reset-password.new-password')"
                                  :rules="[v => !!v || this.$t('v3.mandatoryField')]"
                                  v-model="password"/>
                    <v-text-field color="wellis"
                                  outlined
                                  autocomplete="new-password"
                                  type="password"
                                  required
                                  :label="$t('v31.reset-password.password-confirm')"
                                  :rules="[v => !!v || this.$t('v3.mandatoryField'), v => v === password || this.$t('v31.reset-password.errors.pass-confirm')]"
                                  v-model="passwordConfirmation"/>

                    <div v-if="source === 'RESET_PASSWORD'">
                        <label>{{ $t('v31.reset-password.reset-code') }}</label>
                        <v-otp-input color="wellis" v-model="cResetCode" :length="6"/>
                    </div>
                    <v-btn class="mt-5" block color="wellis-text-white" type="submit" :disabled="!isReady">{{
                            $t('btnSend')
                        }}
                    </v-btn>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="wellis" text @click="() => $router.push({name: 'app.guest'})">{{
                        $t('v31.reset-password.backToLogin')
                    }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import AuthApi from '../../api/auth.api';
import JwtService from '../../core/backend/jwt.service';

export default {
    name: 'ResetPassword',

    created() {
        JwtService.removeToken();
        this.username = this.$route.query.u || null;
        this.resetCode = this.$route.query.c || null;

        this.source = this.$route.query.source || 'RESET_PASSWORD';
        if (!this.validSources.includes(this.source)) {
            this.$ApplGeneral({
                content: this.$t('v31.reset-password.errors.invalid-source-type'),
                onClose: function() {
                    this.$router.push({
                        name: 'app.guest',
                    });
                },
            });
        }
    },

    data() {
        return {
            loadingOverlay: false,

            source: null,
            validSources: ['RESET_PASSWORD', 'USER_PASSWORD_EXPIRED'],

            formValid: true,
            gateway: 'FND_USER',
            username: null,
            password: null,
            passwordConfirmation: null,
            resetCode: null,
        };
    },

    methods: {
        submitHandler(event) {
            event.preventDefault();
            this.validate().then(() => {
                this.loadingOverlay = true;
                AuthApi.resetPassword(this.username, this.resetCode, this.password).then(() => {
                    this.$ApplGeneral({
                        content: this.$t('v31.reset-password.success'),
                        onClose: () => {
                            this.$router.push({
                                name: 'app.guest',
                            });
                        },
                    });
                }).catch(error => {
                    let content = this.$t('whoops');
                    if (error.response.data.exception) {
                        if (error.response.data.exception.class === 'AuthenticationException') {
                            content = this.$t('v31.reset-password.errors.' + error.response.data.message);
                        }

                        if (error.response.data.exception.class === 'ValidationException') {
                            content = '';
                            if (error.response.data.payload) {
                                Object.values(error.response.data.payload).forEach(field => {
                                    Object.values(field).forEach(msg => {
                                        content += msg + '<br>';
                                    });
                                });
                            }
                        }

                    }
                    this.$ApplGeneral({
                        content: content,
                    });
                }).finally(() => {
                    this.loadingOverlay = false;
                });
            });
        },

        validate() {
            return new Promise(resolve => {
                if (this.$refs.resetPasswordForm.validate()) {
                    resolve();
                }
            });
        },
    },

    computed: {
        cResetCode: {
            get: function() {
                return (this.resetCode || '').toUpperCase();
            },

            set: function(value) {
                this.resetCode = value;
            },
        },

        isReady() {
            return (this.resetCode || '').length === 6;
        },
    },
};
</script>

<style scoped>

</style>
